var moment = require("moment-timezone")
import {DATABASE_NUMERIC_DATATYPE,FORMATTED_NUMERIC} from '../data/metaData.js';
import {OEPRATOR_TO_EDC_OPERATOR} from '../constants/constants.js'
export function isNumericDataType(data_type=''){
	return data_type && DATABASE_NUMERIC_DATATYPE.indexOf(data_type.toLowerCase()) >=0
}

export function isFormattedNumeric(columntype=''){
	return columntype && FORMATTED_NUMERIC.indexOf(columntype.toUpperCase()) >=0
}

export function replaceText(targetText='',textToReplace='',replaceWith=''){
	if(targetText!=0 && !targetText)
		return ''
	// number to string
	targetText = targetText + ""
	if (!textToReplace)
		return targetText.replace(/[^0-9]/g, replaceWith)
	else{
		while (targetText.includes(textToReplace)){
		targetText = targetText.replace(textToReplace,replaceWith)
		}
	}
	return targetText
}

export function separate_operator(targetText='', operator= false){
	if(!targetText)
		return ''
	// saparate operator from value
	targetText =  targetText.toString()
	if (targetText.includes('%')){
		targetText = targetText.replace('%', '')
		var op = '%'
	}
	else{
		var op_1 = ""+ targetText.substring(0, 2)
		var op_2 =""+ targetText.substring(0, 1)

		if(OEPRATOR_TO_EDC_OPERATOR[op_1]){
			targetText = targetText.replace(op_1, '')
			var op = op_1
		}
		else if(OEPRATOR_TO_EDC_OPERATOR[op_2]){
			targetText = targetText.replace(op_2, '')
			var op = op_2
		}
	}
	//when operator is true return operator
	if (operator)
		return op
	//return targetText when operator is false
	return targetText
}

export function stripDateFormat(targetText){
	if(!targetText)
		return ''
	// saparate operator from value
	if (targetText.includes('/')){
		/,/g, '-'
		targetText = targetText.replaceAll('/', '')

	}
	else if(targetText.includes('-')){
		targetText = targetText.replaceAll('-', '')
	}

	return targetText
}

export function removeNonNumericText(textVal){
	if(!textVal)
		return "0"
	// convert textVal to string in case number came
	textVal = textVal+''
	let newTextVal = textVal.replace(/\D/g,'')
	if(!newTextVal)
		return "0"
	else
		return newTextVal
}

export function expliteConversionOfData(textValue){
	return "DECODE(REPLACE(TRANSLATE(TRIM(\'+textValue+\'),'1234567890','##########'),'#')"
}