<template>
    <div>
        <v-card class="pa-0">
            <v-card-title class="widget-card-heading widget-heading-standard d-flex justify-space-between">{{widget_action}} Widget
                <svgicon name="cancel_v2"
                class="svg-icon-grid svg-fill-grid EDC-GridIcon justify-end" @click="close"/>
            </v-card-title>
            <v-card-text class="pa-2">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col d-flex justify-start widget-form-titles text--grey text-truncate mb-2">
                        Widget Details
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <!-- <v-autocomplete  class="edc-control" item-text="object_name" item-value="object_id"
                        :items="widgetList" dense outlined hide-details @input="getWidgetDetails"
                        v-model="selectedWidget" label="Select Widget" :disabled="isEdit"/> -->
                            <!-- Show <v-autocomplete> when not in edit mode -->
                        <v-autocomplete :disabled="isEdit"
                        class="edc-control pa-2 pb-1" 
                        item-text="object_name" 
                        item-value="object_id"
                        :items="widgetList" 
                        dense 
                        outlined 
                        hide-details 
                        @input="getWidgetDetails"
                        v-model="selectedWidget" 
                        :label="isEdit?'Selected Widget':'Select Widget'" 
                        />

                    </v-col>
                </v-row>
                <v-row class="EDC-Row pa-2">
                    <v-col class="EDC-Col">
                            <v-text-field 
                            class="edc-control" 
                            dense 
                            outlined 
                            hide-details 
                            v-model="widgetName" 
                            label="Enter Widget Name" 
                            />

                    </v-col>
                </v-row>
                <!-- <v-divider class="EDC-Divider my-2"/>
                <v-row class="EDC-Row" v-if="UIComponents.length">
                    <v-col class="EDC-Col d-flex justify-start">
                        Params
                    </v-col>
                </v-row> -->
                <template v-for="(item,ind) in UIComponents">
                    <v-row class="EDC-Row pa-2" :key="item.id+'ind'+ind" >
                        <v-col cols="12" class="EDC-Col">
                            <edc-chart-opt v-if="item.type === 'edcchartoptions'" :key="item.id+'uicotrol'+ind"
                            :componentDetails="item"
                            @updateModelData="updateUIModelData"
                            :parentSelectedValues="allComponentSelectedValues"
                            />
                            
                            <search-engine v-else-if="item.type === 'edcserachoption'"
                            :key="item.id+'uicotrol'+ind"
                            @updateModelData="updateUIModelData"
                            :parentSelectedValues="allComponentSelectedValues"
                            :componentDetails="item"/>

                            <object-selection v-else-if="item.type ==='edcobjectselection'"
                            :key="item.id+'uicotrol'+ind"
                            @updateModelData="updateUIModelData"
                            :parentSelectedValues="allComponentSelectedValues"
                            :componentDetails="item" :isAsComponent="true"/>

                            <task-list-selection v-else-if="item.type ==='edctasklist'"
                            :key="item.id+'uicotrol'+ind"
                            @updateModelData="updateUIModelData"
                            :parentSelectedValues="allComponentSelectedValues"
                            :componentDetails="item"/>

                            <custom-component v-else :key="item.id+'uicotrol'+ind"
                            :componentDetails="item"
                            @updateModelData="updateUIModelData"
                            :parentSelectedValues="allComponentSelectedValues"
                            />
                        </v-col>
                    </v-row>
                </template>
                <v-divider class="full-page-divider my-2" v-if="UIComponents.length"></v-divider>
                <!-- <v-divider class="EDC-Divider my-2" v-if="UIComponents.length"/> -->
                <v-row class="EDC-Row" v-if="hasModifiers">
                    <v-col class="EDC-Col d-flex justify-start widget-form-titles mb-2">
                        Filters
                    </v-col>
                </v-row>
                <template v-for="(item,ind) in modifierComponents">
                    <v-row class="EDC-Row pa-1" :key="item.id+'modifierind'+ind">
                        <v-col class="EDC-Col" cols="12">
                            <custom-component :key="item.id+'modfiercotrol'+ind"
                            :componentDetails="item"
                            @updateModelData="updateModifierModelData"/>
                        </v-col>
                    </v-row>
                </template>
                <v-divider class="EDC-Divider my-2" v-if="hasModifiers"/>

                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <vc-button itemText="Save" :disabled="disabledSave"
                        @click.native="onSave"/>
                        <vc-button itemText="Cancel" @click.native="close"/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<style scoped>
    .edc-control >>> .v-input__slot{
        min-height: 24px !important;
    }

</style>
<script>
    import config from '../../../config.json'
    import {get as getToServer,post as postToServer} from "../../../methods/serverCall"
    import customComponent from "./controlComponents.vue"
    import edcChartOptionsComponent from './edcChartOptionsComponent.vue';
    import searchEngineSelection from './searchEngineSelection.vue';
    import objectSelections from './objectSelections.vue';
    import taskListSelection from './taskListSelection.vue';
    export default{
        name:"WidgetSelection",
        components:{
            'custom-component':customComponent,
            'edc-chart-opt':edcChartOptionsComponent,
            'search-engine':searchEngineSelection,
            'object-selection':objectSelections,
            'task-list-selection':taskListSelection
        },
        props:{
            Environment:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            widgetJsonForEdit:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            selectedWidgetIdForEdit:{
                type:String,
                default:""
            },
            selectedWidgetName:{
                type:String,
                default:""
            }
        },
        data(){
            return{
                selectedWidgetTitle: "",
                widgetListObject:{},
                selectedWidget:"",
                widgetObjectJson:{},
                widgetDataObject:{},
                widgetName:"Untitled Widget"
            }
        },
        computed:{
            userName(){
                return this.$session.get("email")
            },
            clientId(){
                return this.$session.get("client_id")
            },
            widget_action(){
                if(this.isEdit){
                    return "Update"
                }
                return "Add"
                
            },
            widgetList(){
                let widgetList = []
                console.log("this.widgetListObject",this.widgetListObject)
                if(this.widgetListObject.UWGT && this.widgetListObject.UWGT.widgets &&this.widgetListObject.UWGT.widgets.length){
                    widgetList = this.widgetListObject.UWGT.widgets
                }
                    
                if(this.widgetListObject.PWGT && this.widgetListObject.PWGT.widgets &&this.widgetListObject.PWGT.widgets.length){
                    widgetList =  [...widgetList, ...this.widgetListObject.PWGT.widgets]
                }
                
                // console.log("widgetList",widgetList)
                return widgetList
            },
            // Check if the widget is selected by checking if widgetObjectJson is not empty
            isWidgetSelected() {
                return !_.isEmpty(this.widgetObjectJson);
            },
            // Return widget properties or an empty object if the widget is not selected
            widgetProperties() {
                return this.isWidgetSelected ? this.widgetObjectJson : {};
            },
            // Extract the widget type or return "N/A" if properties are missing
            widgetType() {
                return _.get(this.widgetProperties, 'widgetType.itemValue', '');
            },
            isChartWidget(){
                return this.widgetType === 'CHART'
            },
            // UI settings object extracted from widget properties, fallback to empty object
            uiSettingObject() {
                return _.get(this.widgetProperties, 'uiSettings', {});
            },
            UIComponents(){
                return _.get(this.widgetProperties, 'uiComponents', []);
            },
            modifierComponents(){
                return _.get(this.widgetProperties, 'modifiers', []);
            },
            allComponentSelectedValues(){
                let allSelectedValues = {}
                if(!this.UIComponents.length)
                    return allSelectedValues
                for(var i=0;i<this.UIComponents.length;i++){
                    let currentComponent = this.UIComponents[i]
                    if(!_.isEmpty(currentComponent.selectedValue))
                        allSelectedValues[currentComponent.key] = {
                            "selectedValue":currentComponent.selectedValue
                        }
                }
                return allSelectedValues
            },
            hasModifiers(){
                return this.modifierComponents.length > 0
            },
            allModifierSelectedValues(){
                let allModifierValues = {}
                if(!this.hasModifiers)
                    return allModifierValues
                for(let i=0;i<this.modifierComponents.length;i++){
                    let currentModifier = this.modifierComponents[i]
                    allModifierValues[currentModifier.key] = currentModifier.selectedValue
                }
                return allModifierValues
            },
            disabledSave(){
                // return _.isEmpty(this.allComponentSelectedValues)
                return false
            },
            isEdit(){
                return !_.isEmpty(this.widgetJsonForEdit)
            }
        },
        watch:{
            'widgetJsonForEdit':{
                handler(newValue){
                    this.manageEdit()
                }
            }
        },
        mounted(){
            this.manageEdit()
            this.getObjectList(config.REPOSITORY_API_URL+"/get_all_widgets",'widgetListObject')
        },
        methods:{
            manageEdit(){
                if(this.isEdit){
                    this.selectedWidget = this.selectedWidgetIdForEdit
                    if(this.selectedWidgetName)
                        this.widgetName = this.selectedWidgetName
                    this.getWidgetDetails()
                }
            },
            getObjectList(url,controlToBind){
                var _this = this
                getToServer(_this,url).then(response=>{
                    this[controlToBind] = response
                }).catch(error=>{

                })
            },
            close(){
                this.$emit("close")
            },
            getWidgetDetails(){
                var _this = this
                this.widgetObjectJson = {}
                this.widgetDataObject = {}
                var data ={"widget_id":this.selectedWidget,"env_id":this.Environment.id,
                "user_name":this.userName}
                var url = config.REPOSITORY_API_URL+"/get_widget_by_id"
                postToServer(_this,url,data).then(response=>{
                    this.widgetObjectJson = response.widget_data
                    this.selectedWidgetTitle = response.widget_name ? response.widget_name: ""
                    console.log("this.widgetObjectJson",this.widgetObjectJson)
                    if(_this.isEdit){
                        _this.mapValuesForEdit()
                    }
                }).catch(error=>{
                    alert(error)
                })
            },
            updateUIModelData(componentKey,modelValue){
                this.bindModelData(this.UIComponents,componentKey,modelValue)
            },
            bindModelData(controlList,componentKey,modelValue){
                let currentControl = _.find(controlList,["key",componentKey])

                if(currentControl && (modelValue || modelValue === false)){
                    currentControl['selectedValue'] = modelValue
                }
            },
            onSave(){
                // incase of untitled
                let widgetName = !this.widgetName?'Untitled Widget':this.widgetName
                this.$emit("onSaveWidget",this.widgetObjectJson,this.selectedWidget, 
                widgetName,
                this.isEdit,this.allComponentSelectedValues,this.allModifierSelectedValues)
            },
            mapValuesForEdit(){
                var _this = this
                let alreadySelectedUIValues = this.widgetJsonForEdit.uiComponents
                for(let i=0;i<alreadySelectedUIValues.length;i++){
                    let key = alreadySelectedUIValues[i].key
                    let selectedValues = alreadySelectedUIValues[i].selectedValue
                    this.bindModelData(this.UIComponents,key,selectedValues)
                }
                let alreadySelectedModifierValues = this.widgetJsonForEdit.modifiers || []
                for(let i=0;i<alreadySelectedModifierValues.length;i++){
                    let key = alreadySelectedModifierValues[i].key
                    let selectedValues = alreadySelectedModifierValues[i].selectedValue
                    this.bindModelData(this.modifierComponents,key,selectedValues)
                }
            },
            updateModifierModelData(modifierKey,modelValue){
                this.bindModelData(this.modifierComponents,modifierKey,modelValue)
            }
        }

    }
</script>