<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col" align="left">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="8" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL" style="padding-left:0px !important;">
                            <li>
                                <select id="headerenvselect" v-model="selected_env" v-on:change="onEnvironmentChange();setEnv()" style="-webkit-appearance: menulist" autofocus ref="envname">
                                    <option :value="{}" id="nonselectenv">Select Environment</option>
                                    <option v-for="(env, index) in EnvironmentList" :id="'envnum'+env.index" :value="env" :key="index">{{env.env_name}}</option>
                                </select>
                            </li>
                            <li>Object Based Security Report</li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
        <v-row no-gutters class="EDC-Row EDC-GridRow">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row EDC-GridRow">
                    <v-col class="EDC-Col">
                        <v-card class="bigCard overflow-y-auto style-2scroll">
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col titledata">
                                    <v-row class="EDC-Row EDC-GridRow">
                                        <v-col class="EDC-Col" cols="2" align="left">
                                            Select User/User Group
                                        </v-col>
                                        <v-col class="EDC-Col" cols="2">
                                            <v-autocomplete hide-details item-text="display_name" item-value="uniqueid" v-model="selectUserId" label="Select User/User Group" :items="UserandGroupList" dense @change="onUserOrGroupChange()"></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col" cols="4" align='left' v-if="leftItems">
                                            <v-card class="smallCard " v-for="(item, index) in leftItems" :key="index+'html_content'">
                                                <div class="custom-padding">
                                                    <span v-html=item.html_content></span>
                                                </div>
                                            </v-card>
                                        </v-col>
                                        <v-col class="EDC-Col" cols="4"  align='left' v-if="rightItems && rightItems.length">
                                            <v-card class="smallCard " v-for="(item, index) in rightItems" :key="index+'html_content'">
                                                <div class="custom-padding">
                                                    <span v-html=item.html_content></span>
                                                </div>
                                            </v-card>
                                        </v-col>
                                        <v-col class="EDC-Col" cols="4"  align="left" v-if="mappedRolesData | mappedGroupsData">
                                            <v-row class='EDC-Row' v-if="mappedRolesData">
                                                <v-col class="EDC-Col">
                                                    <v-card class="smallCard">
                                                        <div class="custom-padding">
                                                            <v-row class="EDC-Row">
                                                                <v-col class="EDC-Col">
                                                                    <span style="font-weight: bold; margin-left:14px !important; margin-right: 10px;">Assigned Roles to User</span>
                                                                    <ul style="margin-left:14px !important;">
                                                                        <li v-for="(record, index) in mappedRoles" :key="index+'mappedrole'">
                                                                            <span class='link-span' @click="openInNewTab({ name: 'ManageObjBasedRole', query: { 
                                                                            'role_id': record.role_id, 
                                                                            'role_name':record.role, 
                                                                            'role_type': record.role_type, 
                                                                            'env_id':record.env_id, 
                                                                            'env_name':record.env_name,
                                                                            'read_only_mode':true } })" >{{ record.role }}</span>
                                                                        </li>
                                                                    </ul>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row class='EDC-Row' v-if="mappedGroupsData && !isUserGroup">
                                                <v-col class="EDC-Col">
                                                    <v-card class="smallCard">
                                                        <div class="custom-padding">
                                                            <v-row class="EDC-Row">
                                                                <v-col class="EDC-Col">
                                                                    <span style="font-weight: bold; margin-left:14px !important; margin-right: 10px;">Groups Linked to User</span>
                                                                    <ul style="margin-left:14px !important;">
                                                                        <li v-for="(record, index) in mappedGroups" :key="index+'mappedrole'">
                                                                            <span class='link-span' @click="openInNewTab({ name: 'manageusergroup', query: { 
                                                                                    'groupid': record.group_id, 'type':'edit', 'read_only_mode':true } })" >{{ record.group_name }}</span>
                                                                        </li>
                                                                    </ul>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row class='EDC-Row' v-if="groupMemberList.length > 0 && isUserGroup">
                                                <v-col class="EDC-Col">
                                                    <v-card class="smallCard">
                                                        <div class="custom-padding">
                                                            <v-row class="EDC-Row">
                                                                <v-col class="EDC-Col">
                                                                    <span style="font-weight: bold; margin-left:14px !important; margin-right: 10px;">Users in Group</span>
                                                                    <ul style="margin-left:14px !important;">
                                                                        <li v-for="(record, index) in groupMemberList" :key="index+'mappedrole'">
                                                                            <span class='link-span' @click="openInNewTab({ name: 'manageusergroup', query: { 
                                                                                    'groupid': record.group_id, 'type':'edit', 'read_only_mode':true } })" >{{ record.full_name_with_user_name }}</span>
                                                                        </li>
                                                                    </ul>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbarTimeout" id="edcmessage" top right>
                    {{ snackbarText }}
                </v-snackbar>
                <loading-panel :loader="loader"></loading-panel>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .radioClass >>> label{
        top: 3px !important;
    }

    .EDC-GridRow {
        padding-top: 12px !important;
        padding-bottom: 8px !important;
    }

    .headerCardProps{
        padding:8px !important;
    }

    .autoCompletePadding{
        padding-right:4px !important;
    }
    .cardTitle{
        font-weight: bold;
        font-size: 12px;
        padding:8px !important;
    }
    .smallCard{
        /* height: 24vh !important; */
        margin-top:8px !important;
        margin-left:8px !important;
        margin-right:8px !important;
        margin-bottom:0px !important;
    }
    .bigCard{
        height: 76vh !important;
        /* margin-top:4px !important; */
        margin-left:8px !important;
        margin-right:8px !important;
        padding-bottom: 8px !important;
        /* margin-bottom:8px !important; */
    }
    .titledata{
        margin-top: 13px !important;
        margin-left: 10px !important;
    }
    .object_type_card{
        padding:8px !important;
        margin-top:8px !important;
        /* margin-left:14px !important; */
        /* margin-right:8px !important; */
        margin-bottom:8px !important;
    }
    .object_type_data, #object_type_data{
        margin-left:14px !important;
    }
    .link-span {
        cursor: pointer;
        border: none;
        }

    .link-span:hover {
    text-decoration: underline;
    }
    .custom-padding {
        padding-top: 6px;
        padding-bottom: 6px;
        }

</style>
<script>
    import {COLOR_CODE, CLIENT_SIDE} from '../../../data/macros.js'
    import config from "../../../config.json";
    import { SERVER_ERROR } from "../../../data/client_message.js";
    import { getEnvListForDataviewer} from '../../../methods/EnvironmentList.js'
    import {
        GET_ALL_USER
    } from './../../../data/url_constants.js';
    import { post as postToServer } from "./../../../methods/serverCall.js";
    import { get as getToServer } from "./../../../methods/serverCall.js";
    import {Snackbar_Default_Timeout} from '@/constants/constants.js';

    export default {
        name:'EdcObjectBasedSecurityReprot',
        data(){
            return{
                selected_env:{},
                EnvironmentList:[],
                selectUserId:'',
                userList:[],
                userGroupList:[],
                snackbar: false,
                snackbarText: "",
                colorValue: "error",
                snackbarTimeout: Snackbar_Default_Timeout,
                loader: false,
                mappedRoles:[],
                mappedRolesData:false,
                mappedGroups:[],
                mappedGroupsData:false,
                permissionHtml:'',
                object_wise_html_body: [],
                permissions: false,
                groupMemberList: false,
                isUserGroup: false
            }
        },
        props: {
            role_id: Number
        },
        computed:{
            UserandGroupList(){
                return this.userList.concat(this.userGroupList)
            },
            selectedUserDetails(){
                return _.find(this.UserandGroupList,['uniqueid',this.selectUserId])
            },
            leftItems() {
                if (this.object_wise_html_body){
                    return this.object_wise_html_body.slice(0, 4);
                    // return this.object_wise_html_body.filter((_, index) => index % 2 === 0);
                }else{
                    return false
                }
                    
            },
            rightItems() {
                if (this.object_wise_html_body){
                    return this.object_wise_html_body.slice(4);
                    // return this.object_wise_html_body.filter((_, index) => index % 2 !== 0);
                }else{
                    return false
                }
                    
            }

        },
        watch:{

        },
        mounted(){
            getEnvListForDataviewer(this,false)
            if(this.$session.get('selected_env')){
                this.selected_env = this.$session.get('selected_env')
            }
            this.onEnvironmentChange()
            this.getUsersList()
            this.getAllUserGroups()
        },
        methods: {
             handleSpanClick(event, role_id) {
                const elementId = event.target.id;
                console.log(`Span with role_id ${elementId} clicked!`);
                console.log(`Span with role_id ${role_id} clicked!`);
                const url = this.$router.resolve({ name: 'ManageObjBasedRole', query: {
                    role_id: role_id,
                    object_id: elementId,
                    role_type: 'object_based',
                    env_id: this.selected_env.id,
                    env_name: this.selected_env.env_name,
                    read_only_mode: true
                } }).href;
                window.open(url, '_blank');
            },
            addClickListeners() {
            this.mappedRoles.forEach(item => {
                const elementClass = `.link_span_${item.role_id}`;
                const elements = document.querySelectorAll(elementClass);
                // Iterate over the NodeList and add a click event listener to each element
                elements.forEach((element) => {
                    element.addEventListener('click', () => this.handleSpanClick(event, item.role_id));
                    element.addEventListener('mouseover', () => {
                        element.style.cursor = 'pointer';
                        element.style.textDecoration = 'underline';
                    });
                    element.addEventListener('mouseout', () => {
                        element.style.cursor = 'default';
                        element.style.textDecoration = 'none';  
                    });
                });

                // const element = document.getElementById(elementId);
                // console.log(element)
                // if (element) {
                // element.addEventListener('click', () => this.handleSpanClick(item.role_id));
                // }
                this.applyCSS()
            });
            },
            applyCSS() {
                // Define the CSS rules
                const styles = `
                    .dynamic-content {
                    color: blue;
                    font-size: 18px;
                    border: 1px solid #ccc;
                    padding: 10px;
                    }
                    .dynamic-content:hover {
                    text-decoration: underline; /* Add underline on hover */
                    }
                `;

                // Create a <style> element and append it to the document's head
                const styleSheet = document.createElement('style');
                styleSheet.type = 'text/css';
                styleSheet.innerText = styles;
                document.head.appendChild(styleSheet);
                },
            generateUrl(data) {
                debugger
                const resolved = this.$router.resolve(data);
                console.log('Resolved Route:', resolved);
                console.log('Resolved URL:', resolved.href);
                return resolved.href;
            },
            openInNewTab(data) {
            const userUrl = this.generateUrl(data);
            // window.location.href = userUrl;
            window.open(userUrl, '_blank');
            },
            setEnv(){
                this.$session.set('selected_env',this.selected_env)
            },
            onEnvironmentChange(){
                this.selectUserId = ''
                this.resetProps()
            },
            resetProps(){
                this.permissionHtml = ''
                this.object_wise_html_body = []
                this.mappedGroups=''
                this.mappedRoles = []
                this.mappedGroupsData = false
                this.mappedRolesData = false
                this.permissions = false
            },
            showErrorMessage(errorMessage){
                this.loader = false;                  
                this.colorValue = 'error'
                this.snackbarText = errorMessage || SERVER_ERROR; 
                this.snackbar = true                   
            },
            getUsersList() {
                let _this = this;
                _this.loader = true;
                var data_to_send = {'client_id': this.$session.get('client_id'), "only_normal_users": true}
                this.userList = []
                postToServer(_this, config.USER_PROVISION_URL + '/get_all_active_users', data_to_send).then(response => {
                    _this.loader = false;
                    if(response) {
                        response.map((obj)=>{
                                obj.id = obj.user_id
                                obj.name= obj.username
                                obj.uniqueid = 'usr'+obj.id
                                obj.map_type = 'user'
                                obj.display_name = obj.name +'(User)'
                            })
                        this.userList = response;
                    }
                }).catch(error_response => {
                    _this.showErrorMessage(error_response)
                })
            },
            getAllUserGroups(){
                let _this = this;
                _this.loader = true;
                var request_data = {
                    'client_id': this.$session.get('client_id'),
                }
                postToServer(_this, config.USER_PROVISION_URL + '/get_all_group_details_public', request_data).then(response => {
                    _this.loader = false;
                    if (response) {
                        response.map((obj)=>{
                            obj.id = obj.id
                            obj.uniqueid = 'usrgrp'+obj.id
                            obj.name= obj.group_name
                            obj.map_type = 'group'
                            obj.map_type = 'User Group'
                            obj.display_name = obj.group_name +'(User Group)'
                        })
                    }
                    _this.userGroupList = response
                })
                .catch(errorResponse => {
                    _this.showErrorMessage(errorResponse)
                });
            },
            onUserOrGroupChange(){
                this.resetProps()
                this.getSecurityReport()
            },
            getSecurityReport(){
                var _this = this
                if(!this.selected_env || !this.selected_env.id){
                    this.showErrorMessage("Please Select Environment")
                    return
                }
                if(!this.selectUserId){
                    this.showErrorMessage("Please Select Valid User / User Group")
                    return
                }
                let selectedUser = this.selectedUserDetails
                var data = {"map_type":selectedUser.map_type,"map_object_id":selectedUser.id, "map_object_name":selectedUser.name}
                data['product_name'] = 'pathfinder'
                data['client_id'] = this.$session.get('client_id')
                data['env_id'] = this.selected_env.id
                data['env_name'] = this.selected_env.env_name
                _this.loader = true
                postToServer(_this,config.Security_URL+'/get_security_report', data).then(response=>{
                    _this.loader = false
                    _this.mappedRoles = response.assigned_role_list
                    if(response.assigned_role_list.length>0){
                     _this.mappedRolesData = true
                    }
                    _this.mappedGroups = response.group_name_list
                    _this.groupMemberList = response.group_member_list.user_list ? response.group_member_list.user_list : false;
                    if(response.group_name_list.length>0){
                        _this.mappedGroupsData = true
                    }
                    _this.permissionHtml = response.permissions
                    _this.object_wise_html_body = response.object_wise_html_body
                    if(response.object_wise_html_body){
                        _this.permissions = true
                    }
                    if(selectedUser.map_type.toLowerCase() == "user group"){
                        this.isUserGroup = true
                    }else{
                        this.isUserGroup = false
                    }
                    setTimeout(() => {
                    console.log('Action executed after 2 seconds.');
                    this.$nextTick(() => {
                    this.addClickListeners();
                    })
                    }, 500);

                    
                }).catch(error=>{
                    _this.loader = false
                    _this.showErrorMessage(error)
                })
            },
            exportReport(downloadParams){
                var _this = this
                if(!this.tableList.rows.length)
                    return
                let inputJson = _.cloneDeep(downloadParams)
                Object.assign(inputJson,this.currentGridReqData)
                postToServer(_this, config.Security_URL+'/export_user_security', inputJson).then(response=>{
                    var url = config.Security_URL + "/static/" + response;
                    window.open(url, '_blank');
                    _this.loader=false;
                }).catch(error=>{
                    _this.showErrorMessage(error)
                })
            }
        },

    }
</script>