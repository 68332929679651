<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Erp Accelerator List</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
        
        <edc-data-grid :dataList="tableList" @onNew="addErp" @onCellEvent="onCellEvent" @onEdit="onEdit"  @onDelete="onDelete" @ondblClick="onEdit"></edc-data-grid>
        
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        
    </div>
</template>

<script>
import config from './../../config.json'
import { post as postToServer} from '../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../data/client_message.js'
import {
    CLIENT_SIDE, JDE_FORMATTER_SERVICE_ID, ERP_ACCELERATOR_MGMT
} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import getUserRole from '../../methods/GetUserRole.js'

export default {
    name: 'ErpAccelratorList',
    data: function () {
        return {
            userRole:{},
            loader: false,
            tableList: {
                headers: [{
                        text: 'Id',
                        value: 'id',
                        width: "30%",
                        title: 'ID'
                    },{
                        text: 'Name',
                        value: 'accelerator_name',
                        width: "30%",
                        title: 'ERP Name'
                    },
                    {
                        text: 'Created Date',
                        value: 'timezone_aware_created_date',
                        width: "15%",
                        title: 'Erp Created Date',
                        dataType:'date',
                        hideInlineFilter: true
                    },
                    {
                        text: 'Created Time',
                        value: 'timezone_aware_created_time',
                        width: "15%",
                        title: 'Erp Created Time',
                        dataType:'time',
                        hideInlineFilter: true
                    },
                    {
                        text: 'Created By',
                        value: 'created_by',
                        width: "10%",
                        title: 'Erp Created By'
                    }
                ],
                actions:[],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                itemkey:'id',
                selected_rows: [],
                disableDefaltSorting:true,
                hideFilter:true,
                hideExport:true,
                hideShowColumns:true
                // syncHeaderScroll:false,
            },
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
        }
    },
    
    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions = [{
                            'text': 'new',
                            'key': "id",
                            selectType:"single",
                            role:this.userRole.is_superadmin || getUserRole(this.userRole,JDE_FORMATTER_SERVICE_ID, ERP_ACCELERATOR_MGMT),
                            index:1
                        },{
                            'text': 'edit',
                            'key': "id",
                            selectType:"single",
                            role:this.userRole.is_superadmin || getUserRole(this.userRole,JDE_FORMATTER_SERVICE_ID, ERP_ACCELERATOR_MGMT),
                            index:2
                        },
                        {
                            'text': 'delete',
                            'key': "id",
                            selectType:"multiple",
                            role:this.userRole.is_superadmin || getUserRole(this.userRole,JDE_FORMATTER_SERVICE_ID, ERP_ACCELERATOR_MGMT),
                            index:3
                        }
                ], //if we need conditional action in row then provide key
        this.getErpAcceleratorList();
    },
    methods: {
        onCellEvent(record){
            this.onEdit(record);
        },
        addErp(){
            let _this = this;
            _this.$router.push('/manageErpAccelerator');
        
        },
        onDelete:function(record){
            let _this = this;
            _this.deleteAccelerator(record);
            // let inputJson = _this.envIpJson;
            // this.get_environment_by_client_id(inputJson);
        },
        onEdit:function(record){
            let _this = this;

            this.$router.push({ name: 'manageErpAccelerator', params: { 'id': record.id}})
        },
        getErpAcceleratorList() {
            let _this = this;
            _this.loader = true;
            var client_id = this.$session.get('client_id')
            var data = {"client_id": client_id}
            postToServer(_this, config.JDE_FORMATER_URL + "/get_all_accelerator" ,data).then(response => {
                _this.loader = false;
                response = _.sortBy(response,"id")
                if (response) {
                    _this.tableList.rows = response;
                    _this.tableList.total_count = response.length;
                } else {
                    _this.tableList.rows = [];
                }
            }).catch(error_response => {
                _this.loader = false;
                this.snackbar = true
                this.colorValue = 'error'
                _this.snackbartext = error_response;
            });
        },
        deleteAccelerator(record){
            let _this = this;
            var client_id = this.$session.get('client_id')
            var data_to_send = []
            _.forEach(record,function(obj){
                data_to_send.push({"id":obj.id,
                                    "deleted_by": _this.$session.get('email')})

            })
            postToServer(_this, config.JDE_FORMATER_URL + '/delete_accelerator', data_to_send).then(response => {
            // var data = JSON.parse(response.bodyText)
                this.getErpAcceleratorList()
          }, response => {
            this.loader = null
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(e => {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          })
        }
    }
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>