<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col cols="12" class="EDC-Col">
                        <ul class="breadcrumb">
                            <li>
                                <select v-model="Environment" v-on:change="getUserDashboard" style="-webkit-appearance: menulist" autofocus ref="envname">
                                    <option :value="{}">Select Environment</option>
                                    <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                                </select>
                            </li>
                            <li>Manage Dashboard</li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col pt-2">
                <component :is="componentName" v-if="componentName"
                :definedWidgets="savedWidgetList" :dashboardId="dashboardId"
                :isDesigner="false" :key="componentKey+'key'"/>
                <v-card class="dynamic-height-100" v-else>
                    <!-- Just placeholder -->
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        <loading-panel :loader="loader"></loading-panel>
    </div>
</template>
<style scoped>

</style>
<script>
    import {getDashboardOfUser} from "../methods/layoutFunc"
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import { getEnvListForDataviewer} from '../methods/EnvironmentList.js'
    import { get as getToServer } from '../methods/serverCall.js';
    import config from '../config.json'
    export default{
        name:"dashboardLoader",
        data(){
            return{
                dashboardId:"",
                snackbar:false,
                snackbartext:'',
                colorValue:'error',
                snackbartimeout: Snackbar_Default_Timeout,
                loader: false,
                savedWidgetList:[],
                Environment: {},
                EnvironmentList:[],
                componentKey:1,
                layoutList:[],
                layoutId:"LYT0001"
            }
        },
        computed:{
            userName(){
                return this.$session.get("email")
            },
            clientId(){
                return this.$session.get("client_id")
            },
            componentName(){
                if(_.find(this.layoutList,["object_id",this.layoutId]))
                    return _.find(this.layoutList,["object_id",this.layoutId]).component_name
                return ""
            },
        },
        watch:{
            'EnvironmentList':{
                handler(newValue){
                    if(_.isEmpty(this.$session.get('selected_env'))){
                        this.Environment = newValue[0]
                        this.$session.set(newValue[0])
                    }
                        
                    this.getWidgetList()
                }
            }
        },
        mounted(){
            document.documentElement.style.setProperty('--layout-main-height', '80vh')
            getEnvListForDataviewer(this,false)
            this.Environment = this.$session.get('selected_env')
            this.getWidgetList()
            
        },
        methods:{
            getWidgetList(){
                var _this = this
                var url = config.REPOSITORY_API_URL + "/get_all_layouts"
                getToServer(_this,url).then(response=>{
                    this.layoutList = response
                    this.getUserDashboard()
                }).catch(error=>{

                })
            },
            getUserDashboard(){
                var _this = this
                _this.dashboardId = ""
                _this.savedWidgetList = []
                _this.componentKey++
                if(_.isEmpty(_this.Environment))
                    return
                _this.loader = true
                getDashboardOfUser(_this,_this.Environment).then(response=>{
                    _this.dashboardId = response.dashboard_id
                    _this.layoutId = response.layout_id
                    _this.loader = false
                    _this.savedWidgetList = response.widgets
                }).catch(error=>{
                    alert(error)
                })
            }
        }
    }
</script>