import { post as postToServer } from './../serverCall.js';
import config from '../../config.json'

export function setGridviewProps(_this,response,gridObj){
    _this.gridviewProps = response.gridview_props
    _this.selectedView = _this.gridviewProps.last_selected_view
    gridObj.showDescriptionAsHeader = _this.gridviewProps.show_description_as_header
    gridObj.loadSummary = _this.gridviewProps.show_summary_row
    _this.keepFirstColFixed = _this.gridviewProps.freeze_selection_attachment_cols
}

function resetGridProps(_this){
    var gridObj = _this.tableGridObj
    _this.selectedView =''
    _this.gridviewProps = {}
    _this.keepFirstColFixed = true
    _this.keepSecondColFixed = false
    gridObj.showDescriptionAsHeader = true
    gridObj.loadSummary = false
    _this.tableRequestOtherProps =_.cloneDeep(_this.copyOftableRequestOtherProps)
    _this.resetInlineFilter++
}

export function getGridViewList(_this){
    let requestData = _this.getCommonRequestData({},false)
    requestData['user_name'] = _this.$session.get("email")
    requestData['user_id'] = _this.$session.get("user_id")
    _this.gridViews = []
    postToServer(_this, config.DATA_VIEWER_URL+'/get_all_view_mapping_by_table',requestData).then(response=>{
        _this.gridViews = response
    }).catch(error=>{

    })
}

export function customizeGridView(selectedView, action){
    var _this = this
    let columns = []
    _.forEach(_this.tableGridObj.all_headers,function(obj){
        if(obj.is_attachment_col || obj.LVL)
            return
        columns.push(obj)
    })
    
    if(selectedView === 'GV00' || action === 'add' || action === 'edit' || action === 'copy'){
        _this.objectForCustomizeView = {
            "available_columns":columns,
            "table_name":_this.tableName,
            "primary_keys":_this.primaryKeys,
            'selectedView':selectedView,
            'action':action
        }
        _this.showCustomViewer = true
        return
    }
    else if(action === 'delete'){
        resetGridProps(_this)
        unpublishGridView(_this,selectedView,'deleteAndApply')
        return
    }
    else if(selectedView === 'GV01'){
        resetGridProps(_this)
        unpublishGridView(_this,selectedView,'withoutView')
        return
    }
        // user changed the selection or user open the gridview and do changes in definition
        resetGridProps(_this)
        viewSelectionChangeCall(_this,selectedView)
        addUserViewMapping(_this,selectedView)
}

function unpublishGridView(_this,view_for_delete,typeOfCustomization){
    _this.loader = true
    let object_name = ''
    if(_this.gridViews){
        let viewObj = _.find(_this.gridViews,["object_id",view_for_delete])
        if(viewObj)
            object_name = viewObj.object_name
    }
    let data = {
        "object_id":view_for_delete,'object_name':object_name,
        "client_id":_this.clientId,"user_id":_this.userId,
        "context_id":_this.contextId,'typeOfCustomization':typeOfCustomization,
        'object_revision':'1','env_id':_this.selectedEnvironment.id,'env_name':_this.selectedEnvironment.name
    }
    _this.loader = true
    postToServer(_this,config.DATA_VIEWER_URL+'/delete_view_mapping',data).then(response=>{
        _this.loader = false
        viewSelectionChangeCall(_this,'')
    }).catch(error=>{
        _this.loader = false
        _this.showError(error)
    })
}

function viewSelectionChangeCall(_this,selectedView){
    //getTableData(fetch_count=true,otherProps={})
    getGridViewList(_this)
    _this.showCustomViewer = false
    let otherProps = {
        "gridview_id":selectedView, 
        "is_grid_view_selection_changed":true
    }
    _this.getTableData(true,otherProps)
}

function addUserViewMapping(_this,selectedView){
    var view_mapping_json = {
        "client_id":_this.clientId,"user_id":_this.userId,
        "table_name": _this.tableName,"context_id": _this.contextId,
        "env_id": _this.selectedEnvironment.id, "view_id":selectedView
    }
    postToServer(_this,config.DATA_VIEWER_URL+'/manage_user_last_viewed',view_mapping_json).then(response=>{

    }).catch(error=>{
        _this.showError(error)
    })

}
