<template>
    <div>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col px-1 py-1" :cols="12">
                <custom-control :componentDetails="selectedTaskList"
                @updateModelData="onValueChanged">
                </custom-control>
            </v-col>
            <v-col class="EDC-Col px-1 py-1" :cols="12">
                <custom-control :componentDetails="selectedCategory"
                @updateModelData="onValueChanged">
                </custom-control>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
    
</style>
<script>
    import { mixinWidget } from "../../../mixins/widget.js";
    import config from '@/config.json'
    import {get as getToServer} from "./../../../methods/serverCall.js"
    export default{
        name:"selectedTaskList",
        mixins:[mixinWidget],
        components:{

        },
        props:{
            componentDetails:{
                type:Object,
                default:()=>{
                    return {

                    }
                }
            },
        },
        data(){
            return{
                availableTaskList:[],
                categoryList:[],
                selectedTaskList:{},
                selectedCategory:{},
            }
        },
        computed:{
    
        },
        watch:{
            'availableTaskList':{
                handler(newValue){
                    this.selectedTaskList = this.bindStaticItemList(this.selectedTaskList,this.availableTaskList) 
                }
            },
            'categoryList':{
                handler(newValue){
                    this.selectedCategory = this.bindStaticItemList(this.selectedCategory,this.categoryList) 
                    if(_.isEmpty(this.componentDetails.selectedValue) || _.isEmpty(this.componentDetails.selectedValue.modifiers))
                        this.selectedCategory.selectedValue = _.map(newValue,'category_code')
                    else
                        this.selectedCategory.selectedValue = this.componentDetails.selectedValue.modifiers.category_code || []
                }
            },
        },
        mounted(){
            this.initializeComponents()
            this.getTaskList()
            this.getTaskOptions()
            this.bindSelectedValues()
        },
        methods:{
            initializeComponents(){
                let selectedTaskList = this.getDropdownComponent('selectedTaskList','object',{},'Select TaskList','','object_name')
                selectedTaskList = this.bindStaticItemList(selectedTaskList,this.availableTaskList) 
                this.selectedTaskList = selectedTaskList

                let selectedCategory = this.getDropdownComponent('selectedCategory','array',[],'Select Options','category_code','category_name')
                selectedCategory = this.bindStaticItemList(selectedCategory,this.categoryList) 
                selectedCategory['multiple'] = true
                this.selectedCategory = selectedCategory

            },
            getTaskList(){
                var url = config.PUBLISHER_URL +'/get_all_task_list_by_env'
                this.loadDropdownList(url,'availableTaskList',{"clientId":this.clientId,"envId":this.envId})
            },
            getTaskOptions(){
                var url = config.REPOSITORY_API_URL +'/get_all_task_category'
                getToServer(this,url).then(response=>{
                    this.categoryList = response
                }).catch(err=>{
                    alert(err)
                })
            },
            bindSelectedValues(){
                let selectedValues = _.cloneDeep(this.componentDetails.selectedValue)
                if(_.isEmpty(selectedValues))
                    return
                if(!_.isEmpty(selectedValues.selectedTaskList)){
                    this.selectedTaskList.selectedValue = selectedValues.selectedTaskList
                }

                if(!_.isEmpty(selectedValues.modifiers)){
                    this.selectedCategory.selectedValue = selectedValues.modifiers.category_code || []
                }
            },
            onValueChanged(key,selectedValue){
                this[key].selectedValue = selectedValue
                let emitObject ={}
                if(!_.isEmpty(this.selectedTaskList.selectedValue)){
                    emitObject = {
                        "selectedTaskList":this.selectedTaskList.selectedValue,
                        "modifiers":{
                            "category_code":this.selectedCategory.selectedValue || []
                        }
                    }
                }
                this.$emit("updateModelData",'edctasklist',emitObject)
            },
        },
    }
</script>