<template>
    <div>
        
        <loading-panel  :loader="loader"></loading-panel>
    </div>
</template>
<script>
import {mapActions} from 'vuex'
    import config from '../../config.json'
    import { post as postToServer } from '../../methods/serverCall.js';
    export default{
        name:"WidgetLander",
        data(){
            return{
                widgetData:{},
                loader:false
            }
        },
        mounted(){
            let widgetKey = this.$route.query.widgetkey
            let widgetData = this.$cookies.get(widgetKey)
            this.$cookies.remove(widgetKey)
            if(!widgetData){
                alert("Widget data not found")
                return
            }
            console.log("widgetdata",widgetData)
            this.widgetData = widgetData
            this.fetchDataDetails()
        },
        methods:{
            ...mapActions(['setDataviewerProps']),
            fetchDataDetails(){
                var _this = this
                var data = {
                    "env_id":this.$session.get("last_selected_env_id"),
                    "client_id":this.$session.get("client_id"),
                    "user_id":this.$session.get("email"),
                    "object_id":this.widgetData.objectlist.selectedValue.object_id,
                    "datasource_id":this.widgetData.datasource.selectedValue.datasource_id,
                    "datasource_index":this.widgetData.datasource.selectedValue.datasource_index,
                    "object_type":this.widgetData.objectlist.selectedValue.object_type,
                    "object_name":this.widgetData.objectlist.selectedValue.object_name
                }
                
                _this.loader = true
                postToServer(this,config.GATEWAY_API_URL+'/get_object_details',data).then(response=>{
                    _this.loader = false
                    let dataObject = response
                    console.log("response=>",response)
                    let envDetails = {"env_id":_this.$session.get("last_selected_env_id"),"id":_this.$session.get("last_selected_env_id")}
                    var dataToPass = {
                        'selected_env':envDetails,
                        'selected_ds':dataObject.selected_ds,
                        'selected_bo_tbl':dataObject.selected_bo_tbl,
                        'dictionary_data':dataObject.selected_ds.dictionary_data,
                        'object_librarian_data':dataObject.selected_ds.object_librarian_data,
                        'control_data':dataObject.selected_ds.control_data,
                        'ds_list_for_backend':dataObject.ds_list_for_backend,
                        'EnvironmentList':[envDetails],
                        'isSuperAdmin':true,
                        'securityModelByEnv':[],
                        'ds_list':dataObject.ds_list,
                        'acceleration_type':dataObject.selected_ds.acceleration_type,
                        'whichSide':"wholepage",
                        'isArchivistCall':false,
                        'archivistObj':{},
                        "BV_list":[dataObject.selected_bo_tbl],
                        "taskLoad":true
                    }

                    _this.loader = false
                    // setTimeout(()=>{
                        _this.setDataviewerProps(_.cloneDeep(dataToPass))                    
                    // },1000)
                    this.$router.push("/edcpathfinder")

                }).catch(error=>{
                    alert(error)
                })
            }
        }
    }
</script>