<template>
	<div>
		<v-flex xs12 style="margin-left:15px;margin-right:15px;margin-top:5px;">
			<v-row class="EDC-Row" v-if="isGridForDataviewer">
				<v-col cols="9" class="EDC-Col text-truncate" align="left" v-if="accessError">
					<span class="EDC-Access-Denied">{{accessError}}</span>
				</v-col>
				<v-col class="EDC-Col text-truncate" align="left" v-else-if="dataListHasHeaders">
					<span>
						<span class="EDC-expansion-header" :title="dataList.associatedBusinessObjectName 
						|| dataList.tableDescription">
						{{dataList.tableDescription}} <span v-if="dataList.stepType">{{dataList.stepType}} </span>
						</span>
						<!-- <span v-if="dataList.datasourceName"> - {{dataList.datasourceName}} </span> -->
						<span>
							<v-menu slot="activator" right v-model="gridHeaderAdditionalInformation" :close-on-content-click="false"
								:nudge-width="300">
								<template v-slot:activator="{ on }">
									<svgicon v-on="on" class="svg-icon-grid svg-fill-grid" name="job_details_v2"  title="More Information"></svgicon>
								</template>
								<v-card elevation="0">
									<v-row class="EDC-Row">
										<v-col class="EDC-Col">
											<v-toolbar dense flat tile class="EDC-Toolbar"> <v-toolbar-title  style="width:100% !important;">More Information</v-toolbar-title></v-toolbar>
										</v-col>
									</v-row>
									<v-row class="EDC-Row">
										<v-col class="EDC-Col" style="padding:8px !important;"  align="start">Datasource Name:</v-col>
										<v-col class="EDC-Col" style="padding:8px !important;" align="start"><b>{{dataList.datasourceName || 'N/A'}}</b></v-col>
									</v-row>
									<v-row class="EDC-Row">
										<v-col class="EDC-Col" style="padding:8px !important;" align="start" v-if="dataList.stepType">Worktable Name:</v-col>
										<v-col class="EDC-Col" style="padding:8px !important;" align="start" v-else>Business Object Name:</v-col>
										<v-col class="EDC-Col" style="padding:8px !important;" align="start"><b>{{dataList.associatedBusinessObjectName || 'N/A'}}</b></v-col>
									</v-row>
								</v-card>
							</v-menu>
						</span>
					</span>

				</v-col>
				<v-col class="EDC-Col text-truncate" align="left" v-else cols="9"></v-col>
				<v-col  cols="3" class="EDC-Col" style="padding-top:4px !important;padding-bottom:4px !important;" v-if="!dataList.hideActions && dataList.showCustomizationOpt && dataListHasHeaders" align="left">
					<v-row class="EDC-Row">
						<v-col class="EDC-Col" v-show="!isCompressed">
							<v-row class="EDC-Row">
								<v-col class="EDC-Col" cols="8">
									<v-autocomplete solo dense v-model="selectedView" item-value="object_id" item-text="object_name" :items="availableViewList" autocomplete="off" hide-details @input="viewSelectionChanged">
									</v-autocomplete>
								</v-col>
								<v-col class="EDC-Col" style="padding-left:4px !important;" v-if="showGridView">
									<v-row class="EDC-Row">
										<v-col class="EDC-Col">
											<svgicon class="svg-icon-grid svg-fill-grid" name="add_v2"  title="Create New View" @click="selectedView='GV00';clickOnViewIcons('add')" v-if="!showViewOptions"></svgicon>
										</v-col>
									</v-row>
									<v-row class="EDC-Row">
										<v-col class="EDC-Col" v-if="isGridViewEditable">
											<v-menu slot="activator" right v-model="gridViewOptions" :close-on-content-click="false" v-if="showViewOptions">
												<template v-slot:activator="{ on }">
													<svgicon v-on="on" class="svg-icon-grid svg-fill-grid" name="More"  title="More Options"></svgicon>
												</template>
												<v-card elevation="0">
													<v-row class="EDC-Row">
														<v-col class="EDC-Col" align="start" style="padding:4px !important;">
															<v-row class="EDC-Row">
																<v-col class="EDC-Col" cols="4" align="start">
																	<svgicon class="svg-icon-grid svg-fill-grid" name="document_edit_v2" v-if="showViewOptions && isGridViewEditable" title="Edit View" @click="clickOnViewIcons('edit')"></svgicon>
																</v-col>
																<v-col class="EDC-Col" cols="4" align="start">
																	<svgicon class="svg-icon-grid svg-fill-grid" name="copy_v2"  v-if="showViewOptions" title="Copy View" @click="clickOnViewIcons('copy')"></svgicon>
																</v-col>
																<v-col class="EDC-Col" cols="4" align="start">
																	<v-menu slot="activator" left v-model="unPublish" :close-on-content-click="false"
																	:nudge-width="200" offset-x  v-if="showViewOptions && isGridViewEditable">
																		<template v-slot:activator="{ on }">
																			<svgicon v-on="on" class="svg-icon-grid svg-fill-grid" name="document_unpublish_v2"  title="Unpublish View"></svgicon>
																		</template>
																		<confirmation-panel heading="UnPublish Object" label="Are you sure want to UnPublish?"
																			lblSuccess="Un-Publish" @onCancel="unPublish = false"
																			@onSuccess="clickOnViewIcons('delete');unPublish = false">
																		</confirmation-panel>
																	</v-menu>
																</v-col>
															</v-row>
														</v-col>
													</v-row>
												</v-card>
											</v-menu>
										</v-col>
										<v-col class="EDC-Col" v-else-if="showViewOptions">
											<svgicon class="svg-icon-grid svg-fill-grid" name="copy_v2"  v-if="showViewOptions" title="Copy View" @click="clickOnViewIcons('copy')"></svgicon>
										</v-col>
									</v-row>
								<!-- <template v-slot:append> -->

									
								</v-col>								
							</v-row>
						</v-col>
						
					</v-row>
				</v-col>
				
				<v-col class="EDC-Col" cols="2" align="end"></v-col>
				<v-col class="EDC-Col" cols="1" align="end" style="left:28px !important;top:-16px !important;">
					<!-- <svgicon class="svg-icon-grid svg-fill-grid" name="cancel_v2"  title="Create New View" @click="$emit('closeGrid',dataList.unique_id)"></svgicon> -->
					<v-btn color="primary" dark top right x-small style="height:24px !important;width:24px !important;" fab @click="$emit('closeGrid',dataList.unique_id)" title="Close Grid">
                		<v-icon color="white">mdi-close</v-icon>
              		</v-btn>
				</v-col>
			</v-row>
			<v-card v-if="dataList.headers.length > 0" pl-1 pr-1>
				<v-container class="EDC-Container EDC-GridHeaderContainer" v-if="!dataList.hideActions || !dataList.hideFilter || !dataList.hideExport || !dataList.hideShowColumns">
					<v-row no-gutters align-content="center">
						<v-col cols="3" v-if="!dataList.hideActions">
							<v-row no-gutters style="padding-left:14px !important;">
								<svgicon v-if="showAddNew && !selected.length" title="Add New" :id="addNewId" class="EDC-GridIcon svg-icon-grid svg-fill-grid" name="add_v2" :original="true" @click="createEvent('record', 'onNew')"></svgicon>
								
								<svgicon v-if="showAttachment && !selected.length" title="Add Attachment" class="EDC-GridIcon svg-icon-grid svg-fill-grid" name="attachment" :original="true" @click="createEvent('record', 'onAddAttachment')"></svgicon>

								<v-menu  bottom absolute transition="scale-transition" v-if=" getNewObjWidOpt && newWidOption && newWidOption.role && !selected.length" close-on-content-click close-on-click>
									<template v-slot:activator="{ on }">
										<svgicon style="display: inline-flex !important;"  class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="add_v2" :original="true" v-on="on"></svgicon>
									</template>
									<v-list>
										<template v-for="(item, index) in newMenu">
											<v-list-item  dense  v-if="item.role"
											:key="index" @click="createEvent('record', item.event)" style="cursor:pointer">
											<v-list-item-subtitle class="EDC-ListItem" style="text-align:left !important;">{{item.title}}</v-list-item-subtitle>
											</v-list-item>
										</template>
									</v-list>
								</v-menu>
								<v-menu  bottom absolute transition="scale-transition" v-if="dataList.isEnvironmentalBasedRole 
								&& !selected.length " close-on-content-click close-on-click>
									<template v-slot:activator="{ on }">
										<svgicon style="display: inline-flex !important;" id="addnewrole" class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="add_v2" :original="true" v-on="on"></svgicon>
									</template>
									
									<v-list>
										
										<template v-for="(item, index) in newEnvBasedRoleMenu">
											<v-list-item  dense  v-if="item.role" :id="item.id"
											:key="index" @click="createEvent('record', item.event)" style="cursor:pointer">
											<v-list-item-subtitle class="EDC-ListItem" style="text-align:left !important;">{{item.title}}</v-list-item-subtitle>
											</v-list-item>
										</template>
<!-- 
										<v-list-item  dense v-for="(item, index) in newEnvBasedRoleMenu"
										:key="index" @click="createEvent('record', item.event)" style="cursor:pointer">
										<v-list-item-subtitle class="EDC-ListItem" style="text-align:left !important;">{{item.title}}</v-list-item-subtitle>
									</v-list-item> -->
									</v-list>
								</v-menu>
								<svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-if="selected.length > 0 && dataList && dataList.showContextMenu && dataList.children && dataList.children.length" 
								:original="true" name="context_menu" @click="rowClick"></svgicon>
								<action-panel :key="ActionPanelremountTime" :selectedArray="selected" @createEvent="createEvent" :data="gridActionObject" @onCancelEditing="onCancelEditing"></action-panel>
							</v-row>
						</v-col>
						<v-col align="start" v-if="dataList.showCustomizationOpt">
							<template v-for="(item,index) in selectChips">
								<v-chip v-if="!dataList.hideFilter || isGridForDataviewer"  :key="item" color="primary" style="margin-top: 3px !important; margin-right: 2px !important; margin-left: 2px !important;float:left;" @click:close="chipDeleted(item,index)" small close>{{item}}</v-chip>
							</template>
						</v-col>
						<v-col v-else >
							
						</v-col>
						<v-col cols="2" align="end" v-if="!dataList.hideFilter || !dataList.hideExport || !dataList.hideShowColumns">
								<svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="search_v2" slot="activator" :original="true" @click="displayCriteriaPopUp()" v-if="!dataList.hideFilter" title="Apply Filter"></svgicon>

								<action-panel @onDownload="onDownload" :showDownload="!dataList.hideExport" v-if="!dataList.hideExport" :selectedArray="selected" :downloadLimit="downloadLimit">

								</action-panel>
							<v-menu offset-y class="EDC-GridColumnHide" close-on-click :close-on-content-click='false'>
								<template v-slot:activator="{ on }">
									<v-icon  v-on="on" class="EDC-GridIcon" title="Show / Hide Columns" id="smallicon" v-if="!dataList.hideShowColumns">settings_ethernet</v-icon>
									<!-- <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="columns_show_hide_v2" slot="activator" :original="true"  v-if="!dataList.hideShowColumns"title="Hide/Show Columns"></svgicon> -->

								</template>
								<v-card class="EDC-VCardColumn">
									<v-combobox :items="allHeaders" multiple :menu-props="{ maxHeight: '300' }" hide-details v-model="visibleCols" item-text="text" item-value="text" @change="hideDataGridColumns" class="EDC-ControlPadding">
										<template v-slot:selection="{ item, index }">
											<span v-if="index === 0">{{ item.text }}</span>
											<span v-if="index === 1">(+{{ visibleCols.length - 1 }} Cols)</span>
										</template>
									</v-combobox>
								</v-card>
							</v-menu>
						</v-col>
					</v-row>
				</v-container>
			<v-row class="EDC-Row">
				<v-col class="EDC-Col">
					<v-data-table dense v-model="selected" :multi-sort="!dataList.singleSort"
						:headers="dataList.headers"
						:items="rowsToDisplay"
						:item-key="dataList.itemkey"
						:page="page"
						:items-per-page="perPage"
						:server-items-length="dataList.total_count"
						:show-select="!dataList.hideSelect"
						class="elevation-1" :class="{'fixed-first-col':isFirstColFixed,'fixed-second-col':isSecondColFixed}" 
						hide-default-footer
						:loading="dataList.tableLoading"
						@page-count="pageCount = $event" @update:options="updateTableOptions" :id="dataList.unique_id||'edcGrid'">

						<template v-for="(h,i) in dataList.headers" v-slot:[`header.${h.value}`]="{ header }">
							<v-tooltip top :key="'h'+i">
								<template v-slot:activator="{ on }">
									<span v-on="on" @mouseover="showTooltip = false" v-if="h.is_attachment_col">
										<svgicon class="svg-icon-inline svg-fill-grid" name="attachment" slot="activator" :original="true" title="Attachment Available"></svgicon>
									</span>
									
									<span v-on="on" @mouseover="showTooltip = false" v-else>{{h.displayHeader || h.text}} <span v-if="h.func"> ({{h.func.charAt(0).toUpperCase() + h.func.slice(1)}})</span></span>
								</template>
								<span>{{getColTooltips(h.text,h)}}</span>
							</v-tooltip>
						</template>
						<template v-slot:body="{ items }">
							<tbody style="cursor:pointer; height: auto !important" @mouseleave="showTooltip = false">
								<tr  v-if="showInlineFilter " @mouseover="showTooltip = false">
									<td  v-if="!dataList.hideSelect" class="text-align:left;" style="background:white;">
										<v-col class="EDC-Col"  cols="" align-self="left" >
											<svgicon class="svg-icon-inline svg-fill-grid" v-if="inlineFilterList.length > 0" style="display:grid;margin: 12%" :original="true" name="delete_v2" @click="onCleareAllInlineFilter()"></svgicon>
										</v-col>
									</td>
									
									<td v-for="(h,i) in dataList.headers" :key="i+'fitlerheader'" style="text-align:left;" :class="{'hideCol':h.align===hideColProp}">
										<v-row class="EDC-Row" v-if="h.useRadio || h.showRadio && h.showDeleteInlineFilterIcon && h.hideInlineFilter || h.hideFilter">
											<v-col class="EDC-Col" align-self="left">
												<svgicon class="svg-icon-inline svg-fill-grid" v-if="inlineFilterList.length > 0" style="display:grid;margin: 15%" :original="true" name="delete" @click="onCleareAllInlineFilter()"></svgicon>
											</v-col>
										</v-row>
										<v-row class="EDC-Row" v-else>
											<v-col class="EDC-Col" align="end">
												<v-text-field autocomplete="off" v-if="!h.hideInlineFilter" clearable rounded :id="i+'inlineFilter'" :label="getInlineFilterHelpText(h)" :title="getInlineFilterHelpText(h)"
												type="text" v-model="h.filtertext" @keypress.enter.prevent="applyInlineFilterForAll($event,h, h.filtertext,dataList.headers)" hide-details dense @click:clear="clearInlineFilter($event,h,h.filtertext,dataList.headers)" @keyup.prevent="keyUpInlineFilter($event,h, h.filtertext,dataList.headers)" @mouseover="showTooltip = false"></v-text-field>

										<!-- <input  v-if="!h.hideInlineFilter" style="border-radius:5px !important;border:1px solid; padding-left: 5px;" type="text" v-model="h.filtertext" @keypress.enter.prevent="applyInlineFilter($event,h, h.filtertext)" v-focus width="25px"/> -->
										<!-- <v-span v-else></v-span> -->
											</v-col>
											<v-col class="EDC-Col" cols="2" v-if="h.advanceFilter" align="start">
												<svgicon class="svg-icon-inline svg-fill-grid" name="search_v2" slot="activator" :original="true" :id="i+'inlineFilterIcon'" @click="onClickAdvanceFilter(h)" style="transform: rotate(90deg);" :title="h.advanceFilterFor"></svgicon>
											</v-col>
											<v-col class="EDC-Col" cols="1" v-if="showFilterInformation(h)" align="end">
												<!-- <svgicon class="svg-icon-inline svg-fill-grid" name="job_details_v2" slot="activator" :original="true" :id="i+'inlineFilterIcon'" @click="onClickAdvanceFilter(h)" 
												:title="getFilterInformationTitle(h)"></svgicon> -->
												<v-tooltip bottom max-width="360">
      												<template v-slot:activator="{ on, attrs }">
														<span v-bind="attrs" v-on="on">
															<svgicon class="svg-icon-inline svg-fill-grid"  name="job_details_v2" :original="true" :id="i+'inlineFilterIcon'"></svgicon>
														</span>
													</template>
													<span v-html="getFilterInformationTitle(h)"></span>
												</v-tooltip>

											</v-col>
										</v-row>
									</td>
								</tr>
								<tr v-if="!dataList.rows || dataList.rows.length === 0">
									<td :colspan="dataList.headers.length" class="EDC-NoRecordText">{{NoDataFound}}</td>
								</tr>
								<tr  v-for="(item,index) in items" :key="'item'+index" @dblclick="rowDoubleClick(item,index)" @contextmenu.prevent="rowClick($event,item,index)">
									<template v-if="dataList.rows && dataList.rows.length > 0">
										<td v-if="!dataList.hideSelect"  :id="'tdchk'+index">
											<v-checkbox v-model="selected" :id="'dttblechk'+index" :value="item" style="margin:0px;padding:0px" hide-details color="primary-lighten2" @change="checkboxSelectionChange" :disabled="isCompressed"></v-checkbox>
										</td>

										<td v-for="(header,headerindex) in dataList.headers" :id="'r'+index+'c'+headerindex" :style="{'text-align':header.align|| 'left'}"   :key="'header'+headerindex" :title="item['formatedTitle']|| item[header.display_value]  || item[header.gridDisplayTextTitleKey] || item[header.value]" :class="{'hideCol':header.align===hideColProp}" @mouseover="mouseEnterOnTd($event,header,item[header.value],item)" @mouseleave="mouseLeaveTd" @click="copySelectedRowsToClipboard($event)">

											<svgicon v-if="header.is_attachment_col && item['has_attachment']" class="svg-icon-inline svg-fill-grid" name="attachment" slot="activator" :original="true" :id="index+'attachment'" @click="loadMediaAttachmentDetails(index,item.attachment_details)" title="Attachment Available"></svgicon>

											<!-- v-progress-circular in td -->
											<v-progress-circular v-else-if="header.useProgress"
											:rotate="-90"
											:width="2"
											:size="24"
											color="primary"
											:value="item[header.value]"
											><span style="font-size:10px !important;">{{item[header.value]}}</span>
											</v-progress-circular>

											<!-- v-chip in td -->
											<v-chip style="margin-top:1px;margin-bottom:1px;" v-else-if="header.useChip && item[header.value] != 'Closed'"  :color="getColor(header.chipFor,item[header.value])" small dark>{{getFormatedData(header,item[header.value])}}</v-chip>

											<!-- v-checkbox in td -->
											<v-checkbox v-else-if="header.useCheckbox && item.is_row_editable" v-model="item[header.value]" :value="item[header.value]" style="margin:0px;padding:0px" hide-details color="primary-lighten2" :indeterminate="item.useIndeterminateState" @change="checkChanged($event,header,item)" :disabled="setDisabled(header,item)"></v-checkbox>

											<span :id="getDefaultId(header, headerindex, index)" v-else-if="header.useAutoComplete && item.is_row_editable">
												<!-- v-autocomplete in td . It should not be 'clearable'.If you keep clearable it will cause the problem in add policy-->
												<v-autocomplete :id="getDefaultId(header, headerindex, index)"  hide-details="auto" v-model="item[header.value]" :multiple="header.useMultiple || false" :disabled="header.disabled" :items="header.option" dense :item-text="header.item_text"  :title="getAutocompleteTitleText(header, item[header.value])" :label="header.label || 'select'" :item-value="header.item_value" @input="changeAutoCompleteSelection($event,item,header,headerindex,index)"></v-autocomplete>
											</span>
											<!-- v-autocomplete in td with ondemand . It should not be 'clearable'.If you keep clearable it will cause the problem in add policy-->
											<v-autocomplete v-else-if="header.isTableLoader && item.is_row_editable" hide-details="auto" v-model="item[header.value]"  :items="header.option" dense :item-text="header.item_text" :label="header.label || 'select'" :item-value="header.item_value" @input="changeAutoCompleteSelection($event,item,header,headerindex,index)" :loading="dataList.tableLoading" :search-input.sync="search" v-on:keyup="performTableSearch($event,search,header.option)" ref="cachedAutoComplete"></v-autocomplete>

											<edc-advance-autocomplete v-else-if="header.useAdvanceAutoComplete && item.is_row_editable" 
                                                                :header="header" :key="header.value+index" :selectedValue="item[header.value]" 
                                                                :isAllSelected="item[header.selectAllKey] || false"
                                                                @changeAutoCompleteSelection="changeAdvanceItemSelection($event,item,header,headerindex,index)" 
																@performSearch="performSearch($event, header, item)" :uniqueId="getDefaultId(header, headerindex, index)"
                                                                >
                                                                </edc-advance-autocomplete>

											<!--Edc calender in td. Keep this above the textbox. Because on policy date if user select actual date policy value type then this should get displayed-->
											<edc-calender :input="item[header.value]" @update="setDate(item, header.value, ...arguments)"
											v-else-if="item.is_row_editable && ((item.value_type === 'Actual Date' && header.useTextField) || header.useCalender)" :desne="true"> </edc-calender> 

											<!-- v-textbox in td -->
											<v-text-field v-else-if="header.useTextField && item.is_row_editable" hide-details="auto" v-model="item[header.value]" dense class="EDC-TextField" @keypress="validateInput($event,header.inputType)" :disabled="header.disabled" @focus="AddRowForGridEditing(item)" @blur="AddRowForGridEditing(item)" autocomplete="off" :label="header.label"></v-text-field>

											<v-radio-group v-model="single_selected_row"  hide-details dense v-else-if="header.useRadio" @change="radioSelectionChange(item)">
												<v-radio class="radioClass" :color="colorCode" :value="item" hide-details dense>
													
												</v-radio>
											</v-radio-group>
											<!-- normal td -->
											<span v-else-if='header.LVL' @click="copyToClipboard($event,header)" class='keep-spaces'>{{getIndentedLVL(item[header.value])}}</span>

											<span v-else-if='header.avoidTrim' @click="copyToClipboard($event,header)" class='keep-spaces'>{{getFormatedData(header,item[header.value],item,header.is_key_json)}}</span>

											<span v-else-if="header.is_merge_col">{{getDatasoureName(item,header)}}</span>
							
											<span v-else-if="header.display_value" @click="copyToClipboard($event,header)" class='singleLine'>{{getFormatedData(header,item[header.display_value],item,header.is_key_json)}}</span>

											<span v-else-if='header.gridDisplayTextKey' :title="item[header.gridDisplayTextTitleKey] || item[header.gridDisplayTextKey]"> {{item[header.gridDisplayTextKey] || item[header.value]}} </span>

											<span v-else @click="copyToClipboard($event,header)" class='singleLine'>{{getFormatedData(header,item[header.value],item,header.is_key_json)}}</span>
										</td>
									</template>
								</tr>
								<tr  v-if="showSummaryFooter">
									<td v-if="!dataList.hideSelect" class="text-align:left;">
									
									</td>
									<td v-for="(h,i) in dataList.headers" :key="i+'inlinefooterheader'" style="text-align:left;" :class="{'hideCol':h.align===hideColProp}">
										<v-row class="EDC-Row">
											<v-col class="EDC-Col">
												<v-text-field autocomplete="off" v-if="getSummaryFooterValue(h)" :id="i+'inlinefooter'" type="text" :value="getSummaryFooterValue(h)" class="right_aligned_text summary-text-field" readonly hide-details dense :title="h.summary_func"></v-text-field>
											</v-col>
											
										</v-row>
									</td>
								</tr>
							</tbody>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		<v-container style="max-height:50px;padding:0px;" v-if="showTableFooter">
			<v-row no-gutters dense xs12>
				<v-col cols="4" class="EDC-DashboardCol">
					<v-row no-gutters v-if="!dataList.hideItemPerPage">
						<v-col cols="8" style="margin-left:5%;" v-if="!isCompressed">
							<v-select :items="perPageArray" hide-details v-model="perPage" style="width:50px;padding:0px;" @input="onPerPageChange"></v-select>
							<span style="position: absolute;left:60px;margin-top:-22px;" class="EDCTableFooter">Rows</span>
						</v-col>
						<v-cols v-else style="padding-top:64% !important;padding-bottom:16% !important;">
							<!-- This is dummy space added for isCompressed-->
							<v-label ></v-label>
						</v-cols>
						<v-col cols="3">
							<!-- <span style="position: absolute;left: 0%;margin-top:5%;" class="EDCTableFooter">Rows</span> -->
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="4" class="EDC-DashboardCol" align-self="center">
					<span class="EDCTableFooter" v-if="!dataList.hideRowInfo && rowsToDisplay.length > 0">
					<span id="rownostartfrom">{{ (page - 1) * perPage + 1 }} </span> - <span id="rownoendson"> {{ (page - 1) * perPage + rowsToDisplay.length }} </span> of <span id="totalrows"> {{ getReadableNumber }}</span>
					<span v-if="dataList.showPlus"> + </span> <span v-if="dataList.showFetchedInTimer">{{dataList.fetchTimeDetails}}</span> </span>
					<span class="EDCTableFooter" v-else-if="!dataList.hideRowInfo">
						0 - 0 of 0
					</span>
					<!-- <span v-if="showActualRowCountTotal"> (Total - {{dataList.actualRowCount}})</span> -->
				</v-col>
				<v-col cols="4" class="EDC-DashboardCol" justify="end" align="end" align-self="end" v-if="!dataList.hidePagination">

					<v-pagination style="justify-content:flex-end !important;float:right;" v-model="page" :length="pageCount" :total-visible="pageNumberTotalVisible" @input="onPageChange"></v-pagination>

				</v-col>
			</v-row>
		</v-container>
	</v-card>
</v-flex>
<v-layout>

	<v-dialog v-model="showCriteria"  eager style="overflow-y:none" persistent>
		<v-col>
			<v-card class="rounded-card" style="height: 325px;">
				<v-toolbar dark dense>
					<v-col class="text-md-center">Filters
						<v-icon class="text-lg-left" @click="showCriteria = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
					</v-col>
				</v-toolbar>
				<v-col pa-2 xs12 style="max-height: 225px; overflow-y: scroll;" id="step_detail_table">
					<new-edc-criteria :columns_for_filter="updatedFilter"  :column_name_list="columnList" @savedata="manageCriteria" :new_columns_for_filter="main_filterUI"></new-edc-criteria>
				</v-col>
				<vc-button outline itemText="Apply"  @click.native="saveCriteria">
				</vc-button>
			</v-card>
		</v-col>
	</v-dialog>
</v-layout>

<v-menu
      v-model="showMenu"
      :position-x="menuPositionX"
      :position-y="menuPositionY"
      absolute
      offset-y
    >
      <v-list>
		<v-list-item
          v-for="(item, index) in contextSelfDSMenuList"
          :key="item.node_id+'self'"
          @click="contextMenuClick(item)" dense
        >
          <v-list-item-title dense>{{item.display_name}}</v-list-item-title>
        </v-list-item>

		<v-divider style="margin-top:0px !important;margin-bottom:0px !important;" v-if=" contextSelfDSMenuList.length &&contextMenuList.length"></v-divider>
        <v-list-item
          v-for="(item, index) in contextMenuList"
          :key="item.node_id"
          @click="contextMenuClick(item)" dense
        >
          <v-list-item-title dense>{{item.display_name}}</v-list-item-title>
        </v-list-item>
        <v-divider style="margin-top:0px !important;margin-bottom:0px !important;" v-if="contextCombineMenuList.length"></v-divider>
        <v-list-item
          v-for="(item, index) in contextCombineMenuList"
          :key="item.node_id"
          @click="contextMenuClick(item)" dense
        >
          <v-list-item-title dense>{{item.display_name}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

<v-tooltip top :position-x="tooltipPositionX" :position-y="tooltipPositionY" v-model="showTooltip" class="edcTooltip">
  {{tooltipText}}
</v-tooltip>

</div>
</template>
<script>
import edccriteria from './../newcriteria.vue'
import{CLIENT_SIDE,SERVER_SIDE} from '@/data/macros.js';

import {FLITER_JOIN_OPERATOR_ARRAY_JSON,INLINE_FLITER_JOIN_OPERATOR_ARRAY_JSON,GRID_STATUS_COLOR_CODE,No_Data_Found,OEPRATOR_TO_EDC_OPERATOR} from '../../constants/constants.js'
import {
	COLOR_CODE,BTN_COLOR
} from "@/data/macros.js";
import vcButton from './../button.vue'
import moment from 'moment'
import ActionPanel from "./ActionPanel.vue"
import {manageScreenDisplay} from '@/methods/special.js'
import {isValidNumber} from '@/methods/edcdatavalidation.js'
import {POLICY_VALUE_TYPE,POLICY_VALUE_TYPE_WITH_RETENTION} from '@/data/metaData.js'

import {getJDEDate, convertToUserNativeDate, getTodaysDate} from '@/methods/DateFormat.js'
import * as JDEFormatterObj from "../../methods/JDEFormatter.js"
import * as commonFuncObj from "../../methods/commonFunc.js"
import Confirmation from "./Confarmation";
import {
    REPOSITORY_SERVICE_ID, MANAGE_BO, MANAGE_BV, MANAGE_DM, MANAGE_GV, SECURITY_SERVICE,
	 MANAGE_OBJ_BASED_SECURITY, MANAGE_ACTION_BASED_SECURITY, MANAGE_ENVIRONMENTAL_SECURITY,
	  PUBLISH_SERVICE_ID, MANAGE_TL
} from './../../data/macros.js'
import getUserRole from './../../methods/GetUserRole.js'

export default {
	name:'edcdatagrid',
	components:{
		'new-edc-criteria':edccriteria,
		'vc-button':vcButton,
		'action-panel':ActionPanel,
		'confirmation-panel':Confirmation,
	},
	props:{
		dataList:Object,
		allTableDescriptions:Array,
		tooltipObj:Object,
		hideFooter:Boolean,
		gridFor:String,
		availableDatasources:Array,
		dynamicMenuItem:Array,
		downloadLimit:String,
		keepFirstColFixed:{
			type:Boolean,
			default:false
		},
		keepSecondColFixed:{
			type:Boolean,
			default:false
		},
		gridViews:{
			type:Array,
			default:()=>{
				return []
			}
		},
		accessError:{
			type:String,
			default:''
		},
		selectedViewProp:{
			type:String,
			default:''
		},
		revisedPerPage:{
			type:Number,
			default:0
		},
		showGridView:{
			type: Boolean,
			default: true
		},
		clearSelectedRadio:{
			type:Number,
			default:0
		},
		resetInlineFilter:{
			type:Number,
			default:0
		}
	},
	data(){
		return {
			main_filterUI:[{
        's_parentheses': '',
        'column_name': '',
        'operator': '',
        'v_type1': 'Value',
        'v_type2': 'Value',
        'value1': '',
        'value2':'',
        'e_parentheses': '',
        'is_drv_table': false,
        'ref_row_id':'',
        'ref_step_id':''

      }],
		showInGridFilter:false,
		ActionPanelremountTime:1,
		hideColProp:' d-none',
		rowsToDisplay:[],
  		clickOnSort:false, // this variable is used to avoid sort call on first grid load.In case of server side grid updateOptions method get called. That method make getData col 2-3 time unnecessary.To avoid this overhead added this clickOnSort variable.
  		outlineColor:BTN_COLOR,
  		pageNumberTotalVisible:6,
  		page: 1,
  		pageCount: 0,
  		perPage: 100,
  		perPageArray:[5,10,15,20,25,50,100],
  		search:'',
  		selected:[],
  		defaultCaption:'Caption Not Available',
  		showCriteria:false,
  		columnList:[],
  		updatedFilter:[],
  		inlineFilterList:[], // filterlist contains inline filters
		filter_operators:FLITER_JOIN_OPERATOR_ARRAY_JSON,
		operator_to_edc_operator:OEPRATOR_TO_EDC_OPERATOR,
  		NoDataFound: No_Data_Found,
  		selectChips:[],
  		oldSelectedChips:[],
  		allHeaders:[],
  		visibleCols:[],
  		visibleChilds:[],
  		downloadOption:false,
  		downloadParams:{
  			recordType:"all",
  			minRange:"",
  			maxRange:"",
  			fileType:"xlsx",
  			topN:0,
  		},
  		gridActionObject:{},
  		newWidOption:{},
  		showAddNew:false,
		addNewId:'addnew',
		showAttachment:false,
  		showAddNewWithOption:false,
  		// newEnvBasedRoleMenu:[
	  	// 	{title:'Create Object Based Role', event:'createObjectBasedRole',role:true},
	  	// 	{title:'Create Environmental Role', event:'createEnvRole', role:true},
  		// ],
  		single_selected_row:{},
  		colorCode:COLOR_CODE,
  		headerToggle:true,
  		previousSort:[], // this variable is used to check user actually clicked on the sort or he just updated other table options. For more details check updateTableOptions()
  		contextMenuList:[],
  		contextCombineMenuList:[],
		contextSelfDSMenuList:[],
  		showTooltip:false,
  		tooltipPositionX:0,
  		tooltipPositionY:0,
  		showTooltip:false,
  		tooltipText:'',
  		tooltipTimeout:'',
  		tooltipDelay:4000,
  		showMenu:false,
  		menuPositionX:0,
  		menuPositionY:0,
  		compressVeritcalSpace:false,
  		// createNewView:{"object_id":"GV00","object_name":"Create New View"},
  		showWithoutView:{"object_id":"GV01","object_name":"Default View"},
  		availableViewList:[],
  		unPublish:false,
		isCompressed:false,
		gridViewOptions:false,
		gridHeaderAdditionalInformation:false,
		selectedView:'',
		userRole: this.$session.get('user_role_mapping')
  	}
  },
  computed:{
  	showInlineFilter(){
  		if(this.compressVeritcalSpace)
  			return false
  		return !this.dataList.hideInlineFilter && (this.dataList.total_count > 5 || this.inlineFilterList.length>0)
  	},
  	showTableFooter(){
  		if(this.compressVeritcalSpace || this.hideFooter)
  			return false
  		return this.dataList.total_count>5
  	},
  	showSummaryFooter(){
  		return this.dataList && this.dataList.loadSummary && this.dataList.rows.length
  	},
  	showViewOptions(){
  		return this.selectedView && this.selectedView !='GV00' && this.selectedView != 'GV01'
  	},
  	isGridForDataviewer(){
  		return this.gridFor === 'dataviewer' || this.gridFor === 'pathfinder'
  	},
  	showActualRowCountTotal(){
  		return this.dataList.actualRowCount > 0
  	},
	isFirstColFixed(){
		return this.keepFirstColFixed === true
		// return true
	},
	isSecondColFixed(){
		return this.isFirstColFixed && this.keepSecondColFixed === true
		// return true
	},
	dataListHasHeaders(){
		return !_.isEmpty(this.dataList) && !_.isEmpty(this.dataList.headers) && this.dataList.headers.length >0
	},
	isAnyRecordAlreadyInEdit(){
		return this.dataList && this.dataList.rows && _.find(this.dataList.rows,['is_row_editable',true])
	},
	newMenu(){
		return [
	  		{title:'Create Business Object', event:'createBusinessDoc', 
			role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,MANAGE_BO)},
	  		{title:'Create Business View', event:'createBusinessView',
			role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,MANAGE_BV)},
  			{title:'Create Data Map', event:'createDataMap',
			role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,MANAGE_DM)},
			{title:'Create Task List', event:'createTaskList',
			role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID, MANAGE_TL)}
  		]
	},
	newEnvBasedRoleMenu(){
		return [
	  		{title:'Create Object Based Role', event:'createObjectBasedRole',
			role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE, MANAGE_OBJ_BASED_SECURITY), 'id':'objbasedrole'},
	  		{title:'Create Environmental Role', event:'createEnvRole', 
			role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE, MANAGE_ACTION_BASED_SECURITY), 'id':'objenvbasedrole'},
  		]
	},
	getReadableNumber(){
		if(this.dataList.actualRowCount)
			return this.dataList.actualRowCount.toLocaleString()
		if(this.dataList.total_count && this.dataList.total_count > 0)
			return this.dataList.total_count.toLocaleString()
		return this.dataList.total_count
	},
	isGridViewEditable(){
		var gridObj = _.find(this.availableViewList,["object_id",this.selectedView])
		if(gridObj && gridObj.is_editable)
			return true
		return false
	}
  },
  watch:{
  	'dataList':{
  		handler(newValue){
  			// clearInterval(newValue.hideRowTimer)
  			this.actualDataList = []
  			this.showTooltip = false
  			var obj = _.find(newValue.actions,["text","new"])
  			this.showAddNew = true
  			if(!obj || !obj.role)
  				this.showAddNew = false
			else
				this.addNewId =obj.id || 'addnew'

			if("showNew" in newValue){
				this.showAddNew = newValue.showNew
			}
			var obj = _.find(newValue.actions,["text","attachment"])
  			this.showAttachment = true
  			if(!obj)
  				this.showAttachment = false
  			this.getNewObjWidOpt()
			// this.getNewActionBasedRoleOpt()
  		},
  		deep:true
  	},

  	'dataList.selected_rows':{
  		handler(newValue){
  			if(!newValue.length || !this.dataList.persist_selected_rows)
  				return
  			// need right logic for job plan job moving
  			// newValue.forEach(function(obj){
  			// 	this.selected.push(_.cloneDeep(obj))
  			// })
  			this.$nextTick(function () {
				this.selected = newValue
			})
  			this.dataList.persist_selected_rows = false
  		},
  	},
  	'dataList.rows':{
  		handler(newValue){
  			let _this = this
  			if(!newValue || !newValue.length){
  				
  			}
  			else{
  				if(_this.dataList.loadSummary)
  					// delay added because we are waiting to finish the inputJson binding to the grid object
  					setTimeout(function(){
  						_this.showSummaryToggleChanged(_this.dataList.loadSummary)
  					},1000)
  			}
		    clearInterval(_this.dataList.hideRowTimer);
  			if(newValue && newValue.length >0 && (newValue[0].updatinglivestatus || this.dataList.is_grid_inline_edit) && this.selected.length > 0){
  			}
  			else
  				if(this.dataList.DefaultSelectAll)
  					this.selected = newValue
  				else
  					this.selected = []

  			if(this.dataList.paginationType!=SERVER_SIDE)
  			{
  				//code added for manish sir change.mail date: 11/march/2020
  				if(this.dataList.is_grid_inline_edit && this.inlineFilterList.length > 0 && this.selected.length > 0)
  					return
  				this.dataList.total_count = newValue.length
  				this.performClientSidePagination()
  			}
  			else{
  				// if(newValue.length > this.perPage)
  				// 	this.rowsToDisplay = newValue.slice(0,this.perPage)
  				// else
  					this.rowsToDisplay = newValue
  			}
  			this.manageSelected(this.selected)

  		},
  		deep:true
  	},
  	'dataList.updateInlineFilter':{
  		handler(newValue){
  			if(newValue)
  				this.addInlineFilterText(newValue)
  		}
  	},
  	'dataList.headers':{
  		handler(newValue){
  			// if(this.gridFor === 'FileLookup' || this.gridFor === 'UdcLookup')
  				this.page = 1
  			
  			if(!this.isGridForDataviewer){
  				// alert('header change')
  				this.inlineFilterList = []
  			}
  			// this.perPage = 10
  		}
  	},
  	'dataList.summaryFooter':{
  		handler(newValue){
  		},
  	},
  	'dataList.loadSummary':{
  		handler(newValue){
  			this.showSummaryToggleChanged(newValue)
  		},
  	},
  	'gridViews':{
  		handler(newValue){
  			if(newValue){
				if(!newValue.length)
					return
				let gridViews = _.cloneDeep(newValue)
  				gridViews.splice(0,0,this.showWithoutView)
  				gridViews.splice(1,0,{"object_id":"====================","divider":true,"no-filter":true,   'object_id':"===================="})
  				this.availableViewList = gridViews
			}
  		}
  	},
	'accessError':{
		handler(newValue){

		}
	},
  	'dataList.inputJson':{
  		handler(newValue){
  			if(!newValue || _.isEmpty(newValue))
  				return
  			if(newValue.filter_data && newValue.filter_data.length && this.isGridForDataviewer){
  				// this.updatedFilter = _.cloneDeep(newValue.filter_data)
  				this.autoFillupInlineFilter(_.cloneDeep(newValue.filter_data))
  			}
  		}
  	},
  	'dataList.actualRowCount':{
  		handler(newValue){
  			if(!newValue)
  				this.dataList.actualRowCount = 0
  			else
  				this.dataList.actualRowCount = newValue
  		}
  	},
  	'selectedViewProp':{
  		handler(newValue){
  			this.dataList.summaryFooter = {}
  			this.selectedView = newValue
  		}
  	},
  	'dataList.showDescriptionAsHeader':{
  		handler(newValue){
  			this.manageHeaderText(newValue)
  		}
  	},
	'dataList.isCompacted':{
		handler(newValue){
			this.dataList.hideExport = newValue
			this.isCompressed = newValue
			this.dataList.hidePagination = newValue
			this.dataList.hideRowInfo = newValue
			// this.dataList.hideItemPerPage = newValue
		}
	},
	'dataList.perPage':{
		handler(newValue){

		}
	},
  	'rowsToDisplay':{
  		handler(newValue){
			// if(this.dataList.DefaultSelectAll)
			// 	this.selected = newValue
			// else
			if(this.dataList.filterType === CLIENT_SIDE && !this.dataList.DefaultSelectAll)
				this.selected = []
  	// 		this.manageSelected(this.selected)
  		}
  	},
  	'selectChips':{
  		handler(newValue){
				// if newValue
		}
	},
	'selected':{
		handler(newValue){
			this.manageSelected(newValue)
		}
	},
	'perPage':{
		handler(newValue){
			this.selected = []
		}
	},
	'page':{
		handler(newValue){
			this.selected = []
			// if(newValue===1){
			// 	this.dataList.summaryFooter = {}
			// 	if(this.dataList.loadSummary)
  	// 				// delay added because we are waiting to finish the inputJson binding to the grid object
  	// 				setTimeout(function(){
  	// 					this.showSummaryToggleChanged(this.dataList.loadSummary)
  	// 				},1000)
			// }
		}
	},
		'allTableDescriptions':{
			handler(newValue){

			}
		},
		'tooltipObj':{
			handler(newValue){
				let _this = this
				_this.showTooltip = false
				if(newValue && newValue.valueFortooltip){
					_this.tooltipPositionX = newValue.position.positionX
					_this.tooltipPositionY = newValue.position.positionY
					_this.tooltipText = newValue.valueFortooltip
					_this.showTooltip = true
				}
			}
		},
		'hideFooter':{
			handler(newValue){

			}
		},
		'gridFor':{
			handler(newValue){

			}
		},
		'availableDatasources':{
			handler(newValue){
				
			}
		},
		'dynamicMenu':{
			handler(newValue){
				
			}
		},
		'downloadLimit':{
			handler(newvalue){
			}
		},
		'keepFirstColFixed':{
			handler(newvalue){

			}
		},
		'keepSecondColFixed':{
			handler(newvalue){
				
			}
		},
		'showGridView':{
			handler(newvalue){
				
			}
		},
		'clearSelectedRadio':{
			handler(newValue){
				this.single_selected_row = {}
			}
		},
		'resetInlineFilter':{
			handler(newValue){
				// handle this during new UI design. This should not get empty, 
				// instead of value of header should ge populated
				this.inlineFilterList = []
			}
		}
	},
	beforeDestroy(){

	},
	mounted(){
		var _this = this
		
        this.userRole = this.$session.get('user_role_mapping');
		if(this.revisedPerPage)
			this.perPage = this.revisedPerPage
		else if(this.dataList.perPage)
			this.perPage = this.dataList.perPage
		else{
			// this.perPage = 10
			this.perPage = manageScreenDisplay()
			// if(this.dataList.is_in_tab)
			// this.perPage =  this.perPage - 5
		}
		this.allHeaders = _.cloneDeep(this.dataList.headers)
		this.visibleCols = _.cloneDeep(this.dataList.headers)
		let edcGrid = document.getElementById(this.dataList.unique_id)
		if(edcGrid){
			let scrollParent = edcGrid.getElementsByClassName("v-data-table__wrapper")
		}

	},
	methods:{
		manageSelected(newValue){
			this.dataList.selected_rows = newValue
			this.$emit('onRowSelected',newValue,this.dataList.childrenDataList,this.dataList.headers)
			this.performRowHide()
			this.gridActionObject = _.cloneDeep(this.dataList)
      // logic for show icons properly.  
      this.gridActionObject.actions = []
	  
	  // check is there any action which we need to show always,we need to put that actions 
	  // into the gridActionObject.
	  let alwaysShowActions = _.filter(this.dataList.actions,(obj)=>{
		  return obj.showAlways === true
	  })
	  if(alwaysShowActions)
	  	this.gridActionObject.actions = _.cloneDeep(alwaysShowActions)
      if(newValue.length === 0)
      	return false

			var actions = _.cloneDeep(this.dataList.actions)
			if(!actions)
				return
			// every action should have the index
			if(actions.length > 0 && !actions[0].index)
			return

			actions = _.sortBy(_.cloneDeep(this.dataList.actions),"index")


			var row = _.cloneDeep(newValue[0])
			// Every action json should contain the 'key' key.Cheack the same.
			var action_with_key = _.every(actions,'key')

			// if not every action json contain the 'key' key then return
			if(!action_with_key)
			return false

			// logic for single selected row
			if(newValue.length === 1){
			// now we have to show only that icons which has 'key' and cmp value match (if it has cmp value)
		  var valid_actions = []
		  for (var i = 0; i < actions.length; i++) {
		  	var current_action = actions[i]

	      // continue new action
	      if(current_action.text === 'new' || current_action.text === 'newWidOptions')
	      	continue

	      // grid download icon
	      if(current_action.text === 'GridDownload'){
	          // valid_actions.push(current_action)
	          continue
	        }

		    // now check key has cmpValue column
	      var key = current_action.key
	      if(row[key]){

	          // now check is that action has cmp value key
	          if(current_action.cmpValue){
	          	if(current_action['cmpValue'] === row[key])
	          		valid_actions.push(current_action)
	          	continue
	          } 
	          valid_actions.push(current_action)
	        }
	      }
	      this.ActionPanelremountTime++
	      this.gridActionObject.actions = _.cloneDeep(valid_actions)
	    }
	    else{
	    	this.gridActionObject.actions = []
	    	var multiple_actions = []
	    	for (var i = 0; i < actions.length; i++) {
	    		var current_action = actions[i]
	    		if (current_action.selectType  === 'multiple'){
	    			if(current_action['cmpValue']){
	    				let key = current_action['key']
	    				let cmpValueMatchedArray = _.filter(newValue,function(obj){
	    					return obj[key] === current_action['cmpValue']
	    				})
	    				if(cmpValueMatchedArray.length!=newValue.length)
	    					continue
	    			}
	    			multiple_actions.push(current_action)
	    		}
	    	}
	    	this.ActionPanelremountTime++
	    	this.gridActionObject.actions = _.cloneDeep(multiple_actions)
	    }
		},

		displayCriteriaPopUp(onlyAddFilters){
			var _this = this
			if(this.columnList.length === 0 && this.dataList.headers.length > 0){
				_.forEach(_this.dataList.headers,function(obj){
					let display_name = obj.text
					let extra_action = {}
					if(obj.description)
						display_name = obj.description + ' ('+obj.text+')'
					let datatype = ''
					if(JDEFormatterObj.isJDEDate(obj.column_type))
			    		datatype = 'jde_date'
			    	else if(obj.is_time_format)
			    		datatype = 'jde_time'
			    	else if(obj.is_number_format)
			    		datatype = 'jde_number'
			    	if(obj.paddingReq)
			    		extra_action = {"action":"edc_padding","paddingText":obj.paddingText,"padUpto":obj.padUpto,"padFrom":obj.padFrom}
					_this.columnList.push({'column_name':obj.text, 'display_name': display_name, "column_actual_name":obj.value,"edcdataType":datatype,'extra_action':extra_action,
					'table_name':obj.table_name || _this.dataList.table_name})
				})
			}
			// if(_this.oldSelectedChips.length === _this.selectChips.length)
			if(!onlyAddFilters)
				this.showCriteria = true
		},
		getNewObjWidOpt: function(){
			this.newWidOption = _.find(this.dataList.actions, ['text', 'newWidOptions']);
			return this.newWidOption
		},
		
		
		
		manageCriteria(updated_criteria){
			this.updatedFilter = updated_criteria
		},
		saveCriteria(){
			this.search = ''
			var _this = this
			var search_string = ''
			_this.selectChips = []
			_this.oldSelectedChips = []

			// reshuffle the filters set by user.For example all same name columns will come as toghether with OR and different name will come as AND.
			var updatedFilter_length = _this.updatedFilter.length
			if( updatedFilter_length > 0){
				// first findout all same name columns

				// var updatedFilterCopy = _this.getRevisedFilter()


				// _this.updatedFilter = updatedFilterCopy

				_.forEach(_this.updatedFilter,function(obj,index){

					// need to add operation, incase of multiple filter
					// if(updatedFilter_length > 1 && index!=(updatedFilter_length-1)){
					// 	obj['operation'] = false // autobind and condtion.
					// }

					if(obj.column_name && obj.operator){
						search_string = _this.selectChips.push(obj.column_name + " "+ _this.getOperatorNameById(obj.operator,obj.value1,obj.value2))

						// // in case of no last row , obj.operator will be undefined
						// if(obj.operation === true || obj.operation === false)
						// {
						// 	var operation = obj.operation?" OR ": " AND "
						// 	search_string = search_string + operation
						// }

					}
				})
			}
			_this.oldSelectedChips = _this.selectChips
			_this.search = search_string
			_this.showCriteria = false
			
			let new_merged_filter_list = _this.getRevisedFilter()
			// emit updateFilter
			_this.onUpdateFilter(new_merged_filter_list)

		},
		getOperatorNameById(operator,value1,value2)
		{
			if(operator === '_eq_')
				return "= "+ value1
			if(operator === '_not_eq_')
				return "!= "+value1
			if(operator === '_lt_')
				return "< "+value1
			if(operator === '_gt_')
				return "> "+value1
			if(operator === '_lt_eq_')
				return "<= "+value1
			if(operator === '_gt_eq_')
				return ">= "+value1
			if(operator === '_is_n_')
				return "Is null"
			if(operator === '_is_nt_n_')
				return "In not Null"
			if(operator === '_sl_')
				return "like "+value1+"%"
			if(operator === '_el_')
				return "like %"+value1
			if(operator === '_cl_')
				return "like %"+value1+"%"
			if(operator === '_bet_')
				return "between "+value1+" AND "+value2
			if(operator === '_in_')
				return "in ("+value1+")"
			if(operator === '_n_in_')
				return "not in ("+value1+")"

		},
		onUpdateFilter(filter_list){
			if(!filter_list)
				filter_list = this.updatedFilter
			if(this.dataList.filterType === SERVER_SIDE){
				this.$emit('UpdateFilter', _.cloneDeep(filter_list),1,this.dataList.table_name,this.dataList.unique_id,this.dataList.inputJson)
				this.page = 1
			}
			else
				this.onClientFilter(_.cloneDeep(filter_list))
			
			// console.log(this.updatedFilter)
		},
		setDate(obj, key, param){
			obj[key] = param;
		},
		onClientFilter(filterData){
			var new_rows = this.dataList.rows
			_.forEach(filterData,function(filterObj,index){
				let column_name = filterObj.column_name
				let operator = filterObj.operator
				let value1 = filterObj.value1
				if(!value1)
					value1 = ''
				let operation = filterObj.operation // true means OR , false means AND
				let temp_rows = _.filter(new_rows,function(rowobj){
					if(operator === '_eq_')
						return rowobj[column_name] == value1 || rowobj[column_name].toString().toLowerCase() == value1.toString().toLowerCase()
					else if(operator === '_not_eq_')
						return rowobj[column_name] != value1
					else if(operator === '_lt_')
						return rowobj[column_name] < value1
					else if(operator === '_gt_')
						return rowobj[column_name] > value1
					else if(operator === '_lt_eq_')
						return rowobj[column_name] <= value1
					else if(operator === '_gt_eq_')
						return rowobj[column_name] >= value1
					else if(operator === '_is_n_')
						return rowobj[column_name] == 'N/A'
					else if(operator === '_is_nt_n_')
						return rowobj[column_name] != 'N/A'
					else if(operator === '_sl_')
						return rowobj[column_name] && (rowobj[column_name].startsWith(value1) || rowobj[column_name].toLowerCase().startsWith(value1.toLowerCase()))
					else if(operator === '_el_')
						return rowobj[column_name] && (rowobj[column_name].endsWith(value1) || rowobj[column_name].toLowerCase().endsWith(value1.toLowerCase()))
					else if(operator === '_cl_')
						return rowobj[column_name] && (rowobj[column_name].includes(value1) || rowobj[column_name].toLowerCase().includes(value1.toLowerCase()))
				})
				new_rows = temp_rows
			})
			this.dataList.total_count = new_rows.length
			this.page = 1
			this.performClientSidePagination(new_rows)
		},
		onPageChange(number){
			if(this.dataList.paginationType === SERVER_SIDE)
				this.$emit('onPageChange', number,this.perPage,this.dataList.table_name,this.dataList.unique_id,this.dataList.inputJson)
			else
				this.performClientSidePagination()
		},
		onPerPageChange(number){
			this.page = 1
			if(this.dataList.paginationType === SERVER_SIDE)
				this.$emit('onPerPageChange', number,this.page,this.dataList.table_name,this.dataList.unique_id,this.dataList.inputJson)
			else
				this.performClientSidePagination()
		},
		onSort(sortJson){
			if(!sortJson)
				sortJson = []
			if(this.dataList.sorting_type === SERVER_SIDE){
				this.$emit('onSort', sortJson,this.dataList.table_name,this.dataList.unique_id,this.dataList.inputJson)
			}
			else {
				let sort_cols=[]
				let sort_types = []
				let sorteddatarows = []
				for (var i = 0; i < sortJson.length; i++) {
					sort_cols.push(sortJson[i].column_name)
					sort_types.push(sortJson[i].type)
				}
				if(sort_cols.length > 0){
					sorteddatarows = _.orderBy(this.dataList.rows,sort_cols,sort_types)
					this.performClientSidePagination(sorteddatarows)
				}
				else
					this.performClientSidePagination()
			}
		},
		performClientSidePagination(rows){
			if(!rows)
				rows = this.dataList.rows
			if(this.page <= 1 || rows.length < this.perPage)
				this.rowsToDisplay= rows.slice(0,this.perPage)
			else{
				let data_from = (this.page - 1)* this.perPage
				let data_to = this.page * this.perPage
				this.rowsToDisplay = rows.slice(data_from,data_to)
			}
		},
		chipDeleted(item,index){
			var _this = this
	  		/* item deleted from selection chip.
				find out index of deleted item and remove same item from the _this.updatedFilter.
				Then emit the UpdateFilter function
				*/
  				// delete from _this.updatedFilter
  				_this.updatedFilter.splice(index,1)
  				_this.selectChips.splice(index,1)
  				// remove operation key from last filter row
  				if(_this.updatedFilter.length > 0)
  					delete _this.updatedFilter[_this.updatedFilter.length - 1].operation

  				if(_this.isGridForDataviewer){
  					// remove from the inlineFilterList
  					_.remove(_this.inlineFilterList,function(obj){ return obj.column_name === item.split(' ')[0]})

  				}

  				let new_merged_filter_list = _this.getRevisedFilter()

  				// emit updateFilter
  				_this.onUpdateFilter(new_merged_filter_list)

  			},
  			getRevisedFilter(){
  				var _this = this
  				var updatedFilterCopy = []

  				var new_merged_filter_list = _.cloneDeep(_this.updatedFilter)
  				new_merged_filter_list = new_merged_filter_list.concat(_.cloneDeep(_this.inlineFilterList))

  				if(new_merged_filter_list.length === 0)
  					return updatedFilterCopy
  				var columns = _.uniq(_.map(new_merged_filter_list,"column_name"))
  				for(let i=0;i<columns.length;i++){
					var column_other_details = _.find(this.columnList,["column_name",columns[i]]) || {}
					if(new_merged_filter_list[i].isInlineFilter){
						//get operator from filter value
						new_merged_filter_list[i]['table_name'] = column_other_details.table_name
						if(new_merged_filter_list[i].actual_value1)
							new_merged_filter_list[i].actual_value1 = new_merged_filter_list[i].actual_value1.toString()
						let is_op = commonFuncObj.separate_operator(new_merged_filter_list[i].actual_value1, true)
						//logic for null. If user put only = in the inline filter it means he/she want to fetch null value
						if(new_merged_filter_list[i].actual_value1 === '='){
							new_merged_filter_list[i]['operator'] =  '_is_n_'
							new_merged_filter_list[i]['value1'] = ''
						}

						//logic for not null. If user put only != or <> in the inline filter it means he/she want to fetch not null value
						else if(new_merged_filter_list[i].actual_value1 === '<>' || new_merged_filter_list[i].actual_value1 === '!='){
							new_merged_filter_list[i]['operator'] =  '_is_nt_n_'
							new_merged_filter_list[i]['value1'] = ''
						}

						//logic for contains , start with and end with
						else if (new_merged_filter_list[i].actual_value1.includes('%'))
						{
							let indx_percent = new_merged_filter_list[i].value1.indexOf('%')
							let operator_count = (new_merged_filter_list[i].value1.split("%").length - 1)
							//when count is more than 1 for % apply conatains
							if(operator_count > 1){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['%xx%']
							}
							//when index of %  is o apply ends with
							else if(indx_percent == 0){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['%xx']
							}
							//when % is last character in filter apply starts with
							else if(indx_percent === new_merged_filter_list[i]['value1'].length-1){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['xx%']
							}
							//we dont update value1 when it has edcdatatype that is jde date since value is converted in number
								if(!column_other_details["edcdataType"])
									new_merged_filter_list[i]['value1'] = ""+new_merged_filter_list[i].value1.replace('%', '')
						}
						//logic for anyother operator except %	
						else if (this.operator_to_edc_operator[is_op]){
							new_merged_filter_list[i]['operator']=  this.operator_to_edc_operator[is_op]
							if(!column_other_details["edcdataType"])
								new_merged_filter_list[i]['value1'] = new_merged_filter_list[i]['value1'].replace(is_op, '')
						}
					} 
					var dataType = ''
  					var column_actual_name = ''
  					var extra_action = {}
  					if(!_.isEmpty(column_other_details)){
  						column_actual_name = column_other_details.column_actual_name
  						dataType = column_other_details.edcdataType
  						extra_action = column_other_details.extra_action ||{}
  					}
  					else
  						column_actual_name = columns[i]
					var firstFilter = true // to add parenthesis on first same column filter
					for(let j=0;j<new_merged_filter_list.length;j++){
						var currentFilter = _.cloneDeep(new_merged_filter_list[j])
						
						currentFilter['s_parentheses'] = ''
						currentFilter['e_parentheses'] = ''
						delete currentFilter.operation
						if(currentFilter.column_name === columns[i]){
							if(firstFilter){
								currentFilter['s_parentheses'] = '('
								firstFilter =false
							}
							currentFilter['operation'] = true // autobind OR between same column
							currentFilter['column_name'] = column_actual_name

							if(dataType === 'date'){
								if(currentFilter['value1'] && !currentFilter['isInlineFilter']){
									currentFilter['datedetails1']={
										format:'%Y-%m-%d',
										value:currentFilter['value1']
									}
								}
								if(currentFilter['value2']){
									currentFilter['datedetails2']={
										format:'%Y-%m-%d',
										value:currentFilter['value2']
									}
								}
							}
							else if(dataType === 'jde_date'){
								if(currentFilter['value1'] && !currentFilter['isInlineFilter']){
									if(currentFilter['v_type1'] === 'Date'){
										let jdeDate = getJDEDate(currentFilter['datedetails1']['actual_date'],currentFilter['datedetails1']['format'])
										
										currentFilter['value1'] = jdeDate
										currentFilter['datedetails1']= {}
										currentFilter['v_type1'] = "Value"
									}
									else{
										let jdeDate = getJDEDate(currentFilter['value1'])
										if(!jdeDate)
											continue
										currentFilter['value1'] = jdeDate
									}
								}
								if(currentFilter['value2']){
									if(currentFilter['v_type2'] === 'Date'){
										let jdeDate =  getJDEDate(currentFilter['datedetails2']['actual_date'],currentFilter['datedetails2']['format'])
										if(!jdeDate)
											continue
										currentFilter['value2'] = jdeDate
										currentFilter['datedetails2']= {}
										currentFilter['v_type2'] = "Value"
									}
									else{
										let jdeDate = getJDEDate(currentFilter['value2'])
										if(!jdeDate)
											continue
										currentFilter['value2'] = jdeDate
									}
								}
								
							}
							else if(dataType === 'jde_time'){
								currentFilter['value1'] = JDEFormatterObj.getJDETime(currentFilter['value1'])

								currentFilter['value2'] = JDEFormatterObj.getJDETime(currentFilter['value2'])
							}
							else if(dataType === 'jde_number'){
								currentFilter['value1'] = commonFuncObj.replaceText(currentFilter['value1'])
								currentFilter['value2'] = commonFuncObj.replaceText(currentFilter['value2'])
							}
							if(extra_action.action && extra_action.action === 'edc_padding' && currentFilter['value1']){
								currentFilter['value1'] = JDEFormatterObj.addPadding(currentFilter['value1'],extra_action.paddingText,extra_action.padUpto,extra_action.padFrom)
							}
							updatedFilterCopy.push(currentFilter)
						}
					}
					if(updatedFilterCopy.length > 0){
					updatedFilterCopy[updatedFilterCopy.length - 1]['operation'] = false // autobind AND between two column
					updatedFilterCopy[updatedFilterCopy.length - 1]['e_parentheses'] = ')'
				}
			}
			// remove operation from last filter
			delete updatedFilterCopy[updatedFilterCopy.length - 1].operation
			return updatedFilterCopy
		},
		updateTableOptions(options){
			var sortByJson = []
			if(options.sortBy.length > 0 && options.sortDesc.length > 0){
				this.clickOnSort = true
				for (var i = 0; i<options.sortBy.length; i++) {
					let data = {}
					data['column_name'] = options.sortBy[i]
					data['type'] =options.sortDesc[i]? "desc":"asc"
					sortByJson.push(data)	
				}
			}
			let sortChanged = this.isSortChange(this.previousSort,sortByJson)
			if(!sortChanged)
				return
			this.previousSort = _.cloneDeep(sortByJson)
			this.onSort(sortByJson)
		},
		isSortChange(oldSort,newSort){
			if(oldSort.length != newSort.length)
				return true
			for(var i=0;i<oldSort.length;i++){
				let currentOldSort = oldSort[i]
				let keys = Object.keys(currentOldSort)
				let currentNewSort = newSort[i]
				for(var j=0;j<keys.length;j++){
					let key = keys[j]
					if(currentOldSort[key] != currentNewSort[key])
						return true
				}
			}
			return false
		},
		hideDataGridColumns(){
			var dnone = this.hideColProp
    	// this.dataList.headers = []
    	for(let i=0;i<this.dataList.headers.length;i++){
    		if(this.dataList.headers[i]['align'] != dnone)
    			this.dataList.headers[i]['default_align'] = this.dataList.headers[i]['align']? this.dataList.headers[i]['align'] : 'left'
    		let obj = _.find(this.visibleCols,["text",this.dataList.headers[i].text])
    		if(!obj){
    			this.dataList.headers[i]['align'] = dnone
    		}
    		else{
    			if(this.dataList.headers[i]['default_align'])
    				this.dataList.headers[i]['align'] = this.dataList.headers[i]['default_align']
    		}
    	}
    },
    getFormatedData(header,text,item,is_key_json){
    	if(!text && text != 0){
    		if(is_key_json){
    			let actual_key = header.value
    			let keys = actual_key.split('.')
    			/* Here I am considering only two keys for timebieing*/
    			text = item[keys[0]][keys[1]]
    		}
    		else
    			return text
    	}
    	// this specific validation is to remove transaction id from row count column in sql grid
    	if(header.validate === 'number'){
    		if(isNaN(text))
    			return ''
    	}
    	if(!this.dataList.useWrapping)
    		return text
    	let new_text = text
    	let isDate = false
    	if(!isNaN(text)){
    		if(JDEFormatterObj.isJDEDate(header.column_type))
    		{
    			// its juliean date. Now need to convert into normal date

    			let days = (text%1000) - 1
    			let years = parseInt(text/1000) + 1900
    			let convertedDate = moment(years+"01-01","YYYY-MM-DD")
    			new_text = convertedDate.add('days',days).format("YYYY-MM-DD")
    			header['align'] = 'left'
    		}
    		return new_text
    	}
    	let header_text = header.text
    	new_text = new_text.replace(/\n/g, '').trim()
    	if((header_text.length+2) < new_text.length){
    		let string_to_return = new_text.substring(0,header_text.length-1)
    		return string_to_return+'..'
    	}

    	// if((new_text.length+2) > 12){
    	// 	let string_to_return = new_text.substring(0,11)
    	// 	return string_to_return+'..'
    	// }
    	else
    		return new_text
    },
    getColTooltips(headertext,header){
    	let _this = this
    	if(header.is_attachment_col)
    		return "Attachments"
    	let description = ''
    	let obj = _.find(_this.dataList.headers,["text",headertext])
    	if(obj && obj.title)
    		return obj.title.toUpperCase()
    	return headertext.toUpperCase()
    },
    onDownload(downloadParams){
    	this.$emit('onDownload',downloadParams,_.cloneDeep(this.selected))
    },
    createEvent(recordType,eventName, defaultVal){
    	let record=(recordType == 'single') ? _.cloneDeep(this.selected[0]):this.selected;
    	if(eventName == 'onEdit' && this.dataList.is_grid_inline_edit){

		_.forEach(this.dataList.rows, (rowObj)=>{
			delete rowObj.is_row_editable
		})

		let key = this.dataList.itemkey
		let obj = _.find(this.dataList.rows,[key,record[key]])
		if(obj){
			// let index = _.indexOf(this.dataList.rows,obj)
			obj.is_row_editable = true
			this.AddRowForGridEditing(obj)
			this.$emit('onValueUpdate',record)
		}

    	}
    	else {
    		this.$emit(eventName, record, defaultVal);
    		if(this.dataList.is_grid_inline_edit) // to overcome select all rows default.Please check if condition in datalist.row and rowTodisplay watch
    			this.selected = []
    	}
    },
    onCancelEditing(record){  
    	this.$emit('onCancel', record);
    	this.selected = []
    },
    getColor(chipFor,value){
    	if(!chipFor || !value)
    		return 'grey'
    	if(chipFor === 'status'){
    		return GRID_STATUS_COLOR_CODE[value.toLowerCase()]
    	}
    },
    validateInput(event,inputType){
    	if(!inputType)
    		return true
    	var keycode = event.which
    	if(inputType === 'number'){
    		if(isValidNumber(keycode))
    			return true
    		
    	}
		else if(inputType === 'text'){
    		return true
    		
    	}
    	event.preventDefault()
    },
    radioSelectionChange(record){
    	this.$emit('radioSelectionChange', record);
    },
    copyToClipboard(event,header){
    	if(!header.copyToClipboard)
    		return
    	var source = event.srcElement
    	var textArea = document.createElement("textarea");
    	textArea.value = source.textContent;
    	document.body.appendChild(textArea);
    	textArea.select();
    	document.execCommand("Copy");
    	textArea.remove();
    },
    changeAutoCompleteSelection(event_value,item,header,headerindex,itemindex){
    	this.AddRowForGridEditing(item)
    	if(header.isPolicy){
			/* 
			It means request come from policy grid.
			
				1)If request come from policy type then change the value types based on the policy type
			
				2)If request come from value type then clear the value and also make it disabled if user select permanent policy 
				*/
				if(header.value === 'policy_type'){
					if(item['policy_type'] === 'Retention'){
						this.dataList.headers[headerindex+1].option = POLICY_VALUE_TYPE_WITH_RETENTION
					}
					else{
						this.dataList.headers[headerindex+1].option = POLICY_VALUE_TYPE
					}
				}
				else if(header.value === 'value_type'){
					this.rowsToDisplay[itemindex]['policy_value'] = ''
					this.dataList.headers[headerindex+1].disabled = false
					if(event_value === 'Permanent')
						this.dataList.headers[headerindex+1].disabled = true
				}
			}
			else if(header.isJobRule || header.isRoleMapping || header.isRoleSetup){
				if(this.$refs.cachedAutoComplete){
					this.$refs.cachedAutoComplete.cachedItems = [];
				}
				this.$emit('onValueUpdate',item)
			}
			// else if(!header.options.includes(event_value)){
			// 	this.$emit('onValueUpdate',item)
			// }
			this.$emit('onValueUpdate',item,header)
		},
		AddRowForGridEditing(item){
			if(this.dataList.hideSelect)
				return
			this.$nextTick(function () {
				this.$set(this.selected, 0, item);
			})
		},
		rowDoubleClick(record,recordIndex){
			if(!this.dataList.is_grid_inline_edit)
				this.$emit('ondblClick',record,recordIndex)
			else
			{
				// check is there any record which is already in edit mode, if it does, then do nothing
				if(this.isAnyRecordAlreadyInEdit)
					return
				this.selected = [record]
				this.createEvent('single','onEdit')
			}	
		},
		rowClick(event,row,rowIndex){
			let _this = this
			this.contextMenuList = []
			this.contextCombineMenuList = []
			this.contextSelfDSMenuList = []
			
			if(this.isCompressed)
				return
			if(this.dataList.showContextMenu &&  this.selected.length){
				if(this.dataList.children && this.dataList.children.length){
					let isShowAllOption = true
					let menuList = _.cloneDeep(this.dataList.children)
					let copiedObj = {}
					menuList.forEach(obj=>{
						if(obj.is_archivist_dynamic)
							isShowAllOption=false
						obj.display_name = _this.getContextMenuDisplayName(obj)
						copiedObj = _.cloneDeep(obj)
					})

					// Here we will add 2 more options. 
					// 1st is All
					let combinedContextMenu = []
					menuList = _.sortBy(menuList,["display_name"])
					if(menuList.length > 1 && isShowAllOption){
						let allMenuObj = _.cloneDeep(copiedObj)
						combinedContextMenu.push(_this.getMenuObject(allMenuObj,"all",
						"Load all children","all","all",[],true,"all"))
					}

					if(isShowAllOption && _this.dataList.childrenDataList && _this.dataList.childrenDataList.length){
						let onlyOpenedMenuObj = _.cloneDeep(copiedObj)
						combinedContextMenu.push(_this.getMenuObject(onlyOpenedMenuObj,"open","Reload all open children","open",
						"open",[],true,"open"))
					}

					this.contextMenuList = menuList
					this.contextCombineMenuList = combinedContextMenu
					if(row){
						_this.$nextTick(()=>{
						if(_.indexOf(_this.selected,row)<0)
							_this.selected.push(row)
						})
					}
				}
				else{
					// this.$emit('checkForDynamicMenu',this.dataList.unique_id,_this.selected)
				}
			}
			
			// this.$refs.ctxMenu.open()
			this.showMenu = false
	        this.menuPositionX = event.clientX
	        this.menuPositionY = event.clientY
	        this.$nextTick(() => {
	          this.showMenu = true
	        })
		},
		getMenuObject(obj, title,display_name,node_id,parent_id,children,isMultiple,table_name,datasourceIndex){
			// children = array, isMultiple = boolean -> loadAllChild
			obj.title = title
			obj.display_name = display_name
			obj.node_id = node_id
			obj.parent_id = parent_id
			obj.children = children
			obj.isMultiple = isMultiple
			obj.table_name = table_name
			if(datasourceIndex >-1)
				obj.associated_datasource_index = datasourceIndex
			return obj
		},
		getContextMenuDisplayName(obj){
			var _this = this
			if(!obj.merged_tables || !obj.merged_tables.length)
				obj['merged_tables'] =[obj.table_name]
			let display_name = ''
			for(let i=0;i<obj.merged_tables.length;i++){
				let current_display_name=obj.merged_tables[i]
				let descriptionObj = _.find(_this.allTableDescriptions,["title",obj.merged_tables[i]])
				if(descriptionObj)
					current_display_name = descriptionObj.description + ' ('+obj.merged_tables[i]+')'
				if(display_name)
					display_name = display_name+ ' / '
				display_name = display_name + current_display_name
			}
			return display_name
		},
		contextMenuClick(item){
			let _this = this
			let childDetails = [_.cloneDeep(item)]
			let loadType = ''
			if(_this.selected.length){
				_this.compressVeritcalSpace = true
				_this.rowsToDisplay = _this.selected
			}
			if(item.isMultiple){
				if(item.title === 'all'){
					loadType = 'multiple'
					childDetails = _.cloneDeep(_this.dataList.children)
				} else {
					loadType = 'recursive'
					childDetails = _.cloneDeep(_this.dataList.childrenDataList)
				}
			}
			else if(item.isByDS)
				loadType='ByDS'
			this.$emit('contextMenuClick',childDetails,this.selected,_this.dataList.headers, loadType)
		},
		getIndeterminateState(header,item){
			// if(!hea)
		},
		setDisabled(header,item){
			if(!this.dataList.caption)
				return false
			if(this.dataList.caption ==="adminRole" || this.dataList.caption ==="envRole" || this.dataList.caption ==="notificationGroup"){
				let service_actions = item.service_actions
				if(header.text === 'All')
					return false
				if(service_actions.indexOf(header.value)>-1)
					return false
			}
			return true
		},
		checkChanged(event_value,header,item){
			if(!header.toggleRow || !this.dataList.caption)
				return
			if(this.dataList.caption ==="adminRole"||this.dataList.caption ==="envRole" || this.dataList.caption ==="notificationGroup"){
					let service_actions = item.service_actions
				if(header.text === 'All'){
					if(!event_value) // this event_value come as a null.make it proper boolean
						event_value = false
					service_actions.forEach(function(obj){
						item[obj] = event_value
					})
					return
				}
				else{
					let atleastOneChecked=false
					let atleastOneUnchecked=false
					// let obj = _.find(this.dataList.rows,["text","All"])
					// if(!obj)
					// 	return
					service_actions.forEach(function(obj){
						if(!atleastOneChecked){
							if(item[obj])
								atleastOneChecked = true
						}
						if(!atleastOneUnchecked){
							if(!item[obj])
									atleastOneUnchecked = true
						}
					})
					// if(!atleastOneChecked && !atleastOneUnchecked){
					// 	item.useIndeterminateState = false
					// }
					// if(atleastOneChecked && atleastOneUnchecked)
					// {
					// 	item.useIndeterminateState = true
					// }
					item.all = false
					if(atleastOneChecked)
					{
						let default_actions = item.default_actions
						default_actions.forEach(function(obj){
							item[obj] = true
						})
					}
					if(atleastOneChecked && !atleastOneUnchecked){
						item.all = true
					}

				}
			}
		},
		applyInlineFilterForAll(event,header,filtertext,allHeaders){
			var _this = this
			for(let i=0;i<allHeaders.length;i++){
				let currentCol = allHeaders[i]
				let col_name = currentCol.value
				let removed_obj = _.remove(_this.inlineFilterList,function(obj){
					return obj.column_actual_name === col_name
				})
				_this.applyInlineFilter(event,currentCol,currentCol.filtertext,true)
			}
			_this.saveCriteria()
		},
		applyInlineFilter(event,header,filtertext,onlyAddFilters){
			if(!filtertext)
				return
			let column_name = header.value

			let removed_obj = _.remove(this.inlineFilterList,function(obj){
				return obj.column_actual_name === column_name
			})
			let filterjson= {"column_name":header.text,"column_actual_name":header.value,"value1":filtertext,"operator":"_cl_","hideKey":true,'isInlineFilter':true,'is_description_col':header.is_description_col}
			
			filterjson['datasource_index'] = header.datasource_index

			if (header.is_description_col){
				filterjson['description_for_col'] = header.column_name
			}
			if (header.table_name)
				filterjson['table_name'] = header.table_name
			if(header.dataType === 'number' || header.dataType === 'date' || header.dataType === 'boolean'){
				filterjson['operator'] = "_eq_"
			}
			let revisedFilterText = filtertext
			if(JDEFormatterObj.isJDEDate(header.column_type)){
				let jdeDate = getJDEDate(filtertext,this.$session.get('UI_date_format'))
				if(jdeDate === false)
					throw 'Invalid Date Format Error'
				revisedFilterText = jdeDate
				filterjson['value1'] =  revisedFilterText
			}
			if(header.dataType === 'date'){
				filterjson['datedetails1']={
					format:this.$session.get('date_format'),
					value:revisedFilterText
				}
			}
			if(commonFuncObj.isNumericDataType(header.datatype)){
				filterjson['operator'] = "_eq_"
			}
			if(header.paddingReq){
				header.filtertext = JDEFormatterObj.addPadding(header.filtertext,header.paddingText,header.padUpto,header.padFrom)
			}
			if(JDEFormatterObj.isJDEDate(header.column_type)){
				filterjson['actual_value1'] = convertToUserNativeDate(header.filtertext,this.$session.get('UI_date_format'))
			}
			else
				filterjson['actual_value1'] = header.filtertext

			this.displayCriteriaPopUp(true)
			// this.updatedFilter.push(_.cloneDeep(filterjson))
			this.inlineFilterList.push(_.cloneDeep(filterjson))
			// header.filtertext = ""
			if(!onlyAddFilters)
				this.saveCriteria()
		},
		clearInlineFilter(event,header,filtertext,allHeaders){
			let column_name = header.value
			let value1 = filtertext
			// let removed_obj = _.remove(this.updatedFilter,function(obj){
			// 	return obj.column_name === column_name && obj.value1 === value1
			// })
			let removed_obj = _.remove(this.inlineFilterList,function(obj){
				/*committed because this not working for date filter*/
				// return obj.column_actual_name === column_name && obj.value1 === value1

				return obj.column_actual_name === column_name
			})
			header.filtertext = ''
			this.applyInlineFilterForAll(event,header,filtertext,allHeaders)
			// this.saveCriteria()
		},
		keyUpInlineFilter(event,header,filtertext,allHeaders){
			// 8 backspace, 46 delete
			if((event.keyCode === 8 || event.keyCode===46) && !filtertext){
				let column_name = header.value
				let removed_obj = _.remove(this.inlineFilterList,function(obj){
				return obj.column_actual_name === column_name
				})
				this.applyInlineFilterForAll(event,header,filtertext,allHeaders)
			// this.saveCriteria()
			}
		},
		isChipShow(index){
			if(this.updatedFilter.length < index || this.updatedFilter[index].hideKey)
				return false
			return true
		},
		manageHeaderText(showDescriptionAsHeader){
			let _this = this
			if(!this.dataList.headers.length)
				return
			if(showDescriptionAsHeader){
				_this.dataList.headers.forEach(obj=>{
					obj['displayHeader'] = obj['description']
					obj['title'] = obj['text']
				})
			}
			else {
				_this.dataList.headers.forEach(obj=>{
					obj['displayHeader'] = obj['text']
					obj['title'] = obj['description']
				})
			}
		},
		showSummaryToggleChanged(value){
			// false is for show summary. Keep this way because of on/off functionality of table button
			if(value){
				this.$emit('loadSummaryFooter',this.dataList.unique_id)
			}	
		},
		MouseOverEvent(obj){
			obj.target.style.backgroundColor = "grey";
		},
		MouseOutEvent(obj){
			obj.target.style.backgroundColor = "white";
		},
		mouseEnterOnTd(event,header,item_value,item_row){
			let _this = this
			_this.showTooltip = false
			// clearInterval(this.tooltipTimeout)
			// _this.tooltipTimeout = setTimeout(function(){
			// 	_this.tooltipPositionX = event.x
			// 	_this.tooltipPositionY = event.y
			// 	_this.tooltipText = item_value
			// 	_this.showTooltip = false
			// },_this.tooltipDelay)
			let position = {"positionX":event.x,"positionY":event.y}
			let table_name = _this.dataList.table_name
			_this.$emit('mouseEnterOnTd',header,item_value,item_row,position)
			
		},
		mouseLeaveTd(event){
			let _this = this
			_this.$emit('mouseLeaveTd',_this.dataList.unique_id)
			
		},
		copySelectedRowsToClipboard(event){
			if(!this.selected.length || (this.dataList && this.dataList.is_grid_inline_edit))
				return
			let _this = this
			let copiedString = _.map(this.dataList.headers,'displayHeader').join(',')
			let headersKey = _.map(this.dataList.headers,'value')
			for(let i=0;i<this.selected.length;i++){
				copiedString =copiedString + ' \n '+this.getItemValues(this.selected[i],headersKey)
			}
			_this.$copyText(copiedString).then(function (e) {
				_this.$emit('rowCopyToClipboard','Selected rows copied to clipboard!!','success')
	          console.log(e)
	        }, function (e) {
	          _this.$emit('rowCopyToClipboard','error in copying the text!!','error')
	          console.log(e)
	        })
		},
		getItemValues(array,headers_key,seprator=','){
			let stringValue = ''
			if(!headers_key)
				headers_key = Object.keys(array)
			headers_key.forEach((key)=>{
				if(stringValue)
					stringValue = stringValue + ','+array[key]
				else
					stringValue = array[key]
			})
			return stringValue
		},
		checkboxSelectionChange(){
			let _this = this
			_this.$emit('onRowSelected',_this.selected,_this.dataList.childrenDataList,_this.dataList.headers)
			_this.performRowHide()
		},
		performRowHide(){
			let _this = this
		    clearInterval(_this.dataList.hideRowTimer);
			if(_this.dataList.hideNonSelectedRows){
				if(_this.selected.length && _this.dataList.childrenDataList.length){
					if(!_this.dataList.hideRowDelay)
						_this.dataList.hideRowDelay = 3000
					_this.dataList.hideRowTimer = setTimeout(function(){
						_this.compressVeritcalSpace = true
						_this.rowsToDisplay = _this.selected
					},_this.dataList.hideRowDelay)
				}
				else{
					_this.compressVeritcalSpace = false
					_this.rowsToDisplay = _this.dataList.rows
				}

			}
		},
		onClickAdvanceFilter(header){
			this.$emit('onClickAdvanceFilter',header,this.dataList.unique_id)
		},
		getInlineFilterPlaceHolder(header){
			if(JDEFormatterObj.isJDEDate(header.column_type)){
				return 'dd/mm/yyyy'
			}
			else 
				return ''
		},
		addInlineFilterText(inlineFilterDetails){
			let header = _.find(this.dataList.headers,['text',inlineFilterDetails.column_name])
			if(header){
				header.filtertext = inlineFilterDetails.filtertext
				return this.applyInlineFilter({},header,inlineFilterDetails.filtertext)
			}
		},
		getSummaryFooterValue(header){
			if(_.isEmpty(this.dataList.summaryFooter) || !this.dataList.summaryFooter[header.value])
				return false
			return this.dataList.summaryFooter[header.value]
		},
		loadDiscriptionToggleTitle(){
			if(this.headerToggle)
				return "Show Description"
			return "Show Columns"
		},
		changeGridView(action){
			if(!this.selectedView)
				return
			this.$emit('changeGridView',this.selectedView,action)
		},
		clickOnViewIcons(clickType){
			this.changeGridView(clickType)
		},
		viewSelectionChanged(){
			this.changeGridView()
		},
		autoFillupInlineFilter(filter_data){
			// Inline column filter does not retain the value after the GridView is changed.  To reproduce: Open F0911 using default view Enter 6188 for Batch Number in inline column filter Change GridView (ex: Totals by Fiscal Period by Account).  This will correctly display the selected details for batch 6188. Change GridView to default.  This will correctly display records for batch 6188; however, there is no value in the inline filter.  This makes it appear there is no filter applied.

			// logic is show filter in inline text in header if filter column is part of the column selection else show in chips [{"column_name":"phdoco","column_actual_name":"phdoco","value1":"4899","operator":"_eq_","hideKey":true,"isInlineFilter":true,"s_parentheses":"(","e_parentheses":")"}]
			let _this = this
			_this.selectChips = []
			_this.updatedFilter = []
			_this.inlineFilterList = []
			for(let i=0;i<filter_data.length;i++){
				if(filter_data[i].is_description_col)
					continue
				filter_data[i].column_name = filter_data[i].column_name.toUpperCase()
				let column_name = filter_data[i].column_name.toLowerCase()
				let colObj = _.find(_this.dataList.headers,["value",column_name])
				if(_.isEmpty(colObj))
					colObj = _.find(_this.dataList.headers,["value",column_name.toUpperCase()])
				if(!_.isEmpty(colObj)){
					_this.inlineFilterList.push(filter_data[i])
					colObj.filtertext = filter_data[i].value1
					if(filter_data[i].actual_value1)
					colObj.filtertext = filter_data[i].actual_value1
				}
				else{
					filter_data[i]['operation'] = true
					this.updatedFilter.push(filter_data[i])
					_this.selectChips.push(filter_data[i].column_name + " "+ _this.getOperatorNameById(filter_data[i].operator,filter_data[i].value1,filter_data[i].value2))
				}
			}
			if(_this.updatedFilter.length)
				delete _this.updatedFilter[_this.updatedFilter.length - 1].operation
		},
		loadMediaAttachmentDetails(index,attachment_details){
			let associated_row = this.dataList.rows[index]
			this.$emit('loadMediaAttachmentDetails',attachment_details,associated_row)
		},
		onCleareAllInlineFilter(){
			for(let i=0; i < this.dataList.headers.length; i++){
				this.dataList.headers[i].filtertext = ""
			}
			this.inlineFilterList = []
			this.saveCriteria()
		},
		getIndentedLVL(value){
			if (!value || isNaN(value))
				return value
			else
			{
				let i=0;
				let indentedValue = value.toString()
				while (i < value-1){
					indentedValue = "    "+indentedValue
					i++
				}
				return indentedValue
			}
		},
		performTableSearch(event,value,list){
			// if(list.some( value => { return !value.object_name== value ||  !value.object_description == value} ))

			// length < 50, assumption is less than 50 table will share common matching characters 
			if(list.filter(obj=> obj.object_name.toLowerCase().includes(value.toLowerCase())).length < 50)
				this.$emit('performTableSearch',value)
			else
			 return
			// if(event.keyCode != 13)
			// 	return
			// else
			// 	this.$emit('performTableSearch',value)
		},
		getDatasoureName(item,header){
			if(!this.availableDatasources || !item[header.value])
				return item[header.value]
			else{
				return this.availableDatasources[parseInt(item[header.value])].business_data.datasource_name
			}
		},
		getInlineFilterHelpText(header){
			if(JDEFormatterObj.isJDEDate(header.column_type))
				return this.$session.get('UI_date_format') || ''
			return ''
		},
		showFilterInformation(header){
			return JDEFormatterObj.isJDEDate(header.column_type)
		},
		getFilterInformationTitle(header){
			if(JDEFormatterObj.isJDEDate(header.column_type)){
				let userDateFormat = this.$session.get('UI_date_format')
				return  "Input date should be in the "+userDateFormat+" fsormat.<br/>You can use =,!=, <>, <, > operators to check date along with specific condition. <br/> For Example- To check data which less than today's date you can simply type < "+getTodaysDate(userDateFormat)+'.<br/> To check date with 0 values just type = or type =0<br/>If you didnt put any operator then date will get compare as = (equal to)'
			}
			return ''
		},
		changeAdvanceItemSelection(itemValue,item,header,headerindex,itemindex){
                Object.assign(item,itemValue)
                this.AddRowForGridEditing(item)
                this.$emit('onValueUpdate',item,header,itemValue[header.value],headerindex,itemindex)
            },
		getAutocompleteTitleText(header,value){
			if(!header || !header.option || !header.option.length || (!value && value !=0))
				return ''
			let obj = _.find(header.option, [header.item_value,value])
			if(obj)
				return obj[header.item_text]
			return ''
		},
		performSearch(searchText,header,record){
			this.$emit('performSearch', searchText, header,record)
		},
		getDefaultId(header, headerIndex, rowIndex){
			return header.id || header.value +' '+headerIndex +' '+rowIndex
		}
	}
}
</script>
<style>
.EDC-Access-Denied {
		color:red !important;
		padding-left:8px;
		font-size: 13px;
	}

th{ 
	white-space: nowrap; 
	overflow: hidden; 
	text-overflow:ellipsis;
	font-size: 12px !important;
} 

td{ 
	white-space: nowrap; 
	overflow: hidden; 
	text-overflow:ellipsis;
	font-size: 12px !important;
} 

tbody tr:nth-of-type(even) {
	background-color:var(--v-datatablealternate-base) !important;
}

tbody tr:nth-of-type(odd) {
	background-color:white !important; /* This is set to white for hiding overlapping text in case of the freezed first column */
}

.v-pagination__item{
	font-size: 12px !important;
}

.EDCTableFooter {
	font-size: 12px !important;
}

.v-select__selection{
	font-size: 12px !important;
}
td {
	border:none !important;
}

.EDC-VCardColumn{
	height: 60px !important;
	padding: 0px !important;
	margin: 0px !important;
	width: 250px !important;
	overflow: none !important;
}

.EDC-VCombobox{
	display: inline-block !important;
	padding: 0px !important;
	margin: 0px !important;
	width: 250px !important;
}

.v-input--selection-controls{
    margin-top:0px !important;
    padding-top: 0px !important; 
}

/*.v-text-field {
	padding-top: 0px !important; 
	margin-top: 0px !important;
}*/

table {
	border-top:1px var(--v-secondary-lighten3) solid;
}

table {
	color:var(--v-apptext-base) !important;
}
table .theme--light.v-icon{
	color:var(--v-primary-base) !important;
}

.hideCol {
	display:none;
}

.keep-spaces { white-space: pre-wrap; }

/*
*  STYLE 2
*/

#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1) !important;
	border-radius: 10px !important;
	background-color: white !important;
}

#style-2::-webkit-scrollbar
{
	width: 5px !important;
	background-color: white !important;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px !important;
	background-color: #DCDCDC !important;

}

.scrollbar
{
	background: white !important;
	overflow-y: hidden !important;
	margin-bottom: 25px !important;
	overflow-x:  scroll !important;
}
.v-sheet {
	border-radius: 5px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot{
	padding-left:3px !important;
	padding-top:0px !important;
	padding-right:3px !important;
	padding-bottom:0px !important;
	height:20px !important;
}

.v-text-field--rounded {
	border: 2px solid;
    border-radius: 5px;
    border-color: var(--v-primary-base) !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot > .v-text-field__slot {
	min-width:16px !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner{
	margin-top:0px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner> .v-input__icon{
	height:20px !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner> .v-input__icon > .v-icon.v-icon{
	font-size:16px !important;
}
.v-list-item {
	text-align:start !important;
}
.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 24px;
}

.singleLine {
	display: inline-block;
	white-space: nowrap;
}

.right_aligned_text input {
	text-align: right !important;
}

.summary-text-field.v-input--dense>.v-input__control>.v-input__slot:before {
    border-style: none !important;
    border-top-style: solid;
    border-top-width: thin;
}

.summary-text-field.v-input--dense>.v-input__control>.v-input__slot:after {
    border-style: none !important;
    border-top-style: solid;
    border-top-width: thin;
}
.summary-text-field.v-input--dense>.v-input__control>.v-input__slot{
	border-top-style: solid;
    border-top-width: thin;
}

.header-summetion{
  content: "\2211";
}

.fixed-first-col{
	/* Empty class for apply fixed css */
	background: inherit !important;
}

.fixed-first-col table > tbody > tr > td:nth-child(1),
.fixed-first-col table > thead > tr > th:nth-child(1) {
	position: sticky !important;
	position: -webkit-sticky !important;
	left: 0;
	z-index: 3;
	background: inherit;
}
.fixed-first-col table > thead > tr > th:nth-child(1) {
	z-index: 4;
}

.fixed-first-col  table > thead > tr > th:nth-child(1) {
	background: white !important;
}

.fixed-second-col{
	/* Empty class for apply fixed css */
	background: inherit !important;
}

.fixed-second-col table > tbody > tr > td:nth-child(2),
.fixed-second-col table > thead > tr > th:nth-child(2) {
	position: sticky !important;
	position: -webkit-sticky !important;
	left: 64px;
	z-index: 3;
	background: inherit;
}
.fixed-second-col table > thead > tr > th:nth-child(2) {
	z-index: 4;
}

.fixed-second-col  table > thead > tr > th:nth-child(2) {
	background: white !important;
}

</style>