<template>
  <v-container class="EDC-Container">
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <ul class="breadcrumb">
          <li>
            <router-link to="/clientsettings">Account Preferences</router-link>
          </li>
          <li>Manage Account</li>
        </ul>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-row class="EDC-Row">
      <v-col cols="12" class="EDC-Col">
        <v-form ref="form" v-model="valid">
          <v-card elevation-1>
            <v-card-title style="background:#494949;color:white;padding:5px">
              <span style="float:left;font-size:15px">Manage Account</span>
            </v-card-title>

            <v-col cols="12" class="EDC-Col">
              <v-row class="EDC-Row EDC-RowsSpacing" :align="align"
                no-gutters style="padding-top:20px !important">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="Phone" label="Phone" required :rules="PhoneRules" dense/>
                </v-col>
                <v-col cols="2" class="EDC-Col  EDC-ColsSpacing" >
                      <label class = "text-capitalize" style="float:left"><b>User email verification:</b></label>
                    </v-col>
                    <v-col cols="6" class="EDC-Col" align="start">
                      <v-radio-group v-model="radios" row hide-details dense>
                        <v-radio class="radioClass" label="Enabled" value="1"/>
                        <v-radio class="radioClass" label="Disabled" value="0"/>
                      </v-radio-group>
                    </v-col>
                <!-- <v-col cols="4" class="EDC-Col EDC-ColsSpacing pa-1">
                  <v-select v-model="time_zone" :items="time_zoneitems" label="Time Zone" :rules="time_zoneRules" dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing pa-1">
                  <v-select v-model="date_format" :items="date_formatitems" label="Date Format" required :rules="date_formatRules" dense/>
                </v-col> -->
              </v-row>
              <!-- <v-row class="EDC-Row">
                    <v-col cols="3" class="EDC-Col  EDC-ColsSpacing" >
                      <label style="float:left"><b>User email verification:</b></label>
                    </v-col>
                    <v-col cols="4" class="EDC-Col" align="start">
                      <v-radio-group v-model="radios" row hide-details dense>
                        <v-radio class="radioClass" label="Enabled" value="1"/>
                        <v-radio class="radioClass" label="Disabled" value="0"/>
                      </v-radio-group>
                    </v-col>
              </v-row> -->

              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="password_expiry" label="Password Expiry in days" required :rules="password_expiryRules" dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="wrong_password_attempts" label="No. of Password attempts if wrong" required :rules="wrong_password_attemptsRules" dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="min_password_length" label="Minimum Password Length" required :rules="min_password_lengthRules" dense/>
                </v-col>
              </v-row>

              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="max_password_length" label="Maximum Password Length" required :rules="max_password_lengthRules" dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="password_cannot_be_same_as_previous_n_passwords" label="Password cannot be same as previous n passwords" required :rules="password_cannot_be_same_as_previous_n_passwordsRules" dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="download_limit" @blur="validateRowDownloadLimit" label="Row Download Limit (Max 1 Million)" required type="number" min="1" max="1000000"  dense/>
                </v-col>
              </v-row>

             <!--  <v-row class="EDC-Row">
                <label class="v-label"><b>Password must contain:</b></label>
              </v-row> -->
              <v-row class="EDC-Row">
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing class pa-1">
                  <label class="v-label text-capitalize text-center"><b>Password must contain:</b></label>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing">
                  <v-checkbox v-model="password_must_contain_numeric" label="Number" color="colorCode" class="customCheckbox"/>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing">
                  <v-checkbox v-model="password_must_contain_special_character" label="Special Character" color="colorCode" class="customCheckbox"/>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing">
                  <v-checkbox v-model="password_must_contain_uppercase" label="UpperCase" color="colorCode" class="customCheckbox"/>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing">
                  <v-checkbox v-model="password_must_contain_lowercase" label="Lower Case" class='customCheckbox' color="colorCode" />
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing"></v-col>
              </v-row>
              <v-row no-gutters class="EDC-Row" justify="end">
                <vc-button :loading="loading" :disabled="loading" itemText="Update" @click.native="submit"></vc-button>
                <vc-button itemText="Cancel" @click.native="$router.go(-1)"></vc-button>
              </v-row>
            </v-col>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  <loading-panel :loader="loader"></loading-panel>
  <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </v-container>
</template>

<script>
  import config from "../../config.json"
  import { post as postToServer } from './../../methods/serverCall.js';
  import { get as getToServer } from './../../methods/serverCall.js';
  import { SET_CLIENT_PREF, GET_CLIENT_DETAILS } from '../../data/url_constants.js'
  import { DATE_TIME_FORMAT } from '../../data/manage_account_constants.js'
  import {COLOR_CODE, BTN_COLOR} from '../../data/macros.js'
  import {Snackbar_Default_Timeout} from '@/constants/constants.js'

  export default {
    name: 'Schedule',
     
    props: {
      msg: String
    },

    data() {
      return {
        valid: false,
        snackbar:false,
        snackbartext:'',
        colorValue:'error',
        snackbartimeout: Snackbar_Default_Timeout,
        loader:false,
        loading: false,
        Phone: "",
        date_formatitems: DATE_TIME_FORMAT,
        time_zoneitems: ['UTC', 'Armenia Time', 'Argentina Time', 'Arabia Standard Time', 'Atlantic Standard Time', 'Asia/Calcutta'],
        time_zone: "",
        date_format: "",
        password_expiry: "",
        wrong_password_attempts: 0,
        min_password_length: 0,
        max_password_length: 0,
        password_must_contain_numeric: false,
        password_must_contain_uppercase: false,
        password_must_contain_lowercase: false,
        password_must_contain_special_character: false,
        password_cannot_be_same_as_previous_n_passwords: 0,
        min_password_lengthRules: [],
        max_password_lengthRules: [],
        wrong_password_attemptsRules: [],
        password_expiryRules: [v => !!v || 'Password Expiry is required.'],
        PhoneRules: [v => !!v || 'Phone is required.'],
        time_zoneRules: [v => !!v || 'Time zone is required.'],
        date_formatRules: [v => !!v || 'Date format is required.'],
        password_cannot_be_same_as_previous_n_passwordsRules: [],
        radios: 1,
        outlineColor:BTN_COLOR,
        colorCode:COLOR_CODE,
        download_limit:100000
      }
    },

    mounted () {
      this.getClient()
    },

    methods: {
      submit(){
        if (this.$refs.form.validate()) {
          this.updateData()
        }
      },

      getClient(){
        var Authorization = this.$session.get('access_token')
        this.loader = true;
        this.Organization = this.$session.get('client_id')
        var client_id = this.$session.get('client_id')
        getToServer(this, config.ACCOUNT_MANAGEMENT_URL + GET_CLIENT_DETAILS + client_id).then(response => {
        if(response){
          this.loader = false;
          var data = response;
          // client details
          this.Phone = data.client.phone
          this.time_zone = data.account_preference.time_zone_preference
          this.date_format = data.account_preference.date_format
          this.password_expiry = data.account_preference.password_policy
          this.wrong_password_attempts = data.account_preference.wrong_password_attempts
          this.min_password_length = data.account_preference.min_password_length
          this.max_password_length = data.account_preference.max_password_length
          this.password_must_contain_numeric = data.account_preference.password_allow_numeric
          this.password_must_contain_uppercase = data.account_preference.password_must_contain_uppercase
          this.password_must_contain_lowercase = data.account_preference.password_must_contain_lowercase
          this.password_must_contain_special_character = data.account_preference.password_must_contain_special_character
          this.radios = data.account_preference.user_email_verification.toString()
          this.password_cannot_be_same_as_previous_n_passwords = data.account_preference.password_cannot_be_same_as_previous_n_passwords
          this.download_limit = data.account_preference.download_limit
        }else{
          data = {}
          this.desserts = [];  
        }
        if(data)
          this.desserts = []
          this.desserts = data
        }).catch(DatasourceError => {
          this.loader = false;
          if(DatasourceError){ 
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = DatasourceError;
          }else{
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = 'Something went wrong.Try Again';
          }
        });
      },

      updateData () {
        let l = this.loader
        this[l] = !this[l]
        var Authorization = this.$session.get('access_token')
        var client_id = this.$session.get('client_id')
        var client_details = {
          'client_id': client_id,
          // 'phone' : this.Phone,
          'date_format': this.date_format,
          'time_zone_preference': this.time_zone,
          'wrong_password_attempts': this.wrong_password_attempts,
          'min_password_length': this.min_password_length,
          'max_password_length': this.max_password_length,
          'password_allow_numeric': this.password_must_contain_numeric?1:0,
          'pwd_contain_uppercase': this.password_must_contain_uppercase?1:0,
          'pwd_contain_lowercase': this.password_must_contain_lowercase?1:0,
          'pwd_contain_special_character': this.password_must_contain_special_character?1:0,
          'user_email_verification': parseInt(this.radios),
          'restrict_previous_n_passwords': this.password_cannot_be_same_as_previous_n_passwords,
          "download_limit":this.download_limit
        }
        postToServer(this, config.ACCOUNT_MANAGEMENT_URL + SET_CLIENT_PREF, client_details).then(clientResponse  => {
          if(clientResponse){
            this.loader = false;
            this.colorValue = 'success'
            this.snackbar = true
            this.snackbartext = clientResponse;
            this.$cookies.set('client_ref', true)
            // window.location.href = "/clientsettings"
            this.$router.push('/clientsettings')
          }
        }).catch(error_response => {
          this.loader = false;
          if(error_response){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = error_response;
            this[l]  = false
            this.loader = null
          } else {
            this.snackbar = true
            this.snackbartext = 'Something went wrong.Try Again';
            this.colorValue = 'error'
            this[l]  = false
            this.loader = null
          }
        });
      },
      validateRowDownloadLimit(){
        let value = parseInt(this.download_limit)
        if (Number.isInteger(value) && value >= 1 && value <= 1000000) {

        }
        else{
          this.download_limit = 100000
        }
      }
    }
  }
</script>

<style scoped>

 .radioClass >>> label{
    top: 3px !important;
}

.customCheckbox >>> label{
    top: 5px !important;
}

.v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important; 
}

.EDC-SchRow{
    padding-left:10px !important;
    padding-right:10px !important;
}

.EDC-ColRun{
    padding-top:15px !important;
}

.EDC-ColMainRadio{
    margin-top:6px !important;
}

EDC-VCard {
    padding: 0px !important;
    margin: 12px !important;
    margin-bottom: 0px !important;
    height: 100% !important;
    width: 1230px !important;
}
.EDC-UIPadding {
    padding-right:10px !important;
}
.label-margin label{
    margin-top:7%;
  }

</style>
