<template>
    <div>
        <!-- {{ queryObject }} -->
        <!-- {{ responseData }} -->
    </div>
</template>
<style scoped>

</style>
<script>
    export default{
        name:"widgetLanderRevised",
        data(){
            return{
                queryObject:{},
            }
        },
        mounted(){
            this.queryObject = this.$route.query
            this.$router.push({"name":"edcpathfinder",params:this.queryObject})
        },
        methods:{
            
        }
    }
</script>