<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col cols="8" class="EDC-Col">
                        <v-row class="EDC-Row breadcrumbRow">
                            <v-col class="EDC-Col">
                                <ul class="breadcrumb breadcrumbUL" v-if="isObjectBasedRole">
                                    <li id="lstitemenv">{{ this.selectedEnv.name }}</li>
                                    <li>
                                        <router-link to="/envandobjbasedrolelist">Security Role</router-link>
                                    </li>
                                    <li>
                                       Setup Object Security Role
                                    </li>
                                </ul>
                                <ul class="breadcrumb breadcrumbUL" v-else>
                                    <li id="lstitemenv">{{ this.selectedEnv.name }}</li>
                                    <li>
                                        <router-link to="/envandobjbasedrolelist">Security Role</router-link>
                                    </li>
                                    <li>
                                        Setup Action Security Role
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4">

                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-row align="start" class="EDC-Row EDC-TopRow">
                            <v-col cols="3" class="EDC-Col EDC-ColsSpacing">
                                <v-text-field label="Name of Role" id="txtrolename" v-model="roleName" :disabled="isDisabled" @blur="validateRoleName" class="EDC-UIComponent" hide-details autocomplete="off" dense></v-text-field>
                            </v-col>
                            <v-col cols="3" class="EDC-Col EDC-ColsSpacing EDC-ColDescription">
                                <v-textarea v-model="description" id="txtroledescription" label="Description" :disabled="isDisabled" value rows="1" class="EDC-UIComponent" hide-details dense/>
                            </v-col>
                            <!-- <v-col cols="3" class="EDC-Col EDC-ColsSpacing">
                                <v-autocomplete :items="EnvironmentList" v-model="selectedEnv" item-text="name" return-object label="Select Environment" class="EDC-UIComponent" dense></v-autocomplete>
                            </v-col> -->
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing EDC-ColDescription" v-if="isObjectBasedRole">
                                <vc-button v-if="!read_only_mode" id="btnsave" itemText="Save" @click.native="saveRole" :disabled="isDisabled"></vc-button>
                                <vc-button id="btncancel" itemText="Cancel" @click.native="cancel" ></vc-button>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="EDC-UIComponent"/>
                <v-row no-gutters class="EDC-Row EDC-GridRow">
                    <v-col class="EDC-Col" cols="12" v-if="isObjectBasedRole">
                        <edc-data-grid :dataList="tableList" @onDelete="onDelete" @onEdit="onEdit" @onNew="onNew" @onSave="saveRule" @onCancel="onCancel" @onValueUpdate="onValueUpdate" @performSearch="performSearch"></edc-data-grid>
                    </v-col>
                    <v-col class="EDC-Col" cols="12" v-if="isEnvironmentalRole">
                        <edc-action-based-rule :modulesFromParent="moduleList" :defaultSelectedItems="defaultSelectedItems"
                        @getSelectedModules="selectedModules=$event"></edc-action-based-rule>
                    </v-col>
                    <v-row class="EDC-Row" v-if="isEnvironmentalRole">
                        <v-col class="EDC-Col" align="right">
                            <vc-button id="btnsave" itemText="Save" @click.native="saveRole" :disabled="isDisabled"></vc-button>
                            <vc-button id="btncancel" itemText="Cancel" @click.native="cancel" ></vc-button>
                        </v-col>
                    </v-row>
                </v-row>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbarTimeout" id="edcmessage" top right>
            {{ snackbarText }}
        </v-snackbar>
        <loading-panel :loader="loader"></loading-panel>
    </v-container>
</template>
<style scoped>
    .customCheckbox >>> label {
        top: 5px !important;
    }
    .v-expansion-panel >>> .v-expansion-panel__header {
        padding-bottom: 0px !important;
    }
    
    .EDC-UIComponent {
        margin: 0px !important;
        padding: 0px !important;
    }
    
    .radioClass >>> label {
        top: 3px !important;
    }
    
    .EDC-TopRow {
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    
    .EDC-ColDescription {
        margin-top: -7px !important;
    }
    .EDC-ColEnv {
        margin-top: -22px !important;
    }
    
    .EDC-GridRow {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    
    .EDC-ButtonRow {
        padding-right: 10px !important;
        padding-bottom: 10px !important;
    }
</style>
<script>
    import { post as postToServer } from "./../../../methods/serverCall.js";
    import { get as getToServer } from "./../../../methods/serverCall.js";
    import config from "../../../config.json";
    import { SERVER_ERROR } from "../../../data/client_message.js";
    import {getEnvListForDataviewer} from '../../../methods/EnvironmentList.js'
    import {CLIENT_SIDE} from "@/data/macros.js";
    import getUserRole from '../../../methods/GetUserRole.js'
    import { SECURITY_SERVICE, MANAGE_OBJ_BASED_SECURITY } from '../../../data/macros.js';
    import {mixinSecurity} from '../../../mixins/securitymixin.js'

    export default {
        name:"ManageObjectBasedSecurity",
        mixins:[mixinSecurity],
        data(){
            return {
                selectedEnv:{},
                EnvironmentList:[],
                datasourceList: [],
                objectTypeList:[],
                accessTypeList:[],
                permissionTypeList:[],
                associatedObjectList:[],
                objectList:[],
                userRole:{},
                productCode: 'PF',
                tableList: {
                    headers:[],
                    actions:[],
                    sorting_type: CLIENT_SIDE,
                    filterType: CLIENT_SIDE,
                    paginationType: CLIENT_SIDE,
                    total_count: 0,
                    is_row_edit:true,
                    rows: [],
                    itemkey:'id',
                    selected_rows: [],
                    syncHeaderScroll:false,
                    hideExport:true,
                    hideFilter:true,
                    hideShowColumns:true,
                    tableLoading:false,
                    disableDefaltSorting:true,
                    is_grid_inline_edit:true,
                },
                objectTypeHeader:{ text: 'Object Type', value: 'object_type_code', id:"drpdnobjecttype", display_value:'object_type_name', width:"12%",  option:[], 'item_text':"object_type_name", 'item_value':"object_type_code", useAutoComplete:true, isRoleSetup:true,
                                    },
                accessTypeHeader:{ text: 'Access', value: 'access_code',id:"drpdnaccesscode", display_value:'access_right_name', option:[], width:"10%", 'item_text':'name', 'item_value':'access_code', useAutoComplete:true, isRoleSetup:true},
                permissionHeader:{ text: 'Permission', value: 'action_code', id:"drpdnactioncode",display_value:'action_type', width:"10%", option:[],'item_text':"action_type", 'item_value':"action_code", useAutoComplete:true,isRoleSetup:true},
                datasourceHeader:{ text: 'Datasource', value: 'datasource_index', id:"drpdndatasource", display_value:'datasource_name',width:"12%", option:[], 'item_text':'datasource_name', 'item_value':'datasource_index', useAutoComplete:true},
                associatedObjectHeader:{ text: 'Associated Object', width:"16%", id:"drndnassociatedobject", value: 'associated_object',
                                        display_value:'associate_object_text', 
                                        option:[], 'item_text':'display_name', 
                                        'item_value':'object_id', 
                                        useAdvanceAutoComplete:true,
                                        useMultiple:false,
                                        show_select_all:false,
                                        resetSelectCounter:0,
                                        gridDisplayTextKey:'associate_object_text',
                                        gridDisplayTextTitleKey:'associate_object_text',
                                        disabled:true,
                                        selectAllKey:"isAllAssociatedSelected"
                                    },
                objectsHeader:{ text: 'Object Name', value: 'object_details',id:"drpdnobjects", option:[],'item_text':'display_name', 'item_value':'object_id',headerFor:'',
                                useAdvanceAutoComplete:true,
                                useMultiple:true,
                                gridDisplayTextKey:"display_nameText",
                                gridDisplayTextTitleKey:"objectNameGridTitle",
                                show_select_all:true,
                                resetSelectCounter:0,
                                headerFor:'',
                                returnObject:true,
                                selectAllKey:'isAllObjectSelected',
                                isDynamicList:false
                            },
                businessViewList:[],
                datamapList:[],
                allBusinessObjectList:[],
                gridViewList:[],
                associateList: [],
                allTaskListList: [],
                datasourceConnectionDetails:{},  // This variable used to maintain connection details of DS. 
                                                //Else agent service will make call to environment service each time and it will cause overhead
                acceleratorDetails:{},
                tableRow:{
                "object_type_code": '',
                "access_code":'',
                "action_code":'',
                "datasource_index":'',
                "associate_object_id":'',
                "object_details":'',
                'is_row_editable' : true,
                'isAllObjectSelected':false
                },
                read_only_mode: false
            }
        },
        computed:{
            params() {
                return this.$route.query;
            },
            isDisabled(){
                if(this.roleName === 'Public role')
                return true
            return false
            }
        },
        watch:{
            'objectTypeList':{
                handler(newValue){
                    this.objectTypeHeader.option = newValue
                }
            },
            'accessTypeList':{
                handler(newValue){
                    this.accessTypeHeader.option = newValue
                }
            },
            'permissionTypeList':{
                handler(newValue){
                    this.permissionHeader.option = _.cloneDeep(newValue)
                }
            },
            'associatedObjectList':{
                handler(newValue){
                    this.associatedObjectHeader.option = newValue
                }
            },
            'objectList':{
                handler(newValue){
                    this.objectsHeader.option = newValue
                }
            },
            'selectedEnv':{
                handler(newValue){
                    this.datasourceList = []
                    this.businessViewList = []
                    this.datamapList = []
                    this.allBusinessObjectList = []
                    this.gridViewList = []
                    this.allTaskListList = []
                    this.associateList = []
                    this.acceleratorDetails = {}
                    if(newValue && newValue.id){
                        this.getDatasourceList()
                        this.fetchEDCObjects()
                        this.fetchAcceleratorDetails()
                    }
                }
            },
            'datasourceList':{
                handler(newValue){
                    this.datasourceHeader.option = newValue
                }
            },
        },
        mounted(){
            getEnvListForDataviewer(this);
            this.read_only_mode = this.$route.query.read_only_mode
            this.userRole = this.$session.get('user_role_mapping');
            this.tableList.headers = [this.objectTypeHeader,this.accessTypeHeader, this.permissionHeader, this.datasourceHeader, this.associatedObjectHeader, this.objectsHeader]
            this.tableList.actions = [
                {'text':'edit','key':"id", selectType:"single",role:(this.userRole.is_superadmin ||getUserRole(this.userRole,SECURITY_SERVICE, MANAGE_OBJ_BASED_SECURITY)) && !this.read_only_mode, index: 1},
                {'text':'new', id:"icnbtnrulenew", 'key':"object_type", 'enabled':this.Environment, role:(this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE, MANAGE_OBJ_BASED_SECURITY)) && !this.read_only_mode , index: 2},
                {'text':"save",id:"icnrulesave","key":"is_row_editable", selectType:"single",
                 role:(this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE, MANAGE_OBJ_BASED_SECURITY)) && !this.read_only_mode, index: 3},
                {'text':"cancel",id:"icnbtnrulecancel","key":"is_row_editable", selectType:"single", 
                role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE, MANAGE_OBJ_BASED_SECURITY), index: 4},
                {'text':'delete',id:"icnbtnruledelete", 'key':"id", selectType:"multiple",
                role:(this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,MANAGE_OBJ_BASED_SECURITY)) && !this.read_only_mode, index: 5}
            ]
            this.selectedEnv = {"id":this.$route.query.env_id, "name":this.$route.query.env_name}
            this.role_id = this.$route.query.role_id
            
        },
        methods: {
            getDatasourceList(){
                var _this = this
                this.datasourceList = []
                _this.loader = true
                getToServer(this, config.ENVIRONMENT_API_URL+'/get_ds_id_name_using_env_id_public/'+this.selectedEnv.id, true).then(response =>{
                    _.forEach(response,(obj,index)=>{
                        obj['datasource_index'] = index
                    })
                    _this.datasourceList = response
                    _this.loader = false
                    _this.manageTableRows()
                }).catch(errorResponse => {
                    _this.showMessage(errorResponse)
                });
            },
            getAllObjectTypes() {
                var _this = this
                var url = config.Security_URL + "/get_object_type";
                var input_data = {"product_code": this.productCode}
                postToServer(_this, url,input_data).then(response => {
                    
                    _this.objectTypeList = response
                }).catch(errorResponse => {
                    _this.showMessage(errorResponse)            
                }); 
            },
            GetAccessTypes() {
                var _this = this
                var url = config.Security_URL + "/get_access_rights";
                getToServer(_this, url).then(response => {
                    _this.accessTypeList = response;
                }).catch(errorResponse => {
                    _this.showMessage(errorResponse)
                }); 
            },
            GetPermissionTypes(objectType,header) {
                var _this = this
                if(!objectType)
                    return
                if(header && header.value ==='object_type_code')
                    _this.permissionTypeList = []
                var url = config.Security_URL + "/get_permissions_by_prod_and_object";
                var input_data = { "product_code": this.productCode,
                            "object_type_code": objectType}
                postToServer(_this, url, input_data).then(response => {
                    _this.permissionTypeList = response;
                }).catch(error_response => {
                    _this.showMessage(error_response)            
                }); 
            },
            cancel() {
                if(this.isEnvBasedEdcObjectSelected){
                    this.$router.push({"name":"envandobjbasedrolelist",params:{"roletype":'object_based'}}).catch(()=>{})
                    this.setsameComponentRedirectObj({"roletype":'environmental'})
                }
                else{
                    this.$router.push({"name":"envandobjbasedrolelist",params:{"roletype":'object_based'}}).catch(()=>{})
                    this.setsameComponentRedirectObj({"roletype":'object_based'})
                }
                
            },
            validateRoleName() {
                if(!this.roleName.trim()) {
                    this.roleName = "Untitled Role";
                }
            },
            onValueUpdate(record,header) {
                var _this = this
                this.objectsHeader.headerFor = this.getHeaderFor(record)
                this.objectsHeader.show_select_all = !this.isAliasSelected(record) && !this.isColSelected(record)
                this.objectsHeader.isDynamicList = this.isDBOSelected(record) || this.isAliasSelected(record) || this.isColSelected(record)
                 if(header){
                    if(this.isHeaderIsObjectType(header)){
                        _this.objectList = []
                        _this.associatedObjectHeader.option = []
                        record.datasource_index = ''
                        record.associated_object = ''
                        record.object_details = []
                        record.isAllObjectSelected = false
                        _this.GetPermissionTypes(record.object_type_code)
                        _this.datasourceHeader.disabled =  true
                        _this.associatedObjectHeader.disabled =  true
                        _this.objectsHeader.show_select_all = true
                        _this.objectsHeader.resetSelectCounter++;
                        if(_this.isRecordDatasourceDependant(record))
                            _this.datasourceHeader.disabled = false
                        
                        if(_this.isColSelected(record)){
                            _this.objectsHeader.show_select_all = false
                            _this.associatedObjectHeader.disabled =  false
                        }

                        else if(_this.isEnvBasedEdcObjectSelected(record)){
                            _this.assignEDCObjectList(record, true)
                        }
                        else if(_this.isAliasSelected(record)){
                            _this.getAliasList('',{})
                        }
                    }
                    else if(this.isHeaderIsDatasource(header)){
                        _this.objectList = []
                        _this.associatedObjectHeader.option = []
                        _this.objectsHeader.resetSelectCounter++;
                        if(_this.isDBOSelected(record) ||  _this.isColSelected(record))
                            _this.getTableList(record.datasource_index,record, '',header)
                        
                        else if(_this.isBusinessObjectTypeSelected(record))
                            _this.fetchEDCObjects(record, true)
                    }
                    else if(this.isHeaderIsAssociate(header)){
                        _this.objectList = []
                        _this.objectsHeader.resetSelectCounter++;
                        _this.getColumnList(record.datasource_index,record.associated_object,record)
                    }
                 }
                 else if(record.id){
                    _this.associatedObjectHeader.disabled = true
                    _this.datasourceHeader.disabled = true
                    _this.fetchEDCObjects(record)
                    _this.GetPermissionTypes(record.object_type_code,header)
                    if(_this.isDBOSelected(record) || _this.isColSelected(record)){
                        _this.datasourceHeader.disabled = false
                        if(_this.isColSelected(record)){
                            _this.associatedObjectHeader.disabled = false
                        }
                        _this.getTableList(record.datasource_index,record,'', header)
                    }
                    if(_this.isAssociateObjectTypeSelected(record)){
                        _this.getColumnList(record.datasource_index,record.associate_object_id, record)
                    }
                    if(_this.isDBOSelected(record)){
                        _this.datasourceHeader.disabled = false
                        _this.associatedObjectHeader.disabled = true
                    }
                    if(_this.isBusinessObjectTypeSelected(record)){
                        _this.associatedObjectHeader.disabled = true
                        _this.datasourceHeader.disabled = false
                    }
                    if(_this.isBVObjectTypeSelected(record) || _this.isDatamapObjectTypeSelected(record) || _this.isGridViewObjectTypeSelected(record) || _this.isAliasSelected(record)|| _this.isTaskListbjectTypeSelected(record)){
                        _this.associatedObjectHeader.disabled = true
                        _this.datasourceHeader.disabled = true
                    }
                    if(_this.isAliasSelected(record)){
                        _this.datasourceHeader.disabled = true
                        _this.associatedObjectHeader.disabled = true
                        _this.getAliasList('', record)
                    }                   
                }
            },
            isHeaderIsObjectType(header){
                return header.value === 'object_type_code'
            },
            isHeaderIsDatasource(header){
                return header.value === 'datasource_index'
            },
            isHeaderIsAssociate(header){
                return header.value === 'associated_object'
            },
            isAliasSelected(record){
                return record.object_type_code === 'ALS'
            },
            isRecordDatasourceDependant(record){
                return this.isBusinessObjectTypeSelected(record) || this.isDBOSelected(record) || this.isColSelected(record)
            },
            isDBOSelected(record){
                return record.object_type_code === "DBO"
            },
            isColSelected(record){
                return record.object_type_code === "COL"
            },
            isBVObjectTypeSelected(record){
                return record.object_type_code === "BV"
            },
            isBusinessObjectTypeSelected(record){
                return record.object_type_code === "BO"
            },
            isDatamapObjectTypeSelected(record){
                return record.object_type_code === "DM"
            },
            isGridViewObjectTypeSelected(record){
                return record.object_type_code === "GV"
            },
            isTaskListbjectTypeSelected(record){
                return record.object_type_code === "TL"
            },
            getHeaderFor(record){
                if(this.isDBOSelected(record))
                    return 'Tables'
                if(this.isColSelected(record))
                    return 'Columns'
                if(this.isBusinessObjectTypeSelected(record))
                    return 'Business Objects'
                    if(this.isBVObjectTypeSelected(record))
                    return 'Business Views'
                if(this.isGridViewObjectTypeSelected(record))
                    return 'Gridviews'
                if(this.isTaskListbjectTypeSelected(record))
                    return 'Task Lists'
                if(this.isAliasSelected(record))
                    return 'Aliases'
                if(this.isDatamapObjectTypeSelected(record))
                    return 'Datamaps'

            },
            isEnvBasedEdcObjectSelected(record){
                return this.isBVObjectTypeSelected(record)  || this.isDatamapObjectTypeSelected(record)||this.isGridViewObjectTypeSelected(record) || this.isTaskListbjectTypeSelected(record)
            },
            isAssociateObjectTypeSelected(record){
                if(record.object_type_code === "COL"){
                    return true
                }
                return false
            },
            fetchEDCObjects(record){
                var _this = this
                if(!this.selectedEnv.id)
                    return
                let dataToSend = {
                    "env_id":this.selectedEnv.id,
                    "client_id": this.clientId,
                    "filter": [],
                    "page": 1,
                    "page_size": 500,
                    "sort": []
                }
                postToServer(_this, config.PUBLISHER_URL +'/published_objects_in_env_for_dv_public', dataToSend, false).then(response=>{
                    var objList = []
                    _.forEach(response.result,function(obj){
                        obj.display_name = obj.object_name + " ("+ obj.object_id +")"
                        objList.push({'object_type':obj.object_type, "display_name":obj.display_name, 
                        'object_name':obj.object_name, "object_id":obj.object_id})
                    })

                    _this.businessViewList = _.filter(response.result, { 'object_type': 'BV'});
                    _this.datamapList = _.filter(response.result, { 'object_type': 'DM'});
                    _this.gridViewList = _.filter(response.result, { 'object_type': 'GV'});
                    _this.associateList = _.filter(response.result, {'object_type': 'COL'});
                    _this.allBusinessObjectList = _.filter(response.result, { 'object_type': 'BO'});
                    _this.allTaskListList = _.filter(response.result, { 'object_type': 'TL'});
                    if(record)
                        _this.assignEDCObjectList(record)

                }).catch(ProcessDocError => {
                    _this.showMessage(ProcessDocError)
                })
            },
            assignEDCObjectList(record, isFromValueChange=false){
                if(this.isBVObjectTypeSelected(record)){
                    this.objectList = _.cloneDeep(this.businessViewList)
                } else if(this.isDatamapObjectTypeSelected(record)){
                    this.objectList = _.cloneDeep(this.datamapList)
                } else if(this.isBusinessObjectTypeSelected(record)){
                    this.objectList = _.cloneDeep(this.allBusinessObjectList)
                } else if(this.isGridViewObjectTypeSelected(record)){
                    this.objectList = _.cloneDeep(this.gridViewList)
                } else if(this.isAssociateObjectTypeSelected(record)){
                    this.objectList = _.cloneDeep(this.associateList)
                }else if(this.isTaskListbjectTypeSelected(record)){
                    this.objectList = _.cloneDeep(this.allTaskListList)
                }
                else {
                    // alert(record.object_type_code)
                    // alert("Object type not mapped")
                    return
                }


                if(!this.objectList.length && isFromValueChange){
                    this.fetchEDCObjects(record)
                }
            },
            onNew(record){
                let obj = _.find(this.tableList.rows,["is_row_editable",true])
                this.tableList.headers[3].disabled = true
                if(obj)
                {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = 'First fillup the open row';
                    return
                }
                this.tableList.rows.unshift(_.cloneDeep(this.tableRow));      
            },
            onEdit(record){
                
            },
            onCancel(record)
            {
                let obj = _.find(this.tableList.rows,["is_row_editable",true])
                if(record.id){
                    delete obj.is_row_editable
                    this.getRoleDetails(this.$route.query.role_id)
                }
            else 
                this.tableList.rows.splice(0,1)
            },
            saveRule(record){
                var data = {}
                var objList = []
                var _this = this
                _this.loader = true;
                var associate_description = ''
                var associate_id = ''
                var associate_name = ''
                var associateData = _this.associatedObjectHeader.option
              
                for(let i =0 ; i< associateData.length; i++){
                    if(associateData[i]['object_id'] == record.associated_object){
                        associate_id = associateData[i]['object_id']
                        associate_name = associateData[i]['object_name']
                        associate_description = associateData[i]['display_name']
                    break;
                }
                }
                let rowData = _.cloneDeep(record)
                var client_id = this.$session.get('client_id');
                if(rowData.isAllObjectSelected)
                    objList = [{
                        object_id : 'all',
                        object_name : 'All',
                        description : 'All '+ this.objectsHeader.headerFor
                    }]
                else
                    for(let i in rowData.object_details){
                        objList.push({
                            object_id : rowData.object_details[i]['object_id'],
                            object_name : rowData.object_details[i]['object_name'],
                            description : rowData.object_details[i]['display_name']
                        })
                    }
                if(rowData.datasource_index === ''){
                    rowData.datasource_index = null
                } 
                if(!rowData.object_type_code || !rowData.access_code || !rowData.action_code || !objList.length){
                    _this.showMessage("Fields Object Type, Access, Permission, Object Names are compulsory")
                    return
                } else if((_this.isBusinessObjectTypeSelected(record) || _this.isDBOSelected(record) || _this.isColSelected(record)) && (record.datasource_index==='' || record.datasource_index===null)){
                    _this.showMessage("Please Select Datasource Name")
                    return
                } else if(_this.isColSelected(record) && !record.associated_object){
                    _this.showMessage("Please Select Associated Object Name")
                    return
                }
                data["role_id"] = this.roleId
                data["client_id"] = parseInt(client_id)
                data["env_id"] = this.selectedEnv.id
                data["object_rule_data"] = {"object_type_code":rowData.object_type_code,
                                                "access_code":rowData.access_code,
                                                "action_code":rowData.action_code,
                                                "datasource_index": rowData.datasource_index,
                                                "associate_object_id":associate_id,
                                                "associate_object_name": associate_name,
                                                "associate_object_description": associate_description,
                                                "object_details":objList
                                            }
                if(!rowData.id){
                    var url = config.Security_URL + "/add_object_based_rule";
                }
                else{
                    var url = config.Security_URL + "/update_object_based_rule";
                    data["object_rule_data"]['rule_id'] = rowData.id

                }
                if(data){
                    postToServer(_this, url, data).then(response => {
                        if(response.skipped_object_response == null){
                            _this.showMessageWithTimeout(response.response, "success")
                        }
                        else{
                            _this.showMessageWithTimeout(response.skipped_object_response, "error")
                            _this.loader = false;
                        }
                        _this.getRoleDetails(this.$route.query.role_id)

                    })
                    .catch(errorResponse => {
                        _this.showMessage(errorResponse)
                    });
                }
            },
            onDelete(records){
                var _this = this
                var data = {"id_list":_.map(records, "id"),"role_id":this.roleId, "client_id":this.clientId,
            "delete_by":this.$session.get('email'), "env_id":this.selectedEnv.id}
                var url = config.Security_URL + "/delete_object_based_rule";
                postToServer(_this, url, data).then(response => {
                    _this.getRoleDetails(this.$route.query.role_id)
                    _this.showMessageWithTimeout("Rule deleted successfully", "success")
                })
                .catch(errorResponse => {
                    _this.showMessage(errorResponse)
                });
            },
            showMessageWithTimeout(message, type){
                var _this =this
                setTimeout(()=>{
                    _this.showMessage(message, type)
                },1500)
            },
            saveObjectBasedRole() {
                var _this = this
                var client_id = this.$session.get('client_id');
                var data = {
                    "client_id": client_id,
                    "env_id": this.$route.query.env_id,
                    "env_name": this.$route.query.env_name,
                    "role_name": this.roleName,
                    "role_type": "object_based",
                    "product_name" :window.sessionStorage.getItem('product') ,
                    "description": this.description,
                }
                if(!this.role_id)
                    var url = config.Security_URL + "/create_role";
                else{
                     var url = config.Security_URL + "/update_role";
                    data["role_id"] = this.role_id
                }
                postToServer(_this, url, data).then(response => {
                    this.snackbar = true;
                    this.colorValue = "success";
                    this.roleId = response.role_id
                    this.snackbarText = "Role save successfully";
                    // this.getRoleDetails(this.roleId)
                })
                .catch(errorResponse => {
                    _this.showMessage(errorResponse)
                });
            },
            manageTableRows(){
                // This function is just fot tieup the values for datasource index with datasource name
                var _this = this
                if(!_this.datasourceList.length || !_this.tableList.rows.length)
                    return
                _.forEach(_this.tableList.rows,(obj)=>{
                    if(obj.datasource_name)
                        return
                    if (isNaN(parseInt(obj.datasource_index)))
                        return
                    obj.datasource_name = _this.datasourceList[parseInt(obj.datasource_index)].datasource_name
                })

            },
            getTableList(datasource_index, record, searchText='',header={}){
                var _this = this
                if(header && header.value === 'datasource_index'){
                    this.objectList = []
                }
                let datasource_id = this.datasourceList[datasource_index].datasource_id
                var data = {
                    "requestForBO":true,
                    "datasource_id":datasource_id,
                    "search_text":searchText,
                    "available_datasource_list":this.datasourceConnectionDetails[datasource_id] || [],
                    "client_id":_this.clientId,
                    "env_id":_this.selectedEnv.id,
                    'description_needed':true,
                    'check_in_description':true
                }
                
                postToServer(_this,config.AGENT_API_URL+'/get_tables_by_dataviewer_env', data).then(response=>{
                    let tableList = response.available_table_list['table_list']
                    _.forEach(tableList, (obj)=>{
                        obj.object_name =  obj.title
                        obj.object_id = obj.title
                        if(obj.description)
                            obj['display_name'] = obj.description + " ("+obj.title+")"
                        else
                            obj['display_name'] = obj.title
                        obj.object_type = 'DBO'
                        
                    })
                    if(_this.isDBOSelected(record)){
                        // let allObj = {'object_name':'All', 'description':'All',"display_name":'All', "object_id":"all"} 
                        // tableList.unshift(allObj)
                        if(record.object_details && record.object_details.length && record.object_details[0]['object_id']!='all'){
                            tableList = _this.rearangeSelectedTable(record.object_details,tableList)
                        }
                        _this.objectList = _.cloneDeep(tableList)
                    }
                    else if(_this.isColSelected(record)){
                        if(record.associated_object){
                            tableList = _this.rearangeSelectedTable([{"object_id":record.associated_object,
                        "object_name":record.associated_object, "display_name":record.associate_object_text}],tableList)
                            setTimeout(()=>{
                                _this.getColumnList(record.datasource_index,record.associated_object, record)
                            },1000)
                        }
                        _this.associatedObjectHeader.option = tableList
                    }
                        _this.datasourceConnectionDetails[datasource_id] = [response.available_datasource_list]
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            rearangeSelectedTable(selectedItems, allItemList){
                for(let i=0;i<selectedItems.length;i++){
                    let currentSelected = selectedItems[i]
                    for(let j=0;j<allItemList.length;j++){
                        let currentOption = allItemList[j]
                        if(currentOption.object_id === currentSelected.object_id){
                            allItemList.splice(j,1)
                            break;
                        }
                    }
                }
                return selectedItems.concat(allItemList)
            },
            getColumnList(datasource_index, table_name, record){
                if(!table_name)
                    return
                var _this = this
                let datasource_id = this.datasourceList[datasource_index].datasource_id
                let request_data = {
                    "client_id":_this.clientId,
                    "env_id":_this.selectedEnv.id,
                    'description_needed':true,
                    'check_in_description':true,
                    "table_name":table_name
                }
                if(this.datasourceConnectionDetails[datasource_id])
                    Object.assign(request_data,_.cloneDeep(this.datasourceConnectionDetails[datasource_id][0]))
                else{
                    // tablelist will give us the datasourceConnectionDetails data so we are just calling it
                    _this.getTableList(datasource_index,{})
                    // on edit table load might take some time
                    return setTimeout(()=>{
                        _this.getColumnList(datasource_index, table_name, record)
                    }, 2000)
                }
                
                request_data['table_name'] = table_name
                postToServer(_this, config.AGENT_API_URL +'/get_all_columns', request_data).then(response=>{
                    let column_list = response.column_list
                    var revisedColumnList = []
                    _.forEach(column_list, (obj)=>{
                        let colObj = {"object_name": obj, "object_id":obj, "display_name":obj, "description":obj}
                        let subStringAlias = obj.substring(2,obj.length)
                        let descObj = _.find(response.description, ['column_name',subStringAlias])
                        if(descObj){
                            colObj['description'] = descObj.description
                            colObj['display_name'] = descObj.description + "("+obj+")"
                        }
                        revisedColumnList.push(_.cloneDeep(colObj))
                    })
                    if(record && record.object_details && record.object_details.length){
                        revisedColumnList = _this.rearangeSelectedTable(record.object_details, revisedColumnList)
                    }
                    _this.objectList = _.cloneDeep(revisedColumnList)
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            getAliasList(searchText,record){
                var _this = this
                if(_.isEmpty(_this.acceleratorDetails)){
                    alert("Accelerator details not found")
                    return
                }
                let dataToSend = {"client_id":this.clientId,'search_alias':searchText}
                dataToSend['data_source_details'] = _this.acceleratorDetails
                let url = config.JDE_FORMATER_URL +'/fetch_data_items_for_alias'
                postToServer(_this, url, dataToSend).then(response=>{
                    let databaseAlises = []
                    for(let i=0;i<response.length;i++){
                        let obj = response[i]
                        if(obj.alias_type == 'C' && obj.alias.split(",").length >1){
                            continue
                        }
                        obj.object_id = obj.alias
                        if(obj.description){
                            obj.description = obj.description.trim()
                            obj.object_name = obj.description
                        }
                        else
                            obj.object_name = obj.alias
                        obj.display_name = obj.object_name + "("+obj.object_id+")"
                        databaseAlises.push(
                            {"object_id":obj.object_id, "object_name":obj.object_name, 
                            "description":obj.description,"display_name":obj.display_name}
                        )

                    }
                    if(record.object_details && record.object_details.length){
                        databaseAlises = _this.rearangeSelectedTable(record.object_details,databaseAlises)
                    }
                    _this.objectList = _.cloneDeep(databaseAlises)
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            fetchAcceleratorDetails(){
                var _this = this
                var dataToSend = {
                    "env_id":this.selectedEnv.id,
                    "client_id":_this.clientId,
                    "return_with_env_details":true
                }
                postToServer(_this, config.ENVIRONMENT_API_URL +'/get_datasource_details_with_env_id_for_dv_public', dataToSend).then(response=>{
                    let envDsDetails = response['all_datasource_details'][0]
                    if(envDsDetails['accelerator_details'] && envDsDetails['accelerator_details']['dictionary_datasource'])
                        _this.acceleratorDetails = envDsDetails['accelerator_details']['dictionary_datasource']
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            performSearch(searchText,header,record){
                if(!searchText)
                    searchText = ''
                if(this.isDBOSelected(record) || (this.isColSelected(record) && header.value === 'associated_object')){
                    this.getTableList(record.datasource_index,record,searchText)
                }
                if(this.isAliasSelected(record)){
                    this.getAliasList(searchText, record)
                }

            }
            
        },
    }
</script>