<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col cols="8" class="EDC-Col">
                        <ul class="breadcrumb">
                            <li>
                                <select v-model="Environment" v-on:change="getUserDashboard" style="-webkit-appearance: menulist" autofocus ref="envname">
                                    <option :value="{}">Select Environment</option>
                                    <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                                </select>
                            </li>
                            <li v-if="changeDashboard" @dblclick="toggleEditName">
                                <select 
                                    v-model="dashboardId" 
                                    @change="onDashbordSelectionChange" 
                                    @blur="changeDashboard = false"
                                    style="-webkit-appearance: menulist" 
                                >
                                    <option 
                                        v-for="dashboard in availableDashboardList" 
                                        :value="dashboard.dashboard_id" 
                                        :key="dashboard.dashboard_id+'dashboard'"
                                    >
                                        {{ dashboard.dashboard_name }}
                                    </option>
                                </select>
                            </li>
                            <li v-else-if="editableName" >
                                <input type="text" v-model="dashboardName"
                                @blur="validatedName" class="dashboard-input"
                                ref="dashboardInput"
                                @keyup.enter="validatedName">
                            </li>
                            <li v-else @click="toggleChangeDashboard(true)" @dblclick="toggleEditName">
                                {{ dashboardName }}</li>
                        </ul>
                    </v-col>
                    <v-col cols="4" class="EDC-Col d-flex justify-end">
                        <vc-button itemText="Delete Dashboard" v-if="isDashboardSelected" 
                        @click.native="showDeleteConfirm"/>
                        <vc-button itemText="Add Widget" @click.native="onAddNew"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row no-gutters class="pb-4">
            <template v-for="(widget,ind) in availableWidgetList">
                <v-col :cols="widget.currentColSize" class="pa-0 py-1" :key="widget.uniqueKey">
                    <resizableWidgetCard :widgetObjectJson="widget.widgetObject"
                    :propColSize="widget.currentColSize"
                    @removeWidget="removeWidget(ind)" @onEdit="onEdit($event, widget.widgetTitle, 
                    widget.selectedWidget,ind)"
                    @onCopy="onCopy($event,widget.selectedWidget,ind)"
                    @colSizeChanged="onColSizeChanged($event,widget)"
                    @onWidgetResize="onWidgetResize($event,widget)"
                    @onWidgetTitleChanged="onWidgetTitleChanged($event,widget)"
                    :propHeightSize="widget.currentHeightSize"
                    :widgetDefaultTitle="widget.widgetTitle"/>
                </v-col>
            </template>
        </v-row>
        <v-dialog v-model="showPopup" persistent width="440" class="widget-dialog-box">
            <widget-selection @close="showPopup = false" :key="'key'+keyIncremental"
            :Environment="Environment" @onSaveWidget="onSaveWidget"
            :widgetJsonForEdit="widgetJsonForEdit"
            :selectedWidgetName="selectedWidgetName"
            :selectedWidgetIdForEdit="selectedWidgetIdForEdit"
            />
        </v-dialog>
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        <loading-panel :loader="loader"></loading-panel>
        <simplert :useRadius="true" :useIcon="true" ref="simplert"></simplert>
    </div>
</template>
<style scoped>
    .dashboard-input {
        border: 2px solid #000000; /* Adds a solid blue border */
        border-radius: 4px; /* Optional: rounds the corners */
        outline: none; /* Removes the default blue outline on focus */
    }

    .dashboard-input:focus {
        border-color: #000000; /* Changes border color on focus */
        box-shadow: 0 0 5px rgba(0, 91, 187, 0.5); /* Adds a slight shadow on focus */
    }
</style>
<script>
    import resizableWidgetCard from './resizableWidgetCard.vue';
    import config from '@/config.json'
    import { post as postToServer } from '@/methods/serverCall.js';
    import {getDashboardOfUser} from "./../../methods/layoutFunc"
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import { getEnvListForDataviewer} from './../../methods/EnvironmentList.js'
    import widgetSelection from './widgetOptions/widgetSelection.vue';
    import { v4 as EDCuuidv4 } from 'uuid';
    import Simplert from 'vue2-simplert'
    import {widgetDefaultHeightSize,widgetDefaultWidthSize} from "./../../constants/widgetConsts"

    export default{
        name:"DashboardDesignerDev",
        components:{
            resizableWidgetCard,
            'widget-selection':widgetSelection,
            'simplert':Simplert
        },
        data(){
            return{
                dashboardName: "Untitled Dashboard",
                oldDashboardName:"",
                dashboardId:"",
                snackbar:false,
                snackbartext:'',
                colorValue:'error',
                snackbartimeout: Snackbar_Default_Timeout,
                loader: false,
                savedWidgetList:[],
                Environment: {},
                EnvironmentList:[],
                componentKey:1,
                layoutList:[],
                layoutId:"LYT0001",
                showPopup:false,
                keyIncremental:1,
                availableWidgetList:[],
                selectedWidgetIdForEdit:"",
                widgetJsonForEdit:{},
                selectedWidgetName: "",
                editIndex:-1,
                currentColSize:widgetDefaultWidthSize,
                allDashboardList: [],
                availableDashboardList:[],
                dashboardDetails: {},
                currentHeightSize:widgetDefaultHeightSize,
                last_selected_dashboard_id: "",
                isAllWidgetLoaded:false,
                changeDashboard:false,
                editableName:false,
                saveTimer:null
            }
        },
        watch:{
            'EnvironmentList':{
                handler(newValue){
                    
                    if(_.isEmpty(this.$session.get('selected_env'))){
                        this.Environment = newValue[0]
                        this.$session.set(newValue[0])
                        this.getUserDashboard()
                    }                        
                }
            },
        },
        computed:{
            userName(){
                return this.$session.get("email")
            },
            clientId(){
                return this.$session.get("client_id")
            },
            isDashboardSelected(){
                return this.dashboardId
            }
        },
        mounted(){
            getEnvListForDataviewer(this,false)
            this.Environment = this.$session.get('selected_env')
            this.getUserDashboard()
        },
        methods:{
            getUserDashboard(){
                var _this = this
                this.resetDashboardPage()
                _this.componentKey++
                if(_.isEmpty(_this.Environment))
                    return
                _this.loader = true
                getDashboardOfUser(_this,_this.Environment).then(response=>{
                    _this.loader = false
                    let dashboardDetails = response.selected_dashboard
                    _this.allDashboardList= _.filter(response.all_env_dashboards,(obj)=>{
                        return !obj.is_default
                    })
                    _this.toggleChangeDashboard()
                    _this.mapDashboardDetails(dashboardDetails)
                }).catch(error=>{
                    _this.loader = false
                    alert(error)
                })
            },
            getDashboardDetails(dashboardId){
                var _this = this
                var data = {
                    "username": this.userName,
                    "dashboard_id":dashboardId,
                    "client_id": this.clientId,
                    "env_id":this.Environment.id
                }
                var url = config.USER_PROVISION_URL + "/get_dashboard_details"
                // console.log("data=>",data)
                this.loader = true
                postToServer(_this, url,data).then(response=>{
                    _this.loader = false
                    _this.mapDashboardDetails(response)
                }).catch(error=>{
                    _this.loader = false
                    alert(error)
                })
            },
            onDeleteDashboard(){
                var _this = this
                var data = {
                    "dashboard_id": this.dashboardId,
                    "env_id": this.Environment.id,
                    "username": this.userName
                }
                this.loader = true
                postToServer(_this,config.USER_PROVISION_URL+'/delete_user_dashboard',data).then(response=>{
                    _this.showMessage("Dashboard deleted successfully",'success')
                    _this.resetDashboardPage()

                    _this.allDashboardList = _.filter(_this.allDashboardList,(obj)=>{
                        return obj.dashboard_id != _this.dashboardId
                    })
                    if(_this.allDashboardList.length > 0){
                        _this.dashboardId = _this.allDashboardList[0].dashboard_id
                        _this.dashboardName = _this.allDashboardList[0].dashboard_name
                        _this.getDashboardDetails(_this.dashboardId)
                    } else{
                        // work needed, add check for default
                        _this.dashboardId = null
                        _this.dashboardName = "Untitled Dashboard"
                    }

                }).catch(error=>{
                    _this.showMessage(error)  
                })
            },
            resetDashboardPage(){
                this.availableWidgetList = []
                this.dashboardName = ""
                this.oldDashboardName = ""
                this.isAllWidgetLoaded = false
            },
            mapDashboardDetails(dashboardDetails){
                var _this = this
                if(_.isEmpty(dashboardDetails)){
                    this.dashboardId=null
                    this.dashboardName = "Untitled Dashboard"
                    this.editableName = true
                    return
                }

                _this.loader = true
                _this.isAllWidgetLoaded = false
                _this.dashboardId = dashboardDetails.dashboard_id
                _this.dashboardName = dashboardDetails.dashboard_name
                for(let i=0;i<dashboardDetails.widgets.length;i++){
                    let currentRec = dashboardDetails.widgets[i]
                    let widgetModifiers = currentRec.widget_modifiers
                    let uiComponents = currentRec.widget_data.uiComponents
                    for(let j=0;j<uiComponents.length;j++){
                        let key = uiComponents[j].key
                        if(!widgetModifiers.selectedValues[key])
                            continue
                        uiComponents[j]['selectedValue'] = widgetModifiers.selectedValues[key].selectedValue
                    }
                    let modifierComponents = currentRec.widget_data.modifiers
                    if(!_.isEmpty(modifierComponents)){
                        for(let j=0;j<modifierComponents.length;j++){
                            let key = modifierComponents[j].key
                            modifierComponents[j]['selectedValue'] = widgetModifiers.selectedModifiers[key]
                        }
                    }
                    this.availableWidgetList.push({
                        "widgetObject":currentRec.widget_data,
                        "selectedWidget":currentRec.master_widget_id,
                        "currentColSize":widgetModifiers.currentColumnSize || this.currentColSize,
                        "uniqueKey":currentRec.widget_unique_id,
                        "currentHeightSize":widgetModifiers.currentHeightSize || this.currentHeightSize,
                        "allComponentSelectedValues":widgetModifiers.selectedValues,
                        "allModifierSelectedValues":widgetModifiers.selectedModifiers,
                        "widgetTitle":widgetModifiers.widgetTitle
                    })
                }
                _this.loader = false
                setTimeout(()=>{
                    _this.isAllWidgetLoaded = true
                },3000)
            },
            toggleChangeDashboard(isClicked) {
                this.availableDashboardList =[]
                if(isClicked)
                    this.changeDashboard = !this.changeDashboard;
                const allDashboards = [{ dashboard_id: null, dashboard_name: "Create New Dashboard" }]
                // Iterate over all dashboards
                this.allDashboardList.forEach(dashboard => {
                    allDashboards.push({
                        dashboard_id: dashboard.dashboard_id,
                        dashboard_name: dashboard.dashboard_name
                    });
                });

                // Return the list of available dashboards excluding the selected one
                this.availableDashboardList = allDashboards;
                if(allDashboards.length === 1){
                    this.dashboardId=null
                    this.dashboardName = "Untitled Dashboard"
                    this.editableName = true
                }
            },
            onDashbordSelectionChange(){
                this.resetDashboardPage()
                if(this.dashboardId)
                    this.getDashboardDetails(this.dashboardId)    
                else{
                    this.editableName = true
                    this.changeDashboard = false
                    this.dashboardName = "Untitled Dashboard"
                }
                    
            },
            toggleEditName(){
                this.changeDashboard = false
                this.editableName = !this.editableName
                this.oldDashboardName = this.dashboardName
            },
            validatedName(){
                if(!this.dashboardName)
                    this.dashboardName = this.oldDashboardName
                this.editableName = false
                if(this.dashboardId !== "" && this.dashboardId !== null && this.dashboardId !== null){
                    this.makeSaveCall()
                }
                // if(this.isDashboardSelected)
                //     this.makeSaveCall()
            },
            onAddNew(){
                this.resetEditData()
                this.keyIncremental++
                this.showPopup = true
            },
            onSaveWidget(widgetObject,selectedWidget,selectedWidgetName,isEdit,allComponentSelectedValues,allModifierSelectedValues){
                if(isEdit){
                    this.availableWidgetList[this.editIndex]['widgetObject'] = widgetObject
                    this.availableWidgetList[this.editIndex]['widgetTitle'] = selectedWidgetName
                    this.availableWidgetList[this.editIndex]['allComponentSelectedValues'] = allComponentSelectedValues
                    this.availableWidgetList[this.editIndex]['allModifierSelectedValues'] = allModifierSelectedValues
                } else{
                    let uniqueKey = EDCuuidv4()
                    this.availableWidgetList.push({
                        "widgetObject":widgetObject,
                        "selectedWidget":selectedWidget,
                        "currentColSize":this.currentColSize,
                        "uniqueKey":uniqueKey,
                        "currentHeightSize":this.currentHeightSize,
                        "allComponentSelectedValues":allComponentSelectedValues,
                        "allModifierSelectedValues":allModifierSelectedValues,
                        "widgetTitle":selectedWidgetName
                    })
                }
                this.showPopup = false
                this.isAllWidgetLoaded = true
                this.makeSaveCall()
            },
            addWidgetToList(widgetObject,selectedWidget,currentColSize,currentHeightSize,uniqueKey,allComponentSelectedValues,allModifierSelectedValues,widgetTitle){

            },
            removeWidget(widgetIndex){
                this.resetEditData()
                this.availableWidgetList.splice(widgetIndex,1)
                this.isAllWidgetLoaded = true
                this.makeSaveCall()
            },
            onEdit(widgetJson, selectedWidgetName, selectedWidgetId,editIndex){
                this.resetEditData()
                console.log(widgetJson)
                this.selectedWidgetIdForEdit = selectedWidgetId
                this.widgetJsonForEdit = _.cloneDeep(widgetJson)
                this.selectedWidgetName = selectedWidgetName
                this.editIndex = editIndex
                this.showPopup = true
            },
            onCopy(widgetJson,selectedWidgetId,index){
                let copiedFrom = _.cloneDeep(this.availableWidgetList[index])
                let uniqueId = EDCuuidv4()
                copiedFrom['uniqueKey'] = uniqueId
                copiedFrom['widgetTitle'] = 'Copy Of '+copiedFrom['widgetTitle']
                this.availableWidgetList.push(copiedFrom)
                this.makeSaveCall()
                // console.log("widgetJson")
            },
            resetEditData(){
                this.selectedWidgetIdForEdit = ""
                this.widgetJsonForEdit = {}
                this.editIndex = -1
                this.keyIncremental++
            },
            onColSizeChanged(currentColSize, widgetObj){
                widgetObj.currentColSize = currentColSize
                this.makeSaveCall()
            },
            onWidgetResize(currentHeightSize,widgetObj){
                widgetObj.currentHeightSize = currentHeightSize
                this.makeSaveCall()
            },
            onWidgetTitleChanged(widgetTitle,widgetObj){
                widgetObj.widgetTitle = widgetTitle
                this.makeSaveCall()
            },
            makeSaveCall(){
                clearTimeout(this.saveTimer)
                this.saveTimer = setTimeout(()=>{
                    this.saveDashboard()
                },3000)
            },
            saveDashboard(){
                var _this = this
                if(!this.isAllWidgetLoaded)
                    return
                let widgetMapping = []
                let widget_modifiers = {}
                _.forEach(this.availableWidgetList,(obj, index)=>{
                    let wigetJsonToSave={
                        "widget_unique_id": obj.uniqueKey,
                        "display_order": index + 1,
                        "master_widget_id":obj.selectedWidget,
                        "widget_modifiers": {
                            "selectedValues":obj.allComponentSelectedValues,
                            "currentColumnSize":obj.currentColSize,
                            "currentHeightSize":obj.currentHeightSize,
                            "selectedModifiers":obj.allModifierSelectedValues,
                            "widgetTitle":obj.widgetTitle
                        }
                    }
                    widgetMapping.push(wigetJsonToSave)
                })
                // console.log("widgetMapping=>",widgetMapping)
                // if(!this.dashboardName){
                //     this.dashboardName = "My Dashboard for " + this.Environment.env_name;
                // }
                // this.dashboardName = "My Dashboard for " + this.Environment.env_name;
                var data = {
                    "username":this.userName,
                    "client_id": this.clientId,
                    "env_id":this.Environment.id,
                    "dashboard_name": this.dashboardName,
                    "widgets":widgetMapping,
                    // "dashboard_id":this.dashboardId >0?this.dashboardId:"",
                    "dashboard_id":this.dashboardId
                }
                // console.log(JSON.stringify(data))
                var url = config.USER_PROVISION_URL + "/manage_user_dashboard"
                // console.log("data=>",data)
                // this.loader = true
                postToServer(_this, url,data).then(response=>{
                    if(!this.isDashboardSelected){
                        this.allDashboardList.push({"dashboard_id":response.dashboard_id,"dashboard_name":this.dashboardName})
                    } else{
                        let dashbordObj = _.find(this.allDashboardList,['dashboard_id',this.dashboardId])
                        if(dashbordObj)
                            dashbordObj['dashboard_name'] = this.dashboardName
                    }
                    this.dashboardId = response.dashboard_id
                    // this.loader = false
                }).catch(error=>{
                    // this.loader = false
                    alert(error)
                })
            },
            showDeleteConfirm(){
                let confirmFn = function () {
					this.$parent.onDeleteDashboard()
				}
				let obj = {
					title: 'Confirmation',
					message: "Are you sure you want to delete '"+this.dashboardName+" dashboard?",
					type: 'info',
					useConfirmBtn: true,
					onConfirm: confirmFn,
					customCloseBtnText:'No',
					customConfirmBtnText:'Yes'
				}
				this.$refs.simplert.openSimplert(obj)
            },
            showMessage(message,type='error'){
                this.loader =false
                this.snackbar = true
                this.snackbartext = message
                this.colorValue = type
            }
        }
    }
</script>