<template>
    <div>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <env-ds-selection :currentCols="6" :showDense="true" v-show="showEnvOptions"
                :selectedEnvId="selectedEnvId" :selectedDSId="selectedDSId"
                @onEnvDSSelectionChanged="onEnvDSSelectionChanged" :showEnvOpt="showEnvOpt"/>
            </v-col>
        </v-row>
        <v-divider class="EDC-Divider" v-if="showEnvOptions"/>
        <v-row class="EDC-Row py-1">
            <v-col class="EDC-Col pl-1">
                <v-row class="EDC-Row">
                    <v-col :class="searchOptionHeaderClasses">Search Options</v-col>
                </v-row>
                <v-row class="EDC-Row py-1 pl-2">
                    <v-col class="EDC-Col d-flex justify-start" cols="3">
                        <b>Database</b>
                    </v-col>
                    <v-col class="EDC-Col d-flex justify-start" cols="4">
                        <v-checkbox class="customCheckbox" v-model="searchForTable"
                            label="Table" @change="searchOptionChanged">
                        </v-checkbox>
                    </v-col>
                    <v-col class="EDC-Col d-flex justify-start">
                        <v-checkbox class="customCheckbox" v-model="searchForView"
                            label="View" @change="searchOptionChanged">
                        </v-checkbox>
                    </v-col>
                    
                </v-row>
                <v-row class="EDC-Row py-1 pl-2">
                    <v-col class="EDC-Col d-flex justify-start" cols="3"></v-col>
                    <v-col class="EDC-Col d-flex justify-start">
                        <v-checkbox class="customCheckbox" v-model="searchForAlias"
                            label="Synonyms" @change="searchOptionChanged">
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row pl-2">
                    <v-col class="EDC-Col d-flex justify-start " cols="3">
                        <b>Pathfinder</b>
                    </v-col>
                    <v-col class="EDC-Col d-flex justify-start" cols="4">
                        <v-checkbox class="customCheckbox" v-model="searchForBV"
                            label="Business View" @change="searchOptionChanged">
                        </v-checkbox>
                    </v-col>
                    <v-col class="EDC-Col d-flex justify-start">
                        <v-checkbox class="customCheckbox" v-model="searchForBO"
                            label="Business Object" @change="searchOptionChanged">
                        </v-checkbox>
                    </v-col>
                    <!-- <v-col class="EDC-Col d-flex justify-start">
                        
                    </v-col> -->
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
.customCheckbox >>> label{
    top: 4px !important;
}
</style>
<script>
    import envDatasourceSelection from './envDatasourceSelection.vue';
    export default{
        name:"MoreActions",
        components:{
            'env-ds-selection':envDatasourceSelection
        },
        props:{
            selectedEnvId:{
                type:Number,
                default:0
            },
            selectedDSId:{
                type:String,
                default:""
            },
            showEnvOptions:{
                type:Boolean,
                default:false
            },
            searchOptions:{
                type:Object,
                default:()=>{
                    return {
                        "searchForTable":true,
                        "searchForView":true,
                        "searchForAlias":false,
                        "searchForBV":true,
                        "searchForBO":true
                    }
                }
            },
            showEnvOpt:{
                type:Boolean,
                default:true
            },
            searchOptionHeaderClasses:{
                type:String,
                default:'EDC-Col d-flex justify-start'
            }
        },
        data(){
            return{
                searchForTable:true,
                searchForView:true,
                searchForAlias:false,
                searchForBV:true,
                searchForBO:true
            }
        },
        watch:{
            'searchOptions':{
                handler(){
                    this.manageSearchOptions()
                },
                deep:true
            },
            selectedEnvId(){
                
            },
            selectedDSId(){
                
            },
            showEnvOpt(){

            }
        },
        mounted(){
            this.manageSearchOptions()
        },
        methods:{
            manageSearchOptions(){
                this.searchForTable = this.searchOptions.searchForTable
                this.searchForView = this.searchOptions.searchForView
                this.searchForAlias = this.searchOptions.searchForAlias
                this.searchForBV = this.searchOptions.searchForBV
                this.selectedDS = this.searchOptions.selectedDS
            },
            onEnvDSSelectionChanged(selectedEnv,selectedDS,dsListForBackEnd){
                this.$emit("onEnvDSSelectionChanged",selectedEnv,selectedDS,dsListForBackEnd)
            },
            searchOptionChanged(){
                let searchOptions = {
                    "searchForTable":this.searchForTable,
                    "searchForView":this.searchForView,
                    "searchForAlias":this.searchForAlias,
                    "searchForBV":this.searchForBV,
                    "searchForBO":this.searchForBO
                }   
                this.$emit("onOptionChanged",searchOptions)
            }
        }
    }
</script>