<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Object Publishing List</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    
    <edc-data-grid :dataList="tableList" @onDelete="onDelete" @onEdit="onEdit" @onNew="onNew" @onSave="onSave" @onCancel="onCancel"></edc-data-grid>
    
    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import moment from 'moment'
import config from './../../config.json'
import { get as getToServer, post as postToServer } from './../../methods/serverCall.js';
import environmentList from '../../methods/EnvironmentList.js'
import {SERVER_ERROR} from './../../data/client_message.js'
import {GET_ALL_ENV, GET_ALL_WORKFLOW_LIST, UPDATE_ENV_RULE, DELETE_ENV_RULE,
            ENVIRONMENT_RULES, ADD_ENV_RULE} from './../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from './../../data/macros.js'
import _ from 'lodash'
import getUserRole from '../../methods/GetUserRole.js'

import {PUBLISH_SERVICE_ID, MANAGE_OBJECT_PUBLISH_RULE, MANAGE_OBJECT_PUBLISH_USING_DS_RULE} from "../../data/macros.js"
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'ClientJobListNew',
    
    data: function () {
        return {
            loader:false,            
            tableList: {
                headers: [
                    { text: 'Source Environment', value: 'source_env_name', width:"33%", option:[], 'item_text':'name', 'item_value':'name', useAutoComplete:true},
                    { text: 'Target Environment', value: 'target_env_name', width:"33%",option:[], 'item_text':'name', 'item_value':'name', useAutoComplete:true}, 
                    { text: 'Workflow for Publish', value: 'publish_workflow_name', width:"33%", option:[], 'item_text':'workflow_name', 'item_value':'workflow_name', useAutoComplete:true}], 
                actions: [],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                is_row_edit:true,
                rows: [],
                itemkey:'id',
                selected_rows: [],
                syncHeaderScroll:false,
                disableDefaltSorting:true,
                is_grid_inline_edit:true,
                hideDownload:true,
                hideFilter : true,
                hideExport : true,
                hideShowColumns : true
            },
            EnvironmentList:[],
            snackbar:false,
            snackbartext:'',
            colorValue:'success', 
            snackbartimeout: Snackbar_Default_Timeout,           
            valid:true,
            userRole:{},
        }
    },
    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions = [
            {'text':'edit','key':"id", selectType:"single",
            role:this.userRole.is_superadmin ||getUserRole(this.userRole,PUBLISH_SERVICE_ID,MANAGE_OBJECT_PUBLISH_RULE), index: 1},
            {'text':'new','key':"id", 'enabled':this.Environment, 
            role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,MANAGE_OBJECT_PUBLISH_RULE), index: 2},
            {'text':"save","key":"is_row_editable", selectType:"single", 
            role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,MANAGE_OBJECT_PUBLISH_RULE), index: 3},
            {'text':"cancel","key":"is_row_editable", selectType:"single", 
            role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,MANAGE_OBJECT_PUBLISH_RULE), index: 4},
            {'text':'delete','key':"id", selectType:"single",
            role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,MANAGE_OBJECT_PUBLISH_RULE), index: 5}]

       this.getEnvironmentList();
       this.getWorkflowList(); 
       this.getRuleList();
    },
    methods: {
        onSave(record){
            record.id ? this.updateRule(record): this.saveRule(record);            
        },
        onCancel(){
            // debugger;
            this.getRuleList();
        },
        getEnvironmentList() {
            let _this = this;
            let data = {"client_id":_this.$session.get('client_id')}
            postToServer(this, config.ENVIRONMENT_API_URL + '/get_environment_data_for_pathfinder',data).then(response=>{
                    if(response){
                        _this.tableList.headers[0].option = _.cloneDeep(response.result);
                        _this.tableList.headers[1].option = response.result;
                        _this.tableList.headers[0].option.push({'id': '0', 'name': 'Repository'})
                    }
                },response=>{
                    this.ShowErrorMessage=true
                    this.ErrorMessage=response
                })
        },
        getWorkflowList(){
            let _this = this;
            
        },
        getRuleList(){
            let _this = this;
            _this.loader = true;
            var data = {"rule_for": "PATHFINDER",
                        "client_id": _this.$session.get('client_id')}
            postToServer(this, config.PUBLISHER_URL + ENVIRONMENT_RULES, data).then(response => {
                _this.loader = false;
                if(response){
                _this.tableList.rows = response;
                _this.tableList.total_count = response.length;
                
                // console.log(this.EnvironmentList)
                } else {
                _this.tableList.rows = [];
                }
            },response => {
                _this.loader = false;
                _this.tableList.rows = [];
                this.ShowErrorMessage=true
                this.ErrorMessage=response
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = response;
            }).catch(EnvironmentError => {
                _this.loader = false;
                _this.tableList.rows = [];
                if(EnvironmentError){
                    // this[l]  = false
                        this.loader = null 
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = EnvironmentError;
                    }
                    else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
            })
        },
        saveRule(rule){
            // debugger
            let sourceObj = _.find(this.tableList.headers[0].option,['name',rule.source_env_name]);
            let targetObj = _.find(this.tableList.headers[1].option,['name',rule.target_env_name]);
            let publishObj = _.find(this.tableList.headers[2].option, ['workflow_name', rule.publish_workflow_name]);
            let inputJson = {
                           source_env_id:sourceObj ? sourceObj.id+'':null,
                           source_env_name:rule.source_env_name || '',
                           target_env_id:targetObj ? targetObj.id+'':null,
                           target_env_name:rule.target_env_name || '', 
                           publish_workflow_id:publishObj ? publishObj.object_id+'':null, 
                           unpublish_workflow_id: null, 
                           publish_workflow_name:rule.publish_workflow_name || null, 
                           unpublish_workflow_name: null, 
                           created_by:this.$session.get('email'),
                           client_id:this.$session.get('client_id'),
                           rule_for: "PATHFINDER",
                           }
            let url =  config.PUBLISHER_URL + ADD_ENV_RULE;              
            postToServer(this, url, inputJson).then(response  => {
                    this.snackbar = true
                    this.colorValue = 'success'
                    this.snackbartext = response;
                    this.getRuleList();
                }).catch(DatasourceError => {
                    this.getRuleList();
                if(DatasourceError){
                    this.snackbar = true;
                    this.colorValue = 'error';
                    this.snackbartext = DatasourceError;
                    }
                    else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                    }
            });               
        },
        onDelete(rule){
            let sourceObj = _.find(this.tableList.headers[0].option,['name',rule.source_env_name]);
            let targetObj = _.find(this.tableList.headers[1].option,['name',rule.target_env_name]);
            let publishObj = _.find(this.tableList.headers[2].option, ['workflow_name', rule.publish_workflow_name]);
            let inputJson = {id:rule.id,
                            modified_by:this.$session.get('email'),
                            client_id:this.$session.get('client_id') 
                           }
            let url =  config.PUBLISHER_URL + DELETE_ENV_RULE;              
            postToServer(this, url, inputJson).then(response  => {
                    this.snackbar = true
                    this.colorValue = 'success'
                    this.snackbartext = "Rule deleted Successfully";
                    this.getRuleList();
                }).catch(DatasourceError => {
                    this.getRuleList();
                if(DatasourceError){
                    this.snackbar = true;
                    this.colorValue = 'error';
                    this.snackbartext = DatasourceError;
                    }
                    else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                    }
            });               
        },
        updateRule(rule){
            // debugger
            let sourceObj = _.find(this.tableList.headers[0].option,['name',rule.source_env_name]);
            let targetObj = _.find(this.tableList.headers[1].option,['name',rule.target_env_name]);
            let publishObj = _.find(this.tableList.headers[2].option, ['workflow_name', rule.publish_workflow_name]);
            let inputJson = {
                            id:rule.id,
                            source_env_id:sourceObj ? sourceObj.id+'':null,
                            source_env_name:rule.source_env_name || '',
                            target_env_id:targetObj ? targetObj.id+'':null,
                            target_env_name:rule.target_env_name || '', 
                            publish_workflow_id:publishObj ? publishObj.object_id+'':null, 
                            unpublish_workflow_id:null, 
                            publish_workflow_name:rule.publish_workflow_name || null, 
                            unpublish_workflow_name: null, 
                            modified_by:this.$session.get('email'),
                            client_id:this.$session.get('client_id'),
                            rule_for: "PATHFINDER",}
            let url =  config.PUBLISHER_URL + UPDATE_ENV_RULE;              
            postToServer(this, url, inputJson).then(response  => {
                    this.snackbar = true
                    this.colorValue = 'success'
                    this.snackbartext = response;
                    this.getRuleList();
                }).catch(DatasourceError => {
                    this.getRuleList();
                if(DatasourceError){
                    this.snackbar = true;
                    this.colorValue = 'error';
                    this.snackbartext = DatasourceError;
                    }
                    else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                    }
            });               
        },
       onNew(record){
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fillup the open row';
                return
            }
            this.tableList.rows.unshift({
                'source_env_name':'',
                'target_env_name':'',
                'publish_workflow_name':'',
                'is_row_editable' : true
            });              
        },
        onEdit(record){
        }
    }
}
</script>
<style>
/* tr:nth-child(even) {background-color: #E8E8E8;} */
/* tr:hover td {background:#A9A9A9;} */
/* .blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>