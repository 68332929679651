<template>
    <div>
        <v-row no-gutters>
            <template v-for="(currentList,ind) in tasksForSelectedList">
                <v-col :key="'task'+ind" cols="12" sm="12" md="12" lg="4" xl="4" xxl="3" class="pa-1">
                    <v-card class="pa-0">
                        <v-card-subtitle class="pa-1 font-weight-bold d-flex justify-start section_name">
                            {{ currentList.sectionName }}
                        </v-card-subtitle>
                         <v-divider class="my-0"></v-divider>
                        <v-card-text  class="pa-2">
                            <v-row dense no-gutters>
                                <v-col 
                                v-for="(item, index) in currentList.tasks" 
                                :key="index+'task'+ind" 
                                cols="12"
                                class="d-flex align-start mb-2"
                                >
                                <v-icon small class="mr-2">{{ item.taskResource.default_icon_class }}</v-icon>
                                <span class="task-link text-caption text--grey" @click="routeUrl(getTaskUrl(item))">
                                    {{ item.taskName }}
                                </span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </div>
</template>


<style scoped>
    /* Styling for the list item link */
    .task-link {
        text-decoration: none;
        font-weight: normal; /* Ensure the task name is not bold */
        color: #5e5e5e; /* Light grey color */
    }

    /* Add underline on hover */
    .task-link:hover {
        text-decoration: underline;
    }
    .section_name{
        font-size: 12px !important; 
        margin-left: 5px !important;
    }
</style>
<script>
import { v4 as EDCuuidv4 } from 'uuid';
    export default{
        name:"TaskWidgets",
        props:{
            dataObject:{
                type:Object,
                default:()=>{
                    return {
                        "selectedTaskListObject":{},
                        "tasksForSelectedList":[],
                        "showHeading":false
                    }
                }
            }
        },
        watch:{
            'dataObject':{
                handler(newValue){

                },
                deep:true
            }
        },
        computed:{
            selectedTaskListObject(){
                return this.dataObject.selectedTaskListObject || {}
            },
            tasksForSelectedList(){
                return this.dataObject.tasksForSelectedList || []
            },
            showHeading(){
                return this.dataObject.showHeading||false
            },
            widgetHeading(){
                return this.selectedTaskListObject.object_name || "Untitled Task List"
            }
        },
        mounted() {
            
        },
        methods: {
            routeUrl(path) {
            var _this = this
            if(this.previousPath===path)
                return
            this.previousPath = path

            setTimeout(()=>{
                _this.previousPath = ''
            },1000)

            return this.openRouteInDifferentTab(path)
            
            },
            
            openRouteInDifferentTab(path){
            var _this = this
            var routeData = this.$router.resolve({"name":"edclander",query:{pathToRedirect:path}});
            window.open(routeData.href, '_blank');
            setTimeout(()=>{
                _this.previousPath = '/edcdataviewer'
            },1000)
            },
            getTaskUrl(item) {
            switch (item.taskResource.resource_code) {
                case 'USER_SETUP':
                return '/adduser';
                case 'SETUP_ACTION_BASED_SEC':
                return '/manageenvandobjrole';
                case 'SETUP_ADMIN_SEC':
                return '/actionbasedrolelist';
                case 'SETUP_DS':
                    return '/managedatasource'
                case 'SETUP_ENV':
                    return '/manageenvironment'
                case 'VIEW_SEC_REPORT':
                    return '/rolereport'
                case 'SEE_OBJ_IN_PATHFINDER':
                    let redirectionKey = 'w-'+EDCuuidv4()
                    this.$cookies.set(redirectionKey,item.selectedValueForUiComponents)
                    return '/widgetlander?widgetkey='+redirectionKey
                // Add more cases as needed
                default:
                return '#'; // Default fallback URL
            }
        }
    }
    }
</script>