<template>
    <div>
         <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>
                                <select id="headerenvselect" v-model="selected_env" v-on:change="getAllRole(),setEnv()" style="-webkit-appearance: menulist" autofocus ref="envname">
                                    <option :value="{}" id="nonselectenv">Select Environment</option>
                                    <option v-for="(env, index) in EnvironmentList" :value="env" :key="index" :id="'envnum'+index">{{env.env_name}}</option>
                                </select>
                            </li>
                            <li>Security</li>
                            <!-- <li>{{roleTypeDisplay}} Role</li> -->
                            <li>Setup {{roleTypeDisplay}} Security Roles by Environment</li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
            <!-- <v-col cols="4"></v-col> -->
        </v-row> 

        <edc-data-grid  :dataList="tableList" @createObjectBasedRole="addRole('object_based')"
         @onNew="onNew()" @onEdit="onEdit" @onDelete="onDelete" @ondblClick="onEdit" 
         @onCopy="onCopy"></edc-data-grid>

    <v-dialog v-model="show_copy_dialog" width="400">
        <v-form ref="copy_form">
        	<v-col class="EDC-Col">
	          	<v-card elevation-1>
	            	<v-row no-gutters class="EDC-Row">
	             		<v-toolbar dark dense absolute style="width:100%">
                      <v-toolbar-title class="EDC-ToolbarTitle">Copy Role To Environment</v-toolbar-title>
                    <v-spacer></v-spacer>
                      <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="show_copy_dialog=false" title="Close"></svgicon>
	              		</v-toolbar>
	            	</v-row>
                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing textFieldClass">
                        <v-text-field id="txtrolename" v-model="roleName" label="Role Name" value rows="1" :disabled="this.roleName === 'Public role'"
                         class="EDC-UIComponent" hide-details dense />
                    </v-row>
	            	
                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing autoCompleteClass">
	              		<v-autocomplete id="copytoenv" label="Select environment to copy to" v-model="copy_to_environment" item-value="id" item-text="name" :items="EnvironmentList" dense return-object></v-autocomplete>
	            	</v-row> 
                <v-row no-gutters class="EDC-Row EDC-RowsSpacing"  justify="center">
	              		<vc-button id="btncopy" itemText="Copy" @click.native="copyToEnvironment(false)" 
                        :disabled="isDisabled"></vc-button>
	            	</v-row> 
	          	</v-card>
	        </v-col>
        </v-form>
    </v-dialog>

    <v-dialog v-model="show_overwrite_dialog" width="400">
        <v-form ref="overwrite_form">
        	<v-col class="EDC-Col">
	          	<v-card elevation-1>
	            	<v-row no-gutters class="EDC-Row">
	             		<v-toolbar dark dense absolute style="width:100%">
                      <v-toolbar-title class="EDC-ToolbarTitle">Confirm Action</v-toolbar-title>
                    <v-spacer></v-spacer>
                      <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="show_overwrite_dialog=false" title="Close"></svgicon>
                    </v-toolbar>
	            	</v-row>
                    <v-spacer></v-spacer>

                    <v-row no-gutters class="textClass">
                       {{overwrite_message}}. Do you want to overwrite?
                    </v-row>
                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="center">
                        <vc-button itemText='Yes' id="btnoverwriteyes" @click.native="copyToEnvironment(true)"></vc-button>
                        <vc-button itemText='No' id="btnoverwriteno" @click.native="show_overwrite_dialog=false"></vc-button>
                    </v-row>
	          	</v-card>
	        </v-col>
        </v-form>
    </v-dialog>
   
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbarTimeout" id="edcmessage" top right>
            {{ snackbarText }}
        </v-snackbar>
    <loading-panel :loader="loader"></loading-panel>
    </div>
</template>

<script>
import config from '../../../config.json'
import {post as postToServer } from '../../../methods/serverCall.js';
// import getUserRole from '../../../methods/GetUserRole.js'
import {SERVER_ERROR} from '../../../data/client_message.js'
import {CLIENT_SIDE, SECURITY_SERVICE, MANAGE_OBJ_BASED_SECURITY, MANAGE_ROLE_MAPPING, 
MANAGE_ACTION_BASED_SECURITY} from '../../../data/macros.js'
import { getEnvListForDataviewer} from '../../../methods/EnvironmentList.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
export default {
    name: 'DemoGrid',
    
    data: function () {
        return {
            userRole:'',
            roleType: "administrator",
            selected_env:{},
            EnvironmentList:[],
            show_copy_dialog:false,
            show_overwrite_dialog:false,
            copy_to_environment:'',
            copy_id:'',
            copy_data: {},
            copy_name: '',
            overwrite_message: '',
            roleName: '',
            name_list:[],
            publicRoleName:'Public role',
            // isDisabled:true,
            tableList: {
                headers: [
                    { text: 'Role Name', value: 'role', width: '30%', title: 'Security Role Name' },
                    { text: 'Role Type', value: 'display_role_type', title: 'Type Of Role' },
                    { text: 'Created By', value: 'created_by', width: '30%', title: 'Created By' },
                    { text: 'Created Date', value: 'timezone_aware_created_date', width: '20%', title: 'Created Date' },
                    { text: 'Created Time', value: 'timezone_aware_created_time', width: '20%', title: 'Created Time' }
                ],
                actions:[],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                hideFilter:true,
                hideExport:true,
                hideShowColumns:true,
                itemkey:'role_id',
            },
            snackbar: false,
            snackbarText: "",
            colorValue: "error",
            snackbarTimeout: Snackbar_Default_Timeout,
            showEnvType:false,
            valid:true, 
            sorting : config.SORTING_TYPE,
            isloading:false,
            menu:false,
            edit:"",
            loader:false,
            perPage: 5,
            page: 1,
        }
    },
    computed:{
            clientId(){
                return this.$session.get('client_id')
            },
            isDisabled(){
                if(this.roleName && this.copy_to_environment !== '')
                return false
            return true
            },
            roleTypeDisplay(){
                if(this.isEnvironmentalRole)
                    return 'Action'
                return 'Object'
            },
            isEnvironmentalRole(){
                return this.roleType === 'environmental'
            }
        },
    watch:{
        '$store.state.sameComponentRedirectObj':{
            handler(newvalue,oldvalue){
                this.managePage()
            },
            deep:true
        },
    },
    mounted() {
        var _this = this
        this.userRole = this.$session.get('user_role_mapping');
       
        if(this.$session.get('selected_env')){
            this.selected_env = this.$session.get('selected_env')
        }
        setTimeout(()=>{
            _this.managePage()
        },100)
        this.getEnvironmentList();
    },
    methods: {
        getUserMenu(serviceName){
          if(this.userRole.is_superadmin)
            return true
            
        if(this.isEnvironmentalRole)
            var security_role = MANAGE_ACTION_BASED_SECURITY
        else
            var security_role = MANAGE_OBJ_BASED_SECURITY
            
          if(this.userRole[serviceName]){
            var all_group = this.userRole[serviceName]
            if (all_group.includes(security_role)){
                return true
            }
          }
          if (serviceName === 'default')
            return true
          return false;
        },

        managePage(){
            this.roleType = this.$store.state.sameComponentRedirectObj.roletype || 'object_based'
             this.tableList.actions=[
                {'text':'new', 'key':"role_id", id:"addnewobjrole", 
                role:this.userRole.is_superadmin || this.getUserMenu(SECURITY_SERVICE),index:1},
                {'text':'edit','key':"role_id", id:"icneditobjrole", selectType:"single",
                 role:this.userRole.is_superadmin || this.getUserMenu(SECURITY_SERVICE),index:2},
                {'text':'delete','key':"role_id", id:"icndeleteobjrole", selectType:"multiple",
                 role:this.userRole.is_superadmin || this.getUserMenu(SECURITY_SERVICE),index:3},
                ];
        if (!this.isEnvironmentalRole){
            this.tableList.actions.push({'text':'copy','key':"role_id", id:"icncopyobjrole", selectType:"single", 
                role:this.userRole.is_superadmin || this.getUserMenu(SECURITY_SERVICE),index:4})
        }
            this.getAllRole()
        },
        getEnvironmentList() {
            let _this = this;
            getEnvListForDataviewer(this,false)
        },
        setEnv(){
            this.$session.set('selected_env',this.selected_env)
        },
        showErrorMessage(errorMessage){
            this.loader = false;                  
            this.colorValue = 'error'
            this.snackbarText = errorMessage || SERVER_ERROR; 
            this.snackbar = true                   
        },
        onNew(){
            let _this = this;
            _this.$router.push({name:'ManageObjBasedRole',query: {
                env_id:this.selected_env.id, 
                env_name:this.selected_env.name,
                role_type: this.roleType,
                product_code: "PF"}});
          
        },
        onEdit(record){
            let _this = this;
            if(record.role === "superadmin" && record.role_type === "administrator"){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbarText = 'User cannot edit superadmin role';
                return;
            }
            this.$router.push({ name: 'ManageObjBasedRole', query: { 
                role_id: record.role_id, 
                roleId: record.role_id,
                role_name:record.role, 
                role_type: record.role_type, 
                env_id:record.env_id, 
                env_name:record.env_name,
                product_code: "PF"}});
            
        },
        onDelete(records){
            let _this = this
            _this.loader = true;
            let isPublicExists = _.find(records, ['role',this.publicRoleName])
            if(isPublicExists){
                _this.showErrorMessage("You connot delete public role")
                return
            }
            var delete_object_url = config.Security_URL + "/delete_object_based_role" 
            let data = {
                "role_id_list":_.map(records, 'role_id'),
                "client_id":this.$session.get('client_id'), 
                "deleted_by":this.$session.get("email"),
                "env_id":_this.selected_env.id,
                "role_type": this.roleType
            }
            postToServer(_this, delete_object_url, data).then(response => {
                _this.snackbar = true
                _this.loader = false;
                _this.colorValue = 'success'
                _this.snackbarText = response;
                _this.getAllRole()

            }) 
            .catch(error_response => {
                _this.showErrorMessage(error_response)
            });
        },
        onCopy(record){
            this.copy_data = record
            this.show_copy_dialog = true
            this.copy_id = record.role_id
            if(record.role !== this.publicRoleName){
                this.roleName = 'Copy Of' + " " +record.role
            }
            else{
                this.roleName = record.role
            }
        },
        copyToEnvironment(isOverwrite){
            let _this = this;
            var client_id = this.$session.get('client_id');
            var data = { 
                "role_id": this.copy_data.role_id,
                "role_name": this.roleName,
                "client_id": client_id,
                "env_id": this.copy_to_environment.id,
                "env_name": this.copy_to_environment.env_name,
                "product_name" :window.sessionStorage.getItem('product') ,
            }
            if(isOverwrite == true){
                data['is_overwrite'] = isOverwrite
            }
            var url = config.Security_URL + "/copy_role_to_env";
            postToServer(_this, url, data).then(response => {
                if(response.overwrite){
                    this.snackbar = true;
                    this.colorValue = "success";
                    this.snackbarText = response.message
                    this.show_overwrite_dialog = true
                    this.overwrite_message = response.message
                }
                else{
                    this.snackbar = true;
                    this.colorValue = "success";
                    this.snackbarText = "Successfully copied role to environment";
                }
                // if(response.message){
                //     this.snackbar = true;
                //     this.colorValue = "success";
                //     this.snackbarText = response.message;
                // }
            })
            this.show_overwrite_dialog = false
            this.show_copy_dialog = false
            this.getAllRole()
            .catch(errorResponse => {
                _this.showErrorMessage(errorResponse)
            });
        },
        getAllRole() {
            var _this = this; 
            var client_id = this.$session.get('client_id')
            this.tableList.rows = []
            if(!this.selected_env || !this.selected_env.id)
                return
            var data = {"client_id":client_id,
                        "env_id": this.selected_env.id,
                        "env_name": this.selected_env.name,
                        "product_name":window.sessionStorage.getItem('product'),
                        "is_public_role_required":!this.isEnvironmentalRole,
                        "role_type":this.roleType
                    }
            var url = config.Security_URL + "/get_all_object_based_role";
            this.loader=true;
            postToServer(this, url, data).then(Response => {
                _this.loader=false;
                if(Response)
                    this.tableList.rows = Response;
            }).catch(errorResponse => {
                _this.showErrorMessage(errorResponse)
            });
        },
    }   
}
</script>
<style scoped>
.textClass{
    padding-top: 66px;
    padding-right: 24px;
    padding-left: 17px;
}
.textFieldClass{
    padding-left:24px !important; 
    padding-top:84px !important;
    padding-right: 24px !important;
}
.autoCompleteClass{
    padding-left:24px !important;
    padding-right: 24px !important; 
    padding-top:30px !important;
}
.labelClass{
    padding-left:24px !important; 
    padding-top:18px !important;
    padding-right: 24px !important;
}
</style>