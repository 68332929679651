import {mapActions} from 'vuex'
import {getLayoutCardObject} from "../methods/layoutFunc.js"
export const mixinLayout={
    props:{
        dashboardId:{
            type:String,
            default:""
        },
        definedWidgets:{
            type:Array,
            default:()=>{
                return []
            }
        },
        isDesigner:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            layoutSlotContainer:{
                "slot_1":getLayoutCardObject(1),
                "slot_2":getLayoutCardObject(2),
                "slot_3":getLayoutCardObject(3),
                "slot_4":getLayoutCardObject(4),
                "slot_5":getLayoutCardObject(5),
                "slot_6":getLayoutCardObject(6),
                "slot_7":getLayoutCardObject(7),
                "slot_8":getLayoutCardObject(8),
                "slot_9":getLayoutCardObject(9),
                "slot_10":getLayoutCardObject(10),
                "slot_11":getLayoutCardObject(11),
                "slot_12":getLayoutCardObject(12)
            }
        }
    },
    computed:{
        currentBreakpoint() {
            const { xs, sm, md, lg, xl } = this.$vuetify.breakpoint;
      
            if (xs) return 'xs';
            if (sm) return 'sm';
            if (md) return 'md';
            if (lg) return 'lg';
            if (xl) return 'xl';
      
            return 'Unknown';
        },
        slot1Obj(){
            return this.layoutSlotContainer.slot_1
        },
        slot2Obj(){
            return this.layoutSlotContainer.slot_2
        },
        slot3Obj(){
            return this.layoutSlotContainer.slot_3
        },
        slot4Obj(){
            return this.layoutSlotContainer.slot_4
        },
        slot5Obj(){
            return this.layoutSlotContainer.slot_5
        },
        slot6Obj(){
            return this.layoutSlotContainer.slot_6
        },
        slot7Obj(){
            return this.layoutSlotContainer.slot_7
        },
        slot8Obj(){
            return this.layoutSlotContainer.slot_8
        },
        slot9Obj(){
            return this.layoutSlotContainer.slot_9
        },
        slot10Obj(){
            return this.layoutSlotContainer.slot_10
        },
        slot11Obj(){
            return this.layoutSlotContainer.slot_11
        },
        slot12Obj(){
            return this.layoutSlotContainer.slot_12
        },
        widgetSlotMapping(){
            let widgetSlotMapping = []
            if(_.isEmpty(this.layoutSlotContainer))
                return widgetSlotMapping
            let allSlots = _.keys(this.layoutSlotContainer)
            for(let i=0;i<allSlots.length;i++){
                let currentSlot = this.layoutSlotContainer[allSlots[i]]
                if(currentSlot.draggedWidgetId)
                    widgetSlotMapping.push({
                        "widget_slot_id":currentSlot.slotNumber,
                        "widget_id":currentSlot.draggedWidgetId,
                        "widget_modifiers":currentSlot.widget_modifiers
                    })
            }
            return widgetSlotMapping
        }
    },
    watch:{
        'dashboardId':{
            handler(newValue){
                if(!newValue){
                    // user changed the environemnt
                    this.layoutSlotContainer={
                        "slot_1":getLayoutCardObject(1),
                        "slot_2":getLayoutCardObject(2),
                        "slot_3":getLayoutCardObject(3),
                        "slot_4":getLayoutCardObject(4),
                        "slot_5":getLayoutCardObject(5),
                        "slot_6":getLayoutCardObject(6),
                        "slot_7":getLayoutCardObject(7),
                        "slot_8":getLayoutCardObject(8),
                        "slot_9":getLayoutCardObject(9),
                        "slot_10":getLayoutCardObject(10),
                        "slot_11":getLayoutCardObject(11),
                        "slot_12":getLayoutCardObject(12)
                    }
                }
            }
        },
        'definedWidgets':{
            handler(newValue){
                this.manageLayoutSlots()    
            }
        }
    },
    mounted(){
        this.manageLayoutSlots()
    },
    methods:{
        ...mapActions(['setDraggedWidget']),
        dropOnDiv(slotNumber){
            let droppedWidget = _.cloneDeep(this.$store.state.draggedWidget)
            //reset dragged Object
            if(_.isEmpty(droppedWidget)){
                alert("Failed to drop, please re-try")
                return
            }
            this.layoutSlotContainer['slot_'+slotNumber].draggedWidgetId = droppedWidget.object_id
            // this.layoutSlotContainer['slot_'+slotNumber].draggedWidgetName = droppedWidget.object_name
            this.$emit("onLayoutChange",this.widgetSlotMapping)
        },
        deAttachWidget(dimentionDetails,slotNumber){
            if(dimentionDetails)
                alert("Dimentions of widget "+dimentionDetails.widget+" are not matching with slot dimetions "+dimentionDetails.slot)
            this.layoutSlotContainer["slot_"+slotNumber] = getLayoutCardObject(slotNumber)
            this.$emit("onLayoutChange",this.widgetSlotMapping)
        },
        manageLayoutSlots(){
            if(!this.definedWidgets.length)
                return
            for(let i=0;i<this.definedWidgets.length;i++){
                let currentSlot = _.cloneDeep(this.definedWidgets[i])
                let slot_id = currentSlot.widget_slot_id
                console.log("slot_id",slot_id)
                let slotNumber = parseInt(slot_id.replace("slot_",""))
                this.setDraggedWidget({"object_id":currentSlot.widget_id})
                this.dropOnDiv(slotNumber)
            }
        },
        onWidgetChange(slotNumber,widgetModifers){
            this.layoutSlotContainer["slot_"+slotNumber]['widget_modifiers'] = _.cloneDeep(widgetModifers)
            this.$emit("onLayoutChange",this.widgetSlotMapping)
        }
    }
}