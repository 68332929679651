<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row" v-if="!isFromHeader && showEnvDsSelection">
			<v-col class="EDC-Col colPadding" :cols="colSize">
				<v-row class="EDC-Row">
					<v-col class="EDC-Col" cols="1">

					</v-col>
					<v-col class="EDC-Col">
						<v-autocomplete class="EDC-SearchBox" clearable outlined style="border-radius:24px;" autocomplete="off" 
						:items="EnvironmentList" item-text="env_name"
						v-model="selected_env" label="Select Environment" return-object 
						@input="getSecurityDetails()" hide-details :disabled='disableSelection || disableEnvironmentSelection' height="48">
						</v-autocomplete>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row class="EDC-Row HeaderPadding" v-if="!isFromHeader && showEnvDsSelection">
			<v-col class="EDC-Col colPadding" :cols="colSize" v-if="!isMerged">
				<v-row class="EDC-Row">
					<v-col class="EDC-Col" cols="1">

					</v-col>
					<v-col class="EDC-Col">
						<v-autocomplete class="EDC-SearchBox" clearable outlined style="border-radius:24px;" autoomplete="off" :items="ds_list" item-text="ds_name"
						v-model="selected_ds" label="Select Datasource"
						return-object  @input="Get_Tables('');manageUserPreferences()" hide-details 
						:disabled="disableSelection || disableDatasourceSelection" height="48"></v-autocomplete>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row :class="{'EDC-Row':true,HeaderPadding:!isFromHeader}">
			<v-col class="EDC-Col colPadding" :cols="isFromHeader?11:colSize">
				<v-row class="EDC-Row">
					<v-col class="EDC-Col" cols="1" v-if="!disableSelection" style="padding-top:12px !important;" align="left"> 
						<v-menu  bottom center transition="scale-transition" style="display:block" v-model="showMoreActions" :close-on-content-click="false" :nudge-width="400">
							<template v-slot:activator="{ on }">
								<svgicon v-if="theme==='light'" style="cursor:pointer;" v-on="on" class="svg-icon-grid svg-fill-grid" name="More" slot="activator"  :original="true" title="Show More Options"></svgicon>
								<svgicon v-else style="cursor:pointer;color:white !important;" v-on="on" class="svg-icon-grid svg-fill-grid" name="More" slot="activator"  :original="true" title="Show More Options"></svgicon>
							</template>
							<v-card class="EDC-Card" style="padding:8px !important;width:420px !important;">
								<v-row class="EDC-Row" v-if="isFromHeader || isEnvAndDSelected" style="padding-top:8px !important;">
									<v-col class="EDC-Col">
										<v-autocomplete clearable autocomplete="off" :items="EnvironmentList" item-text="env_name"
										v-model="selected_env" label="Select Environment" return-object solo
										@input="getSecurityDetails()" hide-details :disabled='disableSelection || disableEnvironmentSelection'></v-autocomplete>
									</v-col>
									<v-col class="EDC-Col colPadding" cols="6" v-if="!isMerged">
										<v-autocomplete clearable autocomplete="off" :items="ds_list" item-text="ds_name"
										v-model="selected_ds" label="Select Datasource"
										solo return-object  @input="Get_Tables('');manageUserPreferences()" hide-details 
										:disabled="disableSelection || disableDatasourceSelection"></v-autocomplete>
									</v-col>
								</v-row>
								<v-row class="EDC-Row" style="padding-bottom:8px !important;padding-top:8px !important;">
									<v-col class="EDC-Col" align="left">Search Options</v-col>
								</v-row>
								<v-row class="EDC-Row">
									<v-col cols="2" class="EDC-Col" align="left"><b>Database</b></v-col>
									<v-col cols="4" class="EDC-Col" align="left">
										<v-checkbox class="customCheckbox" v-model="searchForTable"
											label="Table" @change="manageUserPreferences">
										</v-checkbox>
									</v-col>
									<v-col cols="3" class="EDC-Col" align="left">
										<v-checkbox class="customCheckbox" v-model="searchForView"
											label="View" @change="manageUserPreferences">
										</v-checkbox>
									</v-col>
									<v-col cols="3" class="EDC-Col" align="left">
										<v-checkbox class="customCheckbox" v-model="searchForAlias"
											label="Synonyms" @change="manageUserPreferences">
										</v-checkbox>
									</v-col>
								</v-row>
								<v-row class="EDC-Row">
									<v-col cols="2" class="EDC-Col" align="left"><b>Pathfinder</b></v-col>
									<v-col cols="4" class="EDC-Col" align="left">
										<v-checkbox class="customCheckbox" v-model="searchForBusinessView"
											label="Business View" @change="manageUserPreferences">
										</v-checkbox>
									</v-col>
									<v-col cols="4" class="EDC-Col" align="left">
										<v-checkbox class="customCheckbox" v-model="searchForBusinessObject"
											label="Business Object" @change="manageUserPreferences">
										</v-checkbox>
									</v-col>
								</v-row>
							</v-card>
						</v-menu>
					</v-col>
					<v-col class="EDC-Col">
						<v-autocomplete class="EDC-SearchBox"  outlined style="border-radius:24px;"
						clearable autocomplete="off" :items="source_table_list"
						:search-input.sync="source_search" item-text="displaytext"
						v-model="selected_bo_tbl" label="Select Business View / Object / Table Name"
						return-object  hide-no-data :loading="tableLoading"
						hide-details height="48" :key='table_key' cache-items :disabled="disableSelection" 
						prepend-inner-icon="search" @keypress.enter.prevent="isObjectSelected?loadData():'';"></v-autocomplete>
					</v-col>
				</v-row>
				
			</v-col>
			<v-col class="EDC-Col colPadding" cols="1" style="padding-left:2px !important;padding-top:4px !important;">
				<vc-button :class="{'HeaderBtn':isFromHeader && isDarkTheme}" itemText='Go' 
				style="height:32px !important;" @click.native="loadData()"
				:disabled="!isObjectSelected"></vc-button>
			</v-col>
			
			<v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbarTimeout" id="edcmessage" top right>
                {{ snackbarText }}
            </v-snackbar>

		</v-row>
	</v-container>
</template>
<style scoped>
	.customCheckbox >>> label{
	  top: 4px !important;
	}

	.colPadding{
		padding-left: 8px !important;
	}

	.HeaderPadding{
		padding-top: 12px !important;
	}

	.HeaderBtn >>> .v-btn__content {
		color:white;
	}

	.EDC-SearchBox >>> label{
		font-size: 14px !important;
		font-weight: bold;
	}

	.EDC-SearchBox >>> input{
		font-size: 16px !important;
		font-weight: bold;
	}

	.EDC-SearchBox >>> .v-list-item__title {
		font-size: 16px !important;
		font-weight: bold !important;
	}

</style>
<script>
	import {mapActions} from 'vuex'
	import {getEnvListForDataviewer} from './../methods/EnvironmentList.js'
	import config from './../config.json'
	import { post as postToServer } from './../methods/serverCall.js';
	import { get as getToServer } from './../methods/serverCall.js';
	import {getTableList} from "./../methods/agent_api_call.js"
	import {Snackbar_Default_Timeout} from '@/constants/constants.js'

	export default {
		name:"EDCSearchEngine",
		props:{
			theme:{
				type:String,
				default:'light'
			},
			isFromHeader:{
				type:Boolean,
				default:false
			},
			tableList:{
				type:Array,
				default:()=>{
					return []
				}
			},
			whichSide:{
				type:String,
				default:'wholepage',
			},
			isSplitMode:{
				type:Boolean,
				default:false
			},
			isMerged:{
				type:Boolean,
				default:false
			}
		},
		data(){
			return{
				EnvironmentList:[],
				selected_env:{},
				disableSelection:false,
				ds_list:[],
				selected_ds:{},
				source_table_list:[],
				selected_bo_tbl:{},
				tableLoading:false,
				table_key:1, // to refresh the auto complete for the table selection
				showMoreActions:false,
			    searchForBusinessView:true,
			    searchForBusinessObject:true,
			    searchForTable:true,
			    searchForView:false,
			    searchForAlias:false,
			    source_search:'',
    			target_search:'',
			    lastEnvPreference:'',
    			lastDSPreference:'',
    			securityModelByEnv:[],
    			isSuperAdmin:false,
    			BO_list:[],
    			dictionary_data:{},
    			object_librarian_data:{},
    			control_data:{},
    			acceleration_type:'',
    			bo_tbl_seprator:'====================',
    			ds_list_for_backend:[],
    			isAutoLoadingProps:false, // there are some fields get cleanup in WATCH on env,ds value change. When we will autofillup the values from STORE , that time we do not want to cleanup that values.
				disabledTableSearch:false,
    			disableDatasourceSelection:false,
    			disableEnvironmentSelection:false,
    			isCallFromArchivist:false,
    			archivistRequestFor:'',
    			archivistObj:{},
				userPreferencesLoaded:false,
				snackbar: false,
            snackbarText: "",
            colorValue: "error",
            snackbarTimeout: Snackbar_Default_Timeout,
			}
		},
		watch:{
			selected_env(newval){
				if(this.isAutoLoadingProps)
					return
			    this.acceleration_type = ''
			    this.selected_ds = {}
			    this.selected_bo_tbl = {}
			    this.dictionary_data = {}
			    this.object_librarian_data = {}
			    this.control_data={}
			    this.BO_list = []
			    // this.ResetGrid()
			},
			EnvironmentList(newval){
		    	// if(this.isByTicketID)
		     //  		this.getTicketDetails(this.$route.query.ticket_id)
		    	// else
		      		this.performAutoEnvSelection(newval,this.$session.get('last_selected_env_id'))
		    },
		    source_search (val) {
		    	val && (!this.selected_bo_tbl || val !== this.selected_bo_tbl.displaytext) && this.querySelections(val)
		   	},
			target_search (val) {
		    	val && (!this.selected_bo_tbl || val !== this.selected_bo_tbl.displaytext) && this.querySelections(val)
		   	},
		   	selected_bo_tbl(newval){
		    	// this.ResetGrid()
		  	},
		  	selected_ds(newval){
				this.setAvailableDatasourcesForMergeView({"availableDS":[],"selectedDS":{}})
				
				if(newval && newval.id){
					let availableList = []
					this.ds_list.forEach((obj)=>{
						if(obj.id !=newval.id){
							availableList.push(obj)
						}
					})
					this.setAvailableDatasourcesForMergeView({"availableDS":availableList,"selectedDS":newval})
				}

		  		if(this.isAutoLoadingProps)
					return
		    	++this.table_key
		    	this.selected_bo_tbl = {}
		    	// this.ResetGrid()
		  	},
			isSplitMode:{
				handler(newVal){
					
				}
			},
			isMerged:{
				handler(newVal){

				}
			},
			'$store.state.archivistRedirectionObj':{
        		handler(newValue){

        		}
			},
		},
		computed:{
			isArchivistCall(){
				return !_.isEmpty(this.$store.state.archivistRedirectionObj)
			},
			isDarkTheme(){
				return this.$store.state.themeType && this.$store.state.themeType === 'dark'
			},
			isObjectSelected(){
				return !_.isEmpty(this.selected_bo_tbl)
			},
			isEnvAndDSelected(){
				return !_.isEmpty(this.selected_env) && !_.isEmpty(this.selected_ds)
			},
			colSize(){
				if(this.isSplitMode)
					return 10
				return 6
			},
			showEnvDsSelection(){
				return !this.isEnvAndDSelected && this.userPreferencesLoaded
			}
		},
		mounted(){
			let _this = this
			_this.disableDatasourceSelection = false
			_this.disabledTableSearch  = false
			if(_this.isArchivistCall){
				_this.isAutoLoadingProps = true
				let archivistObj = _this.$store.state.archivistRedirectionObj
				_this.EnvironmentList = archivistObj.EnvironmentList
				for(var i=0;i<archivistObj.all_datasource_details.length;i++){
	        		_this.ds_list.push(_this.manageDatasourceWithErpDetails(_.cloneDeep(archivistObj.all_datasource_details[i])))
	        		_this.ds_list_for_backend.push(_this.generateDS(_.cloneDeep(archivistObj.all_datasource_details[i])))
	      		}
	      		_this.isSuperAdmin = true
	      		_this.securityModelByEnv = []
	      		_this.selected_env = _this.EnvironmentList[0]
				if(_this.whichSide === 'right'){
					_this.selected_ds = _this.ds_list[0]
				} else{
					_this.selected_ds = _this.ds_list[archivistObj.selectedDatasourceIndex]
				}
	      		_this.disableEnvironmentSelection = archivistObj.disableEnvironmentSelection
	      		_this.disableDatasourceSelection = archivistObj.disableDatasourceSelection && _this.selected_ds.disabledTableSelection
	      		_this.disabledTableSearch = archivistObj.disabledTableSearch && _this.selected_ds.disabledTableSelection
	      		_this.archivistObj = archivistObj.job_details
	      		_this.archivistRequestFor = archivistObj.request_for
	      		if(_this.disabledTableSearch || !_.isEmpty(archivistObj.selected_table)){
	      			_this.source_table_list = archivistObj.source_table_list
	      			let selected_obj = _this.source_table_list[0]
	      			if(archivistObj.selected_table){
	      				selected_obj = _.find(_this.source_table_list,['title',archivistObj.selected_table.table_name])
	      				if(!selected_obj)
	      					selected_obj = _.find(_this.source_table_list,['output_table_reference',archivistObj.selected_table.table_name])

	      			}
	      			if(selected_obj)
	      				_this.selected_bo_tbl = selected_obj
	      			else
	      				_this.selected_bo_tbl = _this.source_table_list[0]
	      		}
				debugger;
	      		_this.dictionary_data = _this.selected_ds.dictionary_data
		  		_this.object_librarian_data = _this.selected_ds.object_librarian_data
		  		_this.control_data = _this.selected_ds.control_data
		  		_this.acceleration_type = _this.selected_ds.acceleration_type

				setTimeout(()=>{
			    	_this.isAutoLoadingProps = false
					if(_this.$store.state.archivistRedirectionObj[_this.whichSide]){
						if(_this.selected_ds.resetArchivistObject){
							let newObj = _this.$store.state.archivistRedirectionObj
							newObj[_this.whichSide] = false
							_this.setArchivistRedirectionObj(newObj)
						}
						_this.loadData()
					}
			    },700)
				if(!_this.disabledTableSearch){
					_this.GetBusinessObjectByArchivist(true)
				}
			}
			else if(!this.isFromHeader && _.isEmpty(this.$store.state.dataviewerProps)){
				this.getUserPreferences()
	  			getEnvListForDataviewer(this, false,false,false,true)
			}
			else{
				_this.isAutoLoadingProps = true
				let data = _.cloneDeep(this.$store.state.dataviewerProps)
			    _this.EnvironmentList = data.EnvironmentList
			    _this.ds_list = data.ds_list
			    _this.isSuperAdmin = data.isSuperAdmin
			    _this.securityModelByEnv = data.securityModelByEnv
			    _this.ds_list_for_backend = data.ds_list_for_backend
				_this.selected_env = data.selected_env
			    _this.selected_ds = data.selected_ds
			    _this.source_table_list = data.source_table_list
			    _this.selected_bo_tbl = data.selected_bo_tbl
			    _this.dictionary_data = data.dictionary_data
			    _this.object_librarian_data = data.object_librarian_data
			    _this.control_data = data.control_data
			    _this.acceleration_type = data.acceleration_type
			    setTimeout(()=>{
			    	_this.isAutoLoadingProps = false
					_this.userPreferencesLoaded = true
			    },1000)
			}
		},
		methods:{
			...mapActions(['setDataviewerProps','setArchivistRedirectionObj','setAvailableDatasourcesForMergeView']),
			performAutoEnvSelection(data,env_id){
			  	if(!env_id)
			    	env_id = this.lastEnvPreference
			  	if(!data || data.length <0 || !env_id)
			    	return false

			  	var obj = _.find(data,["id",parseInt(env_id)])
			  	if(!obj)
			    	return false
			  	this.selected_env = obj
			  	this.getSecurityDetails()
			},
			emitChange(eventName,params){
				this.$emit(eventName,params)
			},
			getSecurityDetails(){
			    var _this = this
				_this.isSuperAdmin = true
				_this.getDataSourcesDetails()
			      if(!this.isByTicketID)
			        _this.GetBusinessObjectList()
				return
			    if(_this.isAutoLoadingProps)
			    	return
			    this.securityModelByEnv = []
			    if(!this.selected_env || !this.selected_env.id)
			      return false
			    var data = {"env_id":this.selected_env.id,"client_id":this.$session.get('client_id'),"user_id":this.$session.get("user_id")}
			    postToServer(_this,config.Security_URL+'/get_object_security_model_by_user_by_env', data).then(response=>{
			      if(response.is_super_admin)
			        _this.isSuperAdmin = response.is_super_admin

			      _this.securityModelByEnv = response.security_model
			      _this.getDataSourcesDetails()
			      if(!this.isByTicketID)
			        _this.GetBusinessObjectList()
			    }).catch(error=>{

			    })
			},
			loadData(){
				var _this = this
				if(_this.isAutoLoadingProps)
			    	return
				var dataToPass = {
					'selected_env':_this.selected_env,
					'selected_ds':_this.selected_ds,
					'selected_bo_tbl':_this.selected_bo_tbl,
					'dictionary_data':_this.dictionary_data,
					'object_librarian_data':_this.object_librarian_data,
					'control_data':_this.control_data,
					'ds_list_for_backend':_this.ds_list_for_backend,
					'EnvironmentList':_this.EnvironmentList,
					'isSuperAdmin':_this.isSuperAdmin,
					'securityModelByEnv':_this.securityModelByEnv,
					'ds_list':_this.ds_list,
					'source_table_list':_this.source_table_list,
					'acceleration_type':_this.acceleration_type,
					'whichSide':_this.whichSide,
					'isArchivistCall':_this.isArchivistCall,
					'archivistObj':_this.archivistObj
				}
				_this.setDataviewerProps(_.cloneDeep(dataToPass))
			},
			getDataSourcesDetails(){
				var _this = this
				if(_this.isAutoLoadingProps)
			    	return
			  	// this.ResetGrid()

			  	this.source_table_list = []
			  	this.target_table_list = []
			  	if(!this.selected_env || !this.selected_env.id)
			    	return false
			  	_this.$session.set('selected_env', this.selected_env)
			  	this.ds_list = []
			  	_this.ds_list_for_backend =[]
			  	_this.selected_ds ={}
			  	var data = {'client_id':this.$session.get('client_id'),
			    	'env_id':this.selected_env.id}
			  	var url = config.ENVIRONMENT_API_URL + '/get_datasource_details_with_env_id_for_dv_public'
			  	postToServer(_this, url ,data).then(response => {
			    	if(response && response.all_datasource_details){
			      		for(var i=0;i<response.all_datasource_details.length;i++){
			        		_this.ds_list.push(_this.manageDatasourceWithErpDetails(_.cloneDeep(response.all_datasource_details[i]),i))
			        		_this.ds_list_for_backend.push(_this.generateDS(_.cloneDeep(response.all_datasource_details[i])))
			      		}
			      	let table_search = ''
			      	let makeServerCall = false
			      	let autoSelectTable = false
			      	if(_this.isByTicketID){
			        	_this.selected_ds = _.find(_this.ds_list,['ds_id',_this.ticketObj.ds_id])
			         	_this.source_table_list = [_this.ticketObj.selected_object]
			         	_this.$nextTick(()=>{
			           		_this.selected_bo_tbl = _this.ticketObj.selected_object
			         	})
			        	setTimeout(()=>{
			           		_this.loadData()
			        	},100)
			      	}
			      	else{
			        	if(_this.lastDSPreference){
			          		let selected_ds = _.find(_this.ds_list,['ds_id',_this.lastDSPreference])
			          		if(selected_ds)
			            		_this.selected_ds = selected_ds
			        		}
						
			        	/* // commented below to avoid auto selection confustion.
						else
			          		_this.selected_ds = _this.ds_list[0]*/
			        	_this.lastDSPreference = ''


		        		setTimeout(function(){
		          			_this.Get_Tables(' ')
		        		},800)

			        	setTimeout(function(){
			          		_this.manageUserPreferences()
			        	},1500)
			      	}
			    }
			  }).catch(EngineError => {

			 	})
			},
			querySelections (v) {
			    let _this = this
			    clearInterval(this.callTableTimer)
		     	this.callTableTimer = setTimeout(()=>{
		      		_this.Get_Tables(v,true)
		    	},800)
			},
 			Get_Tables(table_search_string,make_server_call,forceTableSearch){
		  		var _this = this;
		  		if(!forceTableSearch &&(_this.isAutoLoadingProps || _this.disabledTableSearch))
			    	return
		  		if(!this.selected_ds || !this.selected_ds.datasource_id)
		    		return
		    	/* commenting below code on dated 17th March 2022 to avoid passing empty value to the datsourceProp in case of user started the searching the table and before loading the fresh list into the source_table_list */

		  		// _this.source_table_list = []
		  		_this.target_table_list = []

		  		_this.dictionary_data = _this.selected_ds.dictionary_data
		  		_this.object_librarian_data = _this.selected_ds.object_librarian_data
		  		_this.control_data = _this.selected_ds.control_data
		  		_this.acceleration_type = _this.selected_ds.acceleration_type
		  		var latest_table_list = []
		   		if(!_this.selected_ds.mapped_agent_id || !_this.selected_env.id)
		    		return

		    	let is_bo_add = false
		    	let is_bv_exist = false
		    	if(_this.searchForBusinessObject || _this.searchForBusinessView){
		      		if(_this.searchForBusinessObject){
						_.forEach(_this.BO_list,function(obj){
							if(obj.object_type!="BO" || (obj.ds_id != _this.selected_ds.datasource_id && !_this.isArchivistCall)){
								is_bv_exist = true
								return
							}
							var obj_to_push = {"title":obj.object_name,"object_id":obj.object_id,"object_name":obj.object_name,"type":obj.object_type,"object_version":obj.object_version,"displaytext":obj.object_name +' - Business Object'}
							if(_this.hasObjectAccess(obj.object_type,obj.object_id)){
								is_bo_add = true
								latest_table_list.unshift(obj_to_push)
							}
			      		})
					}
					
		      		if(is_bo_add && is_bv_exist && !_this.isMerged)
		          		latest_table_list.unshift({"title":_this.bo_tbl_seprator,"type":"sp","divider":true,"no-filter":true,   'displaytext':_this.bo_tbl_seprator+"-"})
		    		}
		    		if(_this.searchForBusinessView && !_this.isMerged){
		      			_.forEach(_this.BO_list,function(obj){
		        			if(obj.object_type!="BV")
		          			return

		        		var obj_to_push = {"title":obj.object_name,"object_id":obj.object_id,"object_name":obj.object_name,"type":obj.object_type,"object_version":obj.object_version,"displaytext":obj.object_name+' - Business View'}

		        		if(_this.hasObjectAccess(obj.object_type,obj.object_id))
		          			latest_table_list.unshift(obj_to_push)
		      		})
		    	}
		    	if(!make_server_call){
		        	_this.source_table_list = latest_table_list
		    	}
			  	var object_list = []
			  	if(_this.searchForTable)
			    	object_list.push("table")
			  	if(_this.searchForView)
			    	object_list.push("view")
			  	if(_this.searchForAlias)
			    	object_list.push("alias")

			  	if(!object_list.length){
			    	return
			  	}
			  	_this.tableLoading = true
			  	_this.datasource_id = _this.selected_ds.datasource_id
			  	_this.$session.set('mapped_agent_details',[_this.selected_ds.mapped_agent_id])
			  	getTableList(_this,_this.selected_env.id, _this.selected_ds.connection_string, _this.selected_ds.schema_name, _this.selected_ds.database_type, table_search_string,'',{"check_in_description":true,"description_needed":true,"object_type":object_list,'mapped_agent_id':_this.selected_ds.mapped_agent_id,'object_librarian_data':_this.selected_ds.object_librarian_data}).then(tableResponse =>{
			    		console.log(tableResponse)
					    // now here logic is show business objects and the table in single list. and use seprator as  ========= with disabled
					    // latest_table_list = tableResponse.table_view_list
					    // this.CheckObjectPermission(tableResponse.table_view_list,"DBO")
					    setTimeout(()=>{
					    	if(_this.BO_list.length)
					        	latest_table_list.unshift({"title":_this.bo_tbl_seprator,"type":"sp","divider":true,"no-filter":true,   'displaytext':_this.bo_tbl_seprator})
					        	_.forEach(tableResponse.table_view_list,function(obj){
					            	obj['displaytext'] = obj['title']
					            	let objType = "Table"
					            	if(obj.type == 'V')
					            		objType = 'View'
					            	else if(obj.type === 'A')
					            		objType = 'Synonyms'

					            	if(obj['description'])
					              		obj['displaytext'] = obj['title'] + ' - '+ objType +'  -  '+obj['description']
					            	if(_this.hasObjectAccess('DBO',obj['title']))
					              		latest_table_list.push(obj)
					        	})
			        		_this.source_table_list = latest_table_list
			      			_this.tableLoading = false;
			         	},2000)
			 	}).catch(errorResponse => {
			  		_this.tableLoading = false;
				});
			},
			GetBusinessObjectList() {
    			if(!this.selected_env || !this.selected_env.id)
      				return false
			    var user_id = this.$session.get('user_id')
			    var client_id = this.$session.get('client_id')
			    var get_bo_list_url = config.PUBLISHER_URL + '/published_objects_in_env_for_dv_public'
			    var data = {"client_id": client_id, "env_id": this.selected_env.id, "ds_id":this.selected_ds.datasource_id,
			    'filter':[{"column_name":"object_type",'operator':"_in_","value1":['BO','BV']}],"page_size":500}
			    var _this = this
    			postToServer(_this, get_bo_list_url, data, true).then(response => {
      				response = response.result
      				response = _.reverse(_.sortBy(response,"object_name"))
      				_this.BO_list = response
    			}).catch(error_response => {

    			});
  			},
			GetBusinessObjectByArchivist(forceTableSearch=false){
				if(!this.selected_env || !this.selected_env.id)
      				return false
			    var user_id = this.$session.get('user_id')
			    var client_id = this.$session.get('client_id')
			    var get_bo_list_url = config.PUBLISHER_URL + '/published_objects_in_env_for_dv_public'
			    var data = {"client_id": client_id, "env_id": this.selected_env.id, "ds_id":this.selected_ds.datasource_id,
			    'filter':[{"column_name":"object_type",'operator':"_in_","value1":['BO']}],"page_size":500}
			    var _this = this
    			postToServer(_this, get_bo_list_url, data, true).then(response => {
      				response = response.result
      				response = _.reverse(_.sortBy(response,"object_name"))
      				_this.BO_list = response
					_this.Get_Tables('',true,forceTableSearch)
    			}).catch(error_response => {
					_this.Get_Tables('',true, forceTableSearch)
    			});
			},
  			hasObjectAccess(object_type,object_id){
    			// Same function has been written in the dataviewer service. If you add any condition here , then also add condition there.
			    let _this = this
			    if(_this.isSuperAdmin)
      				return true
    			let key_to_check = 'object_id'
    			if(object_type === 'DBO')
      				key_to_check ='object_name'

    			let hasObjectLevelSecurity = _.filter(_this.securityModelByEnv,(obj)=>{
     				return obj[key_to_check] === object_id && (!obj.datasource_id || obj.datasource_id === _this.selected_ds.datasource_id)
    			})
			    if(hasObjectLevelSecurity.length)
			      hasObjectLevelSecurity = hasObjectLevelSecurity[0]

			    if(hasObjectLevelSecurity && hasObjectLevelSecurity.action_type === 'Grant' && (!hasObjectLevelSecurity.datasource_id || hasObjectLevelSecurity.datasource_id === _this.selected_ds.datasource_id))
			    	return true
			    if(hasObjectLevelSecurity && hasObjectLevelSecurity.action_type === 'Deny' && (!hasObjectLevelSecurity.datasource_id || hasObjectLevelSecurity.datasource_id === _this.selected_ds.datasource_id))
			    	return false

    			// there is no object level security specified. Now check security specified at bulk level (All with object type)
    			let hasObjectTypeHasSecurity = _.filter(_this.securityModelByEnv,(obj)=>{
      				return obj.object_type === object_type && obj.object_name === 'All' && (!obj.datasource_id || obj.datasource_id === _this.selected_ds.datasource_id)
    			})

    			return hasObjectTypeHasSecurity.length && hasObjectTypeHasSecurity[0].action_type === 'Grant' && (!hasObjectTypeHasSecurity.datasource_id || hasObjectTypeHasSecurity.datasource_id === _this.selected_ds.datasource_id)
  			},
  			getPreferencesObj(){
      			let tbl_obj_preference = {"isBV":this.searchForBusinessView,"isBO":this.searchForBusinessObject,'isTable':this.searchForTable,'isView':this.searchForView,'isAlias':this.searchForAlias}
      			if(this.selected_env && this.selected_env.id)
        			tbl_obj_preference['selectedEnv'] = this.selected_env.id
      			if(this.selected_ds && this.selected_ds.id)
        			tbl_obj_preference['selectedDS'] = this.selected_ds.id
		    	var data = {
		        	"user_name":this.$session.get('email'),
		        	"product_name":config.PRODUCT_NAME,
		        	"client_id":this.$session.get("client_id"),
		        	"tbl_obj_preference":JSON.stringify(tbl_obj_preference)
		      	}
      			return data
    		},
    		getUserPreferences(){
      			var _this = this
      			var data = _this.getPreferencesObj()
      			postToServer(_this,config.USER_PROVISION_URL+'/get_user_preference_by_username',data).then(response=>{
        			let userPreferences = JSON.parse(response.tbl_obj_preference)
        			console.log('userPreferences ',userPreferences)
        			_this.searchForBusinessView = userPreferences.isBV
			        _this.searchForBusinessObject = userPreferences.isBO
			        _this.searchForTable = userPreferences.isTable
			        _this.searchForView = userPreferences.isView
			        _this.searchForAlias = userPreferences.isAlias

        			if(userPreferences.selectedEnv)
          				_this.lastEnvPreference = userPreferences.selectedEnv

        			if(userPreferences.selectedDS)
          				_this.lastDSPreference = userPreferences.selectedDS
					setTimeout(()=>{
						_this.userPreferencesLoaded = true
					},5000)

      			}).catch(error=>{
					setTimeout(()=>{
						_this.userPreferencesLoaded = true
					},5000)
        			_this.manageUserPreferences()
      			})
    		},
    		manageUserPreferences(){
      			var _this = this
				if(_this.isArchivistCall)
      				return
      			setTimeout(()=>{
        			var data = _this.getPreferencesObj()
        			postToServer(_this,config.USER_PROVISION_URL+'/add_user_preference',data).then(response=>{

        			}).catch(error=>{

        			})
      			},300)
    		},
    		manageDatasourceWithErpDetails(datasource,index){
      			var mainDatasource = this.manageDatasourceJson(datasource.business_data)
      			if(datasource.temp_data)
        			mainDatasource['temp_data'] = this.manageDatasourceJson(datasource.temp_data)
      			if(datasource.accelerator_details){
        			mainDatasource['acceleration_type'] = datasource.accelerator_details.accelerator_type
	        		if(datasource.accelerator_details.dictionary_datasource){
	          			mainDatasource['dictionary_data'] = this.manageDatasourceJson(datasource.accelerator_details.dictionary_datasource)
	          			mainDatasource['dictionary_data']['dictionary_conn_str'] = mainDatasource['dictionary_data']['conn_str']
	          			mainDatasource['dictionary_data']['dictionary_data_schema'] = mainDatasource['dictionary_data']['schema_name']
	          			mainDatasource['dictionary_data']['dictionary_data_id'] = mainDatasource['dictionary_data']['datasource_id']
	        		}
	        		if(datasource.accelerator_details.object_librarian_datasource){
	          			mainDatasource['object_librarian_data'] = this.manageDatasourceJson(datasource.accelerator_details.object_librarian_datasource)
	          			mainDatasource['object_librarian_data']['object_librarian_conn_str'] = mainDatasource['object_librarian_data']['conn_str']
	          			mainDatasource['object_librarian_data']['object_librarian_schema'] = mainDatasource['object_librarian_data']['schema_name']
	          			mainDatasource['object_librarian_data']['object_librarian_id'] = mainDatasource['object_librarian_data']['datasource_id']
	        		}
	        		if(datasource.accelerator_details.control_datasource){
	          			mainDatasource['control_data'] = this.manageDatasourceJson(datasource.accelerator_details.control_datasource)
	          			mainDatasource['control_data']['control_conn_str'] = mainDatasource['control_data']['conn_str']
	          			mainDatasource['control_data']['control_data_schema'] = mainDatasource['control_data']['schema_name']
	          			mainDatasource['control_data']['control_conn_str'] = mainDatasource['control_data']['datasource_id']
	        		}
	      		}
						mainDatasource['datasource_index'] = index 
						if(datasource.datasource_index>-1)
							mainDatasource['datasource_index'] = datasource.datasource_index
						mainDatasource['isArchivistWorkTable'] = datasource.isArchivistWorkTable
						mainDatasource['disabledTableSelection'] = datasource.disabledTableSelection
						mainDatasource['resetArchivistObject'] = datasource.resetArchivistObject
      			return mainDatasource
    		},
	    	manageDatasourceJson(datasourceJson){
	      		datasourceJson['env_id'] = this.selected_env.id
	      		datasourceJson['ds_id'] = datasourceJson.datasource_id
	      		datasourceJson['id'] = datasourceJson.datasource_id
	      		datasourceJson['ds_name'] = datasourceJson.datasource_name
	      		datasourceJson['db_type'] = datasourceJson.database_type
	      		datasourceJson['schema'] = datasourceJson.schema_name
	      		datasourceJson['conn_str'] = datasourceJson.connection_string
	      		return datasourceJson
	    	},
	    	generateDS(unformattedDS){
	      		let formatedDS = {"business_data":unformattedDS.business_data,
	        	"engine_data":unformattedDS.business_data,"db_type":unformattedDS.business_data.database_type}
	      		if(unformattedDS.accelerator_details){
	        		formatedDS["acceleration_type"]=unformattedDS.accelerator_details.accelerator_type
	        		formatedDS["control_data"]=unformattedDS.accelerator_details.control_datasource
	        		formatedDS["object_librarian_data"]=unformattedDS.accelerator_details.object_librarian_datasource
	        		formatedDS["dictionary_data"]=unformattedDS.accelerator_details.dictionary_datasource
	      		}
	      		return formatedDS
	    	},
			 showMessage(errorMessage, type ='error'){
				this.colorValue = type
				this.snackbarText = errorMessage || 'Unhanlded Exception'; 
				this.snackbar = true                   
			},
		}
	}
</script>
