<template>
    <div class="widget-options-div style-2scroll">
        <v-card class="pa-1">
            <v-row class="EDC-Row pb-2">
                <v-col class="EDC-Col d-flex justify-start widget-form-titles text--grey text-truncate">
                    Search In
                </v-col>
            </v-row>
            <v-row class="EDC-Row">
                <v-col class="EDC-Col">
                    <object-selection :showObjectSelection="false"
                    @onDatasourceSelectionChanged="onOnDatasourceSelectionChanged"
                    :selectedDatasourceId="selectedDatasourceId"/>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="pa-1 my-2">
            <v-row class="EDC-Row">
                <v-col class="EDC-Col">
                    <more-options :showEnvOptions="false"
                    :searchOptions="searchOptions"
                    @onOptionChanged="onOptionChanged"
                    searchOptionHeaderClasses="EDC-Col d-flex justify-start widget-form-titles text--grey text-truncate"/>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="pa-1">
            <v-row class="EDC-Row pb-1">
                <v-col class="EDC-Col d-flex justify-start widget-form-titles text--grey text-truncate">
                    Search Modifiers
                </v-col>
            </v-row>
            <v-row class="EDC-Row pl-2">
                <v-col class="EDC-Col" cols="12">
                    <v-checkbox class="customCheckbox" v-model="searchModifiers.openInNewTab"
                        label="Open Search Result In New Tab"
                        @change="onValuesChanged">
                    </v-checkbox>
                </v-col>
                <v-col class="EDC-Col" cols="12">
                    <v-checkbox class="customCheckbox" v-model="searchModifiers.showGoButton"
                        label="Fetch Result On Go button"
                        @change="onValuesChanged">
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>
<style scoped>
    .customCheckbox >>> label{
        top: 4px !important;
    }
</style>
<script>
    import moreOptions from '../../searchEngine/moreOptions.vue';
    import objectSelections from './objectSelections.vue';
    export default{
        name:"searchEngineSelection",
        components:{
            'more-options':moreOptions,
            'object-selection':objectSelections
        },
        props:{
            componentDetails:{
                type:Object,
                default:()=>{
                    return {}
                }
            }
        },
        data(){
            return{
                selectedDatasource:{},
                selectedDatasourceId:"",
                searchOptions:{
                    "searchForTable":true,
                    "searchForView":true,
                    "searchForAlias":false,
                    "searchForBV":true,
                    "searchForBO":true
                },
                searchModifiers:{
                    "openInNewTab":false,
                    "showGoButton":false
                },
                componentId:"edcserachoption"
            }
        },
        computed:{
            
        },
        mounted(){
            this.manageSavedObject()
        },
        methods:{
            manageSavedObject(){
                var _this = this
                if(_.isEmpty(this.componentDetails.selectedValue))
                    return
                let allKeys = _.keys(this.componentDetails.selectedValue)
                _.forEach(allKeys,(key)=>{
                    _this[key] = this.componentDetails.selectedValue[key]
                })
            },
            onOnDatasourceSelectionChanged(selectedDatasource){
                this.selectedDatasource=selectedDatasource
                this.onValuesChanged()
            },
            onOptionChanged(searchOptions){
                this.searchOptions = searchOptions
                this.onValuesChanged()
            },
            onValuesChanged(){
                this.selectedDatasourceId = _.isEmpty(this.selectedDatasource)?"":this.selectedDatasource.datasource_id
                if(this.selectedDatasourceId){
                    let objectToPass = {
                        "searchOptions":_.cloneDeep(this.searchOptions),
                        "searchModifiers":this.searchModifiers,
                        "selectedDatasourceId":this.selectedDatasourceId
                    }
                    this.$emit("updateModelData",this.componentId,objectToPass)
                    this.$emit("allowSave",true)
                }
                else{
                    this.$emit("allowSave",false)
                }
            }
        }
    }
</script>