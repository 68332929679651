<template>
    <div>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col" :cols="currentCols">
                <v-autocomplete :class="cssClassToApply" outlined autocomplete="off" 
                :items="environmentList" item-text="env_name" item-value="env_id"
                v-model="selectedEnvironmentId" label="Select Environment"
                hide-details @input="envSelectionChange"
                :dense="showDense">
                </v-autocomplete>
            </v-col>
            <v-col class="EDC-Col" :cols="currentCols">
                <v-autocomplete :class="cssClassToApply" outlined autoomplete="off" 
                :items="datasourceList" item-text="ds_name" item-value="ds_id"
                v-model="selectedDatasourceId" label="Select Datasource"
                hide-details @input="datasourceSelectionChange"
                :dense="showDense">
                </v-autocomplete>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import config from "./../../config.json"
    import { post as postToServer } from './../../methods/serverCall.js';
    import {GET_ALL_ENV_FOR_DV} from "./../../data/url_constants.js"
    export default{
        name:"envDatasourceSelection",
        props:{
            selectedEnvId:{
                type:Number,
                default:0
            },
            selectedDSId:{
                type:String,
                default:""
            },
            envFor:{
                type:String,
                default:"pathfinder"
            },
            cssClassToApply:{
                type:String,
                default:'pa-1'
            },
            currentCols:{
                type:Number,
                default:6
            },
            showDense:{
                type:Boolean,
                default:false
            },
            showEnvOpt:{
                type:Boolean,
                default:true
            }
        },
        data(){
            return{
                selectedEnvironmentId:0,
                selectedDatasourceId:'',
                environmentList:[],
                datasourceList:[],
                isEnvDSLoadedOnceOnPage:false, // this variable will maintain the status on user has 
                //selected env & DS on page 
                dsListForBackEnd:[]
            }
        },
        computed:{
            clientId(){
                return this.$session.get('client_id')
            },
            hasBothOptionsSelected(){
                return !_.isEmpty(this.envDetails) && !_.isEmpty(this.datasourceDetails) && !this.isEnvDSLoadedOnceOnPage
            },
            selectedEnvObj(){
                return _.find(this.environmentList, ['env_id',this.selectedEnvironmentId]) || {}
            },
            selectedDSObj(){
                return _.find(this.datasourceList, ['datasource_id',this.selectedDatasourceId]) || {}
            }
        },
        watch:{
            selectedEnvId(){
                this.selectedEnvironmentId = this.selectedEnvId || 0
                this.fetchDatasourceList()
            },
            selectedDSId(){
                this.selectedDatasourceId = this.selectedDSId || ''    
            },
            showEnvOpt(){
                
            }
        },
        mounted(){
            this.selectedEnvironmentId = this.selectedEnvId || 0
            this.selectedDatasourceId = this.selectedDSId || ''
            this.fetchEnvironmentList()
        },
        methods:{
            envSelectionChange(){
                this.selectedDatasourceId = ''
                this.datasourceList = []
                this.dsListForBackEnd = []
                this.emitEvent()
                this.fetchDatasourceList()
            },
            datasourceSelectionChange(){
                if(!_.isEmpty(this.selectedDSObj))
                    this.emitEvent()
            },
            emitEvent(){
                this.$emit("onEnvDSSelectionChanged",_.cloneDeep(this.selectedEnvObj),_.cloneDeep(this.selectedDSObj),_.cloneDeep(this.dsListForBackEnd))
            },
            fetchEnvironmentList(){
                var _this = this
                var data = {
                    "client_id":_this.clientId
                }
                var url = config.ENVIRONMENT_API_URL + GET_ALL_ENV_FOR_DV
                postToServer(_this,url,data).then(response=>{
                    let envList = []
                    response.result.forEach((obj)=>{
                        envList.push({
                            "env_id":obj.id,
                            "env_name":obj.name,
                            "id":obj.id
                        })
                    })
                    _this.environmentList = envList
                    if(_this.selectedEnvironmentId){
                        _this.fetchDatasourceList()
                    }
                }).catch(error=>{
                    console.log(error)
                    // alert(error)
                })
            },
            fetchDatasourceList(){
                var _this = this
                if(!_this.selectedEnvironmentId)
                    return
                var data = {
                    "client_id":_this.clientId,
                    "env_id":_this.selectedEnvironmentId
                }
                this.dsListForBackEnd = []
                var url = config.ENVIRONMENT_API_URL + '/get_datasource_details_with_env_id_for_dv_public'
                postToServer(_this, url ,data).then(response => {
                    if(response && response.all_datasource_details){
                        for(var i=0;i<response.all_datasource_details.length;i++){
                            this.datasourceList.push(_this.manageDatasourceWithErpDetails(_.cloneDeep(response.all_datasource_details[i]),i))
                            this.dsListForBackEnd.push(_this.generateDS(_.cloneDeep(response.all_datasource_details[i])))  
                        }
                        // console.log("this.datasourceList=>",this.datasourceList)
                        if(!_this.selectedDatasourceId)
                            _this.selectedDatasourceId = this.datasourceList[0].datasource_id    
                        
                        _this.emitEvent()
                    }
                }).catch(error=>{
                    alert(error)
                })
            },
            manageDatasourceWithErpDetails(datasource, index) {
                const mainDatasource = this.formatDatasource(datasource.business_data);

                // Add optional properties if they exist
                if (datasource.temp_data) {
                    mainDatasource.temp_data = this.formatDatasource(datasource.temp_data);
                }

                if (datasource.accelerator_details) {
                    const { accelerator_details } = datasource;
                    mainDatasource.acceleration_type = accelerator_details.accelerator_type;

                    this.addDatasourceDetails(
                        mainDatasource,
                        accelerator_details.dictionary_datasource,
                        "dictionary_data",
                        "dictionary"
                    );

                    this.addDatasourceDetails(
                        mainDatasource,
                        accelerator_details.object_librarian_datasource,
                        "object_librarian_data",
                        "object_librarian"
                    );

                    this.addDatasourceDetails(
                        mainDatasource,
                        accelerator_details.control_datasource,
                        "control_data",
                        "control"
                    );
                }

                // Add other properties
                mainDatasource.datasource_index = datasource.datasource_index > -1 ? datasource.datasource_index : index;
                mainDatasource.isArchivistWorkTable = datasource.isArchivistWorkTable;
                mainDatasource.disabledTableSelection = datasource.disabledTableSelection;
                mainDatasource.resetArchivistObject = datasource.resetArchivistObject;

                return mainDatasource;
            },

            addDatasourceDetails(target, source, key, prefix) {
                if (source) {
                    const formattedData = this.formatDatasource(source);
                    target[key] = formattedData;

                    // Add prefixed properties
                    formattedData[`${prefix}_conn_str`] = formattedData.conn_str;
                    formattedData[`${prefix}_schema`] = formattedData.schema_name;
                    formattedData[`${prefix}_id`] = formattedData.datasource_id;
                }
            },
            formatDatasource(datasourceJson) {
                return {
                    ...datasourceJson,
                    env_id: this.selectedEnvironmentId,
                    ds_id: datasourceJson.datasource_id,
                    id: datasourceJson.datasource_id,
                    ds_name: datasourceJson.datasource_name,
                    db_type: datasourceJson.database_type,
                    schema: datasourceJson.schema_name,
                    conn_str: datasourceJson.connection_string,
                };
            },
            generateDS(unformattedDS) {
                const formattedDS = {
                    business_data: unformattedDS.business_data,
                    engine_data: unformattedDS.business_data,
                    db_type: unformattedDS.business_data.database_type,
                };

                if (unformattedDS.accelerator_details) {
                    const { accelerator_details } = unformattedDS;
                    formattedDS.acceleration_type = accelerator_details.accelerator_type;
                    formattedDS.control_data = accelerator_details.control_datasource;
                    formattedDS.object_librarian_data = accelerator_details.object_librarian_datasource;
                    formattedDS.dictionary_data = accelerator_details.dictionary_datasource;
                }
                return formattedDS;
            },

        }
    }
</script>