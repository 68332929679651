<template>
    <div>
        <v-autocomplete class="edc-control" v-if="isAutoComplete" :id="componentId" 
         hide-details="auto" dense v-model="modelValue" 
        :multiple="useMultiple" :disabled="isDisabled" 
        :items="itemList" :item-text="itemText"  
         :label="labelText" :item-value="itemValue" 
         @input="onItemSelectionChange($event)" :return-object="returnObject" outlined></v-autocomplete>
                        
         <v-text-field class="edc-control" v-if="isTextField" :label="labelText" dense
         :type="textType" :min="minValue" :max="maxValue" @blur="onTextValueChange"
         v-model="modelValue" hide-details="auto" outlined></v-text-field>

         <v-checkbox v-if="isCheckbox" class="radioClass" :label="labelText" hide-details
         @change="onCheckChange" v-model="modelValue"></v-checkbox>

    </div>
</template>
<style scoped>
    .radioClass >>> label{
        top: 5px !important;
    }
    .edc-control >>> .v-input__slot{
        min-height: 24px !important;
    }
</style>
<script>
import { makeAPICall,parseInputParams } from './../../../methods/layoutFunc.js';
    export default{
        name:"ComponentContainer",
        props:{
            parentSelectedValues:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            componentDetails:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            triggerFetchItemListFor:{
                type:Array,
                default:()=>{
                    return []
                }
            },
            disabled:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return{
                modelValue:"",
                itemList:[],
                currentAPIInputData:[],
                valueTimer:null
            }
        },
        computed:{
            isCheckbox(){
                return this.componentDetails.type === "checkbox"
            },
            isAutoComplete(){
                return this.componentDetails.type === "autocomplete" || this.componentDetails.type === "dropdown"
            },
            isVselect(){
                return this.componentDetails.type === "vselect"

            },
            isNumberField(){
                return this.componentDetails.type === 'number'
            },  
            isTextField(){
                return this.componentDetails.type === "text_field" || this.isNumberField
            },
            componentId(){
                return this.componentDetails.id || "cmpid"
            },
            componentKey(){
                return this.componentDetails.key
            },
            labelText(){
                return this.componentDetails.label || "select"
            },
            itemText(){
                return this.componentDetails.itemText || "object_name"
            },
            itemValue(){
                return this.componentDetails.itemValue || ""
            },
            returnObject(){
                return this.componentDetails.returnType === 'object'
            },
            isServerLoadType(){
                return this.componentDetails.loadType === 'server'
            },
            useMultiple(){
                return this.componentDetails.multiple || false
            },
            isDisabled(){
                return this.componentDetails.isDisabled || false
            },
            isServerSearch(){
                return this.componentDetails.searchType === 'server'
            },
            itemListSource(){
                return _.get(this.componentDetails,'itemListSource',{})
            },
            isServerAPI(){
                return this.itemListSource.type === "api"
            },
            serverAPIDetails(){
                return this.isServerAPI?this.itemListSource.api:{}

            },
            isStaticItemList(){
                return this.itemListSource.type === "static"
            },
            OnSelectionChangeDetails(){
                return _.get(this.componentDetails,'onChange',{})
            },
            isDatasourceDropdown(){
                return this.componentId === "drpdatasourcelist"
            },
            dependOn(){
                return this.componentDetails.dependOn||[]
            },
            textType(){
                return this.isNumberField?"number":"text"
            },
            minValue(){
                return this.componentDetails.minValue || ''
            },
            maxValue(){
                return this.componentDetails.maxValue || ''
            }
        },
        watch:{
            'componentDetails':{
                handler(newValue){

                },
                deep:true
            },
            'parentSelectedValues':{
                handler(newValue,oldValue){
                    // here we have to make sure the "key" difference between old and new should not be current
                    // component key else it will make redudent call => todo
                    // clearTimeout(this.valueTimer)
                    // this.valueTimer = setTimeout(()=>{
                    //     this.manageAutoCompleteItemList()
                    // },300)
                    if(_.isEmpty(newValue[this.componentKey]))
                        this.manageAutoCompleteItemList()
                },
                deep:true
            },
            'componentDetails.selectedValue':{
                handler(newValue){
                    this.manageModelValue()
                },
                deep:true
            },
            'triggerFetchItemListFor':{
                handler(newValue){

                }
            },
            'componentDetails.itemListSource':{
                handler(newValue){
                    this.manageAutoCompleteItemList()
                },
                deep:true
            },
            'componentDetails.isDisabled':{
                handler(newValue){

                }
            }
        },
        mounted() {
            if(this.isAutoComplete)
                this.manageAutoCompleteItemList()
            this.manageModelValue()
            
        },
        methods:{
            manageModelValue(){
                let value = this.componentDetails.selectedValue
                if(this.isCheckbox){
                    this.modelValue = value || false
                } else if(this.isTextField)
                    this.modelValue = value || ''
                else{
                    if(!_.isEmpty(value)){
                        this.modelValue =  value
                        if(this.isAutoComplete)
                            this.onItemSelectionChange(this.modelValue)
                    } else{
                        this.modelValue = this.returnObject?{}:""
                    }
                }     
            },
            manageAutoCompleteItemList(){
                var _this = this
                if(_.isEmpty(this.componentDetails))
                    return

                if(this.isStaticItemList){
                    this.itemList = this.componentDetails.itemListSource.itemList
                    return
                }

                if(_.isEmpty(this.serverAPIDetails))
                    return
                this.itemList = []
                if(this.dependOn.length){
                    // we have to check each depended object has value selected in parentSelectedValues
                    let isDependOnSelected = true
                    for(var i=0;i<this.dependOn.length;i++){
                        if(_.isEmpty(this.parentSelectedValues[this.dependOn[i]])){
                            isDependOnSelected = false
                            break
                        }
                    }
                    if(!isDependOnSelected)
                        return
                }

                let inputData = parseInputParams(this,this.serverAPIDetails.params,this.parentSelectedValues)
                makeAPICall(this,this.serverAPIDetails,inputData).then(response=>{
                    this.itemList = response.result || response
                }).catch(error=>{
                    // alert("error in API get_all_task_list_by_env")
                })

            },
            onItemSelectionChange(selectedObject){
                var _this = this
                // if(_.isEmpty(this.OnSelectionChangeDetails)){
                    // alert("OnSelectionChangeDetails not found")
                    // return
                // }
                this.$emit("updateModelData",_this.componentKey,selectedObject)

                if(this.OnSelectionChangeDetails.triggerWidgetApiCall)
                    this.$emit("onTriggerWidgetApiCall",this.OnSelectionChangeDetails.api,selectedObject)
                else{

                }
            },
            onCheckChange(){
                this.$emit("updateModelData",this.componentKey,this.modelValue)
            },
            onTextValueChange(){
                if(this.isNumberField){
                    if(this.modelValue < this.minValue)
                        this.modelValue = this.minValue
                    else if(this.modelValue > this.maxValue)
                        this.modelValue = this.maxValue
                }
                this.$emit("updateModelData",this.componentKey,this.modelValue)
            }
        }
    }
</script>