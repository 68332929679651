/*
    Created this mixin to reduce the length of edcPathfinderGrid vue page.
*/
import UDCViewer from './../views/UDCViewer.vue'
import FilelookupViewer from './../views/FilelookupViewer.vue'
import VALUElookupViewer from './../views/VALUElookupViewer.vue'
import * as JDEFormatterObj from "../methods/JDEFormatter.js"
import { post, post as postToServer } from '../methods/serverCall.js';
import config from '../config.json'


export const loopupMixin={
    components:{
        'udc-viewer':UDCViewer,
        'file-lookup-viewer':FilelookupViewer,
        'value-viewer':VALUElookupViewer
    },
    data(){
        return{
            showFileViewer:false,
            fileViewerWidth:800,
            showUDCViewer:false,
            lookupTitle:"",
            lookupFor:'',
            lookupHeader:{},
            lookupLoader:false,
            udcGridObj:{},
            tooltipObj:{},
            tooltipValuesHistory:[],
            udcServerCallTimer:null,
            fileGridObj:{},
            valueLookupList:[],
            showValueViewer:false
        }
    },
    methods:{
        onClickAdvanceFilter(header){
            this.lookupTitle = header['description']
            this.lookupFor =header['frerul']
            if(JDEFormatterObj.isUDClookup(header)){
                this.showUDCViewer = true
                if(!JDEFormatterObj.isSameLookupOpenLastTime(header,this.lookupHeader)){
                this.getUDCHeader(header)
                this.getUDCDetails(header,'multiple')
                }
            }
            else if(JDEFormatterObj.isVALUElookup(header)){
                this.showValueViewer = true
                if(!JDEFormatterObj.isSameLookupOpenLastTime(header,this.lookupHeader)){
                this.getValueLookUp(header)
                }
            }
            else if(JDEFormatterObj.isFILEloopup(header) || JDEFormatterObj.isSERVERloopup(header)){
                this.showFileViewer = true
                if(!JDEFormatterObj.isSameLookupOpenLastTime(header,this.lookupHeader) || _.isEmpty(this.fileGridObj)){
                this.fileGridObj = {}
                this.errorInFileLookupFetch =''
                this.getFILEDetails(header,{},true)
                }
            }
        },
        mouseLeaveTd(){
            let _this = this
            clearInterval(_this.udcServerCallTimer)
        },
        loadMoreInfoOfTd(header,item_value,item_row,position){
            let _this = this
            clearInterval(_this.udcServerCallTimer)
            if(!item_value || (typeof item_value === 'string' && _.isEmpty(item_value.trim())))
                return

            _this.tooltipObj = {}
            if(JDEFormatterObj.isUDClookup(header)){
                let obj = _.find(this.tooltipValuesHistory,['drky',item_value.trim()])
                if(obj){
                    let new_Obj = _.cloneDeep(obj)
                    new_Obj['item'] = item_row
                    new_Obj['position'] = position
                    new_Obj['valueFortooltip'] = obj.drdl01
                    _this.tooltipObj = new_Obj
                    return
                }
                _this.udcServerCallTimer = setTimeout(function(){
                    _this.getUDCValues(header,item_value,item_row,position)
                },_this.serverCallDelay)
            }
            else if(JDEFormatterObj.isFILEloopup(header)||JDEFormatterObj.isSERVERloopup(header)){
                let obj = _.find(this.tooltipValuesHistory,['file_lookup_item_value',item_value])
                if(obj){
                    let new_Obj = _.cloneDeep(obj)
                    new_Obj['item'] = item_row
                    new_Obj['position'] = position
                    new_Obj['valueFortooltip'] = obj.valueFortooltip
                    _this.tooltipObj = new_Obj
                    return
                }
                _this.udcServerCallTimer = setTimeout(function(){
                    _this.getFILEDetails(header,{},false,item_value,item_row,position)
                },_this.serverCallDelay)
            }
        },
        getUDCHeader(header){
            let _this = this
            let dtsy = header.frero1
            let dtrt = header.frero2
            var job_data = this.currentDatasource.control_data
            job_data['dtsy'] =dtsy
            job_data['dtrt'] = dtrt
            this.lookupLoader = true
            this.lookupHeader = _.cloneDeep(header)
            postToServer(_this, config.JDE_FORMATER_URL + '/get_udc_header',job_data).then(response => {
                this.lookupLoader = false
                this.lookupTitle = response[0].udc_description
            }).catch(error => {
                this.lookupLoader = false
            })
        },
        getUDCValues(header,item_value,item_row,position){
            this.getUDCDetails(header,'single',{},item_value,item_row,position)
        },
        getUDCDetails(header,return_type='multiple',udcGridProps={},drky='',item_row={}, position=''){
            let _this = this
            let drsy = header.frero1
            let drrt = header.frero2
            if(header['frower'] === 'UDC' && !_this.$session.get("is_jde_world_erp")){
                drsy = header.froer1
                drrt = header.froer2
            }
            var job_data = _this.currentDatasource.control_data
            job_data['drky'] =drky
            job_data['drsy'] =drsy
            job_data['drrt'] = drrt
            job_data['return_type'] = return_type
            Object.assign(job_data, udcGridProps)
            if(return_type === 'multiple'){
                _this.udcGridObj = {"rows":[],"total":0}
                _this.lookupLoader = true
            }
            postToServer(_this, config.JDE_FORMATER_URL + '/get_udc_details',job_data).then(response => {
                _this.lookupLoader = false
                if(return_type === 'multiple'){
                    let total_count = 0
                    if(response.length){
                        total_count = response[0].total_count
                    }
                    _this.udcGridObj = {"rows":response,"total_count":total_count}
                }
                else{
                    if(response && response.length===1){
                        response[0]['item'] = item_row
                        response[0]['position'] = position
                        response[0]['valueFortooltip'] = response[0].drdl01
                        _this.tooltipValuesHistory.push(response[0])
                        _this.tooltipObj = response[0]
                    }
                }
            }).catch(error => {
                _this.lookupLoader = false
                _this.showError(error)
            })
        },
        applyUDCFilter(rows,column_name,udc_column){
            let _this = this
            let filtertext = rows[0][udc_column]
            if(!filtertext)
                // udc has valid 2 spaces. back return null.
                filtertext = '  '
            _this.tableGridObj.updateInlineFilter = {'column_name':column_name,'filtertext':filtertext}
            _this.showUDCViewer = false
        },
        getFILEDetails(header,fileGridProps={},is_column_details_req=false,single_value_for_search='',item_row={},position=''){
            let _this = this
            let frdtai = header.frdtai

            var job_data = {"client_id":this.clientId, "env_id":this.selectedEnvironment.id}
            job_data['data_ds'] =this.currentDatasource.engine_data
            job_data['dd_source'] = this.currentDatasource.dictionary_data
            job_data['ol_source'] = this.currentDatasource.object_librarian_data
            job_data['frdtai'] = header.frdtai
            Object.assign(job_data,fileGridProps)
            job_data['acceleration_type']=this.currentDatasource.acceleration_type
            job_data['is_column_details_req'] = is_column_details_req
            job_data['frerul'] = this.lookupFor

            job_data['single_value_for_search'] = single_value_for_search
            if(!single_value_for_search){
                _this.lookupLoader = true
                if(!_.isEqual(_this.lookupHeader,header)){
                _this.lookupHeader = _.cloneDeep(header)
                }
            }
            else{
                job_data['frerul'] = header['frerul']
            }
            postToServer(_this, config.JDE_FORMATER_URL + '/get_file_lookup_details',job_data).then(response => {
                _this.lookupLoader = false
                if(single_value_for_search){
                    if(response.data && response.data.length===1){
                        response.data[0]['item'] = item_row
                        response.data[0]['position'] = position
                        response.data[0]['valueFortooltip'] = response.data[0][response.column_for_tooltip] || response.data[0][response.column_for_tooltip.toUpperCase()]
                        response.data[0]['file_lookup_item_value'] = single_value_for_search
                        _this.tooltipValuesHistory.push(response.data[0])
                        _this.tooltipObj = response.data[0]
                    }
                    return
                }
                let total_count = 0
                if(response.data && response.data.length){
                    total_count = response.data[0].total_count
                }
                let file_headers = []
                _this.fileViewerWidth = 800
                if(is_column_details_req){
                    if(response.columns && response.columns.length >4)
                        _this.fileViewerWidth = 1000
                    if(response.columns && response.columns.length >6)
                        _this.fileViewerWidth = 1200
                    file_headers = JDEFormatterObj.getFILEGridHeaders(response.columns,_this.currentDatasource.engine_data.database_type)
                }
                _this.fileGridObj = {"rows":response.data,"total_count":total_count,"headers":file_headers,"column_to_pass":response.column_to_pass}
            }).catch(error => {
                _this.lookupLoader = false
                _this.showError(error,'info')
            })
        },
        applyFileFilter(rows,column_name,file_column){
            let _this = this
            let filtertext = rows[0][file_column] || rows[0][file_column.toUpperCase()]
            _this.tableGridObj.updateInlineFilter = {'column_name':column_name,'filtertext':filtertext}
            _this.showFileViewer = false
        },
        getValueLookUp(header){
            let _this = this
            let frdtai = header.frdtai
            // this.lookupTitle = frdtai
            var job_data = this.currentDatasource.dictionary_data
            job_data['frdtai'] = header.frdtai
            _this.lookupLoader = true
            _this.lookupHeader = _.cloneDeep(header)
            postToServer(_this, config.JDE_FORMATER_URL + '/get_value_lookup_details',job_data).then(response => {
                _this.lookupLoader = false
                _this.valueLookupList = response
            }).catch(error => {
                _this.lookupLoader = false
                _this.showError(error)
            })
        },
        applyVALUEFilter(column_name,value){
            let _this = this
            _this.tableGridObj.updateInlineFilter = {'column_name':column_name,'filtertext':value}
            _this.showValueViewer = false
        },
    }
}