<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="12" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Package List</li>
                            <li v-if="erp_list.length > 0">
                                <select v-model="erp_code" autofocus style="-webkit-appearance: menulist;" @change="fetch_erp_version()">
                                    <option v-for="code in erp_list" v-bind:key="code" :value="code.erp_code">{{code.erp_code_desc}}</option>
                                </select>
                            </li>
                            <li v-if="erp_list.length > 0">
                                <select v-model="erp_version" autofocus style="-webkit-appearance: menulist;" @change="GetPackageList()">
                                    <option v-for="version in erp_version_list" v-bind:key="version" :value="version">{{version}}</option>
                                </select>
                                <li v-if="erp_list.length == 0">All Packages
                            </li>
                            
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onHistory="onHistory" @onReportDownload="onReportDownload" @onDelete="onDelete" @onCellEvent="onCellEvent"></edc-data-grid>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

        <simplert :useRadius="true" :useIcon="true" ref="simplert"></simplert>

        <loading-panel :loader="loader"></loading-panel>

    </div>
</template>

<script>
import Simplert from 'vue2-simplert'
import {fileUpload, post as postToServer, deleteFromServer} from "@/methods/serverCall.js"
import config from '../../config.json'
import { GET_ALL_PACKAGES, DELETE_PACKAGE  } from '../../data/url_constants.js';
import { fetch_all_erp, fetch_erp_version } from "./../../methods/erp_details.js"
import {CLIENT_SIDE, SERVER_SIDE , MANAGE_PACKAGES, REPOSITORY_SERVICE_ID, PACKAGE_BUILDER} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import { routers } from 'jointjs'
import getUserRole from './../../methods/GetUserRole.js'
import {SERVER_ERROR} from '../../data/client_message.js'

export default {
    data () {
        return {
            snackbar:false,
            snackbartext:'',
            snackbartimeout: Snackbar_Default_Timeout,
            colorValue:'error',
            loader: false,
            erp_list: [],
            erp_version_list: [],
            erp_code : '',
            erp_version : '',
            tableList: {
                headers: [
                    {text: 'Package Display Name', value: 'package_display_name', sortable: true,
                    disabled: true, width: '15%', align:'left', title: 'Display Name of Package'},
                    {text: 'Package Name', value: 'package_name', sortable: true, disabled: true,
                    width: '20%', align:'left', title: 'Name of Package'},
                    {text: 'ERP Code', value: 'erp_code', sortable: true,
                    disabled: true, width: '3%', align:'left', title: 'ERP Code'},
                    {text: 'ERP Version', value: 'erp_version', sortable: true, disabled: true,
                    width: '2%', align:'left', title: 'ERP Version', dataType:'int'},
                    {text: 'Package File Name', value: 'package_file_name', sortable: true,
                    disabled: true, width: '15%', align:'left', title: 'Name of Package File'},
                    {text: 'Created By', value: 'created_by', sortable: true, disabled: true,
                    width: '15%', align:'left', title: 'Creator of Package'},
                    {text: 'Created Date', value: 'timezone_aware_created_date', sortable: true,
                    disabled: true, width: '10%', align:'left', title: 'Date of Package Created', dataType:'date'},
                    {text: 'Created Time', value: 'timezone_aware_created_time', sortable: true,
                    disabled: true, width: '10%', align:'left', title: 'Time of Package Created', dataType:'time', hideInlineFilter: true},
                    {text: 'Package Description', value: 'package_description', sortable: true,
                    disabled: true, width: '10%', align:'left', title: 'Description of Package'}
                ],               
                
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                itemkey: "package_display_name",
                hideFilter:true,
                hideExport:true,
                hideShowColumns:true
            },
            package_list: [],
        }
    },

    watch:{
        erp_list(newval){
        }
    },

    components: {
        Simplert
    },

    mounted(){
        var _this = this
        this.userRole = this.$session.get('user_role_mapping');
        console.log(this.userRole)
        this.tableList.actions = [
            {'text':'onReportDownload', selectType:"single",directDownload:true,'key':"is_download_ready", cmpValue:'Ready', 
            role:this.userRole.is_superadmin || getUserRole(this.userRole,PACKAGE_BUILDER, MANAGE_PACKAGES), index:2},
            {'text':'history','key':"id", selectType:"single", 
            role:this.userRole.is_superadmin || getUserRole(this.userRole,PACKAGE_BUILDER, MANAGE_PACKAGES), index:1},
            {'text':'delete','key':"id", selectType:"single", 
            role:this.userRole.is_superadmin || getUserRole(this.userRole,PACKAGE_BUILDER, MANAGE_PACKAGES), index:3}
            ];
        var client_id = this.$session.get("client_id")
        
        _this.erp_code = this.$route.params.erp_code ? this.$route.params.erp_code : _this.erp_code;
        _this.erp_version = _this.$route.params.erp_version ? _this.$route.params.erp_version : _this.erp_version;
        fetch_all_erp(this, client_id, true)
        if(this.erp_list.length==0){
            this.GetPackageList();
        }
        
    },

    methods:{
        GetPackageList() {
            let _this = this;
            _this.loader = true;
            if (this.erp_code == "select" || this.erp_code == "select" || this.erp_version=="select" || this.erp_version=="select"){
                this.tableList.rows = [];
                this.tableList.total_count =0; 
            }
            
    
            var get_package_list_url = config.PACKAGE_API_URL + GET_ALL_PACKAGES
            var client_id = this.$session.get('client_id')
            var t_data = {'erp_code': this.erp_code, 'erp_version': _this.erp_version, 'client_id': client_id}
            postToServer(this, get_package_list_url, t_data).then(response =>
            {
                var data = response
                if(data){
                    _this.loader=false
                    this.tableList.rows = data;
                    this.tableList.total_count =data.length; 
                }
                else
                {
                    data = []
                    this.tableList.rows = data;
                    this.tableList.total_count = data.length; 
                }
            }).catch(ProcessDocError => {
                _this.loader = false; 
                if(ProcessDocError){
                    this.tableList.rows = [];
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = ProcessDocError;
                }   
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },

        onHistory:function(record){
            this.$router.push({ name: 'PackageObject', params: {package_id: record.id, package_name: record.package_name, package_display_name: record.package_display_name,package_description: record.package_description}});
        },

        onReportDownload(record){
            var url = config.PACKAGE_API_URL + "/static/" + record.package_file_name
            window.open(url, '_blank');
        },

        onDelete(record){
            console.log(record)
            var package_data = {"package_id": record.id}
            let url = config.PACKAGE_API_URL + DELETE_PACKAGE
            postToServer(this, url, package_data).then(response => {
                  this.colorValue = 'success'
                  this.snackbar = true
                  this.snackbartext = response;
                  this.GetPackageList()
            }).catch(SERVER_ERROR => {
                debugger
                 this.snackbar = true;
                 this.colorValue = 'error'
                 this.snackbartext = SERVER_ERROR;
             })
        },

        onCellEvent(record){ 
        this.onReportDownload(record);
        },

        fetch_erp_version(){
            var client_id = this.$session.get("client_id")
            fetch_erp_version(this, client_id, this.erp_code, true)
            
        },
    }
}
</script>
<style>
</style>