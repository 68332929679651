<template>
    <div class="px-1">
        <vue-resizable
        :minHeight="minHeight"
        :height="height"
        ref="resizableComponent"
        @resize:move="onManualResizeEnd"
        @resize:end="onManualResizeEnd"
        :active="['b','r']"
        class="resizable-container ma-0" style="height:100% !important;width: 100% !important;">
            <v-col :cols="12" class="resizable-col pa-0 ma-0">
                <v-card :style="{ height: height + 'px' }" class="d-flex flex-column" :loading="isLoading">
                    <v-card-title class="widget-heading-standard d-flex justify-space-between">
                        <span class="d-flex-inline"> 
                            <span v-if="isTitleEdit">
                                <v-text-field label="" solo dense
                                type="text" @blur="onNameChange"
                                v-model="widgetTitle" hide-details="auto"
                                @keyup.enter="onNameChange"></v-text-field>
                            </span>
                            <span v-else @dblclick="isTitleEdit=true" @click="redirectToPage">{{ widgetTitle }} </span>
                            <v-menu v-if="!isTitleEdit" slot="activator" right v-model="widgetMoreInfo" :close-on-content-click="false"
								:nudge-width="280">
                                <template v-slot:activator="{ on }">
                                    <svgicon v-on="on" name="job_details_v2" class="svg-icon-inline svg-fill-grid justify-start" />
                                </template>
                                <v-card elevation="0">
									<v-row class="EDC-Row">
										<v-col class="EDC-Col">
											<v-toolbar dense flat tile class="EDC-Toolbar" style="height:24px !important;"> 
                                                <v-toolbar-title  style="width:100% !important;" class="pb-6">More Information</v-toolbar-title></v-toolbar>
										</v-col>
									</v-row>
                                    <v-card-text class="pa-2">
                                        <v-row class="EDC-Row">
                                            <v-col class="EDC-Col pa-1 d-flex justify-start" cols="5">Object Name:</v-col>
                                            <v-col class="EDC-Col pa-1 d-flex justify-start text-truncate" :title="selectedObjectName"><b>{{ selectedObjectName }}</b></v-col>
                                        </v-row>
                                        <v-row class="EDC-Row">
                                            <v-col class="EDC-Col pa-1 d-flex justify-start" cols="5">Object Id:</v-col>
                                            <v-col class="EDC-Col pa-1 d-flex justify-start"><b>{{ selectedObjectId }}</b></v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                                
                        </span>
                        <v-menu offset-y left class="EDC-GridColumnHide" close-on-click :close-on-content-click='true'>
                            <template v-slot:activator="{ on }">
                                <svgicon v-on="on" name="More" class="svg-icon-grid svg-fill-grid EDC-GridIcon justify-end">

                                </svgicon>
                            </template>
                            <v-list>
                                <v-list-item dense id="menulistitemhover" @click="onEdit">Edit</v-list-item>
                                <v-list-item dense id="menulistitemhover" @click="onCopy">Copy</v-list-item>
                                <v-list-item dense id="menulistitemhover" @click="showConfirmation">Delete</v-list-item>
                                <!-- As Per SIDDHESH: commentng reset size option, because vue-resizable componnt is not taking width, need to forcefully do that -->
                                <!-- <v-list-item dense id="menulistitemhover" 
                                @click="resetToDefault" v-if="isResetApplicable">Reset Size</v-list-item> -->

                            </v-list>
                        </v-menu>
                        <!-- <svgicon name="cancel_v2"
                        class="svg-icon-grid svg-fill-grid EDC-GridIcon justify-end"
                        @click="showConfirmation"/> -->
                    </v-card-title>
                    <v-card-text class="overflow-auto style-2scroll flex-grow-1 pa-1">
                        <card-text-component :widgetObjectJson="widgetObjectJson"
                        @onWidgetUniqueKeyChange="widgetUniqueKey=$event"
                        @widgetTitleChange="onWidgetTitleChange" @onLoadingChange="isLoading=$event"
                        @applyCardTextCssClasses="cardTextCssClasses=$event"
                        @onObjectSelected="onObjectSelected" :widgetDivHeight="height"
                        @onRedirectionDetails="redirectionURL=$event"
                        @applyWidgetType="widgetType=$event"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </vue-resizable>
        <simplert :useRadius="true" :useIcon="true" ref="simplert">
        </simplert>
    </div>
</template>
<style scoped>
    /* Ensure the vue-resizable container has a border to visualize the resizing */
    .resizable-container {
        border: 1px solid #ddd;
    }

    /* Custom styles for the resizable column */
    .resizable-col {
        transition: all 0.3s ease;
        background-color: #f5f5f5;
    }

    .widget-heading-standard:hover{
        text-decoration: underline;
        cursor: pointer;
    }

</style>
<script>
    import VueResizable from "vue-resizable"; // Import vue-resizable component
    import { eventBus } from './../../eventBus';
    import cardTextComponent from "./widgetLoaders/cardTextComponent.vue";
    import Simplert from 'vue2-simplert'
    import {widgetDefaultHeightSize,widgetDefaultWidthSize,widgetMinimumHeightSize} from "./../../constants/widgetConsts"
    export default {
        components: {
            VueResizable,
            'simplert':Simplert,
            'card-text-component':cardTextComponent
        },
        props:{
            widgetObjectJson:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            propHeightSize:{
                type:Number,
                default:3
            },
            propColSize:{
                type:Number,
                default:3
            },
            widgetDefaultTitle:{
                type:String,
                default:""
            }
        },
        data() {
            return {
                // Initial sizes for width and height
                height: 240,
                currentColSize: 3, // Start with max 12 columns
                widgetUniqueKey:"",
                widgetTitle:"",
                isLoading:false,
                cardTextCssClasses:"",
                // heightSnappingDiff:40,
                selectedObjectId:"",
                selectedObjectName:"",
                widgetMoreInfo:false,
                isTitleEdit:false,
                redirectionURL:'',
                widgetType:"",
                baseValueForHeight:20,
                currentHeightSize:5,
            };
        },
        computed:{
            incrementValues(){
                let baseValue = this.baseValueForHeight
                let incrementValues = {
                    xs: baseValue,
                    sm: baseValue,
                    md: baseValue*2,
                    lg: baseValue*2,
                    xl: baseValue*4,
                    xxl: baseValue*8,
                }
                return incrementValues
            },
            heightSnappingDiff(){
                // Detect the current breakpoint
                const { xs, sm, md, lg, xl, xxl } = this.$vuetify.breakpoint;
                if (xs || sm) return this.incrementValues.xs;
                if (md || lg) return this.incrementValues.md;
                if (xl) return this.incrementValues.xl;
                if (xxl) return this.incrementValues.xxl;
                return this.incrementValues.xs; // Default value
            },
            isResetApplicable(){
                return this.currentColSize!= widgetDefaultWidthSize || this.currentHeightSize != widgetDefaultHeightSize
            },
            minHeight(){
                return this.heightSnappingDiff * widgetMinimumHeightSize
            },
        },
        watch:{
            // 'propHeightSize':{
            //     handler(newValue){
            //         this.height = (newValue || widgetDefaultHeightSize)*this.heightSnappingDiff
            //     }
            // },
            // 'propColSize':{
            //     handler(newValue){
            //         this.currentColSize = newValue
            //     }
            // },
            'widgetDefaultTitle':{
                handler(newValue){
                    this.widgetTitle = this.widgetDefaultTitle
                }
            },
        },
        mounted() {
            var _this = this
            this.currentColSize = this.propColSize || widgetDefaultWidthSize
            this.currentHeightSize = this.propHeightSize || widgetDefaultHeightSize
            this.height = this.currentHeightSize*this.heightSnappingDiff
            this.widgetTitle = this.widgetDefaultTitle || ''

            // Dynamically set column size based on screen size
            eventBus.$on('stopWidgetLoader', (widgetUniqueKey) => {
                if(widgetUniqueKey === this.widgetUniqueKey)
                _this.isLoading = false
            });
            
        },
        beforeDestroy() {
            
        },
        methods: {
            onManualResizeEnd(event){
                var width = event.width
                this.height = this.getNearestSnappingPoint(event.height)
                this.currentHeightSize = this.height/this.heightSnappingDiff
                // here we need to snap currentColSize based on the width and the screensize
                var screenWidth = window.innerWidth;
                var colWidths = [3, 6, 9, 12];
                var colWidthValues = colWidths.map(cols => (screenWidth / 12) * cols);

                // Find the closest column width
                let closestCol = colWidths[0];
                let minDifference = Math.abs(width - colWidthValues[0]);

                for (let i = 1; i < colWidths.length; i++) {
                    var difference = Math.abs(width - colWidthValues[i]);
                    if (difference < minDifference) {
                        minDifference = difference;
                        closestCol = colWidths[i];
                    }
                }
                this.currentColSize = closestCol
                this.$emit("colSizeChanged",this.currentColSize)
                this.$emit("onWidgetResize",this.currentHeightSize)
            },
            getNearestSnappingPoint(currentHeight) {
                // Calculate the remainder when dividing currentHeight by heightSnappingDiff.
                // This remainder helps us determine how close the current height is to the next snapping point.
                let reminder = currentHeight % this.heightSnappingDiff;

                // Check if the remainder is more than half of heightSnappingDiff.
                // If true, snap to the higher snapping point (rounding up).
                if (reminder > (this.heightSnappingDiff / 2)) {
                    // Calculate the higher snapping point by adjusting currentHeight.
                    // First, subtract the remainder to get to the lower point, then add heightSnappingDiff to round up.
                    return parseInt(currentHeight - reminder + this.heightSnappingDiff);
                }

                // Otherwise, snap to the lower snapping point by subtracting the remainder from currentHeight.
                // Check if the snapped height is above the minimum height (minHeight).
                // If snapped height is below minHeight, use minHeight instead.
                return parseInt(currentHeight - reminder) > this.minHeight 
                    ? parseInt(currentHeight - reminder) 
                    : this.minHeight;
            },
            onClear(){
                this.$emit("removeWidget")
            },
            showConfirmation(){
                let confirmFn = function () {
					this.$parent.onClear()
				}
				let obj = {
					title: 'Confirmation',
					message: "Are you sure you want to remove '"+this.widgetTitle+"' widget from layout?",
					type: 'info',
					useConfirmBtn: true,
					onConfirm: confirmFn,
					customCloseBtnText:'No',
					customConfirmBtnText:'Yes'
				}
				this.$refs.simplert.openSimplert(obj)
            },
            onEdit(){
                this.$emit("onEdit",this.widgetObjectJson)
            },
            onCopy(){
                this.$emit("onCopy",this.widgetObjectJson)
            },
            resetToDefault(){
                this.currentColSize = widgetDefaultWidthSize
                this.height = widgetDefaultHeightSize * this.heightSnappingDiff
                this.currentHeightSize = widgetDefaultHeightSize
                this.$emit("colSizeChanged",this.currentColSize)
                this.$emit("onWidgetResize",this.currentHeightSize)
            },
            onObjectSelected(objectId,objectName, selectedObject){
                // assumed each time, there will be object_id & object name
                this.selectedObjectId = objectId
                this.selectedObjectName = objectName
            },
            onNameChange(){
                this.isTitleEdit = false
                if(!this.widgetTitle)
                    this.widgetTitle = this.selectedObjectName
                this.$emit("onWidgetTitleChanged",this.widgetTitle)
            },
            onWidgetTitleChange(widgetTitle){
                if(!this.widgetTitle)
                    this.widgetTitle= widgetTitle
                this.$emit("onWidgetTitleChanged",this.widgetTitle)
            },
            redirectToPage(){
                if(this.widgetType === "TASK_LIST"){
                    return
                }
                if(!this.redirectionURL){
                    alert("Url details not found")
                    return
                }
                window.open(this.redirectionURL, '_blank');
            }
        }
    }

</script>