<template>
    <div>
        <component :is="currentWidgetComponent" :dataObject="widgetDataObject"
        @onWidgetUniqueKeyChange="onWidgetUniqueKeyChange"
        :widgetDivHeight="widgetDivHeight" @onLoadingChange="onLoadingChange"
        @onDrilldownClick="onDrillDownDetails"
        :redirectionURL="redirectionURL"/>
    </div>
</template>
<style scoped>

</style>
<script>
    import taskListWidget from "./taskListWidget.vue"
    import datagridWidget from "./dataGridWidget.vue"
    import searchWidget from "./searchWidget.vue";
    import chartWidget from "./chartWidget.vue"
    import { makeAPICall,parseInputParams,parseModifiers,createRedirectionUrl } from '../../../methods/layoutFunc.js';

    export default {
        components: {
            'task-list-widget':taskListWidget,
            'data-grid-widget':datagridWidget,
            'chart-widget':chartWidget,
            'search-widget':searchWidget
        },
        props:{
            widgetObjectJson:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            widgetDivHeight:{
                type:Number,
                default:0
            }
        },
        data() {
            return {
                widgetTitle:"",
                widgetDataObject:{},
                widgetHeightChangeObjserver:null,
                currentWidgetAPIDetails:{},
                currentComponentSelectedValue:{},
                apiTriggerComponentName:"",
                redirectionURL:""
            }
        },
        computed: {
            userName(){
                return this.$session.get("email")
            },
            clientId(){
                return this.$session.get("client_id")
            },
            // Check if the widget is selected by checking if widgetObjectJson is not empty
            isWidgetSelected() {
                return !_.isEmpty(this.widgetObjectJson);
            },

            // Return widget properties or an empty object if the widget is not selected
            widgetProperties() {
                return this.isWidgetSelected ? this.widgetObjectJson : {};
            },

            // Extract the widget type or return "N/A" if properties are missing
            widgetType() {
                return _.get(this.widgetProperties, 'widgetType.itemValue', '');
            },

            isWidgetDataLoaded(){
                return !_.isEmpty(this.widgetDataObject)
            },
            // Check current widget is tasklist or not
            isWidgetForTask(){
                return this.widgetType === "TASK_LIST"
            },

            // Check current widget is GRID or not
            isWidgetForGrid(){
                return this.widgetType === "GRID"
            },
            isWidgetForChart(){
                return this.widgetType === 'CHART'
            },
            isWidgetForSearch(){
                return this.widgetType === 'PATHFINDER_SEARCH'
            },
            // CSS class for the widget title, defaulting to an empty string
            titleClass() {
                return this.isWidgetSelected ? this.widgetObjectJson.title.titleClass : '';
            },

            // UI settings object extracted from widget properties, fallback to empty object
            uiSettingObject() {
                return _.get(this.widgetProperties, 'uiSettings', {});
            },

            // Check if the UI settings object is not empty
            hasUISettings() {
                return !_.isEmpty(this.uiSettingObject);
            },

            // CSS classes for the content, fallback to "content-standard"
            contentCssClasses() {
                return _.get(this.uiSettingObject, 'cssClasses', 'content-standard');
            },

            // CSS classes for overflow behavior, fallback to default scrolling class
            overflowCssClasses() {
                return _.get(this.uiSettingObject, 'overflowCssClasses', 'overflow-auto style-2scroll flex-grow-1');
            },
            cardTextCssClasses(){
                let defaultClassList = [this.contentCssClasses,this.overflowCssClasses,'pa-0']
                // want to show controls at the center of the grid
                if (!this.isWidgetDataLoaded)
                    defaultClassList = [...defaultClassList,...['d-flex','justify-center','align-center']]
                return defaultClassList
            },
            // Height of the widget, fallback to an empty object if not defined
            widgetHeight() {
                return _.get(this.uiSettingObject, 'widgetHeight', {});
            },
            // API details from the datasource info, fallback to empty object
            apiDetails() {
                return {}
            },
            UIComponents(){
                return _.get(this.widgetProperties, 'uiComponents', []);
            },
            allComponentSelectedValues(){
                let allSelectedValues = {}
                if(!this.UIComponents.length)
                    return allSelectedValues
                for(var i=0;i<this.UIComponents.length;i++){
                    let currentComponent = this.UIComponents[i]
                    if(!_.isEmpty(currentComponent.selectedValue))
                        allSelectedValues[currentComponent.key] = {
                            "selectedValue":currentComponent.selectedValue
                        }
                }
                return allSelectedValues
            },
            modifierComponents(){
                return _.get(this.widgetProperties, 'modifiers', []);
            },
            hasModifiers(){
                return this.modifierComponents.length > 0
            },
            allModifierSelectedValues(){
                let allModifierValues = {}
                if(!this.hasModifiers)
                    return allModifierValues
                for(let i=0;i<this.modifierComponents.length;i++){
                    let currentModifier = this.modifierComponents[i]
                    allModifierValues[currentModifier.key] = currentModifier.selectedValue
                }
                return allModifierValues
            },
            currentWidgetComponent(){
                if(this.isWidgetForTask)
                    return "task-list-widget"
                if(this.isWidgetForGrid)
                    return "data-grid-widget"
                if(this.isWidgetForChart)
                    return "chart-widget"
                if(this.isWidgetForSearch)
                    return 'search-widget'
                return ""
            },
            Environment(){
                return this.$session.get('selected_env')
            },
            redirectionDetails(){
                return _.get(this.widgetProperties, 'redirection', {});
            },
            widgetTitle1(){
                return this.widgetDefaultTitle
            }
        },
        watch:{
            'widgetObjectJson':{
                handler(newValue){
                    this.manageWidgetObject()
                }
            },
            'widgetTitle':{
                handler(newValue){
                    this.$emit("widgetTitleChange",newValue)
                }
            },
            'widgetDivHeight':{
                handler(newValue){
                    
                }
            }
        },
        mounted() {
            var _this = this
            this.manageWidgetObject()
        },
        methods:{
            manageWidgetObject(){
                var _this = this
                if(!_this.isWidgetSelected)
                    return
                this.$emit("applyCardTextCssClasses",this.cardTextCssClasses)
                this.$emit('applyWidgetType',this.widgetType)
                for(let i=0;i<this.UIComponents.length;i++){
                    if(this.UIComponents[i].onChange && this.UIComponents[i].onChange.triggerWidgetApiCall){
                        _this.currentWidgetAPIDetails = _this.UIComponents[i].onChange.api
                        _this.currentComponentSelectedValue = _.cloneDeep(_this.UIComponents[i].selectedValue)
                        _this.apiTriggerComponentName = _this.UIComponents[i].key
                        _this.onTriggerWidgetApiCall(_this.UIComponents[i].selectedValue)
                        _this.generateRedirectionDetails()
                        break;
                    } else if(this.UIComponents[i].key === 'edcserachoption'){
                        // this wont have any trigger
                        this.widgetDataObject = _this.UIComponents[i].selectedValue
                    }
                }
            },
            onTriggerWidgetApiCall(selectedObject,isDrilldown=false){
                if(this.isWidgetForTask){
                    var isDrilldown = false
                }
                var _this = this
                var APIDetails = _.cloneDeep(this.currentWidgetAPIDetails)
                debugger;
                // this.widgetTitle = ""
                let allComponentSelectedValues = _.cloneDeep(this.allComponentSelectedValues)
                if(_.isEmpty(selectedObject))
                    return
                if(isDrilldown){
                    allComponentSelectedValues[this.apiTriggerComponentName]['selectedValue'] = selectedObject
                }
                else{
                    let objectId = selectedObject.object_id
                    let objectName = selectedObject.object_name
                    if(this.isWidgetForChart || this.isWidgetForGrid){
                        objectId = selectedObject.selectedObject.object_id
                        objectName = selectedObject.selectedObject.object_name
                    }
                    if(this.isWidgetForTask){
                        objectId = selectedObject.selectedTaskList.object_id
                        objectName = selectedObject.selectedTaskList.object_name
                    }
                    this.$emit("onObjectSelected",objectId,objectName,selectedObject)
                }
                // make sure all required params are there, not needed now but -> todo
                let inputData = parseInputParams(this,APIDetails.params,allComponentSelectedValues)
                
                let modifierData = parseModifiers(this.modifierComponents) 
                // Object.assign(inputData,{"modifiers":modifierData.payloadModifiers})                

                this.$emit("onLoadingChange",true)
                
                if(this.isWidgetForChart)
                    this.widgetTitle = selectedObject.chartName
                makeAPICall(this,APIDetails,inputData).then(response=>{
                    this.$emit("onLoadingChange",false)
                    if(this.isWidgetForTask){
                        this.widgetDataObject = {
                            "selectedTaskListObject":selectedObject,
                            "tasksForSelectedList":response.object_json.sections,
                            "openInNewTab":modifierData.browserModifiers.openInNewTab
                        }
                        this.widgetTitle = selectedObject.object_name
                        // alert(JSON.stringify(response.object_json))

                        this.taskListId = selectedObject.object_id
                        // alert(selectedObject.object_desc)
                    } else if(this.isWidgetForGrid){
                        this.$emit("onLoadingChange",true)
                        Object.assign(response,selectedObject)
                        _this.widgetDataObject = response
                        this.widgetTitle = response.widgetHeader
                    } else if(this.isWidgetForChart){
                        this.widgetDataObject ={
                            "series":response,
                            "selectedProps":_this.currentComponentSelectedValue
                        }
                    }
                }).catch(error=>{
                    this.$emit("onLoadingChange",false)
                    // alert("error in API onTriggerWidgetApiCall")
                })
            },
            onWidgetUniqueKeyChange(widgetKey){
                this.$emit('onWidgetUniqueKeyChange',widgetKey)
            },
            onLoadingChange(value){
                this.$emit("onLoadingChange",true)
            },
            generateRedirectionDetails(){
                if(_.isEmpty(this.redirectionDetails))
                    return
                let redirectionQueryParams = this.redirectionDetails.params || this.redirectionDetails.queryParams
                let parsedQueryParams = parseInputParams(this,redirectionQueryParams,_.cloneDeep(this.allComponentSelectedValues))
                let url = createRedirectionUrl(this,this.redirectionDetails.productName,this.redirectionDetails.url,parsedQueryParams)
                this.redirectionURL = url
                if(!this.isWidgetForChart)
                    this.$emit("onRedirectionDetails",url)
            },
            onDrillDownDetails(drillDownValue){
                let currentComponentSelectedValue = _.cloneDeep(this.currentComponentSelectedValue)
                currentComponentSelectedValue['drilldownDetails'] = drillDownValue
                this.onTriggerWidgetApiCall(currentComponentSelectedValue,true)
                
            }
        }
    }
</script>