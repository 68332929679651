<template>
    <div>
         <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="8" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Security</li>
                            <li>Setup Admin Security Roles</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <!-- <v-col cols="4"></v-col> -->
        </v-row> 

        <edc-data-grid  :dataList="tableList" @onNew="addRole" @onEdit="onEdit" @onDelete="onDelete" @ondblClick="onEdit"></edc-data-grid>

   
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbarTimeout" id="edcmessage" top right>
                {{ snackbarText }}
            </v-snackbar>
        <loading-panel :loader="loader"></loading-panel>
    </div>
</template>

<script>
import config from '../../../config.json'
import {post as postToServer } from '../../../methods/serverCall.js';
import getUserRole from '../../../methods/GetUserRole.js'
import {SERVER_ERROR} from '../../../data/client_message.js'
import {CLIENT_SIDE} from '../../../data/macros.js'
import { getEnvListForDataviewer} from '../../../methods/EnvironmentList.js'
import {SECURITY_SERVICE, MANAGE_ADMIN_SECURITY} from "../../../data/macros.js"
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
export default {
    name: 'DemoGrid',
    
    data: function () {
        return {
            userRole:'',
            overwrite_message: '',
            roleName: '',
            name_list:[],
            publicRoleName:'Public role',
            roleType: "non_environmental",
            roleTypeForTitle: "Administrative",
            productCode:'PF',
            tableList: {
                headers: [
                    { text: 'Role Name', value: 'role', width: '30%', title: 'Security Role Name' },
                    // { text: 'Role Type', value: 'display_role_type', title: 'Type Of Role' },
                    { text: 'Created By', value: 'created_by',  title: 'Created By' },
                    { text: 'Created Date', value: 'timezone_aware_created_date', title: 'Created Date' },
                    { text: 'Created Time', value: 'timezone_aware_created_time',  title: 'Created Time' }
                ],
                actions:[],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                hideFilter:true,
                hideExport:true,
                hideShowColumns:true,
                itemkey:'role_id',
                isActionBasedRole:true,
                isEnvironmentalBasedRole:false
            },
            snackbar: false,
            snackbarText: "",
            colorValue: "error",
            snackbarTimeout: Snackbar_Default_Timeout,
            loader:false,
            isEnvironmentalBasedRole:false
        }
    },
    computed:{
            clientId(){
                return this.$session.get('client_id')
            },
        },
    watch:{
        
    },
    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions=[
            {'text':'new','key':"role_id", id:"addnewaFctionrole", selectType:"single",
             role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,MANAGE_ADMIN_SECURITY),index:1},    
            {'text':'edit','key':"role_id", id:"icneditactionrole", selectType:"single", 
            role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,MANAGE_ADMIN_SECURITY),index:2},
            {'text':'delete','key':"role_id", id:"icndeleteactionrole", selectType:"multiple", 
            role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,MANAGE_ADMIN_SECURITY),index:3},
        ];
        this.getAllRole();
    },
    methods: {
        showMessage(message,type='error'){
            this.loader = false;                  
            this.colorValue = type
            this.snackbarText = message; 
            this.snackbar = true                   
        },
        addRole(){
            let _this = this;
            _this.$router.push({name:'ManageActionBasedRole',query: {
                role_type: _this.roleType, roleTypeForTitle: _this.roleTypeForTitle}});
          
        },
        onEdit(record){
            let _this = this;
            if(record.role === "Superadmin" || record.role === "System user"){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbarText = record.role+' role cannot be edited by the user'
                return;
            }
            this.$router.push({ name: 'ManageActionBasedRole', query:{
                'type':record.role_type, 
                "roleId":record.role_id, 
                "roleTypeForTitle": _this.roleTypeForTitle
                }});
        },
        onDelete(records){
            let _this = this
            _this.loader = true;
            let isPublicExists = _.find(records, ['role',this.publicRoleName])
            if(isPublicExists){
                _this.showMessage('Public role cannot be deleted by the user')
                return
            }

            isPublicExists = _.find(records, ['role','Superadmin'])
            if(isPublicExists){
                _this.showMessage("Superadmin role cannot be deleted by the user")
                return
            }
            isPublicExists = _.find(records, ['role','System user'])
            if(isPublicExists){
                _this.showMessage("System user role cannot be deleted by the user")
                return
            }
            var delete_object_url = config.Security_URL + "/delete_object_based_role" 
            let data = {
                "role_id_list":_.map(records, 'role_id'),
                "client_id":this.$session.get('client_id'), 
                "deleted_by":this.$session.get("email"),
                "role_type": this.roleType
            }
            postToServer(_this, delete_object_url, data).then(response => {
                _this.showMessage("Role(s) delete successfully")
                _this.getAllRole()
            }) 
            .catch(error_response => {
                _this.showMessage(error_response)
            });
        },
        getAllRole() {
            var _this = this; 
            var client_id = this.$session.get('client_id')
            this.tableList.rows = []

            var data = {"client_id":client_id,
                        "product_code":this.productCode,
                        "role_type":_this.roleType
                    }
            var url = config.Security_URL + "/get_all_admin_role";
            this.loader=true;
            postToServer(this, url, data).then(response => {
                _this.loader=false;
                _this.tableList.rows = response;
            }).catch(errorResponse => {
                _this.showMessage(errorResponse)
            });
        },
    }   
}
</script>
<style scoped>
.textClass{
    padding-top: 66px;
    padding-right: 24px;
    padding-left: 17px;
}
.textFieldClass{
    padding-left:24px !important; 
    padding-top:84px !important;
    padding-right: 24px !important;
}
.autoCompleteClass{
    padding-left:24px !important;
    padding-right: 24px !important; 
    padding-top:30px !important;
}
.labelClass{
    padding-left:24px !important; 
    padding-top:18px !important;
    padding-right: 24px !important;
}
</style>