<template>
    <v-container>
        <v-row class="EDC-Row">
            <span class="breadcrumb">Select Package To Import</span>
        </v-row>
        
        <v-row class="EDC-Row" v-if="has_import_rights">
            <label style="font-size: 16px !important; padding-left: 5px !important" v-if="has_import_rights">File
                <input id="file" ref="file" type="file" @change="handleFileUpload()" v-if="has_import_rights">
            </label>
        </v-row>

        <v-row class="EDC-Row">
            <vc-button itemText="Import" @click.native="submitFile" v-if="has_import_rights"></vc-button>
        </v-row>
    
        <edc-data-grid :dataList="tableList" @onRowSelected="onRowSelected"></edc-data-grid>

        <!-- <simplert ref="simplert" :use-radius="true" :use-icon="true"/> -->
        <!-- <edc-confirmation :showEdcConfirmation="showEdcConfirmation" :buttonOptions="edcConfirmationOption" :messageForDisplay="msgInConfirmation" @onConfirmationAction="onConfirmationAction" :showApplyForAll="applyforall" @onCancelConfirmation="showEdcConfirmation=false" :applyForAllLabel="ApplyforNext"></edc-confirmation> -->


        <v-dialog v-model="firstPopUp" width="450" persistent>
            <v-card class="EDC-Container">
                <v-row class="EDC-Row Row-Padding">
                    <v-toolbar dense class="EDC-Toolbar">
                        <v-toolbar-title>{{confirmationHeader}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-btn icon>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn> -->
                    </v-toolbar>
                </v-row>
                <v-row class="EDC-Row Row-Padding">
                    <v-col class="EDC-Col" align="left">
                        Importing {{totalImports}} items from <b>Package</b> to <b>Repository</b>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" align="left" style="font-size:16px">
                        {{subDescriptionMsg}}
                    </v-col>
                </v-row>
                <!-- <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        {{firstMessage}}
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        Skip these files
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        Let me decide or
                    </v-col>
                </v-row> -->
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" align="left">
                        <v-list>
                            <v-list-item @click="clickOnImportOption(1)">
                              <v-list-item-content>
                                <v-list-item-title>{{firstMessage}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="clickOnImportOption(2)">
                              <v-list-item-content>
                                <v-list-item-title>Skip these objects</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="clickOnImportOption(3)">
                              <v-list-item-content>
                                <v-list-item-title>Let me decide for each object</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

        <v-dialog v-model="secondPopUp" width="700">
            <v-card class="EDC-Container">
                <v-row class="EDC-Row Row-Padding">
                    <v-toolbar dense class="EDC-Toolbar">
                        <v-toolbar-title>{{userActionHeader}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-btn icon>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn> -->
                    </v-toolbar>
                </v-row>
                <v-row class="EDC-Row Row-Padding">
                    <v-col class="EDC-Col" align="left" style="font-size:14px;color:blue">
                        Which object do you want to import?
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" align="left" cols="5">
                        <b>Objects in the repository</b>
                    </v-col>
                    <v-col class="EDC-Col" align="left" cols="7">
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col" cols="1" align="left">
                                <v-checkbox small dense v-model="importAllChk" style="margin:0px;padding:0px" hide-details color="primary-lighten2" label="" class="Checkbox-Options" title="Import all objects" @change="toggleItemsToImport"></v-checkbox>
                            </v-col>
                            <v-col class="EDC-Col checkbox-text-custom" align="left">
                                <b>New Objects</b>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row scrollbar" style="max-height:400px !important;overflow-x:hidden !important;overflow-y:scroll !important;" id="style-2">
                    <v-col class="EDC-Col" align="left" cols="12">
                        <template v-for="(item,idnx) in replaceObject.object_details">
                            <v-row class="EDC-Row Row-Padding" :key="idnx+'newobjrw'" style="padding-left:4px !important;">
                                <v-col class="EDC-Col" cols="5">
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col" cols="3" align="left">
                                            Id
                                        </v-col>
                                        <v-col class="EDC-Col" align="left">
                                            {{item.old_object_details.object_id}}
                                        </v-col>
                                    </v-row>
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col" cols="3" align="left">
                                            Name
                                        </v-col>
                                        <v-col class="EDC-Col" align="left">
                                            {{item.old_object_details.object_name}}
                                        </v-col>
                                    </v-row>
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col" cols="3" align="left">
                                            Version
                                        </v-col>
                                        <v-col class="EDC-Col" align="left">
                                            {{item.old_object_details.object_version}}
                                        </v-col>
                                    </v-row>
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col" cols="3" align="left">
                                            Published
                                        </v-col>
                                        <v-col class="EDC-Col" align="left">
                                            {{item.Object_in_env}}
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="EDC-Col" cols="7">
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col" cols="1" align="left">
                                            <v-checkbox small dense v-model="item.isSelected" style="margin:0px;padding:0px" hide-details color="primary-lighten2" label="" class="Checkbox-Options" @change="itemSelectionChanged"></v-checkbox>
                                        </v-col>
                                        <v-col class="EDC-Col checkbox-text-custom" cols="2" align="left">
                                            Id
                                        </v-col>
                                        <v-col class="EDC-Col checkbox-text-custom" align="left">
                                            {{item.new_object_details.object_id}}
                                        </v-col>
                                    </v-row>
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col" cols="1" align="left">
                                            
                                        </v-col>
                                        <v-col class="EDC-Col" cols="2" align="left">
                                            Name
                                        </v-col>
                                        <v-col class="EDC-Col" align="left">
                                            {{item.new_object_details.object_name}}
                                        </v-col>
                                    </v-row>
                                    <!-- <v-row class="EDC-Row">
                                        <v-col class="EDC-Col" cols="1" align="left">
                                            
                                        </v-col>
                                        <v-col class="EDC-Col" cols="2" align="left">
                                            Version
                                        </v-col>
                                        <v-col class="EDC-Col" align="left">
                                            {{item.new_object_details.object_version}}
                                        </v-col>
                                    </v-row> -->
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col" cols="1" align="left">
                                            
                                        </v-col>
                                        <v-col class="EDC-Col" cols="2" align="left">
                                            Version
                                        </v-col>
                                        <v-col class="EDC-Col" align="left">
                                            <!-- <v-checkbox small dense v-model="item.create_new_version" style="margin:0px;padding:0px" hide-details color="primary-lighten2" label="Create New Version" :disabled="item.is_object_published" class="Checkbox-Options"></v-checkbox> -->
                                            <v-radio-group  v-model="item.create_new_version" hide-details dense :disabled="item.is_object_published">
                                                <v-radio hide-details dense class="radioClass" :color="colorCode" :label="'Overwrite Version '+item.new_object_details.object_version" :value="false"></v-radio>
                                                <v-radio hide-details dense class="radioClass" :color="colorCode" label="Create New Version" :value="true"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" :key="idnx+'divider'">
                                <v-divider></v-divider>
                            </v-row>
                        </template>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" cols="12" align="right">
                        <vc-button itemText="Continue" @click.native="userSelectedObjectsForImport"></vc-button>
                        <vc-button itemText="Cancel" @click.native="secondPopUp=false"></vc-button>
                    </v-col>    
                </v-row>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    </v-container>
</template>
<style scoped>
    .Row-Padding{
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }
    .checkbox-text-custom{
        padding-top: 2px !important;
    }
    .radioClass >>> label{
        top: 4px !important;
        font-size: 12px !important;
        color: black !important;
    } 
    .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child){
        margin-bottom: 0px !important;
    }
    
</style>
    <script>
    import Simplert from 'vue2-simplert'
    import {fileUpload, post as postToServer} from "@/methods/serverCall.js"
    import config from '../../config.json'
    import {CLIENT_SIDE, MANAGE_PACKAGES, PACKAGE_BUILDER, REPOSITORY_SERVICE_ID} from '../../data/macros.js'
    import {BTN_COLOR} from '@/data/macros.js'
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import getUserRole from './../../methods/GetUserRole.js'
    // import edcConfirmationBox from '@/views/edcConfirmationBox/edcConfirmationBox.vue' 

    export default {
    components: {
        // Simplert,
        // 'edc-confirmation':edcConfirmationBox
    },
          data () {
        return {
            counter:0,
            file: '',
            isforall: false,
            ApplyforNext: '',
            applyforall: true,
            snackbartext:'',
            snackbar:false,
            colorValue:"success",
            create_new_version:false,
            replace:false,
            skip:false,
            has_import_rights: false,
            snackbartimeout: Snackbar_Default_Timeout,
            object_list: [],
            tableList: {
                headers: [
                    { text: 'Object Type', value: 'object_type_desc', sortable: false, width: '25%'},
                    { text: 'Object Name', value: 'object_name', sortable: false, width: '25%'},
                    { text: 'Object Id', value: 'object_id', sortable: false, width: '25%'},
                    { text: 'Object Version', value: 'object_version', sortable: false, width: '25%'},

                ],               
                actions: [],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                selectedRows:[],
                outlineColor:BTN_COLOR,
                itemkey:'edc_id',
                hideFilter : true,
                hideExport : true,
                hideShowColumns : true,
            },
            firstPopUp:false,
            totalImports: '',
            firstReplaceMessage:'',
            confirmationHeader:"Replace or Skip Objects",
            subDescriptionMsg:"The repository has ",
            firstMessage:"Replace the objects in the destination",
            copiedType:'replace',// in case of override it will be override,
            secondPopUp:false,
            // replaceObject:{
            //     'total_count':10,
            //     'file_exists':6,
            //     'object_details':[
            //         {
            //             'old_object_details':{"object_id":"1","object_name":"abc","object_version":1},
            //             'new_object_details':{"object_id":"1","object_name":"abc_new","object_version":1},
            //             'is_object_published':false
            //         },
            //         {
            //             'old_object_details':{"object_id":"2","object_name":"abc1","object_version":1},
            //             'new_object_details':{"object_id":"2","object_name":"abc_new1","object_version":1},
            //             'is_object_published':true
            //         },
            //     ]
            // },
            replaceObject:{},
            userActionHeader:'',
            importAllChk:false,
        }
    },
    mounted(){
        this.userRole = this.$session.get('user_role_mapping');
        var _this = this
        this.has_import_rights = this.userRole.is_superadmin || getUserRole(
            this.userRole,PACKAGE_BUILDER, MANAGE_PACKAGES)
        // setTimeout(()=>{
        //     _this.replaceObject.object_details.forEach((obj)=>{
        //         obj.create_new_version = obj.is_object_published
        //         obj.isSelected = false
        //     })
        //     _this.firstPopUp = true
        // },3000)
    },
    methods:{
        handleFileUpload(){
              this.file = this.$refs.file.files[0];
              this.showPackageObjects()
          },
          showPackageObjects(){
            let formData = new FormData();
            formData.append('filename', this.file);
            postToServer(this, config.REPOSITORY_API_URL + '/package_details', formData).then(response  => {
                if(response){
                    let edc_id=1
                    response.map(function(obj){
                        obj.edc_id = edc_id
                        edc_id++
                    })
                    this.tableList.rows = response;
                    this.object_list = response;
                    this.tableList.total_count = response.length; 
                } else {
                    this.tableList.rows = [];
                    this.tableList.total_count = 0; 
                }
            }).catch(error_response => {
                if(error_response){
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
                }
            })
          },
          onRowSelected(selectedRows) {
            this.selectedRows = selectedRows;
          },
          ShowConfirm(response){
              let _this = this
              this.replaceObject = response.data
              if (_this.replaceObject.replaceable && !_this.replaceObject.create_new_version){
                  _this.firstMessage = "Replace "+String(_this.replaceObject.replaceable)+" objects in the destination"
              }
              else if(!_this.replaceObject.replaceable && _this.replaceObject.create_new_version){
                  _this.firstMessage = "Create new version for "+String(_this.replaceObject.create_new_version)+" objects in the destination"
              }
              else if(_this.replaceObject.replaceable && _this.replaceObject.create_new_version){
                  _this.firstMessage = "Replace "+String(_this.replaceObject.replaceable)+" and create new version for "+String(_this.replaceObject.create_new_version)+" objects in the destination"
              }
              _this.firstPopUp = true
              _this.subDescriptionMsg ="The repository has "+_this.replaceObject.objects_exist+' objects with the same id'
              _this.totalImports = this.replaceObject.total_count
              _this.replaceObject.object_details.forEach((obj)=>{
                obj.create_new_version = obj.is_object_published
                obj.isSelected = false
            })
          },
        //   onConfirmationAction(confirmationValue,isForAll){
        //         var _this = this
        //         this.isforall = isForAll
        //         if (confirmationValue == 'override'){
        //             _this.replace = true
        //             _this.create_new_version = false
        //             _this.skip = false
        //             _this.selectedRows = this.resp.object_existing
        //             _this.remaining_object_list = this.resp.objects_remaining
        //             _this.submitFileWithoutCheck()
        //         }
        //         else if (confirmationValue == 'newversion'){
        //             _this.replace = false
        //             _this.create_new_version = true
        //             _this.skip = false
        //             _this.selectedRows = this.resp.object_existing
        //             _this.remaining_object_list = this.resp.objects_remaining
        //             _this.submitFileWithoutCheck()
        //         }
        //         else if (confirmationValue == 'skip'){
        //             _this.replace = false
        //             _this.create_new_version = false
        //             _this.skip = true
        //             _this.selectedRows = this.resp.object_existing
        //             _this.remaining_object_list = this.resp.objects_remaining
        //             _this.submitFileWithoutCheck()
        //         }
				// alert(confirmationValue)
				// this.counter++
				// _this.showEdcConfirmation = false
				// if(isForAll){
				// 	alert('is for all')
				// 	return
				// }
				// setTimeout(()=>{
				// 	_this.msgInConfirmation = "Message "+_this.counter
				// 	_this.showEdcConfirmation = true
				// },1000)
			// },
        submitFile(){
            let formData = new FormData();
            formData.append('filename', this.file);
            formData.append('fname', this.file.name);
            formData.append('client_id',  this.$session.get('client_id'));
            formData.append('added_by', this.$session.get('email'));
            formData.append('object_check', true);
            formData.append('replace', false);
            formData.append('is_import_call', true);
            formData.append('product_name', window.sessionStorage.getItem('product'));
            // stringify because of form data cant send object directly
            formData.append('object_list', JSON.stringify(this.selectedRows))

            	fileUpload(this, config.REPOSITORY_API_URL + '/import_package', formData).then(response  => {
                        if(response.message && response.message.indexOf('will get replaced') != -1){
                            this.ShowConfirm(response)
                        } else {
                        var _this = this
                        // setTimeout(function() {
                        // _this.$router.push({ name: 'RepositoryHistory',params:{"request_id":""}})
                        // }, 400);
                        
                        this.snackbar = true
                        this.colorValue = 'success'
                        this.snackbartext = response;
                        }
    				}).catch(error_response => {
                        if(error_response){
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = error_response;
                }
    				})
        },
        submitFileWithoutCheck(){
            let formData = new FormData();
            formData.append('filename', this.file);
            formData.append('fname', this.file.name);
            formData.append('client_id',  this.$session.get('client_id'));
            formData.append('added_by', this.$session.get('email'));
            formData.append('replace', this.replace);
            formData.append('is_import_call', true);
            // stringify because of form data cant send object directly
            formData.append('object_list', JSON.stringify(this.selectedRows))

                fileUpload(this, config.REPOSITORY_API_URL + '/import_package', formData).then(response  => {
                        var _this = this
                        // setTimeout(function() {
                        // _this.$router.push({ name: 'RepositoryHistory',params:{"request_id":""}})
                        // }, 400);
                        this.firstPopUp = false
                        this.secondPopUp = false
                        this.snackbar = true
                        this.colorValue = 'success'
                        this.snackbartext = response;
                    }).catch(error_response => {
                        if(error_response){
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = error_response;
                }
                    })
        },
        clickOnImportOption(optionIndex){
            let _this = this
            _this.replace = true
            if(optionIndex ===1){
                if(this.copiedType === 'replace'){
                    var obj_details = this.replaceObject.object_details
                    obj_details.forEach((obj)=>{
                        if(obj.create_new_version){
                            obj.new_object_details.action = 'create_new_version'
                        }else{
                            obj.new_object_details.action = 'override'
                        }})
                    var new_obj_data = []
                    for(var new_object_details in this.replaceObject.object_details){
                        new_obj_data.push(this.replaceObject.object_details[new_object_details].new_object_details)
                    }
                    _this.selectedRows = new_obj_data
                    _this.submitFileWithoutCheck()
                }else{
                    // create new version
                }
            } else if(optionIndex ===2){
                //skip all
                this.replaceObject.object_details.forEach((obj)=>{
                    obj.new_object_details.action = 'skip'
                })
                var new_obj_data = []
                    for(var new_object_details in this.replaceObject.object_details){
                        new_obj_data.push(this.replaceObject.object_details[new_object_details].new_object_details)
                    }
                    _this.selectedRows = new_obj_data
                    _this.submitFileWithoutCheck()
            }
            if(optionIndex ===3){
                this.userActionHeader = this.replaceObject.object_details.length + ' Total object conflicts'
                this.secondPopUp = true
            }
        },
        userSelectedObjectsForImport(){
            let _this = this
            let selectedObjects = _.filter(_this.replaceObject.object_details,(obj)=>{
                return obj.isSelected === true
            })
            selectedObjects.forEach((obj)=>{
                if(obj.create_new_version){
                    obj.new_object_details.action = 'create_new_version'
                }else{
                    obj.new_object_details.action = 'override'
                }})
            var new_obj_data = []
            for(var new_object_details in selectedObjects){
                new_obj_data.push(selectedObjects[new_object_details].new_object_details)
            }
            _this.selectedRows = new_obj_data
            _this.submitFileWithoutCheck()
            if(!selectedObjects.length){
                this.replaceObject.object_details.forEach((obj)=>{
                    obj.new_object_details.action = 'skip'
                })
                var new_obj_data = []
                    for(var new_object_details in this.replaceObject.object_details){
                        new_obj_data.push(this.replaceObject.object_details[new_object_details].new_object_details)
                    }
                    _this.selectedRows = new_obj_data
                    _this.submitFileWithoutCheck()
                // alert('Atleast select one object')
                // return
            }
        },
        toggleItemsToImport(toggleValue){
            let _this = this
            _this.replaceObject.object_details.forEach((obj)=>{
                obj.isSelected = toggleValue
            })
        },
        itemSelectionChanged(){
            let _this = this
            let selectedObjects = _.filter(_this.replaceObject.object_details,(obj)=>{
                return obj.isSelected
            })
            _this.importAllChk = selectedObjects.length?false:true
        }
    }
    }
    </script>
