import actionbasedrule from '../components/RoleManagement/groupComponent.vue'
import { post as postToServer } from "./../methods/serverCall.js";
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import config from "../config.json";

export const mixinSecurity={
    components:{
        'edc-action-based-rule':actionbasedrule,
    },
    data(){
        return{
            roleName: "Untitled Role Name",
            roleType: this.$route.query.role_type,
            roleTypeForTitle: this.$route.query.roleTypeForTitle,
            env_id: this.$route.query.env_id,
            env_name: this.$route.query.env_name,
            description:'',
            snackbar: false,
            snackbarText: "",
            colorValue: "error",
            snackbarTimeout: Snackbar_Default_Timeout,
            loader: null,
            productCode:'PF',
            roleType:this.$route.query.role_type || 'non_environmental',
            roleId:this.$route.query.roleId || this.$route.query.role_id,
            moduleList:[],
            selectedModules:[],
            defaultSelectedItems:[]
        }
    },
    computed:{
        clientId(){
            return this.$session.get('client_id')
        },
        isObjectBasedRole(){
            return this.roleType === 'object_based'
        },
        isEnvironmentalRole(){
            return this.roleType === 'environmental'
        },
        isAdministrative(){
            return this.roleType === 'non_environmental'
        }
    },
    mounted(){
        if(this.isObjectBasedRole){
            this.getAllObjectTypes()
            this.GetAccessTypes()
            
        } else{
            this.getGroups()   
        }
        if(this.$route.query.role_id){
            this.getRoleDetails(this.$route.query.role_id)
        }
    },
    methods: {
        getGroups(){
            var _this = this
            var data = {
                "product_code":this.productCode,
                "role_type":this.roleType
            }
            // alert(this.roleType)
            _this.loader = true
            postToServer(_this, config.Security_URL+'/get_groups_role_type',data).then(response=>{
                _this.loader = false
                _.forEach(response,(moduleObj)=>{
                    moduleObj.isSelected = false
                    moduleObj.isInterminate = false
                    _.forEach(moduleObj.group_list, (groupObj)=>{
                        groupObj.isSelected = false
                    })
                    _this.getRoleDetails()
                })
                _this.moduleList = response
                console.log("environemnt list",_this.moduleList)
            }).catch(error=>{
                _this.showMessageWithTimeout(error)
            })
        },
        getRoleDetails(){
            var _this = this
            if(!this.roleId)
                return
            var client_id = this.$session.get('client_id');
            var data = {
                "role_id": this.roleId,
                "client_id": client_id,
                "env_id": this.env_id,
                env_name: this.env_name,
                role_type: this.roleType,
                product_code: this.product_code
            }
            if(this.isObjectBasedRole){
                var url = config.Security_URL + "/get_object_based_role_details"
            } else if(this.isEnvironmentalRole){
                var url = config.Security_URL+'/get_action_based_role_detail'
            }
            else{
                var url = config.Security_URL+'/get_admin_role_detail'
            }
            postToServer(_this, url, data).then(response => {
                _this.loader = false
                this.roleId = response.role_id
                this.roleName = response.role
                this.description = response.role_description
                if(this.isObjectBasedRole){
                    this.selectedEnv = {"id":response.env_id,"name":response.env_name}
                    _.forEach(response.object_rule_data, (obj)=>{
                        this.objectsHeader.headerFor = this.getHeaderFor(obj)
                        if(obj.object_details && Array.isArray(obj.object_details) && obj.object_details.length > 0){
                            if(obj.object_details.length > 1){
                                obj.display_nameText = (obj.object_details[0]['object_description']) +" (+" + (obj.object_details.length-1) + this.objectsHeader.headerFor+")"
                            }
                            else
                                if(obj.object_details.length > 0){
                                    obj.display_nameText = obj.object_details[0]['object_description']
                                }
                                
                        }
                        
                        obj.isAllObjectSelected = false
                        obj.associated_object = obj.associate_object_id
                        obj.associate_object_text = obj.associate_object_description
                        var objForBind = []
                        if(obj.object_details && Array.isArray(obj.object_details) && obj.object_details.length > 0){
                            obj.objectNameGridTitle = _.map(obj.object_details,'object_description').join(",")
                            var objForBind = []
                            _.forEach(obj.object_details, (lvl2Obj)=>{
                                objForBind.push({
                                    "object_type":obj.object_type_code, 
                                    "display_name":lvl2Obj.object_description,
                                    "object_name":lvl2Obj.object_name, 
                                    "object_id":lvl2Obj.object_id,
                                })
                            })
                            if(objForBind.length === 1 && objForBind[0].object_id === 'all'){
                                obj.isAllObjectSelected = true
                                objForBind = []
                            }
                            obj.object_details = objForBind
                        }
                    })
                    this.tableList.rows = response.object_rule_data
                    _this.manageTableRows()
                }
                else{
                    _this.defaultSelectedItems =  response.module_code_list
                    _this.selectedModules = _.cloneDeep(_this.defaultSelectedItems)
                }
            })
            .catch(errorResponse => {
                _this.showMessage(errorResponse)
            });
        },
        showMessage(errorMessage, type ='error'){
            this.loader = false;                  
            this.colorValue = type
            this.snackbarText = errorMessage || 'Unhanlded Exception'; 
            this.snackbar = true                   
        },
        saveRole(){
            if(this.isObjectBasedRole)
                return this.saveObjectBasedRole()
            else
                this.saveActionBasedRole()
        },
        saveActionBasedRole(){
            var _this = this
            if(!this.selectedModules.length){
                this.showMessage("Atleast select 1 module")
                return
            }
            let data = {
                "client_id":this.clientId,
                "created_by":this.$session.get('email'),
                "mapped_module_list":this.selectedModules,
                "role_id":this.roleId,
                "product_code":this.productCode,
                "role_name":this.roleName,
                "role_type": this.roleType,
                "description":this.description
            }
            if (this.roleType === "environmental"){
                data["env_id"] =  this.selectedEnv.id
                data["env_name"] =  this.selectedEnv.name
            }
            _this.loader = true
            if(this.isEnvironmentalRole){
                var url = config.Security_URL+'/manage_action_based_role'
            }
            else{
                var url = config.Security_URL+'/manage_admin_role'
            }
            postToServer(_this, url, data).then(response=>{
                _this.showMessage("Role managed successfully", "success")
                let path = 'envandobjbasedrolelist'
                if(this.isAdministrative){
                    path = 'actionbasedrolelist'
                }
                setTimeout(()=>{
                    _this.$router.push({name:path});
                },1000)

            }).catch(error=>{
                _this.showMessage(error)
            })
        }

    },
}