import {mapActions} from 'vuex'
export const mixinPathfinder={
    props:{
        isWidget:{
            type:Boolean,
            default:false
        },
        widgetUniqueKey:{
            type:String,
            default:""
        },
        rowCount:{
            type:Number,
            default:10
        },
        widgetFilters:{
            type:Object,
            default:()=>{
                return {
                    "rowCount":10,
                    "showAttachmentColumn":false
                }
            }
        },
        externalDataToLoad:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        searchCompProps:{
            type:Object,
            default:()=>{
                return {
                    "showEnvOpt":true,
                    "showOptions":true,
                    "loadUserPreference":true,
                    "autoSelectedEnvId":0,
                    "autoSelectedDatasourceId":"",
                    "showGO":true,
                    "openInNewTab":false,
                    "searchOptions":{}
                }
            }
        }
    },
    data(){
        return{
            selectedEnvironment:{},
            selectedDatasource:{},
            dsList:[],
            datasourceForBackend:[],
            loader: false,
            snackbar:false,
            snackbartext:'',
            snackbartimeout:5000,
            colorValue:"",
            clientId:0,
            userId:'',
            isSuperAdmin:false,
            securityModelByEnv:[],
        }
    },
    computed:{
        isSplitMode(){
            return this.openModeType === 'split'
        },
        isMerged(){
            return this.openModeType === 'merged'
        },
    },
    watch:{
        '$store.state.pathfinderObject':{
            handler(newvalue){
                
            }
        },
        'widgetUniqueKey':{
            handler(newVal){
                
            }
        },
        'externalDataToLoad':{
            handler(newValue){
                
            }
        }
    },
    mounted(){
        this.clientId = this.$session.get('client_id')
        this.userId = this.$session.get('email')
    },
    methods: {
        ...mapActions(['setPathfinderObject']),
        showError(error,type='error'){
            this.snackbar = true
            this.colorValue = type
            this.snackbartext = error || "Unhandled error occured"
        },
        isEmptyGridObj(){
            let pathFinderObj = _.cloneDeep(this.$store.state.pathfinderObject)
            let leftSideObj = pathFinderObj['left'] || {}
            let rightSideObj = pathFinderObj['right'] || {}
            if(_.isEmpty(leftSideObj) && _.isEmpty(rightSideObj))
                return true
            return false
        }
    },
}
