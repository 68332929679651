<template>
    <div>
         <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="8" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Security</li>
                            <li>Assign Admin Security</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <!-- <v-col cols="4"></v-col> -->
        </v-row> 

        <edc-data-grid :dataList="tableList" @onNew="onNew" @onEdit="onEdit" @onDelete="onDelete" @onCancel="onCancel" @onValueUpdate="onValueUpdate" @onSave="saveRoleMapping"></edc-data-grid>

   
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbarTimeout" id="edcmessage" top right>
                {{ snackbarText }}
            </v-snackbar>
        <loading-panel :loader="loader"></loading-panel>
    </div>
</template>

<style scoped>
    .customCheckbox >>> label {
        top: 5px !important;
    }
    .v-expansion-panel >>> .v-expansion-panel__header {
        padding-bottom: 0px !important;
    }
    
    .EDC-UIComponent {
        margin: 0px !important;
        padding: 0px !important;
    }
    
    .radioClass >>> label {
        top: 3px !important;
    }
    
    .EDC-TopRow {
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    
    .EDC-ColDescription {
        margin-top: -7px !important;
    }
    .EDC-ColEnv {
        margin-top: -22px !important;
    }
    
    .EDC-GridRow {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    
    .EDC-ButtonRow {
        padding-right: 10px !important;
        padding-bottom: 10px !important;
    }
</style>
<script>
    import { post as postToServer } from "./../../../methods/serverCall.js";
    import { get as getToServer } from "./../../../methods/serverCall.js";
    import config from "../../../config.json";
    import { SERVER_ERROR } from "../../../data/client_message.js";
    import {
    GET_ALL_USER
} from './../../../data/url_constants.js';

    // import { getEnvListForDataviewer} from '../../../methods/EnvironmentList.js'
    import {CLIENT_SIDE} from "@/data/macros.js";
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import getUserRole from '../../../methods/GetUserRole.js'
    import { SECURITY_SERVICE, MANAGE_ACTION_BASED_SECURITY, MANAGE_ADMIN_SECURITY  } from '../../../data/macros.js';
    export default {
        name:"ManageObjectBasedSecurity",
        data(){
            return {
                selected_type_list: [{
                    id: 1,
                    name: 'User',
                    },{
                    id: 2,
                    name: 'User Group',
                }],
                selected_type: '',
                selected_object: '',
                selected_object_list : [],
                selected_role: '',
                selected_role_list : [],
                snackbar: false,
                snackbarText: "",
                colorValue: "error",
                role_type: "non_environmental",
                snackbarTimeout: Snackbar_Default_Timeout,
                loader: false,
                EnvironmentList:[],
                selected_env_list: [],
                productCode: 'PF',
                tableList: {
                    headers:[],
                    actions:[],
                    sorting_type: CLIENT_SIDE,
                    filterType: CLIENT_SIDE,
                    paginationType: CLIENT_SIDE,
                    total_count: 0,
                    rows: [],
                    selected_rows: [],
                    syncHeaderScroll:false,
                    filterType: this.clientSide,
                    hideInlineFilter: true,
                    hideFilter:true,
                    hideExport:true,
                    hideShowColumns:true,
                    is_grid_inline_edit:true,
                    itemkey:'id',
                },
            // headers: [
                //     { text: 'Map Type', value: 'map_type', title: 'Map Type' },
                //     { text: 'Object Name', value: 'map_object_name',title: 'Object Name' },
                //     { text: 'Role Name', value: 'role_name',title: 'Role Name' },
                //     { text: 'Created By', value: 'created_by',title: 'Created By' }
                // ],
                userTypeHeader:{ text: 'Map Type', value: 'map_type_data', id:'drpdnmapype', 
                                 display_value:'map_type_text',
                                 option:[{
                                    name: 'User'},{name: 'User Group'}], 
                                    'item_text':"name", 
                                    'item_value':"name", 
                                 useAutoComplete:true},
                objectTypeHeader:{  text: 'User / User Group', value: 'map_object_name_data', 
                                    display_value:'map_object_name_text', id:'drpdnusrgrp',
                                    option:[], 
                                    'item_text':'name', 
                                    'item_value':'id', 
                                    useAutoComplete:true},
                roleTypeHeader:{ text: 'Role Name', value: 'role_name_data', 
                                display_value:'display_role_name', option:[],
                                'item_text':"name", 'item_value':"id",
                                 id:'drpdnrolename', useAutoComplete:true},
                // roleHeader:{ text: 'Role Name', value: 'role_name_data', 
                //                 display_value:'role_name_text', option:[],
                //                 'item_text':"name", 'item_value':"id", id:'drpdnrolename',
                //                 useAutoComplete:true},
                // envHeader:{ text: 'Environment', value: 'env_name', 
                //                 display_value:'role_name_text', option:[],
                //                 'item_text':"name", 'item_value':"id", id:'drpdnenvname',
                //                 useAutoComplete:true,useAdvanceAutoComplete:true,
                //                 useMultiple:true,                                
                //                 gridDisplayTextKey:"display_nameText",
                //                 gridDisplayTextTitleKey:"objectNameGridTitle",
                //                 show_select_all:true,
                //                 resetSelectCounter:0,
                //                 headerFor:'Environment',
                //                 returnObject:true,
                //                 selectAllKey:'isAllObjectSelected',
                //                 isDynamicList:false
                //                 },
                tableRow:{
                    'map_type_data':'',
                    'map_object_name_data': '',
                    'role_name_data': '',
                    'is_row_editable': true,
                }
            }
        },
        watch:{
            'selected_type_list':{
                handler(newValue){
                    this.userTypeHeader.option = newValue
                }
            },
            'selected_object_list':{
                handler(newValue){
                    this.objectTypeHeader.option = newValue
                }
            },
            'selected_role_list':{
                handler(newValue){
                    this.roleTypeHeader.option = newValue
                }
            },
            // 'selected_env_list':{
            //     handler(newValue){
            //         this.envHeader.option = newValue
            //     }
            // },
        },
        computed:{
            securityAction(){
                if(this.isEnvironmentalRole){
                    return MANAGE_ACTION_BASED_SECURITY
                }
                return MANAGE_ADMIN_SECURITY
            },
            isEnvironmentalRole(){
                return this.role_type === 'environmental'
            }
        },
        mounted() {
            this.userRole = this.$session.get('user_role_mapping');
            this.tableList.headers = [this.userTypeHeader, this.objectTypeHeader, this.roleTypeHeader]
            this.tableList.actions = [
                {'text': 'new',id:'icnaddnewmapping', 'key': "map_type_data",selectType:"single",'enabled':this.Environment, 
                role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,this.securityAction),index:1},
                {'text': 'edit', id:'icneditrolemaping', 'key': "id",selectType:"single",
                role:this.userRole.is_superadmin ||getUserRole(this.userRole,SECURITY_SERVICE,this.securityAction), index: 1},
                {'text':"save",id:'icnsaverolemaping', "key":"is_row_editable", selectType:"single",
                role:this.userRole.is_superadmin ||getUserRole(this.userRole,SECURITY_SERVICE,this.securityAction), index: 3},
                {'text':"cancel",id:'icncancelrolemaping', "key":"is_row_editable", selectType:"single",
                role:this.userRole.is_superadmin ||getUserRole(this.userRole,SECURITY_SERVICE,this.securityAction), index: 4},
                {'text': 'delete',id:'icndeleterolemaping', 'key': "id",selectType:"multiple",index:5,
                role:this.userRole.is_superadmin ||getUserRole(this.userRole,SECURITY_SERVICE,this.securityAction),}
            ]
            // this.GetEnvironmentList();
            this.getAllRole()
            this.getAllUserRoleMapping()
        },
        methods:{
            onValueUpdate(record,header) {
                if(record.map_type_data === 'User'){
                    return this.getUsersList()
                }
                else{
                    this.getAllUserGroups()
                }
                if(header.value === 'map_type_data'){
                    record.map_object_name_data = ''
                    record.role_name_data = ''
                }
                // if(header.value === 'role_type_data'){
                //     this.role_type = record.role_type_data
                //     if(record.role_type_data === "environmental"){
                //         this.selected_env_list = this.EnvironmentList
                //     }
                //     else{
                //         this.selected_env_list = []
                //     }
                //     this.getAllRole()

                // }
            },
            //  GetEnvironmentList() {
            //     getEnvListForDataviewer(this,false)
            // },
            getUsersList() {
            let _this = this;
            _this.loader = true;
            var client_id = this.$session.get('client_id')
            var request_data = {
                    'client_id': this.$session.get('client_id'),
            }
            postToServer(_this, config.USER_PROVISION_URL + '/get_all_active_users', request_data).then(response => {
                _this.loader = false;
                if(response) {
                    response.map((obj)=>{
                            obj.id = obj.user_id
                            obj.name= obj.username
                        })
                    this.selected_object_list = response;
                } else {
                    this.selected_object_list = [];
                }
            }).catch(error_response => {
                _this.showErrorMessage(error_response)
            })
        },
        getAllUserGroups(){
                let _this = this;
                _this.loader = true;
                var request_data = {
                    'client_id': this.$session.get('client_id'),
                   }
                postToServer(_this, config.USER_PROVISION_URL + '/get_all_group_details_public', request_data).then(response => {
                    _this.loader = false;
                    if (response) {
                        response.map((obj)=>{
                            obj.id = obj.id
                            obj.name= obj.group_name
                        })
                        this.selected_object_list = response;
                    } else {
                        this.selected_object_list = [];
                    }
                })
                .catch(errorResponse => {
                    _this.showErrorMessage(errorResponse)
            });
        },
        getAllRole() {
            var _this = this;
            this.selected_role_list =[]
            var client_id = this.$session.get('client_id')
            var data = {"client_id":client_id,
                        "product_code": this.productCode,
                        "role_type": _this.role_type}
            if(this.role_type === "environmental"){
                var url = config.Security_URL + "/get_all_action_based_role";
            }else{
                var url = config.Security_URL + "/get_all_admin_role";
            }
            this.loader=true;
            postToServer(this, url, data).then(response => {
                _this.loader=false;
                if(response)
                response.map((obj)=>{
                            obj.id = obj.role_id
                            obj.name= obj.role

                        })
                this.selected_role_list = response;
            }).catch(errorResponse => {
                _this.showErrorMessage(errorResponse)
            });
        },
        onNew(record){
                let obj = _.find(this.tableList.rows,["is_row_editable",true])
                if(obj)
                {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbarText = 'First fillup the open row';
                    return
                }
                this.tableList.rows.unshift(_.cloneDeep(this.tableRow));   
        },
        onEdit(record){

        },
        onCancel(record){
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
                if(record.id){
                    delete obj.is_row_editable
                    this.getAllUserRoleMapping()
                }
            else 
                this.tableList.rows.splice(0,1)
        },
        showErrorMessage(errorMessage){
                this.loader = false;                  
                this.colorValue = 'error'
                this.snackbarText = errorMessage || SERVER_ERROR; 
                this.snackbar = true                   
            },
        saveRoleMapping(record) {
                var _this = this
                var data = {}
                var client_id = this.$session.get('client_id');
                var user_type = this.userTypeHeader.option
                var objectType = this.objectTypeHeader.option
                var roleType = this.roleTypeHeader.option
                var user_id, user_name,roleId,role_Name

                var objectTypeObj = _.find(objectType,["id", record.map_object_name_data])
                if(!objectTypeObj){
                    this.showErrorMessage("Object type not found")
                    return
                }
                user_id = objectTypeObj['id']
                user_name = objectTypeObj['name']

                var roleTypeObj = _.find(roleType,["id", record.role_name_data])
                if(!roleTypeObj){
                    this.showErrorMessage("Role type not found")
                    return
                }
                roleId = roleTypeObj['id']
                role_Name = roleTypeObj['name']
                roleType = roleTypeObj['role_type']
                
                data = {
                    "client_id": client_id,
                    "product_code": this.productCode,
                    "role_name": role_Name,
                    "role_type": this.role_type,
                    "role_id": roleId,
                    "map_type": record.map_type_data,
                    "map_object_id": user_id,
                    "map_object_name": user_name,
                    "update_request": true
                }
                if(!role_Name || !roleId || !user_id ||!user_id ||!record.map_type_data){
                    alert('All fields are required')
                    return
                }
                if(!record.id)
                     var url = config.Security_URL + "/add_user_group_role_map";
                else{
                    var url = config.Security_URL + "/update_user_group_role_map";
                    data["id"] = record.id
                }
                _this.loader=true;
                postToServer(_this, url, data).then(response => {
                    _this.loader=false;
                    this.snackbar = true;
                    this.colorValue = "success";
                    this.role_id = response.role_id
                    this.snackbarText = "Mapping saved Successfully";
                    this.getAllUserRoleMapping()
                })
                .catch(errorResponse => {
                    _this.showErrorMessage(errorResponse)
                });
            },
        onDelete(records){
            var _this = this
            var data = {
                "id_list": _.map(records,"id"), 
                "client_id":this.$session.get("client_id"), 
                "deleted_by":this.$session.get("email"),
                "role_type": this.role_type
            }
            var url = config.Security_URL + "/delete_user_group_role_map";
            _this.loader=true;
            postToServer(_this, url, data).then(response => {
                _this.loader=false;
                this.snackbar = true;
                this.colorValue = "error";
                this.snackbarText = "Mapping Updated successfully";
                this.getAllUserRoleMapping()
            }).catch(errorResponse => {
                _this.showErrorMessage(errorResponse)
            });
           
        },
        getAllUserRoleMapping(){
            var _this = this; 
            var client_id = this.$session.get('client_id')
            this.tableList.rows = []
            var data = {
                "client_id":client_id,
                "role_type": this.role_type,
                "product_code": this.productCode
            }
            var url = config.Security_URL + "/get_all_user_group_role_map";
            this.loader=true;
            postToServer(this, url, data).then(response => {
                _this.loader=false;
                if(response)
                _.forEach(response, (obj)=>{
                    obj.map_object_name_text = obj.map_object_name
                    obj.role_name_text = obj.role_name
                    obj.map_type_text = obj.map_type
                    obj.map_object_id = obj.map_object_id
                    obj.role_id = obj.role_id
                    obj.role_name = obj.role_name
                    obj.display_role_name = obj.role_name
                    obj.map_type_data =  obj.map_type
                    obj.map_object_name_data= obj.map_object_id
                    obj.role_name_data = obj.role_id
                    
                })
                    this.tableList.rows = response;
            }).catch(errorResponse => {
                _this.showErrorMessage(errorResponse)
            });
        },
        }
    }
</script>