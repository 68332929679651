<template>
    <div ref="parentContainer">
        <v-row no-gutters>
            <template v-for="(currentList,ind) in tasksForSelectedList">
                <v-col :key="'task'+ind" :cols="currentCols" class="pa-3">
                    <v-card class="pa-0 no-border">
                        <v-card-subtitle class="pa-1 font-weight-bold d-flex justify-start section_name">
                            {{ currentList.sectionName }}
                        </v-card-subtitle>
                         <v-divider class="my-0"></v-divider>
                        <v-card-text  class="pa-2">
                            <v-row dense no-gutters>
                                <v-col 
                                v-for="(item, index) in currentList.tasks" 
                                :key="index+'task'+ind" 
                                cols="12"
                                class="d-flex justify-start mb-1"
                                >
                                <v-icon small class="mr-2">{{ item.taskResource.default_icon_class }}</v-icon>
                                <!-- <span class="task-link text-caption text--grey text-truncate"  @click="routeUrl(getTaskUrl(item), item)"> -->
                                <span class="task-link text-caption text--grey text-truncate"  @click="navigateTask(item)">
                                    {{ item.taskName }}
                                </span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </div>
</template>


<style scoped>
    /* Styling for the list item link */
    .task-link {
        text-decoration: none;
        font-weight: normal; /* Ensure the task name is not bold */
        color: #5e5e5e; /* Light grey color */
    }

    /* Add underline on hover */
    .task-link:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    .section_name{
        font-size: 12px !important; 
        margin-left: 5px !important;
    }

    .no-border {
        border: none !important;
        /* Remove shadow as well if needed */
        box-shadow: none !important;
    }
</style>
<script>
import {createRedirectionUrl} from "./../../../methods/layoutFunc.js"
    export default{
        name:"TaskWidgets",
        props:{
            dataObject:{
                type:Object,
                default:()=>{
                    return {
                        "selectedTaskListObject":{},
                        "tasksForSelectedList":[],
                        "showHeading":false,
                        "openInNewTab":false
                    }
                }
            }
        },
        data(){
            return{
                currentCols:6,
                resizeObserver: null
            }
        },
        watch:{
            'dataObject':{
                handler(newValue){

                },
                deep:true
            }
        },
        computed:{
            selectedTaskListObject(){
                return this.dataObject.selectedTaskListObject || {}
            },
            tasksForSelectedList(){
                return this.dataObject.tasksForSelectedList || []
            },
            showHeading(){
                return this.dataObject.showHeading||false
            },
            widgetHeading(){
                return this.selectedTaskListObject.object_name || "Untitled Task List"
            },
            openInNewTab(){
                return this.dataObject.openInNewTab || false
            }
        },
        mounted() {
            // Set up resize observer to listen to the parent container size changes
            this.resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    const parentWidth = entry.contentRect.width;
                    this.updateColsBasedOnWidth(parentWidth);
                }
            });

            if (this.$refs.parentContainer) {
                this.resizeObserver.observe(this.$refs.parentContainer);
            }
        },
        beforeDestroy() {
            // Clean up the resize observer
            if (this.resizeObserver) {
                this.resizeObserver.disconnect();
            }
        },
        methods: {
            updateColsBasedOnWidth(width) {
                // Update `currentCols` based on tentative breakpoints
                if (width >= 1920) {
                    this.currentCols = 2; // XXL screens (2 columns)
                } else if (width >= 1200) {
                    this.currentCols = 3; // XL screens (3 columns)
                } else if (width >= 960) {
                    this.currentCols = 4; // MD, LG screens (4 columns)
                } else if (width >= 580) {
                    this.currentCols = 6; // XS, SM screens (6 columns)
                } else {
                    this.currentCols = 12; // Smaller than XS (1 column)
                }
            },
            routeUrl(path, item) {
                return this.openRouteInTab(path, item)
            },
            openRouteInTab(path, item){
                var _this = this
                if(item.taskResource.resource_code === 'SEE_OBJ_IN_PATHFINDER'){
                    window.open(path, item.openInNewTab?'_blank':'_self');
                    return
                }
                var routeData = this.$router.resolve({"name":"edclander",query:{pathToRedirect:path}});
                if(item.openInNewTab){
                    window.open(routeData.href, '_blank');
                }else{
                    window.open(routeData.href, '_self');
                }
            },
            navigateTask(item) {
                if (!item || !item.taskResource) {
                    console.warn('Invalid item data');
                    return;
                }

                switch (item.taskResource.resource_code) {
                    case 'USER_SETUP':
                        this.navigateToRoute('/adduser', item.openInNewTab);
                        break;

                    case 'USER_GROUP_SETUP':
                        this.navigateToRoute('/manageusergroup', item.openInNewTab);
                        break;

                    case 'SETUP_ACTION_BASED_SEC':
                        const queryParams = {
                            env_id: this.$session.get('selected_env') ? this.$session.get('selected_env').id : null,
                            env_name: this.$session.get('selected_env') ? this.$session.get('selected_env').env_name : null,
                            role_type: 'environmental',
                        };
                        this.navigateToRoute({ name: 'ManageObjBasedRole', query: queryParams }, item.openInNewTab);
                        break;
                    case 'SETUP_OBJ_SEC':
                        const queryParams1 = {
                            env_id: this.$session.get('selected_env') ? this.$session.get('selected_env').id : null,
                            env_name: this.$session.get('selected_env') ? this.$session.get('selected_env').env_name : null,
                            role_type: 'object_based',
                        };
                        this.navigateToRoute({ name: 'ManageObjBasedRole', query: queryParams1 }, item.openInNewTab);
                        break;
                    case 'SETUP_ADMIN_SEC':
                        const queryParams2 = {
                            role_type: 'non_environmental',
                            roleTypeForTitle: "Administrative"
                        };
                        this.navigateToRoute({ name: 'ManageActionBasedRole', query: queryParams2},
                         item.openInNewTab);
                        break;

                    case 'SETUP_DS':
                        this.navigateToRoute('/managedatasource', item.openInNewTab);
                        break;
                    case 'SETUP_ENV':
                        this.navigateToRoute('/manageenvironment', item.openInNewTab);
                        break;
                    case 'VIEW_SEC_REPORT':
                        this.navigateToRoute('/rolereport', item.openInNewTab);
                        break;
                    case 'SEE_OBJ_IN_PATHFINDER':
                        var datasource = item.selectedValueForUiComponents && item.selectedValueForUiComponents.datasource && item.selectedValueForUiComponents.datasource.selectedValue;
                        var selectedObject = item.selectedValueForUiComponents && item.selectedValueForUiComponents.objectlist && item.selectedValueForUiComponents.objectlist.selectedValue;

                        // If datasource or selectedObject are null, return a fallback or handle the error
                        if (!datasource || !selectedObject) {
                            return '/fallback'; // Fallback URL or appropriate error handling
                        }
                        let redirectData = {
                            "routePath": "edcpathfinder",
                            "redirectionFrom": "widget",
                            "env_id": this.$session.get('selected_env') ? this.$session.get('selected_env').id : null, // Safe access
                            "client_id": this.$session.get("client_id"),
                            "user_id": this.$session.get('email'),
                            "object_id": selectedObject.object_id,
                            "object_name": selectedObject.object_name,
                            "object_type": selectedObject.object_type,
                            "datasource_id": datasource.datasource_id,
                            "datasource_index": datasource.datasource_index
                        }
                        let url = createRedirectionUrl(this,'PATHFINDER','/edcpathfinder',redirectData)
                        window.open(url, item.openInNewTab?'_blank':'_self');
                        return
                        // return url

                    default:
                        console.warn('No matching task resource code');
                        break;
                }
            },
            navigateToRoute(routeData, openInNewTab) {
                if (openInNewTab) {
                    // Resolve the route into an absolute URL
                    const route = typeof routeData === 'string' 
                        ? { path: routeData } 
                        : routeData;
                    const resolvedRoute = this.$router.resolve(route);
                    window.open(resolvedRoute.href, '_blank');
                } else {
                    // Regular navigation within the app
                    if (typeof routeData === 'string') {
                        this.$router.push(routeData);
                    } else {
                        this.$router.push(routeData);
                    }
                }
            },

            getTaskUrl(item) {
                switch (item.taskResource.resource_code) {
                    case 'USER_SETUP':
                        return '/adduser';
                    case 'USER_GROUP_SETUP':
                        return '/manageusergroup';
                    case 'SETUP_ACTION_BASED_SEC':
                        return {
                            name: 'ManageObjBasedRole',
                            query: {
                                env_id: this.$session.get('selected_env') ? this.$session.get('selected_env').id : null,
                                env_name: this.$session.get('selected_env') ? this.$session.get('selected_env').env_name : null,
                                role_type: 'environmental'
                            }
                        };
                    case 'SETUP_ADMIN_SEC':
                        return '/actionbasedrolelist';
                    case 'SETUP_DS':
                        return '/managedatasource'
                    case 'SETUP_ENV':
                        return '/manageenvironment'
                    case 'VIEW_SEC_REPORT':
                        return '/rolereport'
                    case 'SEE_OBJ_IN_PATHFINDER':
                        var datasource = item.selectedValueForUiComponents && item.selectedValueForUiComponents.datasource && item.selectedValueForUiComponents.datasource.selectedValue;
                        var selectedObject = item.selectedValueForUiComponents && item.selectedValueForUiComponents.objectlist && item.selectedValueForUiComponents.objectlist.selectedValue;

                        // If datasource or selectedObject are null, return a fallback or handle the error
                        if (!datasource || !selectedObject) {
                            return '/fallback'; // Fallback URL or appropriate error handling
                        }
                        let redirectData = {
                            "routePath": "edcpathfinder",
                            "redirectionFrom": "widget",
                            "env_id": this.$session.get('selected_env') ? this.$session.get('selected_env').id : null, // Safe access
                            "client_id": this.$session.get("client_id"),
                            "user_id": this.$session.get('email'),
                            "object_id": selectedObject.object_id,
                            "object_name": selectedObject.object_name,
                            "object_type": selectedObject.object_type,
                            "datasource_id": datasource.datasource_id,
                            "datasource_index": datasource.datasource_index
                        }
                        let url = createRedirectionUrl(this,'PATHFINDER','/edcpathfinder',redirectData)
                        return url
                    // Add more cases as needed
                    default:
                    return '#'; // Default fallback URL
                }
        }
    }
    }
</script>