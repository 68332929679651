<template>
    <div>
        <v-card v-if="!hideEmptySlot" :class="[heightClass,'d-flex','flex-column']" :loading="isLoading" :ref="'slotCard'+slotNumber">
            <v-card-title :class="{'widget-heading-standard':true, 'd-flex':isDesigner, 'justify-space-between':isDesigner}">{{ title }}
                <svgicon v-if="isWidgetSelected && isDesigner" name="cancel_v2"
                class="svg-icon-grid svg-fill-grid EDC-GridIcon justify-end"
                @click="showConfirmation"/>
                
                <v-menu offset-y open-on-click close-on-content-click v-if="!isDesigner">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-information</v-icon>
                        </v-btn>
                    </template>
                    <v-card class="info-card">
                        <v-card-title class="custom-title"> More Information </v-card-title>
                        <v-card-text class="card-text">
                            <div v-if="isWidgetForTask"><strong>Object ID: </strong> {{ task_list_id }}</div>
                            <div v-if="isWidgetForTask"><strong>Object Descrption: </strong> {{ task_list_desc }}</div>
                            <div v-if="isWidgetForGrid"><strong>Object ID: </strong> {{ grid_object_id }}</div>
                            <div v-if="isWidgetForGrid"><strong>Datasource Name: </strong> {{ grid_object_ds_name }}</div>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </v-card-title>
            <v-card-text :class="cardTextCssClasses">
                <v-row no-gutters>
                    <v-col v-if="isDesigner">
                        <v-row no-gutters class="px-2">
                            <template v-for="(item,ind) in UIComponents">
                                <!-- <v-row no-gutters :key="item.id+'ind'+ind"> -->
                                    <v-col class="pt-3" :cols="12/noOfColumn" :key="item.id+'ind'+ind">
                                        <custom-component :key="item.id+'control'+ind"
                                        :componentDetails="item"
                                        :isDesigner="isDesigner"
                                        @updateModelData="updateModelData"
                                        @onTriggerWidgetApiCall="onTriggerWidgetApiCall"
                                        :parentSelectedValues="allComponentSelectedValues"
                                        />
                                    </v-col>
                                <!-- </v-row> -->
                            </template>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="isWidgetDataLoaded">
                    <v-col class="pa-0 ma-0">
                        <component :is="currentWidgetComponent" :dataObject="widgetDataObject"
                        @stopWidgetLoader="stopWidgetLoader"
                        @onWidgetUniqueKeyChange="widgetUniqueKey=$event"/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card v-else elevation="0" :class="[heightClass,'d-flex','flex-column']">
            <!---- EMpty card place holder-->
        </v-card>
        <simplert :useRadius="true" :useIcon="true" ref="simplert">
        </simplert>
    </div>
</template>
<style scoped>

.info-card {
  max-width: 300px;
}
.card-text {
  font-size: 14px;
  text-align: left;
  direction: ltr;
  padding-top: 6px;
}

.custom-title {
  padding-top: 1px;
  font-size: 14px !important;
  font-weight: bold;
  padding-bottom: 1px;
  background-color: #253841; /* Custom color for title background */
  color: white; /* Text color */
  padding: 8px; /* Padding for better spacing */
}

.custom-divider {
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>
<script>
    import config from '../config.json'
    import { eventBus } from '../eventBus';
    import { post as postToServer } from '../methods/serverCall.js';
    import { makeAPICall,parseInputParams } from '../methods/layoutFunc.js';
    import customComponent from "./controlComponent.vue"
    import taskListWidget from "./taskListWidget.vue"
    import datagridWidget from "./dataGridWidget.vue"
    import Simplert from 'vue2-simplert'
    import {mapActions} from 'vuex'
    export default{
        name:"LayoutSlotCard",
        components:{
            'custom-component':customComponent,
            'task-list-widget':taskListWidget,
            'data-grid-widget':datagridWidget,
            'simplert':Simplert
        },
        props:{
            isDesigner:{
                type:Boolean,
                default:false
            },
            heightClass:{
                type:String,
                default:""
            },
            slotNumber:{
                type:Number,
                default:1
            },
            draggedWidgetId:{
                type:String,
                default:""
            },
            heightLimit:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            dashboardId:{
                type:String,
                default:""
            }
        },
        data(){
            return{
                widgetObjectJson:{},
                isLoading:false,
                loadingError:'',
                isValidDragged:false,
                widgetDataObject:{},
                noOfColumn:1,
                widgetTitle:"",
                widgetUniqueKey:"",
                gridObjectId: "",
                taskListId: "",
                taskListDescription: "",
                gridObjectDS: ""
            }
        },
        computed:{
            userName(){
                return this.$session.get("email")
            },
            clientId(){
                return this.$session.get("client_id")
            },
            // Check if the widget is selected by checking if widgetObjectJson is not empty
            isWidgetSelected() {
                return !_.isEmpty(this.widgetObjectJson);
            },

            // Return widget properties or an empty object if the widget is not selected
            widgetProperties() {
                return this.isWidgetSelected ? this.widgetObjectJson : {};
            },

            // Extract the widget type or return "N/A" if properties are missing
            widgetType() {
                return _.get(this.widgetProperties, 'widgetType.itemValue', '');
            },

            isWidgetDataLoaded(){
                return !_.isEmpty(this.widgetDataObject)
            },
            // Check current widget is tasklist or not
            isWidgetForTask(){
                return this.widgetType === "TASK_LIST"
            },

            // Check current widget is GRID or not
            isWidgetForGrid(){
                return this.widgetType === "GRID"
            },
            
            // Title of the widget or fallback to "Slot X"
            title() {
                if(this.isDesigner || !this.widgetTitle)
                    return this.isWidgetSelected && this.isValidDragged
                    ? this.widgetObjectJson.title.itemValue
                    : `Slot ${this.slotNumber}`;
                else
                    return this.widgetTitle || `Slot ${this.slotNumber}`
            },
            task_list_id(){
                return this.taskListId
            },
            task_list_desc(){
                return this.taskListDescription
            },
            grid_object_id(){
                return this.gridObjectId
            },
            grid_object_ds_name(){
                return this.gridObjectDS
            },

            // CSS class for the widget title, defaulting to an empty string
            titleClass() {
                return this.isWidgetSelected ? this.widgetObjectJson.title.titleClass : '';
            },

            // UI settings object extracted from widget properties, fallback to empty object
            uiSettingObject() {
                return _.get(this.widgetProperties, 'uiSettings', {});
            },

            // Check if the UI settings object is not empty
            hasUISettings() {
                return !_.isEmpty(this.uiSettingObject);
            },

            // CSS classes for the content, fallback to "content-standard"
            contentCssClasses() {
                return _.get(this.uiSettingObject, 'cssClasses', 'content-standard');
            },

            // CSS classes for overflow behavior, fallback to default scrolling class
            overflowCssClasses() {
                return _.get(this.uiSettingObject, 'overflowCssClasses', 'overflow-auto style-2scroll flex-grow-1');
            },
            cardTextCssClasses(){
                let defaultClassList = [this.contentCssClasses,this.overflowCssClasses,'pa-0']
                // want to show controls at the center of the grid
                if (this.isDesigner && !this.isWidgetDataLoaded)
                    defaultClassList = [...defaultClassList,...['d-flex','justify-center','align-center']]
                return defaultClassList
            },
            // Height of the widget, fallback to an empty object if not defined
            widgetHeight() {
                return _.get(this.uiSettingObject, 'widgetHeight', {});
            },
            // API details from the datasource info, fallback to empty object
            apiDetails() {
                return {}
            },
            UIComponents(){
                return _.get(this.widgetProperties, 'uiComponents', []);
            },
            currentWidgetComponent(){
                if(this.isWidgetForTask)
                    return "task-list-widget"
                if(this.isWidgetForGrid)
                    return "data-grid-widget"
                return ""
            },
            allComponentSelectedValues(){
                let allSelectedValues = {}
                if(!this.UIComponents.length)
                    return allSelectedValues
                for(var i=0;i<this.UIComponents.length;i++){
                    let currentComponent = this.UIComponents[i]
                    if(!_.isEmpty(currentComponent.selectedValue))
                        allSelectedValues[currentComponent.key] = {
                            "selectedValue":currentComponent.selectedValue
                        }
                }
                return allSelectedValues
            },
            hideEmptySlot(){
                return !this.widgetType && !this.isDesigner
            },
            Environment(){
                return this.$session.get('selected_env')
            }
        },
        watch:{
            'draggedWidgetId':{
                handler(newValue){
                    this.getWidgetJson()
                }
            },
            'dashboardId':{
                handler(newValue){

                }
            }
        },
        mounted() {
            var _this = this
            // Get the card's dimensions after it's rendered
            this.$nextTick(() => {
                this.getCardSize();
            });
            eventBus.$on('stopWidgetLoader', (widgetUniqueKey) => {
                if(widgetUniqueKey === this.widgetUniqueKey)
                _this.isLoading = false
            });
        },
        methods:{
            ...mapActions(['setDraggedWidget']),
            getCardSize() {
                if(!this.$refs['slotCard'+this.slotNumber])
                    return
                var cardElement = this.$refs['slotCard'+this.slotNumber].$el;
                console.log("cardElement",cardElement)
                if (cardElement) {
                    let width = cardElement.clientWidth;
                    if (width < 500) {
                        this.noOfColumn = 1; // Small screen: 1 item per row
                    } else if (width >= 500 && width < 800) {
                        this.noOfColumn = 2; // Medium screen: 2 items per row
                    } else if (width >=800 && width < 1200) {
                        this.noOfColumn = 3; // Large screen: 3 items per row
                    } else{
                        this.noOfColumn = 4; // XL screen: 4 items per row
                    }
                    
                }
            },
            getWidgetJson(){
                var _this = this
                _this.isValidDragged = false
                this.widgetObjectJson = {}
                this.widgetDataObject = {}
                if(!this.draggedWidgetId){
                    return
                }
                var data = {"widget_id":this.draggedWidgetId,"env_id":this.Environment.id,
                "user_name":this.userName,"dashboard_id":this.dashboardId,
                'widget_slot_id':this.slotNumber}

                let droppedWidget = _.cloneDeep(this.$store.state.draggedWidget)
                this.setDraggedWidget({})
                if(droppedWidget && droppedWidget.isDragged)
                    data['dashboard_id'] = ""
                var url = config.REPOSITORY_API_URL+"/get_widget_by_id"
                this.isLoading=true
                postToServer(_this,url,data).then(response=>{
                    this.isLoading=false
                    // console.log("widget response=>",response)
                    this.widgetObjectJson = response.widget_data
                    setTimeout(()=>{
                        _this.mapModifierData(response.widget_modifiers)
                    },10)
                    _this.performValidation()
                }).catch(error=>{
                    this.isLoading = false
                    this.loadingError = ""
                })
            },
            performValidation(){
                if(_.isEmpty(this.widgetHeight)|| _.isEmpty(this.heightLimit)){
                    // means parameter is not specified on widget or slot, so good to go.
                    this.isValidDragged = true
                }
                if(this.widgetHeight.minimum>=this.heightLimit.minimum && this.widgetHeight.maximum <= this.heightLimit.maximum){
                    this.isValidDragged = true
                }
                else{
                    this.isValidDragged = true
                    
                    // // work needed
                    // let dimentionDetails = {
                    //     "widget":"(Minimum:"+this.widgetHeight.minimum+", Maximum:"+this.widgetHeight.maximum+")",
                    //     "slot":"(Minimum:"+this.heightLimit.minimum+", Maximum:"+this.heightLimit.maximum+")"
                    // }
                    // this.$emit("deAttachWidget",dimentionDetails)
                }
                this.loadWidgetData()
            },
            loadWidgetData(){
                if(_.isEmpty(this.apiDetails))
                    return
                makeAPICall(this,this.apiDetails).then(response=>{
                    console.log("api response",response)
                })  
            },
            onTriggerWidgetApiCall(APIDetails,selectedObject){
                var _this = this
                this.widgetTitle = ""
                // make sure all required params are there, not needed now but -> todo
                let inputData = parseInputParams(this,APIDetails.params,_.cloneDeep(this.allComponentSelectedValues))
                // console.log("inputData",JSON.stringify(inputData))
                this.isLoading=true
                makeAPICall(this,APIDetails,inputData).then(response=>{
                    this.isLoading=false
                    if(this.isWidgetForTask){
                        this.widgetDataObject = {
                            "selectedTaskListObject":selectedObject,
                            "tasksForSelectedList":response.object_json.sections,
                            "showHeading":!this.isDesigner
                        }
                        this.widgetTitle = selectedObject.object_name
                        this.taskListId = selectedObject.object_id
                        // alert(selectedObject.object_desc)
                        this.taskListDescription = selectedObject.object_desc
                    } else if(this.isWidgetForGrid){
                        this.isLoading=true
                        // console.log("gridWidgetData",response)
                        _this.widgetDataObject = response
                        this.widgetTitle = response.widgetHeader
                        this.gridObjectId = response.object_id
                        this.gridObjectDS = response.datasource_name
                    }
                }).catch(error=>{
                    this.isLoading=false
                    // alert("error in API onTriggerWidgetApiCall")
                })
            },
            showConfirmation(){
                let confirmFn = function () {
					this.$parent.onClear()
				}
				let obj = {
					title: 'Confirmation',
					message: "Are you sure you want to remove '"+this.title+"' widget from layout?",
					type: 'info',
					useConfirmBtn: true,
					onConfirm: confirmFn,
					customCloseBtnText:'No',
					customConfirmBtnText:'Yes'
				}
				this.$refs.simplert.openSimplert(obj)
            },
            onClear(){
                this.widgetDataObject = {}
                this.isLoading = false
                this.$emit("deAttachWidget")   
            },
            updateModelData(componentKey,modelValue){
                let currentControl = _.find(this.UIComponents,["key",componentKey])

                if(currentControl){
                    currentControl['selectedValue'] = modelValue
                    this.emitWidgetJson()
                }
                    
            },
            emitWidgetJson(){
                if(!this.isDesigner)
                    return
                // Here we will validate that allComponentSelectedValues has all component values.
                // Logic is we will check "isRequired True" components has value selected.
                let allRequiredSelected = true
                for(let i=0;i<this.UIComponents.length;i++){
                    let keyVal = this.UIComponents[i].key
                    let isRequired = this.UIComponents[i].isRequired
                    if(isRequired && _.isEmpty(this.allComponentSelectedValues[keyVal])){
                        allRequiredSelected = false
                        break
                    }
                }
                let widgetModifier = {}
                
                if(allRequiredSelected)
                    widgetModifier = _.cloneDeep(this.allComponentSelectedValues)
                
                this.$emit("onWidgetChange",this.slotNumber,widgetModifier)
            },
            mapModifierData(widgetModifiers){
                var _this = this
                if(_.isEmpty(widgetModifiers))
                    return
                for(let i=0;i<this.UIComponents.length;i++){
                    let keyVal = this.UIComponents[i].key
                    if(widgetModifiers[keyVal]){
                        // Object.assign(this.UIComponents[i],widgetModifiers[keyVal])
                        // I can do object.assign but mapping each key seprate will be easy to understand, so doing it manually
                        this.UIComponents[i]['selectedValue'] = widgetModifiers[keyVal].selectedValue
                        if(this.UIComponents[i].onChange && this.UIComponents[i].onChange.triggerWidgetApiCall){
                            // timeout added to make sure all UI components get updated with modifier
                            setTimeout(()=>{
                                _this.onTriggerWidgetApiCall(this.UIComponents[i].onChange.api,widgetModifiers[keyVal].selectedValue)
                            },50)
                        }
                    }
                }
                this.emitWidgetJson()
            },
            stopWidgetLoader(){
                alert("stop loading")
                this.isLoading = true
            }
        }
    }
</script>