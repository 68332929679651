<template>
    <v-container>
        <v-row class="EDC-Row" no-gutters v-show="showEnvOpt && searchCompProps.showEnvOpt">
            <v-col cols="4"></v-col>
            <v-col cols="4">
                <env-ds-selection :currentCols="12"
                cssClassToApply="EDC-Search-Control" class="EDC-SearchBox"
                v-show="showEnvOnPage" @onEnvDSSelectionChanged="handleEnvDSChange"
                :selectedEnvId="selectedEnvId" :selectedDSId="selectedDSId"/>
            </v-col>
            <v-col></v-col>
        </v-row>
        <v-row class="EDC-Row" no-gutters>
            <v-col cols="3"></v-col>
            <v-col cols="1" class="d-flex justify-end align-center">
                <v-menu v-if="showOptionIcn"  bottom center transition="scale-transition" style="display:block" v-model="showMoreActions" :close-on-content-click="false" :nudge-width="400">
                    <template v-slot:activator="{ on }">
                        <svgicon v-on="on" class="svg-icon-grid svg-fill-grid" name="More" slot="activator"  :original="true" title="Show More Options"></svgicon>
                    </template>
                    <v-card class="EDC-Card pa-3">
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col">
                                <more-options :searchOptions="searchOptions"
                                :selectedEnvId="selectedEnvId" :selectedDSId="selectedDSId" 
                                :showEnvOptions="!showEnvOnPage"
                                @onEnvDSSelectionChanged="handleEnvDSChange" 
                                @onOptionChanged="updateSearchOptions"
                                :showEnvOpt="showEnvOpt"/>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-menu>
            </v-col>
            <v-col cols="4" class="d-flex justify-center">
                <v-autocomplete class="EDC-SearchBox EDC-Search-Control"  outlined
                clearable autocomplete="off" :items="availableObjectList"
                :search-input.sync="searchText" item-text="displaytext" item-value="unique_object_id"
                v-model="selectedObjectId" label="Select Business View / Object / Table Name"
                hide-no-data :loading="tableLoading" hide-details
                prepend-inner-icon="search" @input="onObjectSelected">
                </v-autocomplete>
            </v-col>
            <v-col class="d-flex justify-start align-center">
                <vc-button itemText='Go' style="height:32px !important;"
                :disabled="disabledGo" @click.native="fetchData"
                :loading="dataLoading" v-if="showGoBtn"/>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>
    </v-container>
</template>
<style>
    .EDC-Search-Control{
        border-radius: 24px !important;
        padding-top: 12px !important;
    }
</style>
<style scoped>
    .EDC-SearchBox{
        
    }

    .EDC-SearchBox >>> label{
		font-size: 14px !important;
		font-weight: bold;
	}

	.EDC-SearchBox >>> input{
		font-size: 16px !important;
		font-weight: bold;
	}
</style>
<script>
    import envDatasourceSelection from './envDatasourceSelection.vue';
    import moreOptions from './moreOptions.vue';
    import config from "./../../config.json"
    import { post as postToServer } from './../../methods/serverCall.js'
    import {getTableList} from "./../../methods/agent_api_call.js"
    import {createRedirectionUrl} from "./../../methods/layoutFunc.js"
    export default{
        name:"EdcSearchObject",
        components:{
            'env-ds-selection':envDatasourceSelection,
            'more-options':moreOptions
        },
        props:{
            whichSide:{
				type:String,
				default:'wholepage',
			},
            showEnvOpt:{
                type:Boolean,
                default:true
            },
            searchCompProps:{
                type:Object,
                default:()=>{
                    return {
                        // defined in pathfinder mixin
                    }
                }
            }
        },
        data(){
            return{
                tableLoading:false,
                searchText:'',
                showMoreActions:false,
                searchOptions:{
                    "searchForTable":true,
                    "searchForView":true,
                    "searchForAlias":false,
                    "searchForBV":true,
                    "searchForBO":true
                },
                selectedEnv:{},
                selectedDS:{},
                selectedObjectId:{},
                selectedEnvId:0,
                selectedDSId:"",
                dsListForBackEnd:[],
                businessViewList:[],
                businessObjectList:[],
                tableList:[],
                callTableTimer:null,
                fetchUserPreference:true,
                userPreferenceTimer:null,
                dataLoading:false,
                widgetParams:{},
                loader:false
            }
        },
        computed:{
            clientId(){
                return this.$session.get('client_id')
            },
            userId(){
                return this.$session.get('user_id')
            },
            userEmail(){
                return this.$session.get('email')
            },
            availableObjectList(){
                // Separator object used to separate different lists
                var bo_tbl_seprator = '===================='
                const separator = {
                    "title": bo_tbl_seprator,
                    "type": "sp",
                    "divider": true,
                    "no-filter": true,
                    'displaytext': bo_tbl_seprator + "-"
                }
                // Function to merge a list into availableObjectList if shouldInclude is true
                const mergeLists = (list, separator, availableObjectList, shouldInclude) => {
                    // Check if shouldInclude is true and the list exists and is not empty
                    if (shouldInclude && list && list.length) {
                        // If availableObjectList is not empty, add separator before merging the list
                        if (availableObjectList.length) {
                            availableObjectList.push(_.cloneDeep(separator));
                        }
                        // Merge the list into availableObjectList
                        availableObjectList.push(...list);
                    }
                };

                // Initialize availableObjectList
                let availableObjectList = [];

                // Merge BV_list into availableObjectList if searchForBusinessView is true
                mergeLists(this.businessViewList, separator, availableObjectList, this.searchOptions.searchForBV);
                // Merge BO_list into availableObjectList if searchForBusinessObject is true
                mergeLists(this.businessObjectList, separator, availableObjectList, this.searchOptions.searchForBO);
                // Merge tableList into availableObjectList if searchForTable is true
                mergeLists(this.tableList, separator, availableObjectList, this.searchOptions.searchForTable);
                // Return the merged availableObjectList
                return availableObjectList
            },
            showEnvOnPage(){
                return !this.selectedEnvId || !this.selectedDSId
            },
            dataDictionaryDS(){
                return this.selectedDS.dictionary_data || {}
            },
            controlDataDS(){
                return this.selectedDS.control_data || {}
            },
            objectLibrarianDS(){
                return this.selectedDS.object_librarian_data || {}
            },
            acceleratorType(){
                return this.selectedDS.acceleration_type || ''
            },
            selectedObject(){
                return _.find(this.availableObjectList,['unique_object_id',this.selectedObjectId]) || {}
            },
            disabledGo(){
                return _.isEmpty(this.selectedObject)
            },
            showGoBtn(){
                return _.isEmpty(this.widgetParams) && this.searchCompProps.showGO === true
            },
            autoLoadObject(){
                if(!_.isEmpty(this.widgetParams) && this.widgetParams.object_type != 'GV')
                    return {"object_id":this.widgetParams.object_id,"object_type":this.widgetParams.object_type}
                return {}
            },
            loadUserPreference(){
                return _.isEmpty(this.widgetParams) && this.searchCompProps.loadUserPreference === true
            },
            showOptionIcn(){
                return _.isEmpty(this.widgetParams) && this.searchCompProps.loadUserPreference === true
            },
            openInNewTab(){
                return this.searchCompProps.openInNewTab || false
            }
        },
        watch:{
            'searchText':{
                handler(newValue){
                    let _this = this
                    clearInterval(this.callTableTimer)
                    
                    if(newValue && newValue === this.selectedObject.displaytext)
                        return

                    this.callTableTimer = setTimeout(()=>{
                        _this.fetchTableList(newValue)
                    },800)
                }
            },
            showEnvOpt(){

            }
        },
        mounted(){
            var _this = this
            if(this.$route.params.redirectionFrom === 'widget'){
				this.widgetParams = this.$route.params
                this.selectedEnvId = parseInt(this.widgetParams.env_id)
                this.selectedDSId = this.widgetParams.datasource_id
                if(this.widgetParams.object_type === 'GV')
                    this.fetchGVParentDetails()
                else
                    if(['BV','BO'].indexOf(this.widgetParams.object_type)>-1)
                        this.selectedObjectId = this.widgetParams.object_id
                    else{
                        _this.widgetParams['object_type'] = 'table'
                        setTimeout(()=>{
                            this.selectedObjectId = this.widgetParams.object_id
                            _this.fetchTableList(response.table_name)
                        },1000)
                        
                    }
                this.loader=true
            }
            if(this.searchCompProps.autoSelectedEnvId)
                this.selectedEnvId = this.searchCompProps.autoSelectedEnvId
            if(this.searchCompProps.autoSelectedDatasourceId)
                this.selectedDSId = this.searchCompProps.autoSelectedDatasourceId
            
            if(this.loadUserPreference)
                this.getUserPreferences()
        },
        methods:{
            handleEnvDSChange(selectedEnv, selectedDS, dsListForBackEnd) {
                if (selectedEnv.env_id !== this.selectedEnv.env_id) {
                    this.resetEnvData(selectedEnv);
                }
                if (selectedDS.datasource_id !== this.selectedDS.datasource_id) {
                    this.resetDSData(selectedDS, dsListForBackEnd);
                }
                this.manageUserPreferences()
            },
            resetEnvData(selectedEnv) {
                this.businessViewList = [];
                this.selectedEnv = selectedEnv;
                this.selectedEnvId = selectedEnv.env_id;
                this.fetchBusinessViews();
            },
            resetDSData(selectedDS, dsListForBackEnd) {
                this.tableList = [];
                this.businessObjectList = [];
                this.selectedDSId = selectedDS.datasource_id || ""
                this.dsListForBackEnd = dsListForBackEnd || [];
                this.selectedDS = selectedDS || {};
                this.fetchBusinessObjects();
                this.fetchTableList();
            },
            updateSearchOptions(searchOptions) {
                Object.assign(this.searchOptions, searchOptions);
                this.manageUserPreferences()
            },
            fetchBusinessViews() {
                if (this.selectedEnvId) {
                    this.fetchEDCObjectList("BV", "businessViewList");
                }
            }, 
            fetchBusinessObjects(){
                if(this.selectedDSId)
                    this.fetchEDCObjectList('BO','businessObjectList')
            },
            fetchEDCObjectList(objectType,controlId){
                var _this = this
                var data = {
                    "client_id": this.clientId, 
                    "env_id": this.selectedEnvId, 
                    "ds_id":this.selectedDSId,
					"ds_index":this.selectedDS.datasource_index,
			        'filter':[{
                        "column_name":"object_type",
                        'operator':"_in_",
                        "value1":[objectType]
                    }],
                    "page_size":500,
					"user_id":this.userId,
					"check_access":true
                }
                var get_bo_list_url = config.PUBLISHER_URL + '/published_objects_in_env_for_dv_public'
                postToServer(_this, get_bo_list_url, data).then(response => {
                    const results = response.result || [];
                    const displayObjType =
                        objectType === "BO" ? " - Business Object" : " - Business View";

                    this[controlId] = results.sort(
                        (a, b) => b.object_name.localeCompare(a.object_name)).map(
                        (obj) => ({
                        title: obj.object_name,
                        object_id: obj.object_id,
                        object_name: obj.object_name,
                        type: obj.object_type,
                        object_version: obj.object_version,
                        displaytext: `${obj.object_name}${displayObjType}`,
                        unique_object_id:obj.object_id
                    }));
                    if(this.autoLoadObject.object_type === objectType){
                        setTimeout(()=>{
                            _this.fetchData()
                        },100)
                    }

                }).catch(error=>{
                    
                })
            },
            fetchTableList(searchText=''){
                var _this = this
                var objectType = ["table", "view", "alias"].filter(
                    type => _this.searchOptions[`searchFor${type.charAt(0).toUpperCase() + type.slice(1)}`]
                );
                let isAutoLoad = false
                if(this.autoLoadObject.object_type === 'table'){
                    searchText = this.autoLoadObject.object_id
                    isAutoLoad = true
                }
                if (!objectType.length || _.isEmpty(this.selectedDS)) {
                    return;
                }
                _this.tableLoading = true
                _this.$session.set('mapped_agent_details',[_this.selectedDS.mapped_agent_id])
                let additionalData = {
					"check_in_description":true,
					"description_needed":true,
					"object_type":objectType,
					'mapped_agent_id':_this.selectedDS.mapped_agent_id,
					'object_librarian_data':_this.objectLibrarianDS,
					"check_access":true,
					"ds_id":_this.selectedDS.datasource_id,
					"ds_index":_this.selectedDS.datasource_index
				}
                let userSecurityDetails = this.$session.get('user_role_mapping')
				if( userSecurityDetails)
					additionalData['is_super_admin'] = userSecurityDetails.is_superadmin
                getTableList(_this,_this.selectedEnvId, _this.selectedDS.connection_string, _this.selectedDS.schema_name, _this.selectedDS.database_type, searchText,'',additionalData).then(tableResponse =>{
                    _this.tableLoading = false
                    _this.tableList = tableResponse.table_view_list.map(obj => {
                        const objType = obj.type === 'V' ? 'View' : obj.type === 'A' ? 'Synonyms' : 'Table';
                        return {
                            ...obj,
                            displaytext: obj.description ? `${obj.title} - ${objType} - ${obj.description}` : obj.title,
                            unique_object_id:obj.title
                        };
                    });
                    if(isAutoLoad){
                        setTimeout(()=>{
                            _this.fetchData()
                        },100)
                    }
                }).catch(error=>{
                    _this.tableLoading = false
                })
            },
            getPreferencesObj(){
      			let tbl_obj_preference = {
                    "isBV":this.searchOptions.searchForBV,
                    "isBO":this.searchOptions.searchForBO,
                    'isTable':this.searchOptions.searchForTable,
                    'isView':this.searchOptions.searchForView,
                    'isAlias':this.searchOptions.searchForAlias
                }
      			if(this.selectedEnvId)
        			tbl_obj_preference['selectedEnv'] = this.selectedEnvId
      			if(this.selectedDSId)
        			tbl_obj_preference['selectedDS'] = this.selectedDSId
		    	var data = {
		        	"user_name":this.$session.get('email'),
		        	"product_name":config.PRODUCT_NAME,
		        	"client_id":this.clientId,
		        	"tbl_obj_preference":JSON.stringify(tbl_obj_preference)
		      	}
      			return data
    		},
            getUserPreferences(){
                var _this = this
                if(!this.fetchUserPreference)
                    return
                var data = _this.getPreferencesObj()
                delete data.tbl_obj_preference
                postToServer(_this,config.USER_PROVISION_URL+'/get_user_preference_by_username',data).then(response=>{
                    let userPreferences = JSON.parse(response.tbl_obj_preference)
                    _this.searchOptions.searchForBV = userPreferences.isBV
			        _this.searchOptions.searchForBO = userPreferences.isBO
			        _this.searchOptions.searchForTable = userPreferences.isTable
			        _this.searchOptions.searchForView = userPreferences.isView
			        _this.searchOptions.searchForAlias = userPreferences.isAlias
                        _this.selectedEnvId = userPreferences.selectedEnv
                    if(userPreferences.selectedDS)
                        _this.selectedDSId = userPreferences.selectedDS
                }).catch(error=>{

                })
            },
            manageUserPreferences(){
                var _this = this
                if(!this.fetchUserPreference)
                    return
                clearTimeout(this.userPreferenceTimer)
                this.userPreferenceTimer = setTimeout(()=>{
        			var data = _this.getPreferencesObj()
        			postToServer(_this,config.USER_PROVISION_URL+'/add_user_preference',data).then(response=>{

        			}).catch(error=>{

        			})
      			},1000)
            },
            fetchGVParentDetails(){
                var _this = this
                var data = {
                    "object_id":this.widgetParams.object_id,
                    "client_id":this.widgetParams.client_id,
                    "env_id":this.widgetParams.env_id
                }
                _this.loader = true
                var url = config.DATA_VIEWER_URL + '/get_gv_object_details'
                postToServer(_this,url,data).then(response=>{
                    _this.widgetParams['object_id'] = response.table_name
                    _this.selectedObjectId = response.table_name
                    if(response.table_name.substring(0,2) === 'BV'){
                        _this.widgetParams['object_type'] = 'BV'
                        // _this.fetchBusinessViews()
                    } else{
                        _this.widgetParams['object_type'] = 'table'
                        _this.fetchTableList(response.table_name)
                    }

                }).catch(error=>{
                    _this.loader = false
                    alert("Error in fetch GV details")
                })
            },
            fetchData(){
                var _this = this
                _this.loader=true
                var dataToPass = {
					'selected_env':_this.selectedEnv,
					'selected_ds':_this.selectedDS,
					'selected_bo_tbl':_this.selectedObject,
					'dictionary_data':_this.dataDictionaryDS,
					'object_librarian_data':_this.objectLibrarianDS,
					'control_data':_this.controlDataDS,
					'ds_list_for_backend':_this.dsListForBackEnd,
					'isSuperAdmin':true,
					'securityModelByEnv':[],
					'acceleration_type':_this.acceleratorType,
					'whichSide':_this.whichSide,
					'isArchivistCall':false,
					'archivistObj':{}
				}
                if(this.widgetParams.autofiltercolumn){
                    dataToPass['redirectionFilters'] = [{
                        "column_name":this.widgetParams.autofiltercolumn,
                        "value":this.widgetParams.autofilterval,
                        "operator":"_eq_"
                    }]
                }
                _this.dataLoading = true
                if(this.openInNewTab){
                    let redirectData = {
                        "routePath":"edcpathfinder",
                        "redirectionFrom":"widget",
                        "env_id":this.selectedEnvId,
                        "client_id":this.clientId,
                        "user_id":this.userEmail,
                        "object_id":this.selectedObject.object_id || this.selectedObject.title,
                        "object_name":this.selectedObject.title,
                        "object_type":this.selectedObject.type,
                        "datasource_id":this.selectedDS.datasource_id,
                        "datasource_index":this.selectedDS.datasource_index
                    }
                    let url = createRedirectionUrl(this,'PATHFINDER','/edcpathfinder',redirectData)
                    window.open(url, '_blank');
                }
                else
                    this.$emit("loadData",dataToPass)
                setTimeout(()=>{
                    // reset widget params
                    _this.widgetParams = {}
                    _this.dataLoading = false
                    _this.loader=false
                },1000)
            },
            onObjectSelected(){
                if(!this.showGoBtn && !_.isEmpty(this.selectedObject))
                    this.fetchData()
            }
        }
    }
</script>