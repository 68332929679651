<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col cols="12">
                        <ul class="breadcrumb">
                            <li>
                                <select v-model="Environment" v-on:change="getDashboardOfUser" style="-webkit-appearance: menulist" autofocus ref="envname">
                                    <option :value="{}">Select Environment</option>
                                    <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                                </select>
                            </li>
                            <li>Manage Dashboard</li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-container>
            <v-card>
                <v-row>
                    <v-col>
                        <v-row class="EDC-Row EDC-TopRow">
                            <!-- <v-col cols="3" class="pa-2 pb-0">
                                <v-text-field label="Name of Dashboard" id="textdashboardname" v-model="dashboardName" hide-details autocomplete="off" dense></v-text-field>
                            </v-col> -->
                            <v-col cols="3" class="pa-2 pt-3 pb-0">
                                <v-autocomplete clearable autocomplete :items="layoutList" item-text="object_name" item-value="object_id" 
                                v-model="layoutId" label="Select Layout" dense hide-details
                                :disabled="disabledLayoutSelection" @input="onLayoutSelectionChange"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="pa-2 pb-0">
                                <vc-button itemText="Save" @click.native="saveDashboard" :disabled="disabledSave"></vc-button>
                                <vc-button itemText="Reset" :disabled="isDefaultDashboard" @click.native="showConfirmation"></vc-button>
                            </v-col>
                            <!-- <v-col cols="3" class="pa-2 pt-0 pb-0">
                                {{ widgetSlotMapping }}
                            </v-col> -->
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mt-2 mb-1"/>
                <v-row class="EDC-Row">
                    <v-col cols="3" class="pa-2">
                        <v-card class="dynamic-height-100 pa-2">
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                    <v-text-field outlined label="Search Widgets Here" append-icon="search" 
                                    dense hide-details v-model="widgetSearchTxt"
                                    clearable></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                        <v-list class="overflow-y-auto style-2scroll" max-height="400">
                                            <draggable v-model="searchableWidgetList" :sortable="false" 
                                            @start="onDragStart" @end="onEnd" class="draggable-list" 
                                            :options="{ handle: '.draggable-task' }"  :move="checkMove" >
                                                <template v-for="(item, index) in searchableWidgetList">
                                                    <v-list-item :key="'divider'+index" v-if="item.type === 'divider'" dense
                                                    style="padding-left:0px !important">
                                                        <v-subheader class="custom-subheader">{{ item.widget_type_name }}</v-subheader>
                                                    </v-list-item>
                                                    <v-list-item v-else
                                                    :key="item.object_id+index"
                                                    class="draggable-list-item justify-start draggable-task" dense
                                                    style="padding-left:0px !important">
                                                        <v-list-item-action class="pa-0 ma-1">
                                                            <v-icon>mdi-drag</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ item.object_name }}</v-list-item-title>
                                                        </v-list-item-content>
                                                        
                                                    </v-list-item>
                                                </template>
                                            </draggable>

                                        </v-list>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col :class="{'pa-1':componentName,'pa-2':!componentName}" cols="9">
                        <component :is="componentName" v-if="componentName" 
                        @onLayoutChange="widgetSlotMapping=$event"
                        :definedWidgets="savedWidgetList" :dashboardId="dashboardId"
                        :key="'key'+keyId" :isDesigner="true"/>
                        <v-card class="dynamic-height-100" v-else>
                            <!-- Just placeholder -->
                        </v-card>
                    </v-col>
                </v-row>
                <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
                <loading-panel :loader="loader"></loading-panel>
                <simplert :useRadius="true" :useIcon="true" ref="simplert">
                </simplert>
            </v-card>
        </v-container>
    </div>
</template>
<style scoped>
    .draggable-list {
        width: 100%;
    }

    .draggable-list-item {
        cursor: grab;
    }

    .widget-list{
        height: 10vh !important;
    }

    .custom-subheader {
        font-weight: bold;            /* Make the subheader text bold */
        padding: 4px;                /* Add padding */
        font-size: 12px;              /* Adjust font size */
        text-transform: uppercase;    /* Capitalize the text */
        height: 24px !important;
        padding-top: 8px !important;
    }
</style>
<script>
    import { getEnvListForDataviewer} from '../methods/EnvironmentList.js'
    import { post as postToServer } from '../methods/serverCall.js';
    import { get as getToServer } from '../methods/serverCall.js';
    import config from '../config.json'
    import draggable from 'vuedraggable'
    import {mapActions} from 'vuex'
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import {getDashboardOfUser} from "../methods/layoutFunc.js"
    import Simplert from 'vue2-simplert'
    export default{
        name:"DashboardDesigner",
        components:{
            draggable,
            'simplert':Simplert
        },
        data(){
            return{
                Environment: {},
                EnvironmentList:[],
                dashboardName:"Untitled Dashboard",
                description:"",
                layoutList:[],
                layoutId:"",
                widgetListObject:{},
                widgetSearchTxt:'',
                searchableWidgetList:[],
                widgetSlotMapping:[],
                dashboardId:"",
                snackbar:false,
                snackbartext:'',
                colorValue:'error',
                snackbartimeout: Snackbar_Default_Timeout,
                loader: false,
                isDefaultDashboard:false,
                savedWidgetList:[],
                keyId:1
            }
        },
        computed:{
            layoutObject(){
                return _.find(this.layoutList,["object_id",this.layoutId]) || {}
                
            },
            componentName(){
                if(!this.layoutId)
                    return ""

                return this.layoutObject.component_name || ''
            },
            userName(){
                return this.$session.get("email")
            },
            clientId(){
                return this.$session.get("client_id")
            },
            layoutName(){
                return this.layoutObject.object_name
            },
            disabledSave(){
                return !this.widgetSlotMapping.length && !this.dashboardId && this.Environment.id >0
            },
            disabledLayoutSelection(){
                return this.dashboardId!="" && this.layoutId!="" && !this.isDefaultDashboard
            },
            widgetList(){
                let widgetList = []
                console.log("this.widgetListObject",this.widgetListObject)
                if(this.widgetListObject.UWGT && this.widgetListObject.UWGT.widgets &&this.widgetListObject.UWGT.widgets.length){
                    widgetList = [...[{"type":"divider","widget_type_name":this.widgetListObject.UWGT.widget_type_name}],...this.widgetListObject.UWGT.widgets]
                }
                    
                if(this.widgetListObject.PWGT && this.widgetListObject.PWGT.widgets &&this.widgetListObject.PWGT.widgets.length){
                    widgetList = [...widgetList,...[{"type":"divider","widget_type_name":this.widgetListObject.PWGT.widget_type_name}]]
                    widgetList =  [...widgetList, ...this.widgetListObject.PWGT.widgets]
                }
                
                // console.log("widgetList",widgetList)
                return widgetList
            }
        },
        watch:{
            'widgetSearchTxt':{
                handler(newValue){
                    this.performSearch()
                }
            },
            'EnvironmentList':{
                handler(newValue){
                    if(_.isEmpty(this.$session.get('selected_env'))){
                        this.Environment = newValue[0]
                        this.$session.set(newValue[0])
                        this.getDashboardOfUser()
                    }
                }
            }
        },
        mounted() {
            document.documentElement.style.setProperty('--layout-main-height', '68vh')
            getEnvListForDataviewer(this,false)
            
            this.Environment = this.$session.get('selected_env')
            this.getObjectList(config.REPOSITORY_API_URL+"/get_all_layouts",'layoutList')
            this.getObjectList(config.REPOSITORY_API_URL+"/get_all_widgets",'widgetListObject')
            this.getDashboardOfUser()
        },
        methods:{
            ...mapActions(['setDraggedWidget']),
            // Triggered when the dragging ends
            onEnd(event) {
                // console.log("List reordered!", event);
            },
            checkMove() {
                return false;
            },
            onDragStart(event){
                if(event && event.oldDraggableIndex >-1){
                    let currentItem = _.cloneDeep(this.searchableWidgetList[event.oldDraggableIndex])
                    currentItem.isDragged = true
                    // console.log("currentItem",currentItem)
                    this.setDraggedWidget(currentItem)
                }
            },
            
            getObjectList(url,controlToBind){
                var _this = this
                getToServer(_this,url).then(response=>{
                    this[controlToBind] = response
                    this.performSearch()
                }).catch(error=>{

                })
            },
            performSearch(){
                var _this = this
                this.searchableWidgetList = this.widgetList.filter((obj)=>!_this.widgetSearchTxt ||  (obj.object_name && obj.object_name.toLowerCase().includes(this.widgetSearchTxt.toLowerCase())))
            },
            saveDashboard(){
                var _this = this
                var data = {
                    "username":this.userName,
                    "client_id": this.clientId,
                    "env_id":this.Environment.id,
                    "layout_id":this.layoutId,
                    "layout_name":this.layoutName,
                    "widgets":this.widgetSlotMapping,
                    "dashboard_id":this.dashboardId,
                    "is_default":this.isDefaultDashboard
                }
                console.log(JSON.stringify(data))
                var url = config.USER_PROVISION_URL + "/manage_user_dashboard"
                // console.log("data=>",data)
                this.loader = true
                postToServer(_this, url,data).then(response=>{
                    _this.dashboardId = response.dashboard_id
                    this.isDefaultDashboard = false
                    this.loader = false
                }).catch(error=>{
                    this.loader = false
                    alert(error)
                })
            },
            getDashboardOfUser(){
                var _this = this
                _this.dashboardId = ""
                _this.layoutId = ""
                this.keyId++
                if(_.isEmpty(_this.Environment))
                    return
                this.loader = true
                getDashboardOfUser(_this,_this.Environment).then(response=>{
                    this.dashboardId = response.dashboard_id
                    this.isDefaultDashboard = response.is_default || false
                    this.layoutId = response.layout_id
                    _this.loader = false
                    _this.savedWidgetList = response.widgets
                }).catch(error=>{
                    this.loader = false
                    alert(error)
                })
            },
            showConfirmation(){
                var _this = this
                let confirmFn = function () {
					_this.resetDashboard()
				}
				let obj = {
					title: 'Confirmation',
					message: "Are you sure you want to reset your current design?",
					type: 'info',
					useConfirmBtn: true,
					onConfirm: confirmFn,
					customCloseBtnText:'No',
					customConfirmBtnText:'Yes'
				}
				this.$refs.simplert.openSimplert(obj)
            },
            resetDashboard(){
                var _this = this
                var data = {
                    "env_id":this.Environment.id,
                    "username":this.userName,
                    "dashboard_id":this.dashboardId
                }
                _this.loader = true
                postToServer(_this,config.USER_PROVISION_URL+"/reset_user_dashboard",data).then(response=>{
                    this.savedWidgetList = []
                    this.dashboardId = ""
                    _this.getDashboardOfUser()
                }).catch(error=>{
                    _this.loader = false
                    alert(error)
                })
            },
            onLayoutSelectionChange(){                
                this.keyId++
                this.savedWidgetList = []
                this.dashboardId = ""
            }
        }
    }
</script>