<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">

                <v-row class="EDC-Row">
                    <v-col cols="8" class="EDC-Col">
                        <v-row class="EDC-Row breadcrumbRow">
                        <v-col cols="4" class="EDC-Col">
                            <ul class="breadcrumb breadcrumbUL">
                            <li>
                                <router-link to="/usergrouplist">User Groups</router-link>
                            </li>
                            <li>Manage User Group</li>
                            </ul>
                        </v-col>
                        <v-col cols="8"></v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4"></v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col>
                        <v-card elevation-1 style="padding:16px !important">
                            <v-row no-gutters align="center" class="EDC-Row" style="padding-top:10px !important">
                                <v-col cols="12">
                                    <v-row no-gutters align="center" class="EDC-Row EDC-RowsSpacing">
                                        <v-col cols="4" class="EDC-Col EDC-ColsSpacing" style="padding-right:2% !important;">
                                            <v-text-field v-model="groupName" label="Group Name" required autocomplete="off" dense></v-text-field>
                                        </v-col>
                                        <v-col cols="5" class="EDC-Col EDC-ColsSpacing" style="padding-left:4% !important;padding-right:6% !important;">
                                            <v-text-field v-model="groupDescription" label="Group Description" autocomplete="off" dense></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters align="center" class="EDC-Row EDC-RowSpacing">
                                        <v-col class="EDC-Col" cols="9">
                                            <v-row class="EDC-Row">
                                                <v-col class="EDC-Col" cols="5">
                                                    <v-card class="list-card">
                                                        <v-row class="EDC-Row">
                                                            <v-col class="EDC-Row" cols="12">
                                                                <edc-toolbar class="EDC-Custom-Toolbar" key="available_users_toolbar" customLabel="Search In Available Users" usedFor="list" bindWithElement="userList" @perform-serach="performSearch" :clearTooltipIndex="clearTooltipIndex"></edc-toolbar>
                                                            </v-col>
                                                        </v-row>
                                                        <v-list dense id="userList" class="EDC-list-for-columns overflow-y-auto style-2scroll" height="320">
                                                            <draggable v-model="userList" @start="dragListStart($event,userList,'source')" :options="dragOptions" :move="onMove" @end="selectDragEnd" id="dragUserList" group="AA">
                                                                <v-list-item dense selectable v-for="(item, i) in userList" :key="i+'sourceusers'" :class="{'custom-list-item':true, 'selected-list-item':item.is_selected,'not-moved-item':!item.is_moved,'deselected-list-item':!item.is_selected}" @click="clickOnListItem($event,item,'sl',i,userList)" v-show="!item.is_moved" :title="item.fullname">
                                                                {{item.full_name_with_user_name}}
                                                                </v-list-item>
                                                            </draggable>
                                                        </v-list>
                                                        <v-row class="EDC-Row" style="padding-top:8px !important;">
                                                            <v-col cols="6" class="EDC-Col" align="center">
                                                            </v-col>
                                                            <v-col cols="6" class="EDC-Col" align="right" style="padding-right: 8px !important;">
                                                                Total Available Users: {{userList.filter(item => item.is_moved === false).length}}
                                                            </v-col>
                                                        </v-row>   
                                                    </v-card>
                                                </v-col>
                                                <v-col class="EDC-Col" cols="1" align-self="center">
                                                    <vc-button  itemText=">>" :disabled="!isSourceSelected" @click.native="moveSourceToTarget"></vc-button>
                                                    <vc-button  itemText="<<" :disabled="!isTargetSelected" @click.native="moveTargetToSource"></vc-button>
                                                </v-col>
                                                <v-col class="EDC-Col" cols="5">
                                                    <v-card class="list-card" id="selectedUserCard">
                                                        <edc-toolbar class="EDC-Custom-Toolbar" key="selected_columns_toolbar" customLabel="Search In Selected Users" usedFor="list" bindWithElement="selectedUsers" @perform-serach="performSearch" :clearTooltipIndex="clearTooltipIndex"></edc-toolbar>
                                                        <v-list dense id="selectedUsers" class="EDC-list-for-columns overflow-y-auto style-2scroll" height="320">
                                                            <draggable v-model="selectedUsers" @start="dragListStart($event,selectedUsers,'target')" :options="dragOptions" :move="onMove"  id="dragSelectedUsers"  @end="selectDragEnd" group="BB">
                                                                <v-list-item dense selectable v-for="(item, i) in selectedUsers" :key="i+'targetcolumns'" :class="{'custom-list-item':true, 'selected-list-item':item.is_selected,'not-moved-item':item.username,'deselected-list-item':!item.is_selected}">
                                                                    <v-col class="EDC-Col" @click="clickOnListItem($event,item,'tl',i,selectedUsers)" :title="item.fullname">
                                                                        {{item.full_name_with_user_name}}
                                                                    </v-col>
                                                                </v-list-item>
                                                            </draggable>
                                                        </v-list>
                                                        <v-row class="EDC-Row" style="padding-top:8px !important;">
                                                            <v-col cols="7" class="EDC-Col" align="center">
                                                            </v-col>
                                                            <v-col cols="5" class="EDC-Col" align="right" style="padding-right: 8px !important;">
                                                            Total Selected Users: {{selectedUsers.length}}
                                                            </v-col>
                                                        </v-row>  
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters align="center" class="EDC-Row EDC-RowSpacing">
                                        <v-col class="EDC-Col" cols="9" align="right" style="padding-right:6% !important;">
                                            <vc-button  v-if="!read_only_mode" itemText="Save"  @click.native="saveGroup"></vc-button>
                                            <vc-button  itemText="Cancel"  @click.native="cancel"></vc-button>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbarTimeout" id="edcmessage" top right>
                {{ snackbarText }}
            </v-snackbar>
            <loading-panel :loader="loader"></loading-panel>
        </v-row>
    </v-container>
</template>
<style scoped>
    .v-text-field.v-text-field--solo .v-input__control{
        min-height: 20px;
    }
    #adduser .v-input--checkbox, label {
        margin-top:2%;
    }
    .EDC-UIComponent{
        margin: 0px !important;
        padding: 0px !important;
    }
    .customCheckbox >>> label{
        top: 5px !important;
    }

    .not-moved-item {
        /* empty class for hide col perpose*/
    }

    .custom-list-item {
        margin-bottom: 0px !important;
        color:#000000;
        cursor:pointer !important;
    }

    .v-list-item {
        padding-left:16px !important;
        padding-top:0px !important;
        padding-right:8px !important;
        padding-bottom:0px !important;
    }

    .selected-list-item {
        background-color:var(--v-secondary-base) !important;
    }

    .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):not(.deselected-list-item) {
        color:white !important;
    }

    .EDC-Toolbar >>> .v-sheet {
        border-radius: 0px !important;
    }

    .EDC-Custom-Toolbar {
        padding:0px !important;
    }
    .v-list-item--dense, .v-list--dense .v-list-item {
        min-height: 16px important;
    }

    .list-card{
	    margin-top:2% !important;
	    margin-bottom:2% !important;
	    height:90% !important;
    }
</style>
<script>
	import draggable from 'vuedraggable'
    import { post as postToServer } from './../../methods/serverCall.js';
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import edctoolbar from './../../views/edctoolbar.vue'
    import config from '../../config.json'
    import { GET_ALL_USERS_LIST} from '../../data/url_constants.js'

    export default {
        components:{
            draggable,
            'edc-toolbar':edctoolbar,
        },
        data(){
            return {
                groupName:'Untitled Group',
                groupDescription:'',
                groupId:'',
                userList:[],
                selectedUsers:[],
                snackbar: false,
                snackbarText: "",
                colorValue: "error",
                loader: false,
                snackbarTimeout: Snackbar_Default_Timeout,
                userRole:{},
                dragStartBy:'',
                clearTooltipIndex:0,
                lastSelectedItem:{},
                action_type: '',
                read_only_mode: false

            }
        },
        computed: {
            dragOptions(){
			    return  {
			      animation: 0,
			      group: 'description',
			    };
			},
            isSourceSelected(){
				if(_.find(this.userList,['is_selected',true]))
					return true
				return false
			},
			isTargetSelected(){
				if(_.find(this.selectedUsers,function(obj){
					return obj && obj.is_selected
				}))
					return true
				return false
			},
        },
        mounted(){
            this.read_only_mode = this.$route.query.read_only_mode
            this.clearTooltipIndex++
            this.userRole = this.$session.get('user_role_mapping');
            this.getAllUsers()
            if(this.$route.query.groupid){
                this.getGroupDetails(this.$route.query.groupid)
                if(this.$route.query.type === 'edit')
                    this.groupId =this.$route.query.groupid
                
                this.action_type = this.$route.query.type
                if(this.action_type === 'copy')
                    this.groupName = "Copy of " + this.$route.query.groupname
            }

            var _this = this
            var htmlSelectedUsers = document.getElementById("selectedUsers");
            htmlSelectedUsers.addEventListener("drop", dropOnTarget);
            htmlSelectedUsers.addEventListener("dragover", dragOverTarget);
            function dropOnTarget(event){
                if(_this.dragStartBy === 'source')
                    _this.moveSourceToTarget()
                else
                    _this.clearSelectedUsers(_this.selectedUsers)
            }
            function dragOverTarget(event){
                if(_this.dragStartBy === 'source')
                    event.preventDefault()
            }

            var htmlUserList = document.getElementById("userList");
            htmlUserList.addEventListener("drop", dropOnSource);
            htmlUserList.addEventListener("dragover", dragOverSource);
            function dropOnSource(event){
                if(_this.dragStartBy === 'target')
                    _this.moveTargetToSource()
                else
                    _this.clearSelectedUsers(_this.userList)
            }
            function dragOverSource(event){
                if(_this.dragStartBy === 'target')
                    event.preventDefault()
            } 
            
        },
        methods:{
            showErrorMessage(errorMessage){
                this.loader = false;                  
                this.colorValue = 'error'
                this.snackbarText = errorMessage || 'Unhandled exception'; 
                this.snackbar = true                   
            },
            getAllUsers() {
                var _this =this;
                if(_this.userList.length)
                    return
                var client_id = _this.$session.get('client_id');
                var data = {"client_id": client_id,
                            "product_name" :window.sessionStorage.getItem('product')}
                var get_user_url = config.Security_URL + GET_ALL_USERS_LIST
                postToServer(_this, get_user_url, data).then(response => {
                    response.unshift({
                        "user_id":0,
                        "username":"ALL USERS",
                        "full_name_with_user_name":"ALL USERS"
                    })
                    _.forEach(response,(obj)=>{
                        obj.is_selected = false
                        obj.is_moved = false
                    })
                    _this.userList = response;
                    this.manageUserList()
                }).catch(errorResponse => {
                    _this.showErrorMessage(errorResponse)
                }); 
            },
            moveSourceToTarget(){
                if(!_.isEmpty(_.find(this.selectedUsers, ["user_id",0]))){
                    alert('All Users already selected')
                    return
                }
                var selectedItems = _.filter(this.userList,function(obj){
    				if(obj.is_selected){
    					obj.is_moved = true
                    }
    				return obj.is_selected
    			})
    			this.selectedUsers = _.uniqBy(_.union(this.selectedUsers,_.cloneDeep(selectedItems)),"full_name_with_user_name")
                _.forEach(this.selectedUsers,(obj)=>{
                    obj.is_selected = false
                })

                _.forEach(selectedItems,(obj)=>{
                    obj.is_selected = false
                })

                if((!_.isEmpty(_.find(this.selectedUsers, ["user_id",0]))) && this.selectedUsers.length > 1){
                    _.forEach(this.selectedUsers,(obj)=>{
                        if(obj.user_id != 0)
                            obj.is_selected = true
                    })
                    this.moveTargetToSource()
                }

            },
            moveTargetToSource(){
                let _this = this
    			var selectedItems = _.remove(this.selectedUsers,function(obj){
    				return obj.is_selected 
    			})
    			_.forEach(selectedItems,function(obj){
    				let sourceObj = _.find(_this.userList,['full_name_with_user_name',obj.full_name_with_user_name])
    				if(sourceObj){
    					sourceObj.is_moved = false
    					obj.is_selected = false
    				}
    			})

                _.forEach(this.userList,(obj)=>{
                    obj.is_selected = false
                })
            },
            getGroupDetails(groupId){
                var _this = this
                var client_id = this.$session.get('client_id');
                var data = {
                    "client_id": client_id,
                    "group_id": groupId,
                    }
                var url = config.USER_PROVISION_URL + "/get_group_details_by_id";
                postToServer(this, url, data).then(response => {
                    if(this.action_type != 'copy'){
                        this.groupName = response.group_name
                    }
                    this.groupDescription = response.description
                    _.forEach(response.user_list,(obj)=>{
                        obj.is_selected =false
                        obj.is_moved =false
                    })
                    this.selectedUsers = response.user_list
                     _this.manageUserList()
                })
                .catch(errorResponse => {
                    _this.showErrorMessage(errorResponse)
                });

            },
            manageUserList(){
                var _this = this
                _.forEach(_this.userList, (obj)=>{
                    let isExists = _.find(this.selectedUsers,["full_name_with_user_name", obj.full_name_with_user_name])
                    if(isExists)
                        obj.is_moved = true
                    else{
                        let isExists = _.find(this.selectedUsers,["username", obj.username])
                        if(isExists){
                            isExists.full_name_with_user_name = isExists.username
                            obj.is_moved = true
                        }
                    }
                })
            },
            performSearch(searchText,listid){
                var columnList = document.getElementById(listid)
                var list_items = columnList.getElementsByClassName('not-moved-item')
                var filter = searchText.toUpperCase()
                for(var i = 0; i < list_items.length; i++){
                    if (list_items[i].innerText.trim().toUpperCase().indexOf(filter) > -1) 
                      list_items[i].style.display = "";
                    else
                      list_items[i].style.display = "none";
                }
            },
            dragListStart(event,eventInitiaterList,dragStartBy){
                // here we are identifing the column on which user has start the dragging -> event.oldDraggableIndex return the list index.So we are selecting that item. If user drop that on target list, automatically onTargetDrop() function from mounted will get called.
                this.dragStartBy = dragStartBy
                if(event && event.oldDraggableIndex > -1 && eventInitiaterList[event.oldDraggableIndex])
                    eventInitiaterList[event.oldDraggableIndex].is_selected = true
            },
            selectDragEnd(event){
                return
            },
            clearSelectedUsers(columnList){
                // on drag start is performing item.is_selected = true. If user change the item order inside the same list then that item remain selected
                _.forEach(columnList,(obj)=>{
                    obj.is_selected = false
                })
            },
            clickOnListItem(event,item,itemBelongTo,itemIndex,itemList){
                /*Item belong to
                    sl - source list
                    tl - target list
                    osl - order source list
                    otl - order target list
                */
                let _this = this
				item.is_selected = !item.is_selected
                if(!item.is_selected)
                {
                    delete _this.lastSelectedItem[itemBelongTo]
                    return
                }
                /*
                    **Multiselect functionality implementation.There has to all below assumptions should be TRUE
                        1) There has to be event object and event.shiftKey = True
                        2) Last selected item for that list, should be selected true
                        3) itemList length should be > 1
                
                    **Logic
                        If above assumptions are true , then make select all columns which come between current and last selected. For traversing, go from lower to upper index.
                */

                if(event && event.shiftKey && itemList.length > 1 && _this.lastSelectedItem[itemBelongTo]>=0){
                    let lowerIndex = _this.lastSelectedItem[itemBelongTo]
                    let upperIndex = itemIndex
                    if(lowerIndex > upperIndex){
                        lowerIndex = itemIndex
                        upperIndex = _this.lastSelectedItem[itemBelongTo]
                    }
                    for(let i=lowerIndex;i<=upperIndex;i++)
                        itemList[i].is_selected = true
                }
                _this.lastSelectedItem[itemBelongTo] = itemIndex
			},
            onMove ({relatedContext, draggedContext}) {

            },
            saveGroup(record){
                var _this = this
                var client_id = this.$session.get('client_id');
                let rowData = this.selectedUsers
                var userList = []
                _.forEach(this.selectedUsers,function(obj){
                        userList.push({"id":obj.user_id,
                                    "username":obj.username})
                    })

                var data = {
                    "client_id": client_id,
                    "group_name": this.groupName,
                    "description": this.groupDescription,
                    "user_list" :userList,
                    }
                if(!this.groupId)
                    var url = config.USER_PROVISION_URL + "/create_group";
                else{
                    var url = config.USER_PROVISION_URL + "/update_group";
                    data["group_id"] = this.groupId
                }
                postToServer(this, url, data).then(response => {
                    this.snackbar = true;
                    this.colorValue = "success";
                    if(response.id)
                    this.group_id = response.id
                    this.snackbarText = "User Group save Successfully";
                    
                    this.$router.push("/usergrouplist")
                })
                .catch(errorResponse => {
                    _this.showErrorMessage(errorResponse)
                });

            },
            cancel(){
                this.$router.push("/usergrouplist")
            }
        }
    }
</script>