export const CLIENT_SIDE = 'client_side';
export const SERVER_SIDE = 'server_side';
export const PER_PAGE = 10;
export const PER_PAGE_ARRAY = [10,20, 50, 'all'];
export const COLOR_CODE = '#323232';
export const BTN_COLOR = 'indigo';
export const CHECKBOX_COLOR='black';

// service id
export const DATASOURCE_SERVICE_ID = 'datasource'
export const ENVIRONMENT_SERVICE_ID = 'environment_setting_service'
export const PUBLISH_SERVICE_ID = 'publisher_service'
export const REPOSITORY_SERVICE_ID = 'repository_service'
export const USER_PROVISIONING_SERVICE_ID = 'user_provisioning'
export const POLICY_SERVICE = 'policy_service'
export const SCHEDULER_SERVICE = 'scheduler'
export const ACCOUNT_MANAGEMENT_SERVICE = 'account_management'
export const SECURITY_SERVICE = 'security_role_service'
export const JOB_MANAGEMENT_SERVICE = 'engine_service'
export const JOB_CALENDAR_SERVICE = 'job_calendar'
export const JOB_PLAN_SERVICE_ID = 'job_plan_service'
export const JDE_FORMATTER_SERVICE_ID = 'jde_formatter'
export const EDC_CACHE_SERVICE = 'edc_cache_service'
export const PACKAGE_BUILDER = 'package_builder'


//actions
export const CREATE_ACTION = 'create'
export const UPDATE_ACTION = 'update'
export const DELETE_ACTION = 'delete'
export const READ_ACTION = 'read'
export const PUBLISH_ACTION = 'publish'
export const UNPUBLISH_ACTION = 'unpublish'
export const SCHEDULE_ACTION = 'schedule'
export const EXECUTE_ACTION ='execute'


export const EDC_GRID_PROPS = {
      "filter": [],
      "filter_data":[],
      "sort": [],
      "page": 1,
      "page_size": 10
    }

  
// GROUPS 
export const MANAGE_USER = 'MANAGE_USER'
export const MANAGE_USER_GROUP = 'MANAGE_USER_GROUP'
export const MNG_CLIENT_SETTING = 'MNG_CLIENT_SETTING'
export const MNG_SMTP_SETTING = 'MNG_SMTP_SETTING'
export const MNG_INSTANCE_SETTING = 'MNG_INSTANCE_SETTING'
export const MNG_LDAP_SETTING = 'MNG_LDAP_SETTING'
export const DATASOURCE_MGMT = 'DATASOURCE_MGMT'
export const MANAGE_DB_LINK = 'MANAGE_DB_LINK'
export const ENVIRONMENT_MGMT = 'ENVIRONMENT_MGMT'
export const MANAGE_ADMINISTRATIVE_SECURITY = 'MANAGE_ADMINISTRATIVE_SECURITY'
export const MANAGE_ENVIRONMENTAL_SECURITY = 'MANAGE_ENVIRONMENTAL_SECURITY'
export const ERP_ACCELERATOR_MGMT = 'ERP_ACCELERATOR_MGMT'
export const MANAGE_PACKAGES = 'MANAGE_PACKAGES'
export const MANAGE_ACTION_BASED_SECURITY = 'MANAGE_ACTION_BASED_SECURITY'
export const MANAGE_OBJ_BASED_SECURITY = 'MANAGE_OBJ_BASED_SECURITY'
export const MANAGE_ROLE_MAPPING = 'MANAGE_ROLE_MAPPING'
export const MANAGE_BO = 'MANAGE_BO'
export const MANAGE_BV = 'MANAGE_BV'
export const DEFAULT_MANAGE_OBJECT = 'DEFAULT_MANAGE_OBJECT'
export const MANAGE_DM = 'MANAGE_DM'
export const MANAGE_GV = 'MANAGE_GV'
export const MANAGE_TL = 'MANAGE_TL'
export const MANAGE_OBJECT_PUBLISH_RULE = 'MANAGE_OBJECT_PUBLISH_RULE'
export const DEFAULT_MANAGE_RULE = 'DEFAULT_MANAGE_RULE'
export const MANAGE_OBJECT_PUBLISH_USING_DS_RULE = 'MANAGE_OBJECT_PUBLISH_USING_DS_RULE'
export const CLEAN_TABLE_CACHE = 'CLEAN_TABLE_CACHE'
export const MANAGE_USER_ENV_MAPPING = 'MANAGE_USER_ENV_MAPPING'
export const MANAGE_REPOSITORY  = 'MANAGE_REPOSITORY'
export const DATASOURCE_MGMT_DEFAULT = 'DATASOURCE_MGMT_DEFAULT'
export const ENVIRONMENT_MGMT_DEFAULT = 'ENVIRONMENT_MGMT_DEFAULT'
export const ACCELERATOR_MGMT_DEFAULT = 'ACCELERATOR_MGMT_DEFAULT'
export const MANAGE_ADMIN_SECURITY = 'MANAGE_ADMIN_SECURITY'
export const PUBLISH_HISTORY = 'PUBLISH_HISTORY'







