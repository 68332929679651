import { post as postToServer } from './serverCall.js';
import {get as getToServer } from './serverCall.js';
import config from '../config.json'
import { SERVER_ERROR } from '../data/client_message.js'
import {
    GET_TABLES,
    GET_ALL_COLUMN,
    GET_STORED_PROCEDURE_LIST,
    GET_STORED_PROCEDURE_DETAILS,
    GET_BULK_TABLES_COLUMNS_URL,
    GET_ACCELERATION_DETAILS,
} from '../data/url_constants.js'
import { DESTINATION_QUEUE } from '../data/agent_constant.js'
import { sessioncheck, destroy_session } from './../methods/user_session.js';
import sizeof from 'object-sizeof'
import _ from 'lodash'


export function getTablesByDVEnv(that,request_data){
    sessioncheck(that)   
    let tableUrl = config.AGENT_API_URL + '/get_tables_by_dataviewer_env';
    return new Promise(function(resolve, reject) {
        postToServer(that, tableUrl, request_data).then(tableResponse => {
            return resolve(tableResponse)
        }).catch(errorResponse => {
            return reject(errorResponse)
        });
    });
}


export function getTableList(that, env_id, connection_str, schema, db_type, 
    table_search_string, tbl_object_type,kwargs) {
    sessioncheck(that)
    var mapped_list = []
    if(that.$session.get('mapped_agent_details'))
        mapped_list = that.$session.get('mapped_agent_details')
    var mapped_agent_obj= _.find(mapped_list,['conn_str',connection_str])

    var object_librarian_details = []
    if(that.$session.get('object_librarian_details'))
        object_librarian_details = that.$session.get('object_librarian_details')

    var object_librarian_obj = _.find(object_librarian_details,['conn_str',connection_str])

    if(!object_librarian_obj && kwargs.object_librarian_data)
        object_librarian_obj = kwargs.object_librarian_data

    var mapped_agent_id = ''
    if(mapped_agent_obj)
        mapped_agent_id = mapped_agent_obj.mapped_agent_id

    if(!mapped_agent_id && kwargs.mapped_agent_id)
        mapped_agent_id = kwargs.mapped_agent_id

    var inputJson = {
        "table_name": table_search_string,
        "table_count": "",
        "env_id": env_id,
        "client_id": that.$session.get('client_id'),
        "conn_str": connection_str,
        "schema": schema,
        "db_type": db_type,
        "object_type": tbl_object_type,
        "mapped_agent_id":mapped_agent_id,
        "ds_id":that.datasource_id
    }


    if(kwargs){
        if(kwargs.description_needed)
            inputJson["description_needed"] = true
        if(kwargs.check_in_description)
            inputJson["check_in_description"] = true
        if(kwargs.object_type)
            inputJson["object_type"] = kwargs.object_type
        Object.assign(inputJson,kwargs)
    }

    if (object_librarian_obj)
    {
        inputJson["object_librarian_schema"] = object_librarian_obj.object_librarian_schema
        inputJson["object_librarian_conn_str"] = object_librarian_obj.object_librarian_conn_str
        inputJson["object_librarian_id"] = object_librarian_obj.object_librarian_id
    }

    if(that.run_on_target){
        inputJson["conn_str"] = that.target_connection_str
        inputJson["schema"] = that.target_schema
        inputJson["ds_id"] = that.target_datasource_id
    }

    let tableUrl = config.AGENT_API_URL + GET_TABLES;
    return new Promise(function(resolve, reject) {
        let key = env_id + '_tableResponse';
        postToServer(that, tableUrl, inputJson).then(tableResponse => {
            if(!mapped_agent_id){
                mapped_agent_id = tableResponse.mapped_agent_id
                if (mapped_agent_id)
                {
                mapped_list.push({'conn_str': tableResponse.conn_str ,'mapped_agent_id':mapped_agent_id})
                that.$session.set('mapped_agent_details',mapped_list)
                }
                if(tableResponse.object_librarian_conn_str && !object_librarian_obj){
                    object_librarian_details.push({
                       'conn_str': tableResponse.conn_str,
                    'object_librarian_schema':tableResponse.object_librarian_schema,
                    'object_librarian_conn_str':tableResponse.object_librarian_conn_str,
                    'object_librarian_id':tableResponse.object_librarian_id
                    })
                }
                // console.log('target',tableResponse.target_conn_str)
                that.datasource_id = tableResponse.source_datasource_id
                that.target_connection_str = tableResponse.target_conn_str
                that.target_schema = tableResponse.target_schema
                that.target_datasource_id = tableResponse.target_datasource_id
                that.$session.set('object_librarian_details',object_librarian_details)
            }
            return resolve(tableResponse)
        }).catch(errorResponse => {
            return reject(errorResponse)
        });
    });
}

export function getStoredProcedureList(that, env_id, procedure_search_string) {
    sessioncheck(that)
    var mapped_agent_id = that.$session.get('selected_env').mapped_agent_id
    var inputJson = {
        "table_name": procedure_search_string,
        "table_count": "",
        "env_id": env_id,
        "client_id": that.$session.get('client_id'),
         "mapped_agent_id":mapped_agent_id,
         "ds_id":that.datasource_id,
         "run_on_target":that.run_on_target
    }

    if(that.run_on_target){
        inputJson["conn_str"] = that.target_connection_str
        inputJson["schema"] = that.target_schema
        inputJson["ds_id"] = that.target_datasource_id
    }


    let tableUrl = config.AGENT_API_URL + GET_STORED_PROCEDURE_LIST;
    return new Promise(function(resolve, reject) {
        let key = env_id + '_procedureList';
        postToServer(that, tableUrl, inputJson).then(tableResponse => {
            return resolve(tableResponse)
        }).catch(errorResponse => {
            return reject(errorResponse)
        });
    });
}

export function getStoredProcedureDetails(that, procedure_name, env_id) {
    sessioncheck(that)
    var mapped_agent_id = that.$session.get('selected_env').mapped_agent_id
    var inputJson = {
        "table_name": "",
        "table_count": "",
        "env_id": env_id,
        "client_id": that.$session.get('client_id'),
        "procedure_name": procedure_name,
         "mapped_agent_id":mapped_agent_id,
         "ds_id":that.datasource_id,
         "run_on_target":that.run_on_target
    }
    let tableUrl = config.AGENT_API_URL + GET_STORED_PROCEDURE_DETAILS;

    if(that.run_on_target){
        inputJson["conn_str"] = that.target_connection_str
        inputJson["schema"] = that.target_schema
        inputJson["ds_id"] = that.target_datasource_id
    }


    return new Promise(function(resolve, reject) {
        let key = env_id + '_' + procedure_name;
        /**
         * Cache Check point
         */
        that.$store.state.cacheData = isLargeMemory(sizeof(that.$store.state.cacheData)) ? [] : that.$store.state.cacheData;

        if (that.$store.state.cacheData[key]) {
            return resolve(that.$store.state.cacheData[key])
        }
        postToServer(that, tableUrl, inputJson).then(tableResponse => {
            let value = tableResponse;
            that.$store.dispatch('setCacheData', { 'key': key, 'value': value });
            return resolve(tableResponse)
        }).catch(errorResponse => {
            return reject(errorResponse)
        });
    });
}

export function getTableColumns(that, table_column_list, env_id, connection_str, schema, table_name, table_type) {
    sessioncheck(that)
    var mapped_list = []
    if(that.$session.get('mapped_agent_details'))
        mapped_list = that.$session.get('mapped_agent_details')
    var mapped_agent_obj= _.find(mapped_list,['conn_str',connection_str])
    var mapped_agent_id = ''
    if(mapped_agent_obj)
        mapped_agent_id = mapped_agent_obj.mapped_agent_id
    var tbl = table_name.replace(/(\r\n\t|\n|\r\t)/gm, "");
    var inputJson = {
        "conn_str": connection_str,
        "env_id": env_id,
        "schema": schema,
        "table_name": tbl,
        "table_type": table_type,
        "client_id": that.$session.get('client_id'),
        "db_type": that.db_type,
         "mapped_agent_id":mapped_agent_id,
         "ds_id":that.datasource_id
    }

    if(that.run_on_target){
        inputJson["conn_str"] = that.target_connection_str
        inputJson["schema"] = that.target_schema
        inputJson["ds_id"] = that.target_datasource_id
    }

    let tableColumnUrl = config.AGENT_API_URL + GET_ALL_COLUMN;
    return new Promise(function(resolve, reject) {
        let key = env_id + '_table_column_list';

        /**
         * Cache Check point
         */
        that.$store.state.cacheData = isLargeMemory(sizeof(that.$store.state.cacheData)) ? [] : that.$store.state.cacheData;

        if (!that.$store.state.cacheData[key]) {
            that.$store.state.cacheData[key] = [];
        }
        // debugger;
        for (var i = 0; i < that.$store.state.cacheData[key].length; i++) {
            if (that.$store.state.cacheData[key][i].table_name == table_name) {
                return resolve(that.$store.state.cacheData[key][i].table_column_list);
            }
        }
        postToServer(that, tableColumnUrl, inputJson).then(tableColumnResponse => {
            if (tableColumnResponse.length > 0) {
                that.$store.state.cacheData[key].push({
                    "table_name": table_name,
                    "table_column_list": tableColumnResponse
                })
            }
            if(!mapped_agent_id){
                mapped_agent_id = tableColumnResponse.mapped_agent_id
                if (mapped_agent_id)
                {
                    mapped_list.push({'conn_str': tableColumnResponse.conn_str ,'mapped_agent_id':mapped_agent_id})
                    that.$session.set('mapped_agent_details',mapped_list)
                }
            }
            return resolve(tableColumnResponse)
        }).catch(errorResponse => {
            return reject(errorResponse)
        });
    });
}


export function getColumnsWithDescription(that,kwargs){
    sessioncheck(that)
    var mapped_list = []
    if(that.$session.get('mapped_agent_details'))
        mapped_list = that.$session.get('mapped_agent_details')
    var mapped_agent_obj= _.find(mapped_list,['conn_str',kwargs.connection_str])
    var mapped_agent_id = ''
    if(mapped_agent_obj)
        mapped_agent_id = mapped_agent_obj.mapped_agent_id
    var tbl = kwargs.table_name.replace(/(\r\n\t|\n|\r\t)/gm, "");
    var inputJson = {
        "conn_str": kwargs.connection_str,
        "env_id": kwargs.env_id,
        "schema": kwargs.schema,
        "table_name": kwargs.table_name,
        "table_type": kwargs.table_type,
        "client_id": that.$session.get('client_id'),
        "db_type": that.db_type,
        "mapped_agent_id":mapped_agent_id,
        "description_needed":true,
        "ds_id":that.datasource_id
    }
    if(that.run_on_target){
        inputJson["conn_str"] = that.target_connection_str
        inputJson["schema"] = that.target_schema
        inputJson["ds_id"] = that.target_datasource_id
    }

    if(that.acceleratorObj){
        inputJson["acceleration_type"] = that.acceleratorObj.acceleration_type
        
        inputJson["dictionary_conn_str"] = that.acceleratorObj.dictionary_conn_str
        inputJson["dictionary_data_id"] = that.acceleratorObj.dictionary_data_id
        inputJson["dictionary_data_schema"] = that.acceleratorObj.dictionary_data_schema

        inputJson["object_librarian_conn_str"] = that.acceleratorObj.object_librarian_conn_str
        inputJson["object_librarian_id"] = that.acceleratorObj.object_librarian_id
        inputJson["object_librarian_schema"] = that.acceleratorObj.object_librarian_schema
    }


    let tableColumnUrl = config.AGENT_API_URL + GET_ALL_COLUMN;    
    return new Promise(function(resolve, reject) {
        let key =  kwargs.env_id + '_table_column_list';

        /**
         * Cache Check point
         */
        that.$store.state.cacheData = isLargeMemory(sizeof(that.$store.state.cacheData)) ? [] : that.$store.state.cacheData;

        if (!that.$store.state.cacheData[key]) {
            that.$store.state.cacheData[key] = [];
        }
        // debugger;
        for (var i = 0; i < that.$store.state.cacheData[key].length; i++) {
            if (that.$store.state.cacheData[key][i].table_name == kwargs.table_name && 
                that.$store.state.cacheData[key][i].table_column_list && 
                _.has(that.$store.state.cacheData[key][i],'table_column_descriptions')) {

                var return_json = {"column_list":that.$store.state.cacheData[key][i].table_column_list,
                                    "description":that.$store.state.cacheData[key][i].table_column_descriptions}

                return resolve(return_json);
            }
            // else if (that.$store.state.cacheData[key][i].table_name ==  kwargs.table_name) {
            //     return resolve(that.$store.state.cacheData[key][i].table_column_list);
            // }
        }
        postToServer(that, tableColumnUrl, inputJson).then(tableColumnResponse => {
            // to check response is list or object we cannot use build in function because microsoft 
            // age might not support it. So logic is this if table response contain the 
            // column list key means its an object else its any array 

            if(!tableColumnResponse.column_list){
                // means description not found.tableColumnResponse is an list of columns only. So create the json
                tableColumnResponse['column_list'] = tableColumnResponse
                tableColumnResponse['description'] = []
            }

            if (tableColumnResponse.column_list && tableColumnResponse.column_list.length > 0) {
                that.$store.state.cacheData[key].push({
                    "table_name": kwargs.table_name,
                    "table_column_list": tableColumnResponse.column_list,
                    "table_column_descriptions":tableColumnResponse.description
                })
            }
            else if (tableColumnResponse.length > 0){
                that.$store.state.cacheData[key].push({
                    "table_name": kwargs.table_name,
                    "table_column_list": tableColumnResponse,
                    "table_column_descriptions":[]
                })
            }
            // if(!mapped_agent_id){
            //     mapped_agent_id = tableColumnResponse.mapped_agent_id
            //     if (mapped_agent_id)
            //     {
            //         mapped_list.push({'conn_str': tableColumnResponse.conn_str ,'mapped_agent_id':mapped_agent_id})
            //         that.$session.set('mapped_agent_details',mapped_list)
            //     }
            // }
            return resolve(tableColumnResponse)
        }).catch(errorResponse => {
            return reject(errorResponse)
        });
    });
}


export function getBulkTablesColumns(that, table_column_list, env_id, connection_str, schema, table_details) {
    sessioncheck(that)
    var mapped_list = []
    if(that.$session.get('mapped_agent_details'))
        mapped_list = that.$session.get('mapped_agent_details')
    var mapped_agent_obj= _.find(mapped_list,['conn_str',connection_str])
    var mapped_agent_id = ''
    if(mapped_agent_obj)
        mapped_agent_id = mapped_agent_obj.mapped_agent_id
    var inputJson = {
        "conn_str": connection_str,
        "env_id": env_id,
        "schema": schema,
        "table_details": table_details,
        "client_id": that.$session.get('client_id'),
        "db_type": that.db_type,
         "mapped_agent_id":mapped_agent_id,
         "description_needed":true,
         "ds_id":that.datasource_id
    }

    if(that.run_on_target){
        inputJson["conn_str"] = that.target_connection_str
        inputJson["schema"] = that.target_schema
        inputJson["ds_id"] = that.target_datasource_id
    }

    if(that.acceleratorObj){
        inputJson["acceleration_type"] = that.acceleratorObj.acceleration_type
        
        inputJson["dictionary_conn_str"] = that.acceleratorObj.dictionary_conn_str
        inputJson["dictionary_data_id"] = that.acceleratorObj.dictionary_data_id
        inputJson["dictionary_data_schema"] = that.acceleratorObj.dictionary_data_schema

        inputJson["object_librarian_conn_str"] = that.acceleratorObj.object_librarian_conn_str
        inputJson["object_librarian_id"] = that.acceleratorObj.object_librarian_id
        inputJson["object_librarian_schema"] = that.acceleratorObj.object_librarian_schema
    }

    //remove steps table . no need to perform bulk search for steps data
    for (var i = table_details.length - 1; i >= 0; i--) {
        if (table_details[i].table_type === "step" || table_details[i].table_type == "S") {
            table_details.splice(i, 1)
        }
    }

    var list_to_return = []
    let tableColumnUrl = config.AGENT_API_URL + GET_BULK_TABLES_COLUMNS_URL;
    return new Promise(function(resolve, reject) {
        let key = env_id + '_table_column_list';

        /**
         * Cache Check point
         */
        that.$store.state.cacheData = isLargeMemory(sizeof(that.$store.state.cacheData)) ? [] : that.$store.state.cacheData;
        var data_to_pass_api = []

        if (!that.$store.state.cacheData[key]) {
            that.$store.state.cacheData[key] = [];
        }
        if (that.$store.state.cacheData[key].length === 0)
            data_to_pass_api = table_details
        else {
            for (var j = 0; j < table_details.length; j++) {
                var table_found = false
                for (var i = 0; i < that.$store.state.cacheData[key].length; i++) {
                    if (that.$store.state.cacheData[key][i].table_name == table_details[j].table_name) {
                        list_to_return.push({
                            "table_name": table_details[j].table_name,
                            "table_column_list": that.$store.state.cacheData[key][i].table_column_list
                        })
                        table_found = true
                        break;
                    }
                }
                if (!table_found)
                    data_to_pass_api.push(table_details[j])
            }
        }
        if (data_to_pass_api.length === 0)
            return resolve(list_to_return)
        inputJson["table_details"] = data_to_pass_api
        postToServer(that, tableColumnUrl, inputJson).then(tableColumnResponse => {
            if(!mapped_agent_id){
                mapped_agent_id = tableColumnResponse.mapped_agent_id
                if (mapped_agent_id)
                {
                mapped_list.push({'conn_str': tableColumnResponse.conn_str ,'mapped_agent_id':mapped_agent_id})
                that.$session.set('mapped_agent_details',mapped_list)
            }
            }
            return resolve(formatTableResponse(that, tableColumnResponse, list_to_return, key))
        }).catch(errorResponse => {
            return reject(errorResponse)
        });
    });
}

function formatTableResponse(that, tableColumnResponse, list_to_return, cache_key) {
    var keys = Object.keys(tableColumnResponse)
    for (var i = 0; i < keys.length; i++) {
        var new_json = {
            "table_name": keys[i],
            "table_column_list": tableColumnResponse[keys[i]].column_list,
            "table_column_descriptions":tableColumnResponse[keys[i]].description
        }
        that.$store.state.cacheData[cache_key].push(new_json)
        list_to_return.push(new_json)
    }
    return list_to_return
}

function isLargeMemory(bytes) {
    // debugger;
    let value = 0,
        type = 'Bytes';
    if ((bytes >> 30) & 0x3FF) {
        value = (bytes >>> 30) + '.' + (bytes & (3 * 0x3FF));
        type = 'GB';
    } else if ((bytes >> 20) & 0x3FF) {
        value = (bytes >>> 20) + '.' + (bytes & (2 * 0x3FF));
        type = 'MB';
    } else if ((bytes >> 10) & 0x3FF) {
        value = (bytes >>> 10) + '.' + (bytes & (0x3FF));
        type = 'KB';
    } else if ((bytes >> 1) & 0x3FF) {
        value = (bytes >>> 1);
        type = 'Bytes';
    } else {
        value = bytes;
        type = 'Bytes';
    }
    if (type == 'GB' || (type == 'MB' && parseInt(String(value)) > (config.WEB_CACHE_SIZE || 10))) {
        return true
    } else {
        return false;
    }
}

export function getAccelerationDetails(that,env_id,client_id){
    sessioncheck(that)
    var data = {"env_id":env_id,"client_id":client_id}
    var url = config.AGENT_API_URL + GET_ACCELERATION_DETAILS
    postToServer(that, url, data).then(response => {
        that.acceleratorObj = response
    }).catch(errorResponse => {
        
    });
}

export function getColumnsMoreInfo(that,env_id,client_id,list_of_columns,type_of_request, driver_details){
    return
    sessioncheck(that)
    console.log("getColumnsMoreInfo called")
    if(!that.acceleratorObj)
        return
    // send only column names to the agent api
    var only_cols =[]

    var sub_string_index = 0
    if (that.acceleratorObj.acceleration_type === 'jde_ed_e1')
        sub_string_index = 2
    // check already col in present in store or not
    var availableColInfo = that.$store.state.moreColumnInfo

    for (var i = 0; i < list_of_columns.length; i++) {
            var current_column = list_of_columns[i]
            // all coumns for selection will come here. 
            // So if that column has the requested details then why would replace that
            if(current_column[type_of_request])
                continue
            if(current_column.is_drv_table ==="true" || current_column.is_drv_table === true){
                // to avoid the unnecessory looping
                if(!driver_details || driver_details.length === 0)
                    continue
                for (var j = 0; j < driver_details.length; j++) {
                    var driver_row = driver_details[j]
                    if(driver_row.column_name === current_column.column_name && driver_row[type_of_request]){
                        current_column["actual_name"] =  driver_row.actual_name
                        current_column[type_of_request]  = driver_row[type_of_request]
                        if(current_column[type_of_request].column_description)
                        current_column["report_alias_name"] = current_column[type_of_request].column_description
                        break                     
                    }
                }
                continue
            }
            var actual_name = current_column.column_name.substring(sub_string_index,current_column.column_name.length)
            current_column["actual_name"] = actual_name

            var obj = _.find(availableColInfo,["actual_name",actual_name])
            if(obj && obj[type_of_request]) {
                current_column[type_of_request] = obj[type_of_request]
                if(current_column[type_of_request].column_description)
                    current_column["report_alias_name"] = current_column[type_of_request].column_description
            }
            else
                only_cols.push(current_column.actual_name)
        }

    if (only_cols.length === 0){
        console.log("getColumnsMoreInfo return duo to 0")
        return 
    }
    var datasource_info = {
        "client_id":client_id,
        "env_id":env_id,
        "mapped_agent_id":that.acceleratorObj.mapped_agent_id,
        "columns":only_cols,
        "type_of_request":type_of_request,
        "acceleration_type":that.acceleratorObj.acceleration_type,
        "db_type":that.acceleratorObj.db_type,
        "dest_queue":that.acceleratorObj.dest_queue,
        "ds_id":that.datasource_id
    }

    if(that.run_on_target){
        inputJson["connection_str"] = that.target_connection_str
        inputJson["schema"] = that.target_schema
        inputJson["ds_id"] = that.target_datasource_id
    }

    if(type_of_request === 'col_description'){
        datasource_info["connection_str"] = that.acceleratorObj.object_librarian_conn_str
        datasource_info["ds_id"] = that.acceleratorObj.object_librarian_id
        datasource_info["schema"] = that.acceleratorObj.object_librarian_schema

    } else if(type_of_request === 'col_format'){
        datasource_info["connection_str"] = that.acceleratorObj.dictionary_conn_str
        datasource_info["ds_id"] = that.acceleratorObj.dictionary_data_id
        datasource_info["schema"] = that.acceleratorObj.dictionary_data_schema
    }
    else {
        return
    }
    if(!datasource_info.connection_str)
        return

    // if(that.acceleratorObj && that.acceleratorObj.control_conn_str){
    //     datasource_info["control_data_str"] = that.acceleratorObj.control_conn_str
    //     datasource_info["control_data_ds_id"] = that.acceleratorObj.control_data_id
    //     datasource_info["control_data_schema"] = that.acceleratorObj.control_data_schema
    // }
    
    var url = config.AGENT_API_URL +'/get_column_alias'
    postToServer(that, url, datasource_info).then(tableColumnResponse => {
            var result = tableColumnResponse.result
            if(tableColumnResponse.dest_queue)
                that.acceleratorObj.dest_queue = tableColumnResponse.dest_queue
            if(result)
                result = _.trim(result)
            if(result  && result.trim())
                result = JSON.parse(result)
            if(result){
                for(var i=0;i<result.length;i++){
                    var current_row = result[i]
                    current_row["column_name"] = current_row["column_name"].trim()
                    current_row["actual_name"] = current_row["column_name"]
                    current_row["column_fraction"] = 
                    current_row["column_fraction"] ? current_row["column_fraction"].trim(): ""
                    current_row["column_type"] = 
                    current_row["column_type"] ? current_row["column_type"].trim(): ""
                    current_row["column_description"] = 
                    current_row["column_description"] ? current_row["column_description"].trim(): ""
                    delete current_row["column_name"]
                    // there might be multiple rows by same name
                    for(var j=0;j<list_of_columns.length;j++){
                        var current_column = list_of_columns[j]
                        if(current_column.actual_name === current_row.actual_name)
                        {
                            current_column[type_of_request] = current_row
                            if(current_row["column_description"])
                                current_column["report_alias_name"] = current_row["column_description"]
                        }
                    }
                    var actual_name = current_row["actual_name"]
                    delete current_row["actual_name"]
                    var obj = _.find(that.$store.state.moreColumnInfo,["actual_name",actual_name])
                    if(obj){
                        var index =_.indexOf(that.$store.state.moreColumnInfo,obj)
                        obj[type_of_request] = current_row
                        that.$set(that.$store.state.moreColumnInfo,index,obj)
                    }
                    else{
                        var data_to_push = {"actual_name":actual_name}
                        data_to_push[type_of_request] =current_row
                        that.$store.state.moreColumnInfo.push(data_to_push)
                    }
                }
            }
        }).catch(errorResponse => {
        });
        return
    }