<template>
    <div class="widget-options-div style-2scroll">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="12">
                <v-card class="pa-1">
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col d-flex justify-start widget-form-titles text--grey text-truncate">
                            Chart Selection
                        </v-col>
                    </v-row>
                    <v-card-text class="px-0 py-1">
                        <v-row class="EDC-Row py-1">
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="selectChartDropdown" 
                                @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                            <!-- <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="chartName" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col> -->
                        </v-row>
                        <v-row class="EDC-Row py-1">
                            <v-col class="EDC-Col">
                                <object-selection :controlCols="6" @onObjectSelectionChanged="fetchColumnsDetails"
                                :selectedValues="objectSelectionValues"/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="EDC-Col mt-3" cols="12">
                <v-card class="pa-2">
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col d-flex justify-start widget-form-titles text--grey text-truncate">
                            Axis Details
                        </v-col>
                    </v-row>
                    <v-card-text class="px-0 py-1">
                        <v-row class="EDC-Row py-1">
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="selectXaxisDropdown" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="xaxisName" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row py-1">
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="selectYaxisDropdown" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="yaxisName" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row py-1">
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="selectAggAxisDropdown" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="selectAggValueDropdown" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row py-1">
                            <v-col class="EDC-Col px-1 d-flex justify-inline" cols="6">
                                <custom-control :componentDetails="selectOrderByDropdown" @updateModelData='onChartValueChanged'/>
                                <v-icon :title="sortTitle" @click="toggleSort" v-if="showSort">{{ sortIcon }}</v-icon>
                            </v-col>
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="maxRowCount" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            
            <v-col class="EDC-Col mt-3" cols="12">
                <v-card class="pa-2">
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col d-flex justify-start widget-form-titles text--grey text-truncate">
                            Drilldown Details
                        </v-col>
                    </v-row>
                    <v-card-text class="px-0 py-1">
                        <v-row class="EDC-Row py-1">
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="selectGraphDrilldownType" 
                                @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="selectGraphDrilldownColumn" 
                                @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row py-1">
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="selectGraphDrilldownAggrFunc" 
                                @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="EDC-Col mt-3" cols="12">
                <v-card class="pa-2">
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col d-flex justify-start widget-form-titles text--grey text-truncate">
                            Chart Options
                        </v-col>
                    </v-row>
                    <v-card-text class="px-0 py-1">
                        <v-row class="EDC-Row py-1">
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="showLegend" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="showLabels" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row py-1">
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="showTooltip" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="showDownoadButton" @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="EDC-Col mt-3" cols="12">
                <v-card class="pa-2">
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col d-flex justify-start widget-form-titles text--grey text-truncate">
                            Color settings
                        </v-col>
                    </v-row>
                    <v-card-text class="px-0 py-1">
                        <v-row class="EDC-Row py-1">
                            <v-col class="EDC-Col px-1" cols="6">
                                <custom-control :componentDetails="selectColorPalette" 
                                @updateModelData='onChartValueChanged'></custom-control>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import objectSelections from "./objectSelections.vue"
    import { mixinWidget } from "../../../mixins/widget.js";
    import config from '@/config.json'
    export default{
        name:"GraphOptionComponent",
        mixins:[mixinWidget],
        components:{
            'object-selection':objectSelections
        },
        props:{
            componentDetails:{
                type:Object,
                default:()=>{
                    return {

                    }
                }
            },
            
        },
        data(){
            return{
                availableDatasourceList:[],
                availableaxisOptions: [],
                objectSelectionValues:{},
                aggOnOptions: [{"value": "xaxis", "name": "X-axis"},
                               {"value": "yaxis", "name": "Y-axis"},
                               {"value": "none", "name": "None"}],
                aggValueList: [ 
                    {"value": "avg", "name": "Average"},
                    {"value": "count", "name": "Count"},
                    {"value": "max", "name": "Max"},
                    {"value": "min", "name": "Min"},
                    {"value": "sum", "name": "Sum"}
                ],
                colorPaletteOptions:[
                    {"value": "default", "name": "Default"},
                    {"value": "gray", "name": "Gray Palette"},
                    {"value": "green", "name": "Green Palette"},
                    {"value": "red", "name": "Red Palette"},
                    {"value": "blue", "name": "Blue Palette"},
                ],
                selectedDatasource:{},
                selectedObject:{},
                chartDetails: {},
                chartOptionComponents:{
                    "selectChartDropdown": "",
                    "selectedWidgetName": "Untitled Chart",
                    "selectXaxisDropdown": "",
                    "xaxisName": "Untitled X-axis",
                    "selectYaxisDropdown": "",
                    "yaxisName": "Untitled Y-axis",
                    "selectAggAxisDropdown": "",
                    "selectAggValueDropdown": "",
                    "selectOrderByDropdown": "",
                    "maxRowCount": 10,
                    "selectGraphDrilldownType":"",
                    "selectGraphDrilldownAggrFunc": "",
                    "selectGraphDrilldownColumn":"",
                    "selectColorPalette":"",
                    "colorPalette":"",
                    "showLegend": false,
                    "showTooltip": true,
                    "showLabels": true,
                    "showDownoadButton": false,
                    "sortType":"asc"
                },
                selectChartDropdown:{},
                availableColumnsForDrilldownType:[
                    {"value": "on_pathfinder", "name": "On Pathfinder"},
                    {"value": "within_chart", "name": "Within Chart"}
                ],
                chartName:{},
                selectXaxisDropdown:{},
                xaxisName:{},
                selectYaxisDropdown:{},
                yaxisName:{},
                selectAggAxisDropdown:{},
                selectAggValueDropdown:{},
                selectOrderByDropdown:{},
                selectGraphDrilldownType: {},
                selectGraphDrilldownAggrFunc: {},
                selectGraphDrilldownColumn:{},
                selectColorPalette:{},
                maxRowCount:{},
                showLegend:{},
                showTooltip:{},
                showLabels:{},
                showDownoadButton:{},
                sortType:"asc"
            }
        },
        computed:{
            showSort(){
                return !_.isEmpty(this.selectOrderByDropdown) && (this.selectOrderByDropdown.selectedValue ==='xaxis' || this.selectOrderByDropdown.selectedValue ==='yaxis')
            },
            isSortAsc(){
                return this.sortType === 'asc'
            },
            sortIcon(){
                return this.isSortAsc?"mdi-arrow-up":"mdi-arrow-down"
            },
            sortTitle(){
                return this.isSortAsc?"Ascending":"Discending"
            },
            isNoneAggregateSelected(){
                // graph drilldown is only applicable if the user has perfrom aggreate on ANY Column
                return ['xaxis','yaxis'].indexOf(this.selectAggAxisDropdown.selectedValue) === -1
            },
            disabledDrilldownColumnAndAggregate(){
                return this.selectGraphDrilldownType.selectedValue === 'on_pathfinder' || this.isNoneAggregateSelected
            },
            availableColumnsForDrilldown(){
                // populating this values are based on the which column user has selected.
                // we need to return "All Columns" expect columns selected the axis 
                // (based on requirement, this will get changed)
                if(!this.selectXaxisDropdown.selectedValue || !this.selectYaxisDropdown.selectedValue)
                    return []
                return _.filter(this.availableaxisOptions,(obj)=>{
                    return obj.column_name != this.selectXaxisDropdown.selectedValue 
                    && obj.column_name!=this.selectYaxisDropdown.selectedValue
                })
            }
        },
        watch:{
            'chartOptionComponents':{
                handler(newValue){
                    setTimeout(()=>{
                        this.validateAndEmit()
                    },1000)
                },
                deep:true
            },
            'availableaxisOptions':{
                handler(newValue){
                    this.selectXaxisDropdown = this.bindStaticItemList(this.selectXaxisDropdown,this.availableaxisOptions)
                    
                    this.selectYaxisDropdown = this.bindStaticItemList(this.selectYaxisDropdown,this.availableaxisOptions)
                }
            },
            'availableColumnsForDrilldown':{
                handler(newValue){
                    this.selectGraphDrilldownColumn = this.bindStaticItemList(this.selectGraphDrilldownColumn,newValue) 
                }
            }
        },
        mounted(){
            this.initializeComponents()
            setTimeout(()=>{
                this.manageSavedObject()
            },100)
        },
        methods:{
            initializeComponents(){
                let selectDropdown = this.getDropdownComponent('selectChartDropdown','string','bar','Select Chart Type','chart_type','chart_name')
                let chartOptions = [ { "chart_type": "bar", "chart_name": "Bar Chart" }, { "chart_type": "line", "chart_name": "Line Chart" }, { "chart_type": "pie", "chart_name": "Pie Chart" } ]
                this.selectChartDropdown  = this.bindStaticItemList(selectDropdown,chartOptions)

                this.chartName = this.getTextboxComponent('chartName','string','Untitled Chart','Chart Name')


                let xAxisDropdown = this.getDropdownComponent('selectXaxisDropdown','object','','Select X-axis','column_name','column_display_name')
                xAxisDropdown = this.bindStaticItemList(xAxisDropdown,this.availableaxisOptions) 
                this.selectXaxisDropdown = xAxisDropdown
                

                this.xaxisName = this.getTextboxComponent('xaxisName','string','Untitled X-axis','X-axis Label')

                let yAxisDropdown = this.getDropdownComponent('selectYaxisDropdown','object','','Select Y-axis','column_name','column_display_name')
                yAxisDropdown = this.bindStaticItemList(yAxisDropdown,this.availableaxisOptions)
                this.selectYaxisDropdown = yAxisDropdown


                this.yaxisName = this.getTextboxComponent('yaxisName','string','Untitled Y-axis','Y-axis Label')
            
                let agregateDropdown = this.getDropdownComponent(
                    'selectAggAxisDropdown','string','None','Select Aggr. On','value','name')
                agregateDropdown = this.bindStaticItemList(agregateDropdown,this.aggOnOptions) 
                this.selectAggAxisDropdown = agregateDropdown

                let aggregateValueDropdown = this.getDropdownComponent(
                    'selectAggValueDropdown','string','None','Select Aggr. Value','value','name')
                aggregateValueDropdown = this.bindStaticItemList(aggregateValueDropdown,this.aggValueList) 
                aggregateValueDropdown['isDisabled'] = this.isNoneAggregateSelected
                this.selectAggValueDropdown = aggregateValueDropdown

                let orderByDropdown = this.getDropdownComponent(
                        'selectOrderByDropdown','string','None','Select Order By','value','name')
                orderByDropdown = this.bindStaticItemList(orderByDropdown,this.aggOnOptions) 
                this.selectOrderByDropdown =  orderByDropdown
                
                let maxRows = this.getTextboxComponent(
                        'maxRowCount','number', 10,'Max Data points')
                maxRows = this.applyNumberPropsToText(maxRows,1,10)
                this.maxRowCount = maxRows
                
                let graphDrilldownType = this.getDropdownComponent('selectGraphDrilldownType','string','on_pathfinder','Graph Drilldown Type','value','name')
                graphDrilldownType = this.bindStaticItemList(graphDrilldownType,this.availableColumnsForDrilldownType) 
                graphDrilldownType['isDisabled'] = this.isNoneAggregateSelected
                this.selectGraphDrilldownType = graphDrilldownType

                let graphDrilldownColumn = this.getDropdownComponent('selectGraphDrilldownColumn','object','','Graph Drilldown Column','column_name','column_display_name')
                graphDrilldownColumn = this.bindStaticItemList(graphDrilldownColumn,this.availableColumnsForDrilldown) 
                graphDrilldownColumn['isDisabled'] = this.disabledDrilldownColumnAndAggregate
                this.selectGraphDrilldownColumn = graphDrilldownColumn
                
                let graphDrilldownAggrFunc = this.getDropdownComponent('selectGraphDrilldownAggrFunc','string','None','Select Aggr. Value','value','name')
                graphDrilldownAggrFunc = this.bindStaticItemList(graphDrilldownAggrFunc,this.aggValueList)
                graphDrilldownAggrFunc['isDisabled'] = this.disabledDrilldownColumnAndAggregate
                this.selectGraphDrilldownAggrFunc = graphDrilldownAggrFunc

                this.selectColorPalette = {}
                let colorDrilldown = this.getDropdownComponent('selectColorPalette','string','default','Color Palette','value','name')
                colorDrilldown = this.bindStaticItemList(colorDrilldown,this.colorPaletteOptions) 
                this.selectColorPalette = colorDrilldown

            
                this.showLegend = this.getCheckboxComponent('showLegend','boolean', false, 'Show Legends')
                this.showTooltip = this.getCheckboxComponent('showTooltip','boolean', true, 'Show Tooltips')
                this.showLabels = this.getCheckboxComponent('showLabels','boolean', true, 'Show Data Labels')
                this.showDownoadButton = this.getCheckboxComponent('showDownoadButton','boolean', false, 'Allow Download')
            
            },
            manageSavedObject(){
                var _this = this
                if(_.isEmpty(this.componentDetails.selectedValue))
                    return
                let allKeys = _.keys(this.componentDetails.selectedValue)
                _.forEach(allKeys,(key)=>{
                    if(typeof(_this[key]) === 'object'){
                        _this[key].selectedValue =  _this.componentDetails.selectedValue[key]                    
                        _this.chartOptionComponents[key] = _this[key].selectedValue
                    } else{
                        _this[key] =_this.componentDetails.selectedValue[key]
                        _this.chartOptionComponents[key] = _this[key]
                    }
                    
                })
                this.objectSelectionValues = {
                    "selectedDatasource":this.selectedDatasource.selectedValue,
                    "selectedObject":this.selectedObject.selectedValue
                }
                console.log("_this.chartOptionComponents",_this.chartOptionComponents)
            },
            onObjectSelectionChanged(selectedObject) {
                this.fetchColumnsDetails(selectedObject);
            },
            fetchColumnsDetails(selectedItem){
                var _this = this
                this.selectedDatasource = selectedItem.selectedDatasource;
                this.selectedObject = selectedItem.selectedObject;
                this.chartOptionComponents['selectedDatasource'] = this.selectedDatasource
                this.chartOptionComponents['selectedObject'] = this.selectedObject
                var additionalData = {
                    "object_id":selectedItem.selectedObject.object_id,
                    "object_type":selectedItem.selectedObject.object_type,
                    "object_version":selectedItem.selectedObject.object_version
                }
                var url = config.GATEWAY_API_URL + "/get_object_columns"
                this.loadDropdownList(url,'availableaxisOptions',additionalData)
            },
            validateAndEmit(){
                if(this.chartOptionComponents.selectChartDropdown && !_.isEmpty(this.selectedDatasource)
                && !_.isEmpty(this.selectedEnvironment) && this.selectXaxisDropdown && this.selectYaxisDropdown){
                    this.$emit("updateModelData","edcchartoptions",this.chartOptionComponents);
                }
                // here make sure all required fields have values
                // if successfully validate for required fields then EMIT
                
            },
            onChartValueChanged(key,selectedValue){
                this.chartOptionComponents[key] = selectedValue
                this[key].selectedValue = selectedValue
                if(key === 'selectAggAxisDropdown'){
                    this.selectAggValueDropdown['isDisabled'] = this.isNoneAggregateSelected
                    this.selectGraphDrilldownType['isDisabled'] = this.isNoneAggregateSelected
                    this.selectGraphDrilldownColumn['isDisabled'] = this.disabledDrilldownColumnAndAggregate
                    this.selectGraphDrilldownAggrFunc['isDisabled'] = this.disabledDrilldownColumnAndAggregate
                    if(this.isNoneAggregateSelected){
                        this.selectAggValueDropdown.selectedValue = ""
                        this.selectGraphDrilldownType.selectedValue = ""
                        this.selectGraphDrilldownColumn.selectedValue = ""
                        this.selectGraphDrilldownAggrFunc.selectedValue = ""
                    }
                }

                if(key === 'selectGraphDrilldownType'){
                    this.selectGraphDrilldownColumn['isDisabled'] = this.disabledDrilldownColumnAndAggregate
                    this.selectGraphDrilldownAggrFunc['isDisabled'] = this.disabledDrilldownColumnAndAggregate
                    if(this.disabledDrilldownColumnAndAggregate){
                        this.selectGraphDrilldownColumn.selectedValue = ""
                        this.selectGraphDrilldownAggrFunc.selectedValue = ""
                    }
                }
            },
            toggleSort(){
                if(this.isSortAsc)
                    this.sortType = 'desc'
                else
                    this.sortType = 'asc'

                this.chartOptionComponents.sortType = this.sortType
            }
        }
    }
</script>
