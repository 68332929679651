<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="8" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>
                                <select id="headerenvselect" v-model="selected_env" v-on:change="onEnvironmentChange();setEnv()" style="-webkit-appearance: menulist" autofocus ref="envname">
                                    <option :value="{}" id="nonselectenv">Select Environment</option>
                                    <option v-for="(env, index) in EnvironmentList" :id="'envnum'+env.index" :value="env" :key="index">{{env.env_name}}</option>
                                </select>
                            </li>
                            <li>Security</li>
                            <li>
                                Assign {{roleTypeDisplay}} Security by Environment
                        </li>
                        </ul>
                        
                    </v-col>
                </v-row>
            </v-col>
        </v-row> 
       <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row no-gutters class="EDC-Row EDC-GridRow">
                    <v-col class="EDC-Col" cols="12">
                        <edc-data-grid :dataList="tableList" @onNew="onNew" @onEdit="onEdit" @onDelete="onDelete" @onCancel="onCancel" @onValueUpdate="onValueUpdate" @onSave="saveRoleMapping"></edc-data-grid>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbarTimeout" id="edcmessage" top right>
            {{ snackbarText }}
        </v-snackbar>
        <loading-panel :loader="loader"></loading-panel>
    </v-container>
</template>
<style scoped>
    .customCheckbox >>> label {
        top: 5px !important;
    }
    .v-expansion-panel >>> .v-expansion-panel__header {
        padding-bottom: 0px !important;
    }
    
    .EDC-UIComponent {
        margin: 0px !important;
        padding: 0px !important;
    }
    
    .radioClass >>> label {
        top: 3px !important;
    }
    
    .EDC-TopRow {
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    
    .EDC-ColDescription {
        margin-top: -7px !important;
    }
    .EDC-ColEnv {
        margin-top: -22px !important;
    }
    
    .EDC-GridRow {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    
    .EDC-ButtonRow {
        padding-right: 10px !important;
        padding-bottom: 10px !important;
    }
</style>
<script>
    import { post as postToServer } from "./../../../methods/serverCall.js";
    import { get as getToServer } from "./../../../methods/serverCall.js";
    import config from "../../../config.json";
    import { SERVER_ERROR } from "../../../data/client_message.js";
    import {
    GET_ALL_USER
} from './../../../data/url_constants.js';

    import { getEnvListForDataviewer} from '../../../methods/EnvironmentList.js'
    import {CLIENT_SIDE} from "@/data/macros.js";
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import getUserRole from '../../../methods/GetUserRole.js'
    import { SECURITY_SERVICE, MANAGE_ACTION_BASED_SECURITY, MANAGE_OBJ_BASED_SECURITY } from '../../../data/macros.js';
    export default {
        name:"ManageObjectBasedSecurity",
        data(){
            return {
                selected_type_list: [{
                    id: 1,
                    name: 'User',
                    },{
                    id: 2,
                    name: 'User Group',
                }],
                roleType:this.$store.state.sameComponentRedirectObj.roletype,
                selected_type: '',
                selected_object: '',
                selected_object_list : [],
                selected_role: '',
                selected_role_list : [],
                snackbar: false,
                snackbarText: "",
                colorValue: "error",
                snackbarTimeout: Snackbar_Default_Timeout,
                loader: false,
                selected_env:{},
                EnvironmentList:[],
                tableList: {
                    headers:[],
                    actions:[],
                    sorting_type: CLIENT_SIDE,
                    filterType: CLIENT_SIDE,
                    paginationType: CLIENT_SIDE,
                    total_count: 0,
                    rows: [],
                    selected_rows: [],
                    syncHeaderScroll:false,
                    filterType: this.clientSide,
                    hideInlineFilter: true,
                    hideFilter:true,
                    hideExport:true,
                    hideShowColumns:true,
                    is_grid_inline_edit:true,
                    itemkey:'id',
                },
            // headers: [
                //     { text: 'Map Type', value: 'map_type', title: 'Map Type' },
                //     { text: 'Object Name', value: 'map_object_name',title: 'Object Name' },
                //     { text: 'Role Name', value: 'role_name',title: 'Role Name' },
                //     { text: 'Created By', value: 'created_by',title: 'Created By' }
                // ],
                userTypeHeader:{ text: 'Map Type', value: 'map_type_data', id:'drpdnmapype', 
                                 display_value:'map_type_text',
                                 option:[{
                                    name: 'User'},{name: 'User Group'}], 
                                    'item_text':"name", 
                                    'item_value':"name", 
                                 useAutoComplete:true},
                objectTypeHeader:{  text: 'User / User Group', value: 'map_object_name_data', 
                                    display_value:'map_object_name_text', id:'drpdnusrgrp',
                                    option:[], 
                                    'item_text':'name', 
                                    'item_value':'id', 
                                    useAutoComplete:true},
                roleTypeHeader:{ text: 'Role Name', value: 'role_name_data', 
                                display_value:'display_role_name', option:[],
                                'item_text':"display_role_name", 'item_value':"id",
                                 id:'drpdnrolename', useAutoComplete:true},
                tableRow:{
                    'map_type_data':'',
                    'map_object_name_data': '',
                    'role_name_data': '',
                    'is_row_editable': true,
                }
            }
        },
        computed:{
            roleTypeDisplay(){
                if(this.isEnvironmentalRole)
                    return 'Action'
                return 'Object'
            },
            securityAction(){
                if(this.isEnvironmentalRole){
                    return MANAGE_ACTION_BASED_SECURITY
                }
                return MANAGE_OBJ_BASED_SECURITY
            },
            isEnvironmentalRole(){
                return this.roleType === 'environmental'
            }
        },
        watch:{
            'selected_type_list':{
                handler(newValue){
                    this.userTypeHeader.option = newValue
                }
            },
            'selected_object_list':{
                handler(newValue){
                    this.objectTypeHeader.option = newValue
                }
            },
            'selected_role_list':{
                handler(newValue){
                    this.roleTypeHeader.option = newValue
                }
            },
            '$store.state.sameComponentRedirectObj':{
                handler(newvalue,oldvalue){
                    this.managePage()
                },
                deep:true
            },
        },
        mounted() {
            var _this = this
            this.userRole = this.$session.get('user_role_mapping');
            this.tableList.headers = [this.userTypeHeader,this.objectTypeHeader, this.roleTypeHeader]
            this.tableList.actions = [
                {'text': 'new',id:'icnaddnewmapping', 'key': "map_type_data",selectType:"single",'enabled':this.Environment,
                 role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE, this.securityAction),index:1},
                {'text': 'edit', id:'icneditrolemaping', 'key': "id",selectType:"single",
                role:this.userRole.is_superadmin ||getUserRole(this.userRole,SECURITY_SERVICE, this.securityAction), index: 1},
                {'text':"save",id:'icnsaverolemaping', "key":"is_row_editable", selectType:"single",
                role:this.userRole.is_superadmin ||getUserRole(this.userRole,SECURITY_SERVICE, this.securityAction), index: 3},
                {'text':"cancel",id:'icncancelrolemaping', "key":"is_row_editable", selectType:"single",
                role:this.userRole.is_superadmin ||getUserRole(this.userRole,SECURITY_SERVICE, this.securityAction), index: 4},
                {'text': 'delete',id:'icndeleterolemaping', 'key': "id",selectType:"multiple",index:5,
                role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE, this.securityAction),}
            ]
            if(this.$session.get('selected_env')){
                this.selected_env = this.$session.get('selected_env')
            }
            
            getEnvListForDataviewer(this,false)
            setTimeout(()=>{
                _this.managePage()
            },100)

        },
        methods:{
            managePage(){
                this.roleType = this.$store.state.sameComponentRedirectObj.roletype || 'object_based'
                this.onEnvironmentChange()
            },
            setEnv(){
            this.$session.set('selected_env',this.selected_env)
            },
            onEnvironmentChange(){
                this.objectTypeHeader.option = []
                this.roleTypeHeader.option = []
                this.tableList.rows = []
                this.getAllRole()
                this.getAllUserRoleMapping()
            },
            onValueUpdate(record,header) {
                if(record.map_type_data === 'User'){
                    return this.getUsersList(record)
                }
                else{
                    this.getAllUserGroups(record)
                }
                if(header.value === 'map_type_data'){
                    record.map_object_name_data = ''
                    record.role_name_data = ''
                }
            },
            getUsersList(record) {
            let _this = this;
            _this.loader = true;
            var client_id = this.$session.get('client_id')
            var data = {
                "client_id": client_id,
                "env_id": this.selected_env.id,
                "map_type": record.map_type_data
            }
            postToServer(_this, config.Security_URL + '/get_user_or_group_by_environment', data).then(response => {
                _this.loader = false;
                if(response) {
                    response.map((obj)=>{
                            obj.id = obj.user_id
                            obj.name= obj.username
                        })
                    this.selected_object_list = response;
                } else {
                    this.selected_object_list = [];
                }
            }).catch(error_response => {
                _this.loader = false;
                this.selected_object_list = [];
                // _this.showErrorMessage(error_response)
            })
        },
        getAllUserGroups(record){
                let _this = this;
                _this.loader = true;
                var request_data = {
                    'client_id': this.$session.get('client_id'),
                    "env_id": this.selected_env.id,
                    "map_type": record.map_type_data
                   }
                postToServer(_this, config.Security_URL + '/get_user_or_group_by_environment', request_data).then(response => {
                    _this.loader = false;
                    if (response) {
                        response.map((obj)=>{
                            obj.id = obj.id
                            obj.name= obj.group_name
                        })
                        this.selected_object_list = response;
                    } else {
                        this.selected_object_list = [];
                    }
                })
                .catch(errorResponse => {
                    _this.loader = false;
                    this.selected_object_list = [];
                    // _this.showErrorMessage(errorResponse)
            });
        },
        getAllRole() {
            var _this = this; 
            if(!this.selected_env || !this.selected_env.id)
                return
            this.selected_role_list =[]
            var client_id = this.$session.get('client_id')
            var data = {"client_id":client_id,
                        "env_id": this.selected_env.id,
                        "env_name": this.selected_env.name,
                        "product_name":window.sessionStorage.getItem('product'),
                        "is_public_role_required" : false,
                        "role_type":_this.roleType
                    }
            var url = config.Security_URL + "/get_all_object_based_role";
            this.loader=true;
            postToServer(this, url, data).then(response => {
                _this.loader=false;
                if(response)
                response.map((obj)=>{
                            obj.id = obj.role_id
                            obj.name= obj.role

                        })
                this.selected_role_list = response;
            }).catch(errorResponse => {
                _this.showErrorMessage(errorResponse)
            });
        },
        onNew(record){
                let obj = _.find(this.tableList.rows,["is_row_editable",true])
                if(obj)
                {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbarText = 'First fillup the open row';
                    return
                }
                this.tableList.rows.unshift(_.cloneDeep(this.tableRow));   
        },
        onEdit(record){

        },
        onCancel(record){
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
                if(record.id){
                    delete obj.is_row_editable
                    this.getAllUserRoleMapping()
                }
            else 
                this.tableList.rows.splice(0,1)
        },
        showErrorMessage(errorMessage){
                this.loader = false;                  
                this.colorValue = 'error'
                this.snackbarText = errorMessage || SERVER_ERROR; 
                this.snackbar = true                   
            },
        saveRoleMapping(record) {
                var _this = this
                var data = {}
                var client_id = this.$session.get('client_id');
                var objectType = this.objectTypeHeader.option
                var roleType = this.roleTypeHeader.option
                var map_type = record.map_type_data
                var user_id, user_name,roleId,role_Name, display_role_name

                var objectTypeObj = _.find(objectType,["id", record.map_object_name_data])
                if(!objectTypeObj){
                    this.showErrorMessage(map_type+" not selected")
                    return
                }
                user_id = objectTypeObj['id']
                user_name = objectTypeObj['name']

                var roleTypeObj = _.find(roleType,["id", record.role_name_data])
                if(!roleTypeObj){
                    this.showErrorMessage("Role not selected")
                    return
                }
                roleId = roleTypeObj['id']
                role_Name = roleTypeObj['name']
                roleType = roleTypeObj['role_type']
                display_role_name = roleTypeObj['display_role_name']

                

                //  for(let i =0 ; i< objectType.length; i++){
                //      if(objectType[i]['id'] == record.map_object_name_data){
                //          user_id = objectType[i]['id']
                //          user_name = objectType[i]['name']
                //          break;
                //          }
                //     // else{
                //     //  user_id = record.map_object_name_data.id
                //     //  user_name = record.map_object_name_data.name
                //     // }
                //  }
                // for(let i =0 ; i< roleType.length; i++){
                //     if(roleType[i]['id'] == record.role_name_data){
                //         roleId = roleType[i]['id']
                //         role_Name = roleType[i]['name']
                //         roleType = roleType[i]['role_type']
                //     break;
                // }
                // else{
                //     roleId = record.role_name_data.id
                //     role_Name = record.role_name_data.name
                //     roleType = record.role_name_data.role_type
                // }
                // }
                 data = {
                    "client_id": client_id,
                    "env_id": this.selected_env.id,
                    "env_name": this.selected_env.name,
                    // "product_name" :window.sessionStorage.getItem('product'),
                    "product_code" : "PF",
                    "role_name": role_Name,
                    "role_type": role_Name,
                    "role_id": roleId,
                    "role_type": roleType,
                    "map_type": record.map_type_data,
                    "map_object_id": user_id,
                    "map_object_name": user_name
                }
                if(!data.env_id || !role_Name || !roleId || !user_id ||!record.map_type_data){
                    alert('All fields are required')
                    return
                }
                if(!record.id)
                     var url = config.Security_URL + "/add_user_group_role_map";
                else{
                    var url = config.Security_URL + "/update_user_group_role_map";
                    data["id"] = record.id
                }
                
                _this.loader=true;
                postToServer(_this, url, data).then(response => {
                _this.loader=false;
                    this.snackbar = true;
                    this.colorValue = "success";
                    this.role_id = response.role_id
                    this.snackbarText = "Mapping saved Successfully";
                    this.getAllUserRoleMapping()
                })
                .catch(errorResponse => {
                    _this.showErrorMessage(errorResponse)
                });
            },
        onDelete(records){
            var _this = this
            var data = {
                "id_list": _.map(records,"id"), 
                "client_id":this.$session.get("client_id"), 
                "deleted_by":this.$session.get("email"),
                "env_id":_this.selected_env.id,
                "role_type": _this.roleType,
            }
            var url = config.Security_URL + "/delete_user_group_role_map";
            _this.loader=true;
            postToServer(_this, url, data).then(response => {
                _this.loader=false;
                this.snackbar = true;
                this.colorValue = "error";
                this.snackbarText = "Mapping Updated successfully";
                this.getAllUserRoleMapping()
            }).catch(errorResponse => {
                _this.showErrorMessage(errorResponse)
            });
           
        },
        getAllUserRoleMapping(){
            var _this = this; 
            var client_id = this.$session.get('client_id')
            this.tableList.rows = []

            if(!this.selected_env || !this.selected_env.id)
                return
            var data = {
                "client_id":client_id,
                "env_id": this.selected_env.id,
                "role_type": _this.roleType,
                "product_code": "PF",
            }
            var url = config.Security_URL + "/get_all_user_group_role_map";
            this.loader=true;
            postToServer(this, url, data).then(response => {
                _this.loader=false;
                if(response)
                _.forEach(response, (obj)=>{
                    obj.map_object_name_text = obj.map_object_name
                    obj.role_name_text = obj.role_name
                    obj.map_type_text = obj.map_type
                    obj.map_object_id = obj.map_object_id
                    obj.role_id = obj.role_id
                    obj.role_name = obj.role_name
                    obj.display_role_name = obj.display_role_name
                    obj.map_type_data =  obj.map_type
                    obj.map_object_name_data= obj.map_object_id
                    obj.role_name_data = obj.role_id
                    
                })
                    this.tableList.rows = response;
            }).catch(errorResponse => {
                _this.showErrorMessage(errorResponse)
            });
        },
        }
    }
</script>