<template>
    <v-card style="overflow:hidden;">
		<v-row class="EDC-Row">
			<v-toolbar class="EDC-Toolbar" dense elevation="1" style="border-radius:0px !important;">
                <v-toolbar-title class="EDC-ToolbarTitle">{{selectedDocument.media_object_heading || 'Media Object Attachment'}}
				</v-toolbar-title>
				<v-spacer v-for="index in 24" :key="index"></v-spacer>

				<v-toolbar-items style="padding-top:12px !important;">
                	<svgicon v-if="showFileViewer || showImageViewer" class="svg-icon-grid svg-fill-toolbar"  name="download_v2" slot="activator" :original="true"  title="Download File"  @click="documentSelectionChange($event,true)"></svgicon>

					<svgicon v-if="showTextViewer" class="svg-icon-grid svg-fill-toolbar"  name="download_v2" slot="activator" :original="true"  title="Download File" @click="createTempFile()"></svgicon>
				</v-toolbar-items>
                <v-spacer></v-spacer>
                <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePanel" title="Close"></svgicon>
            </v-toolbar>

		</v-row>
		<v-row class="EDC-Row  EDC-ControlPadding" style="padding-top:4px !important;">
			<v-col cols="12" class="EDC-Col">
				<v-card elevation-1>
					<v-row class="EDC-Row EDC-ControlPadding" v-if="isJDEWorldDocument">
						<v-col cols="12" class="EDC-Col">
							<v-row class="EDC-Row" style="padding-top:4px !important;">
								<v-col class="EDC-Col" cols="4" align="left">
									Windows Application ID:
								</v-col>
								<v-col class="EDC-Col" cols="2" align="left">
									{{ selectedDocument.media_object_name }}
								</v-col>
							</v-row>
							<v-row class="EDC-Row" style="padding-top:4px !important;">
								<v-col class="EDC-Col" cols="4" align="left">
									Composite Key:
								</v-col>
								<v-col class="EDC-Col" cols="3" align="left">
									{{ selectedDocument.associated_key }}
								</v-col>
								<v-col class="EDC-Col" cols="2" align="left">
									Serial Number:
								</v-col>
								<v-col class="EDC-Col" cols="2" align="left">
									{{ selectedDocument.value }}
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row class="EDC-Row EDC-ControlPadding" v-for="(row,rowIndex) in getRowList()" :key='"row"+rowIndex'>
						<v-col class="EDC-Col" cols="4" v-for="(col,colIndex) in [0,1,2]" align="left" :key="'col'+colIndex+''+rowIndex">
							<v-row class="EDC-Row" v-if="row+col < associatedRowDetails.length">
								<v-col class="EDC-Col" cols="6" align="left" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis !important;" :title="associatedRowDetails[row+col].key">{{associatedRowDetails[row+col].key}} :</v-col>
								<v-col class="EDC-Col" cols="6" align="left" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis !important;" :title="associatedRowDetails[row+col].value">{{associatedRowDetails[row+col].value}}</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		
    	<v-row class="EDC-Row EDC-ControlPadding style-2scroll" style="scroll-y:auto;height:420px !important;">
      		<v-col class="EDC-Col style-2scroll" cols="3" style="margin-top:8px !important;height:400px !important;overflow-y:auto !important;">
      			<div>
      			<v-radio-group @change="documentSelectionChange" v-model="selectedDocument"  hide-details dense>
                    <v-radio v-for="(document,index) in documentList" class="radioClass" :label="document.name" :value="document" :key="index+'documentname'"></v-radio>
                </v-radio-group>
               	</div> 
      		</v-col>
      		<v-col class="EDC-Col EDC-ContainerPadding" style="width:410px;height:410px;display:grid !important;">
      			<object v-show="showImageViewer" id="imageviewer" style="margin: auto" :data="imgFilePath" :type="imgFileContentType">
      				Unable to view the file.
      			</object>
      			<object v-show="showFileViewer" id="fileviewer" width="425" :key="filePath" height="400" :data="filePath" :type="fileContentType">
      				Unable to view the file.
      			</object>
      			<div style="overflow-y:auto !important;height:400px !important;" class="style-2scroll">
      			 	<v-textarea v-if="showTextViewer" class="pa-2"  name="input-7-1" filled disabled auto-grow :value="normalTextData"></v-textarea>
      			</div>
      		</v-col>
    	</v-row>
    	<loading-panel :loader="loader"></loading-panel>
	</v-card>
</template>
<script>
	import config from '../config.json'
	import { post as postToServer } from '../methods/serverCall.js';
	import { get as getToServer } from '../methods/serverCall.js';

	export default {
		name:'edc-document-viewer',
		props:{
			documentObject:{
				type:Object,
				default:()=>{
					return {}
				}
			},
			documentList:{
				type:Array,
				default:()=>{
					return []
				}
			},
			mediaObjDetails:{
				type:Array,
				default:()=>{
					return []
				}
			},
			rowDetails:{
				type:Array,
				default:()=>{
					return []
				}
			},
		},
		data(){
			return{

			}
		},
		computed:{
			isJDEWorldDocument(){
				return this.selectedDocument && this.selectedDocument.is_jde_world_attachment
			},
			associatedRowDetails(){
				if(this.isJDEWorldDocument)
					return this.selectedDocument.rowDetails
				else
					return this.rowDetails
			}
		},
		watch:{
			'documentObject':{
				handler(newvalue,oldvalue){
					if(!newvalue)
						return
					

					// when user download image, it get disappear from the screen
					if(!newvalue.is_download){
						this.showImageViewer = false
						this.showFileViewer = false
						
					}
					this.showTextViewer =false
					this.loader = false
					if(newvalue.success){
						this.manageDocumentObject(newvalue.data)
					}
				}
			},
			'documentList':{
				handler(newvalue,oldvalue){
					if(!newvalue)
						return
					this.showFileViewer = this.showImageViewer = this.showTextViewer =false
					this.selectedDocument = newvalue[0]
					this.documentSelectionChange()
				}
			},
			'rowDetails':{
				handler(newvalue){

				}
			},
			'mediaObjDetails':{
				handler(newvalue){
					if(!newvalue)
						return
					// this.documentObjectName = newvalue.media_object_name
				}
			},
			'selectedDocument':{
				handler(newvalue){
					if(!newvalue)
						return
					this.documentObjectKey = this.selectedDocument.gdtxky
				}
			}
		},
		data(){
			return {
				loader:false,
				filePath:'',
				imgFilePath:'',
				fileContentType:'',
				imgFileContentType:'',
				textContent:'',
				errorText:'',
				fileViewerWidth:"200",
				fileViewerHeight:"200",
				showFileViewer:false,
				showTextViewer:false,
				showImageViewer:false, // image viewer and file viewer is diffrent because pdf/html and images have dimention issue
				selectedDocument:{},
				documentObjectKey:'',
				documentObjectName:'',
				textData:'',
				normalTextData:'',
				fileFormat:'rtf',
				showObjectWithDimention:false
			}
		},
		methods:{
			documentSelectionChange(event,is_download=false){
				this.loader = true
				this.$emit('documentSelectionChange',[this.selectedDocument],this.selectedDocument.media_obj_details, this.isJDEWorldDocument, is_download)
			},
			closePanel(){
				this.$emit('closePanel')
			},
			manageDocumentObject(attachment_details){
				let _this = this
		        let browser_open_type = attachment_details.browser_open_type
		        if(browser_open_type === 'text_render'){
		          _this.showTextViewer = true
		          _this.normalTextData = attachment_details.normal_text
		          _this.textData = attachment_details.text_data
				  _this.fileFormat = attachment_details.file_format || 'rtf'
			      }
		         else if(browser_open_type === 'file_render'){
		         let url = config.AGENT_API_URL + "/get_media_file?path=" + attachment_details.unique_id+"&view=Y";
		          if(attachment_details.content_type.includes('image')){
		          	 _this.showImageViewer = true
		          	 _this.imgFileContentType = attachment_details.content_type
		          	 _this.imgFilePath =  config.AGENT_API_URL + "/get_media_file?path=" + attachment_details.unique_id+"&view=Y"
		          }
		          else{
		          	_this.showFileViewer = true
		          	_this.fileContentType =attachment_details.content_type
		          	_this.filePath =  config.AGENT_API_URL + "/get_media_file?path=" + attachment_details.unique_id+"&view=Y"
		          }
		          
		          
		        }
	          	else{
	          		let url = config.AGENT_API_URL + "/get_media_file?path=" + attachment_details.unique_id;
	          		window.open(url, '_blank');
	          	}
			},
			createTempFile(){
				let _this = this
				_this.loader = true
				let data = {"file_ext":_this.fileFormat,"file_contents":_this.textData}
				postToServer(_this, config.AGENT_API_URL + '/create_tmp_file',data).then(response =>{
					_this.loader = false
					let url = config.AGENT_API_URL + "/get_media_file?path=" + response.unique_id;
	          		window.open(url, '_blank');
			    }).catch(error=>{
			    	_this.loader = false
			    })
			},
			getRowList(){
				let colSize = 3
				let totalRows = Math.ceil(this.associatedRowDetails.length/colSize)
				let listObj = []
				let startWith = 0
				while (totalRows > 0){
					listObj.push(startWith)
					startWith=startWith+colSize
					totalRows = totalRows - 1
				} 
				return listObj
			}
		}
	}
</script>
<style>
 .radioClass label{
    top: 5px !important;
    word-break: break-word !important;
}

.EDC-ObjectDimention {
	width:400px !important;
	height:400px !important;
}
</style>