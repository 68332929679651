<template>
    <div>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col px-1 py-1" :cols="controlCols">
                <custom-control :componentDetails="selectedDatasource"
                @updateModelData="onDatasourceSelectionChanged"></custom-control>
            </v-col>
            <v-col class="EDC-Col px-1 py-1" :cols="controlCols" v-if="showObjectSelection">
                <custom-control :componentDetails="selectedObject"
                @updateModelData="onObjectSelectionChanged"></custom-control>
            </v-col>
            <v-col class="EDC-Col px-1 py-1" :cols="controlCols" v-if="isAsComponent">
                <custom-control :componentDetails="showAttachementColumn" @updateModelData='onValueChanged'></custom-control>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
    
</style>
<script>
    import { mixinWidget } from "../../../mixins/widget.js";
    import config from '@/config.json'
    import { post as postToServer } from '@/methods/serverCall.js';
    export default{
        name:"objectSelection",
        mixins:[mixinWidget],
        components:{

        },
        props:{
            componentDetails:{
                type:Object,
                default:()=>{
                    return {

                    }
                }
            },
            selectedValues:{
                type:Object,
                default:()=>{
                    return{
                        "selectedDatasource":{},
                        "selectedObject":{},
                        "showAttachementColumn":false
                    }
                }
            },
            controlCols:{
                type:Number,
                default:12
            },
            showObjectSelection:{
                type:Boolean,
                default:true
            },
            selectedDatasourceId:{
                type:String,
                default:""
            },
            isAsComponent:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return{
                availableDatasources:[],
                availableObjects:[],
                selectedDatasource:{},
                selectedObject:{},
                showAttachementColumn:{}
            }
        },
        computed:{
            
        },
        watch:{
            'availableDatasources':{
                handler(newValue){
                    if(this.selectedDatasourceId)
                        this.selectedDatasource.selectedValue = _.find(this.availableDatasources,['datasource_id',this.selectedDatasourceId])
                    
                    this.selectedDatasource = this.bindStaticItemList(this.selectedDatasource,this.availableDatasources) 
                }
            },
            'availableObjects':{
                handler(newValue){
                    this.selectedObject = this.bindStaticItemList(this.selectedObject,this.availableObjects) 
                }
            },
            'selectedValues':{
                handler(newValue){
                    this.bindSelectedValues()
                },
                deep:true
            }
        },
        mounted(){
            this.initializeComponents()
            this.getDatasourceList()
            this.bindSelectedValues()
        },
        methods:{
            initializeComponents(){
                let selectDropdown = this.getDropdownComponent('selectedDatasource','object',{},'Select Datasource','','datasource_name')
                selectDropdown = this.bindStaticItemList(selectDropdown,this.availableDatasources) 
                this.selectedDatasource = selectDropdown

                let objectDropdown = this.getDropdownComponent('selectedObject','object',{},'Select Object','object_id','object_display_name')
                objectDropdown = this.bindStaticItemList(objectDropdown,this.availableObjects) 
                this.selectedObject = objectDropdown

                this.showAttachementColumn = this.getCheckboxComponent('showAttachementColumn','boolean', false, 'Show Attachment Column')
            },
            getDatasourceList(){
                var url = config.ENVIRONMENT_API_URL +'/get_all_env_datasources'
                this.loadDropdownList(url,'availableDatasources',{})
            },
            bindSelectedValues(){
                let selectedValues = _.cloneDeep(this.selectedValues)
                if(this.isAsComponent)
                    selectedValues = _.cloneDeep(this.componentDetails.selectedValue)
                if(_.isEmpty(selectedValues))
                    return
                if(!_.isEmpty(selectedValues.selectedDatasource)){
                    this.selectedDatasource.selectedValue = selectedValues.selectedDatasource
                    this.onDatasourceSelectionChanged("selectedDatasource",this.selectedDatasource.selectedValue)
                }

                if(!_.isEmpty(selectedValues.selectedObject)){
                    this.selectedObject.selectedValue = selectedValues.selectedObject
                    this.onObjectSelectionChanged("selectedObject",this.selectedObject.selectedValue)
                }

                this.showAttachementColumn.selectedValue = selectedValues.showAttachementColumn || false
            },
            getObjectList(datasource_id){
                var _this = this
                var url = config.GATEWAY_API_URL +'/get_all_objects_from_env'
                var additionalData = {
                    "datasource_id":datasource_id,
                    "filter": [{"column_name": "object_type", "operator": "_in_", "value1": ["BV", "GV"]}],
                    "sort": [{"column_name": "object_type", "type": "asc"}],
                    "page_size": "*"
                }
                this.loadDropdownList(url,'availableObjects',additionalData)
            },
            onDatasourceSelectionChanged(componentId,selectedValue){
                this.selectedDatasource.selectedValue = selectedValue
                if(this.showObjectSelection)
                    this.getObjectList(selectedValue.datasource_id)
                else{
                    this.$emit("onDatasourceSelectionChanged",selectedValue)
                }
                this.emitControlEvent()
            },
            onObjectSelectionChanged(componentId,selectedValue) {
                this.selectedObject.selectedValue = selectedValue
                if(_.isEmpty(this.selectedDatasource.selectedValue) || _.isEmpty(this.selectedObject.selectedValue))
                    return
                var objectOptionComponents = {
                        "selectedDatasource": this.selectedDatasource.selectedValue,
                        "selectedObject": this.selectedObject.selectedValue
                }
                this.$emit('onObjectSelectionChanged', objectOptionComponents);  // Emit the selected object to the parent component
                this.emitControlEvent()
            },
            onValueChanged(key,selectedValue){
                this[key].selectedValue = selectedValue
                this.emitControlEvent()
            },
            emitControlEvent(){
                if(!this.isAsComponent)
                    return
                let emitObject = {}
                
                if(!_.isEmpty(this.selectedDatasource) && !_.isEmpty(this.selectedObject)){
                    emitObject = {
                        "selectedObject":this.selectedObject.selectedValue,
                        "selectedDatasource":this.selectedDatasource.selectedValue,
                        "showAttachementColumn":this.showAttachementColumn.selectedValue
                    }
                }

                this.$emit("updateModelData",'edcobjectselection',emitObject)
            }
        },
    }
</script>