<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Environment List</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
        <edc-data-grid :dataList="tableList" @onDownload="onDownload" @onNew="addEnvironment" @onCellEvent="onCellEvent"  @onDelete="onDelete" @onEdit="onEdit" @onCopy="onCopy" @ondblClick="onEdit"></edc-data-grid>
        
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>
<script>
import config from '../../config.json'
import {deleteFromServer, post as postToServer } from '../../methods/serverCall.js';
import {SERVER_ERROR} from '../../data/client_message.js'
import { ENVIRONMENTLIST_BY_CLIENT, DELETE_ENVIRONMENT, EXPORT_ENVIRONMENT_DATA} from '../../data/url_constants.js';
import {CLIENT_SIDE} from '../../data/macros.js'
import getUserRole from '../../methods/GetUserRole.js'
import {ENVIRONMENT_SERVICE_ID, ENVIRONMENT_MGMT} from "../../data/macros.js"
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

// import data from './metaData.js'
export default {
    name: 'EDCEnvironmentList',
    data: function () {
        return {
            loader:false,
            tableList: {
                headers: [
                    { text: 'Name', value: 'name', width: '33%', title: 'Environment Name' },
                    { text: 'Environment For', value: 'environment_for', width: '33%', title: 'List Of Products For Which Enviroment Is Created' },
                    { text: 'Created By', value: 'created_by', width: '33%', title: 'Created By' },
                    { text: 'Created Date', value: 'tz_created_date', width: '33%', title: 'Created Date' },
                    { text: 'Created Time', value: 'tz_created_time', width: '33%', title: 'Created Time' },
                ],
                actions:[],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                disableDefaltSorting:true,
            },
            //  flag:true,
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid:true, 
            isloading:false,
            userRole:'',
        }
    },

    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions=[{'text':'new','key':"id", selectType:"single", 
        role:this.userRole.is_superadmin || getUserRole(this.userRole,ENVIRONMENT_SERVICE_ID, ENVIRONMENT_MGMT),index:1},    
                {'text':'copy','key':"id", selectType:"single", 
                role:this.userRole.is_superadmin || getUserRole(this.userRole,ENVIRONMENT_SERVICE_ID,ENVIRONMENT_MGMT),index:2},
                {'text':'edit','key':"id", selectType:"single", 
                role:this.userRole.is_superadmin || getUserRole(this.userRole,ENVIRONMENT_SERVICE_ID,ENVIRONMENT_MGMT),index:3},
                {'text':'delete','key':"id", selectType:"multiple", 
                role:this.userRole.is_superadmin || getUserRole(this.userRole,ENVIRONMENT_SERVICE_ID,ENVIRONMENT_MGMT),index:4}];
        this.get_environment_by_client_id();
    },
    methods: {
        showError(error_message){
            this.loader = false;
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = error_message || SERVER_ERROR;
        },
        onDownload(downloadParams, filterArray){
            let _this = this; 
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(_.cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "client_id": this.$session.get('client_id'),
                "row_option": downloadParams.recordType,
                "env_id": "",
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.ENVIRONMENT_API_URL+EXPORT_ENVIRONMENT_DATA;    
            postToServer(this, url, inputJson).then(response => {
                var url = config.ENVIRONMENT_API_URL + "/static/" + response;
                window.open(url, '_blank');
                _this.loader=false;
                
            }).catch(objError => {
                _this.showError(objError)
            });
        },
        onCellEvent(record){
            this.onEdit(record);
        },
        addEnvironment(){
            let _this = this;
            _this.$router.push('/manageenvironment');
        },
         get_environment_by_client_id(envIpJson){
            let _this = this;    
            _this.loader=true;
            var url = config.ENVIRONMENT_API_URL+'/get_environment_list';
            var client_id = this.$session.get('client_id')
            postToServer(this, url, {"client_id":client_id}).then(envirnentResponse => {
                _this.loader = false;
                _.forEach(envirnentResponse,(obj)=>{
                    obj.id = obj.env_id
                })
                _this.tableList.rows = envirnentResponse;
                _this.tableList.total_count =envirnentResponse.total; 
            }).catch(EnvError => {
                _this.showError(EnvError)
          })
        },
        onDelete:function(record){
            let _this = this;
            _this.deleteEnvironment(record);
        },
        onCopy:function(record){
            let _this = this;
            this.$router.push({ name: 'manageenvironment', params: { environment_id: record.id, type:'copy' }})
        },
        onEdit:function(record){
            let _this = this;
            this.$router.push({ name: 'manageenvironment', params: { environment_id: record.id, type:'edit' }})
        },
        deleteEnvironment(records){
            let _this = this; 
            var url = config.ENVIRONMENT_API_URL+DELETE_ENVIRONMENT
            var client_id = this.$session.get('client_id')
            var data = {
                "client_id":client_id,
                "environment_list":_.map(records,"id"),
                "user_id":this.$session.get('email')
            }
            postToServer(_this, url, data).then(envirnentResponse => {
                _this.get_environment_by_client_id();
            }).catch(EnvError => {
                _this.showError(EnvError)
          });
        },
    }
}
</script>
<style scoped>

</style>