<template>
    <div>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6" xxl="6">
                <v-row no-gutters>
                    <v-col cols="12" class="spacing-slot" id="slot1" @dragover.prevent @drop="dropOnDiv(slot1Obj.slotNumber)">
                        <layout-slot-card id="widget1"
                        heightClass="dynamic-height-50" :slotNumber="slot1Obj.slotNumber"
                        :draggedWidgetId="slot1Obj.draggedWidgetId" :heightLimit="slot1Height"
                        @deAttachWidget="deAttachWidget($event,1)"
                        @onWidgetChange="onWidgetChange"
                        :dashboardId="dashboardId" :isDesigner="isDesigner"/>
                        
                    </v-col>
                    
                    <v-col cols="12" class="spacing-slot" id="slot2" @dragover.prevent @drop="dropOnDiv(slot2Obj.slotNumber)">
                        <layout-slot-card id="widget2"
                        heightClass="dynamic-height-50" :slotNumber="slot2Obj.slotNumber"
                        :draggedWidgetId="slot2Obj.draggedWidgetId" :heightLimit="slot2Height"
                        @onWidgetChange="onWidgetChange" :dashboardId="dashboardId"
                        @deAttachWidget="deAttachWidget($event,2)"
                        :isDesigner="isDesigner"/>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6" xxl="6"
            class="spacing-slot" id="slot3" @dragover.prevent @drop="dropOnDiv(slot3Obj.slotNumber)">
                <layout-slot-card id="widget3"
                heightClass="dynamic-height-100" :slotNumber="slot3Obj.slotNumber"
                :draggedWidgetId="slot3Obj.draggedWidgetId" :heightLimit="slot3Height"
                @onWidgetChange="onWidgetChange" :dashboardId="dashboardId"
                @deAttachWidget="deAttachWidget($event,3)"
                :isDesigner="isDesigner"/>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import {mixinLayout} from "../mixins/layout"
    export default{
        name:"ThreeSlotsWithStackedRow",
        mixins:[mixinLayout],
        data(){
            return{
                slot1Height:{
                    "minimum":10,
                    "maximum":50,
                    "unit":"vh"
                },
                slot2Height:{
                    "minimum":10,
                    "maximum":50,
                    "unit":"vh"
                },
                slot3Height:{
                    "minimum":40,
                    "maximum":100,
                    "unit":"vh"
                },

                // work needed on breakpoint settings
                slot1UISettings:{
                    "sm":true,
                    "md":true,
                    "lg":true,
                    "xl":true,
                    "xxl":true
                },
                slot2UISettings:{
                    "sm":true,
                    "md":true,
                    "lg":true,
                    "xl":true,
                    "xxl":true
                },
                slot3UISettings:{
                    "sm":true,
                    "md":true,
                    "lg":true,
                    "xl":true,
                    "xxl":true
                }
            }
        },
        mounted(){
            console.log("breakpoint",this.$vuetify.breakpoint)
        },
        methods:{
            
        }
    }
</script>