/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand_panel_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="-inkscape-stroke:none" d="M1.059.793a.8.8 0 00-.795.795v3.173a.8.8 0 00.795.796H5.29a.8.8 0 00.795-.796V1.588A.8.8 0 005.29.793zm0 .53H5.29a.26.26 0 01.266.265v.396H.793v-.396a.26.26 0 01.266-.266zm-.266.925h.662v2.78H1.06a.261.261 0 01-.266-.267zm.928 0h3.836V4.76a.26.26 0 01-.266.267h-3.57zm1.719.662l-.53.397-.529.397.53.397.529.397v-.53h1.323V3.44H3.44z" color="#000"/>'
  }
})
