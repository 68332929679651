import { render, staticRenderFns } from "./dashboardLoader.vue?vue&type=template&id=b93ad418&scoped=true&"
import script from "./dashboardLoader.vue?vue&type=script&lang=js&"
export * from "./dashboardLoader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b93ad418",
  null
  
)

export default component.exports