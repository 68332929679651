<template>
    <v-row class="EDC-Row">
        <v-col class="EDC-Col">
            <edc-pathfinder  :isWidget="true" 
            :key="widgetUniqueKey" :widgetUniqueKey="widgetUniqueKey" :widgetFilters="widgetFilters"
            :externalDataToLoad="dataToLoad"/>
        </v-col>
    </v-row>
</template>
<style scoped>

</style>
<script>
import {mapActions} from 'vuex'
import { v4 as EDCuuidv4 } from 'uuid';
import edcPathfinder from './../../pathfinder/edcPathfinder.vue'
export default{
    name:"GridWidget",
    components:{
        'edc-pathfinder':edcPathfinder
    },
    props:{
        dataObject:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        widgetDivHeight:{
            type:Number,
            default:0
        }
    },
    data(){
        return{
            widgetUniqueKey:EDCuuidv4(), // this is variable is used to make sure same widget has own pathfinder grid
            widgetHeightChangeObjserver:null,
            isFirstLoadDone:false,
            dataToLoad:{}
        }
    },
    computed:{
        rowCount(){
            let count = 10
            if(this.widgetDivHeight>0){
                count = parseInt(Math.ceil(this.widgetDivHeight-80)/35)
            }
            if(count < 1)
                count = 1
            if(count > 25)
                count = 25
            return count
        },
        widgetFilters(){
            return {
                "showAttachmentColumn":this.dataObject.showAttachementColumn || false,
                "rowCount":this.rowCount
            }
        }
    },
    watch:{
        'dataObject':{
            handler(newValue){
                this.manageDataObject()
            }
        },
        'widgetUniqueKey':{
            handler(newValue){
                this.$emit("onWidgetUniqueKeyChange",newValue)
            }
        },
        'widgetDivHeight':{
            handler(newValue){
                clearTimeout(this.widgetHeightChangeObjserver)
                this.widgetHeightChangeObjserver = setTimeout(()=>{
                    this.onHeightChange()
                },1000)
            }
        }
    },
    mounted(){
        this.manageDataObject()
        setTimeout(()=>{
            this.isFirstLoadDone = true
        },2000)
    },
    methods: {
        ...mapActions(['setDataviewerProps']),
        manageDataObject(){
            var _this = this
            this.widgetUniqueKey = EDCuuidv4()
            this.setDataviewerProps({})
            if(_.isEmpty(this.dataObject))
                return
            let dataObject = _.cloneDeep(this.dataObject)
            let envDetails = {"env_id":_this.$session.get("last_selected_env_id"),"id":_this.$session.get("last_selected_env_id")}
           
            if(dataObject.selected_bo_tbl.object_type === 'GV'){
                let objectType = 'BV'
                if(dataObject.parent_object_type === 'DBO')
                    objectType = 'table'
                dataObject.selected_bo_tbl['object_type'] = objectType
                dataObject.selected_bo_tbl['object_id'] = dataObject.parent_object_id
                dataObject.selected_bo_tbl['type'] = objectType
                dataObject.selected_bo_tbl['title'] = dataObject.parent_object_id
            }

            this.dataToLoad = {
                'selected_env':envDetails,
                'selected_ds':dataObject.selected_ds,
                'selected_bo_tbl':dataObject.selected_bo_tbl,
                'dictionary_data':dataObject.selected_ds.dictionary_data,
                'object_librarian_data':dataObject.selected_ds.object_librarian_data,
                'control_data':dataObject.selected_ds.control_data,
                'ds_list_for_backend':dataObject.ds_list_for_backend,
                'EnvironmentList':[envDetails],
                'isSuperAdmin':true,
                'securityModelByEnv':[],
                'ds_list':dataObject.ds_list,
                'acceleration_type':dataObject.selected_ds.acceleration_type,
                'whichSide':"wholepage",
                'isArchivistCall':false,
                'archivistObj':{},
                'widgetUniqueKey':this.widgetUniqueKey,
            }
            this.$emit("onLoadingChange",true)
            // setTimeout(()=>{
            //     _this.setDataviewerProps(_.cloneDeep(dataToPass))
            // },100)

        },
        onHeightChange(){
            if(this.isFirstLoadDone)
                this.manageDataObject()
        }
    },
}
</script>