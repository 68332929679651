<template>
    <div>
        <!-- <search-engine v-if="isDataLoaded" :showEnvOpt="false" :showOptions="false"
        :showGO="showGO" :loadUserPreference="false"
        :autoSelectedEnvId="EnvironmentId" :autoSelectedDatasourceId="selectedDatasourceId"/> -->
        <edc-pathfinder :searchCompProps="searchCompProps"/>
    </div>
</template>
<style>
    
</style>
<style scoped>
    
</style>
<script>
    import edcPathfinder from '../../pathfinder/edcPathfinder.vue';
    export default{
        name:"searchWidget",
        components: {
            'edc-pathfinder':edcPathfinder
        },
        props:{
            widgetDivHeight:{
                type:Number,
                default:0
            },
            dataObject:{
                type:Object,
                default:()=>{

                }
            },
        },
        data(){
            return {
                
            }
        },
        computed:{
            isDataLoaded(){
                return !_.isEmpty(this.dataObject)
            },
            showGO(){
                return this.isDataLoaded?this.dataObject.searchModifiers.showGoButton:false
            },
            openInNewTab(){
                return this.isDataLoaded?this.dataObject.searchModifiers.openInNewTab:false
            },
            Environment(){
                return this.$session.get('selected_env')
            },
            EnvironmentId(){
                return this.$session.get('selected_env').id
            },
            selectedDatasourceId(){
                return this.dataObject.selectedDatasourceId || ''
            },
            searchOptions(){
                return this.isDataLoaded?this.dataObject.searchOptions:{}
            },
            searchCompProps(){
                // if you add new property then add it in pathfinder mixin
                return {
                    "showEnvOpt":false,
                    "showOptions":false,
                    "showGO":this.showGO,
                    "loadUserPreference":false,
                    "autoSelectedEnvId":this.EnvironmentId,
                    "autoSelectedDatasourceId":this.selectedDatasourceId,
                    "openInNewTab":this.openInNewTab,
                    "searchOptions":this.searchOptions
                }
            }
        },
        watch:{
            'widgetDivHeight':{
                handler(newValue){

                }
            },
            'dataObject':{
                handler(newValue){

                }
            },
        },
        mounted(){

        },
        methods: {
               
        },
    }
</script>
