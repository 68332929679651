<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row" v-show="showEnvironementSelection">
                    <v-col class="EDC-Col">
                        <v-card>
                            <v-card-text class="d-flex justify-center align-center" style="height:80vh;">
                                <edc-search-engine :key="whichSide" :whichSide="whichSide"
                                @loadData="loadData" :searchCompProps="searchCompProps"/>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-card :class="{'EDC-Pathfinder-Grid-Main-Container':!isWidget}" :elevation="isWidget?0:1" v-if="!showEnvironementSelection">
                            <template v-for="(datalistLevel1) in mainDataList">
                                <v-row class="EDC-Row" :key="datalistLevel1.uniqueId+'row'">
                                    <v-col class="EDC-Col">
                                        <v-row :class="{'EDC-Grid-Row':!isWidget,'EDC-Row':isWidget}">
                                            <v-col class="EDC-Col">
                                                <edc-pathfinder-grid :key="datalistLevel1.uniqueId"
                                                :tableRequestObject="datalistLevel1.tableRequestObject"
                                                :datasourceObject="datasourceObject" @closePanel="closePanel"
                                                :securityObject="securityObject" :perPage="perPage" @loadMyChild="loadChildTable" 
                                                :childrenDataList="datalistLevel1.childrenDataList" 
                                                :externalFilters="datalistLevel1.externalFilters"
                                                :openModeType="openModeType"  
                                                :secondaryDatasourceId="secondaryDatasourceId" 
                                                :resetMergedData="resetMergedData"
                                                :isWidget="isWidget"
                                                :widgetUniqueKey="widgetUniqueKey"
                                                :widgetFilters="widgetFilters"
                                                :redirectionFilters="datalistLevel1.redirectionFilters"
                                                ></edc-pathfinder-grid>
                                            </v-col>
                                        </v-row>
                                        <v-row class="EDC-Row" v-if="datalistLevel1.childrenDataList">
                                            <v-col class="EDC-Col">
                                                <template v-for="(datalistLevel2) in datalistLevel1.childrenDataList">
                                                    <v-row class="EDC-Row" :key="datalistLevel2.uniqueId+'row'">
                                                        <v-col class="EDC-Col">
                                                            <v-row :class="{'EDC-Grid-Row':!isWidget,'EDC-Row':isWidget}">
                                                                <v-col class="EDC-Col">
                                                                    <edc-pathfinder-grid :key="datalistLevel2.uniqueId"
                                                                    :tableRequestObject="datalistLevel2.tableRequestObject"
                                                                    :datasourceObject="datasourceObject" @closePanel="closePanel"
                                                                    :securityObject="securityObject" :perPage="childPerPage"  
                                                                    @loadMyChild="loadChildTable" 
                                                                    :childrenDataList="datalistLevel2.childrenDataList" 
                                                                    :externalFilters="datalistLevel2.externalFilters"
                                                                    :openModeType="openModeType" 
                                                                    :secondaryDatasourceId="secondaryDatasourceId"
                                                                    :isWidget="isWidget"
                                                                    >
                                                                    </edc-pathfinder-grid>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="EDC-Row" v-if="datalistLevel2.childrenDataList">
                                                                <v-col class="EDC-Col">
                                                                    <template v-for="(datalistLevel3) in datalistLevel2.childrenDataList">
                                                                        <v-row class="EDC-Row" :key="datalistLevel3.uniqueId+'row'">
                                                                            <v-col class="EDC-Col">
                                                                                <v-row :class="{'EDC-Grid-Row':!isWidget,'EDC-Row':isWidget}">
                                                                                    <v-col class="EDC-Col">
                                                                                        <edc-pathfinder-grid :key="datalistLevel3.uniqueId"
                                                                                        :tableRequestObject="datalistLevel3.tableRequestObject"
                                                                                        :datasourceObject="datasourceObject" @closePanel="closePanel"
                                                                                        :securityObject="securityObject" :perPage="childPerPage"  
                                                                                        @loadMyChild="loadChildTable" 
                                                                                        :childrenDataList="datalistLevel3.childrenDataList" 
                                                                                        :externalFilters="datalistLevel3.externalFilters"
                                                                                        :openModeType="openModeType"  
                                                                                        :secondaryDatasourceId="secondaryDatasourceId" 
                                                                                        :isWidget="isWidget"
                                                                                        >
                                                                                        </edc-pathfinder-grid>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="EDC-Row" v-if="datalistLevel3.childrenDataList">
                                                                                    <v-col class="EDC-Col">
                                                                                        <template v-for="(datalistLevel4) in datalistLevel3.childrenDataList">
                                                                                            <v-row class="EDC-Row" :key="datalistLevel4.uniqueId+'row'">
                                                                                                <v-col class="EDC-Col">
                                                                                                    <v-row :class="{'EDC-Grid-Row':!isWidget,'EDC-Row':isWidget}">
                                                                                                        <v-col class="EDC-Col">
                                                                                                            <edc-pathfinder-grid :key="datalistLevel4.uniqueId"
                                                                                                            :tableRequestObject="datalistLevel4.tableRequestObject"
                                                                                                            :datasourceObject="datasourceObject" @closePanel="closePanel"
                                                                                                            :securityObject="securityObject" :perPage="childPerPage"  
                                                                                                            @loadMyChild="loadChildTable" 
                                                                                                            :childrenDataList="datalistLevel4.childrenDataList" 
                                                                                                            :externalFilters="datalistLevel4.externalFilters"
                                                                                                            :openModeType="openModeType" 
                                                                                                            :secondaryDatasourceId="secondaryDatasourceId"
                                                                                                            :isWidget="isWidget"
                                                                                                            >
                                                                                                            </edc-pathfinder-grid>
                                                                                                        </v-col>
                                                                                                    </v-row>
                                                                                                    <v-row class="EDC-Row" v-if="datalistLevel4.childrenDataList">
                                                                                                        <v-col class="EDC-Col">
                                                                                                            <template v-for="(datalistLevel5) in datalistLevel4.childrenDataList">
                                                                                                                <v-row class="EDC-Row" :key="datalistLevel5.uniqueId+'row'">
                                                                                                                    <v-col class="EDC-Col">
                                                                                                                        <v-row :class="{'EDC-Grid-Row':!isWidget,'EDC-Row':isWidget}">
                                                                                                                            <!-- @loadMyChild="loadChildTable"  
                                                                                                                                :childrenDataList="datalistLevel5.childrenDataList"  -->
                                                                                                                            <v-col class="EDC-Col">
                                                                                                                                <edc-pathfinder-grid :key="datalistLevel5.uniqueId"
                                                                                                                                :tableRequestObject="datalistLevel5.tableRequestObject"
                                                                                                                                :datasourceObject="datasourceObject" @closePanel="closePanel"
                                                                                                                                :securityObject="securityObject" :perPage="childPerPage"  
                                                                                                                                :childrenDataList="[]"
                                                                                                                                :externalFilters="datalistLevel5.externalFilters" 
                                                                                                                                :openModeType="openModeType" 
                                                                                                                                :secondaryDatasourceId="secondaryDatasourceId"
                                                                                                                                :isWidget="isWidget"
                                                                                                                                >
                                                                                                                                </edc-pathfinder-grid>
                                                                                                                            </v-col>
                                                                                                                        </v-row>
                                                                                                                    </v-col>
                                                                                                                </v-row>
                                                                                                            </template>
                                                                                                        </v-col>
                                                                                                    </v-row>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </template>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </template>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>
        <v-snackbar v-model="snackbar" :right=true :top=true :timeout=snackbartimeout :color='colorValue'>
            {{ snackbartext }}
        </v-snackbar>
    </v-container>
</template>
<style scoped>
.EDC-Grid-Row{
    padding:4px !important;
    margin: 0px !important;
}

.EDC-Pathfinder-Grid-Main-Container{
    min-height: 80vh;
}
</style>

<script>
import edcSearchEngine from  './../searchEngine/searchEngineRevised.vue'

import {getTableObj} from './../../constants/pathfinder.js'
import edcPathfinderGrid from './edcPathfinderGrid.vue'
import {mixinPathfinder} from '../../mixins/pathfinder.js'
import {post as postToServer } from './../../methods/serverCall.js';
import config from '../../config.json'

export default {
    name:'edcPathFinderContainer',
    components:{
        'edc-search-engine':edcSearchEngine,
        'edc-pathfinder-grid':edcPathfinderGrid
    },
    mixins:[mixinPathfinder],
    props:{
        whichSide:{
            type:String,
            default:'wholepage'
        },
        openModeType:{
            type:String,
            default:'single'
        },
        startOverCount:{
            type:Number,
            default:1
        },
        changeModeProps:{
            type:Object,
            default:()=>{
                return {}
            }
        },
    },
    data(){
        return{
            mainDataList:[],
            EnvironmentList:[],
            datasourceObject:{},
            selectedObject:{},
            archivistObj:{},
            perPage:10,
            childPerPage:5,
            secondaryDatasourceId:-1,
            resetMergedData:false,
            redirectionFilters:[]
        }
    },
    computed: {
        showEnvironementSelection(){
            return this.mainDataList.length === 0 && !this.isWidget
        },
        securityObject(){
            return {"isSuperAdmin":this.isSuperAdmin,"securityModelByEnv":this.securityModelByEnv}
        },
        isRightSide(){
            return this.whichSide === 'right'
        },
    },
    watch:{
        '$store.state.dataviewerProps':{
            handler(newValue){
                this.manageDataLoad(newValue)
            }
        },
        'startOverCount':{
            handler(){
                this.mainDataList = []
            }
        },
        'mainDataList':{
            handler(newValue){
                let pathFinderObj = _.cloneDeep(this.$store.state.pathfinderObject)
                let leftSideObj = pathFinderObj['left'] || {}
                let rightSideObj = pathFinderObj['right'] || {}
                
                if(!newValue || !newValue.length){
                    if(!_.isEmpty(pathFinderObj)){
                        if(this.isRightSide)
                            rightSideObj = {}
                        else
                            leftSideObj = {}
                    }
                }else{
                    if(this.isRightSide)
                        rightSideObj = {"dataList":newValue, "selectedObj":this.selectedObject}
                    else
                        leftSideObj = {"dataList":newValue, "selectedObj":this.selectedObject}
                }
                if(!this.isWidget)
                    this.setPathfinderObject({"left":leftSideObj, "right":rightSideObj})
            },
            deep:true
        },
        'openModeType':{
            handler(newValue){

            }
        },
        'externalDataToLoad':{
            handler(newValue){
                this.manageExternalDataToLoad()
            }
        },
        changeModeProps:{
            handler(newVal){
                this.manageChangedMode()
            }
        }
    },
    mounted(){
        this.isJdeWorldAccelerator()
        if(!_.isEmpty(this.$store.state.dataviewerProps)){
            if(this.$store.state.dataviewerProps.taskLoad){
                // this.loader = true
                this.manageDataLoad(this.$store.state.dataviewerProps)
            }
        }
        this.manageExternalDataToLoad()
    },
    methods:{
        manageExternalDataToLoad(){
            if(_.isEmpty(this.externalDataToLoad))
                return
            this.loadData(_.cloneDeep(this.externalDataToLoad))
        },
        manageDataLoad(newValue){
            if(newValue && newValue.whichSide === this.whichSide){

                if(this.isWidget){
                    // alert(this.widgetUniqueKey)
                    // alert(newValue.widgetUniqueKey)
                    if(this.widgetUniqueKey != newValue.widgetUniqueKey)
                        return
                }
                this.loadData(newValue)
            }
        },
        manageChangedMode(){
            /*
                modeActionCode:
                1 & 3 -> NA (No action needed)
                2,5,6 -> CCLP (Clear Childs & Reload Parents)
                4 CCCRLLP (Clear Childs, Clear Righ & Reload Left Parents)
            */
           var _this = this
            let modeActionCode = this.changeModeProps.codeForLeftSide
            this.resetMergedData = false
            this.secondaryDatasourceId = -1
            if(this.mainDataList.length > 0 && modeActionCode === 'NA')
                return
            if(modeActionCode === 'CCLP' || modeActionCode === 'CCCRLLP'){
                for(let i=0;i<this.mainDataList.length;i++){
                    this.mainDataList[i].childrenDataList = []
                }
            }
            if(this.openModeType === 'merged')
                this.secondaryDatasourceId = this.changeModeProps.mergedDatasourceIndex
            if (modeActionCode === 'CCLP' || modeActionCode === 'CCCRLLP')
                // Merging/unmerging happend, we have to reload data.
                this.resetMergedData = true
            setTimeout(()=>{
                _this.resetMergedData = false
            },1000)
        },
        loadData(data){
            let _this = this
            _this.datasourceObject = {
                "selectedEnvironment":_.cloneDeep(data.selected_env),
                "selectedDatasource":_.cloneDeep(data.selected_ds),
                "dsList":_.cloneDeep(data.ds_list),
                "datasourceForBackend":_.cloneDeep(data.ds_list_for_backend)
            }
            _this.selectedEnvironment = data.selected_env
            _this.selectedDatasource = data.selected_ds
            _this.selectedObject = data.selected_bo_tbl
            _this.EnvironmentList = data.EnvironmentList
            _this.isSuperAdmin = data.isSuperAdmin
            _this.securityModelByEnv = data.securityModelByEnv
            _this.dsList = data.ds_list
            _this.datasourceForBackend = data.ds_list_for_backend
            _this.archivistObj = data.archivistObj
            _this.redirectionFilters = data.redirectionFilters
            if(!_this.selectedObject || !_this.selectedObject.title)
                return

            _this.manageSelectedObject(_.cloneDeep(_this.selectedObject))
        },
        pushDataToDatalist(dataList, tableRequestObject, externalFilters={}){
            let redirectionFilters = _.cloneDeep(this.redirectionFilters) || []
            this.redirectionFilters = []
            dataList.push({"tableRequestObject":tableRequestObject,"uniqueId":tableRequestObject.uniqueId,
            "childrenDataList":[], "externalFilters":externalFilters,
            "redirectionFilters":redirectionFilters})
        },
        manageSelectedObject(selectedObject){
            var _this = this
            let tableRequestObject = getTableObj()
            console.log(this.mainDataList)
            if(["T","V","A","TABLE","VIEW","ALIAS"].indexOf(selectedObject.type.toUpperCase()) > -1){
                tableRequestObject['tableName'] = selectedObject.title
                tableRequestObject['table_name'] = selectedObject.title
                tableRequestObject['datasourceIndex'] = selectedObject.datasource_index || _this.selectedDatasource.datasource_index
                // tableRequestObject['datasourceDetails'] = _this.allDatasources[tableRequestObject['datasourceIndex']]
                tableRequestObject['objectType'] = selectedObject.type
                tableRequestObject['parentId'] = selectedObject.parentId || ''
                tableRequestObject['objectDescription'] = selectedObject.description
                if(!_.isEmpty(this.archivistObj)){
                    tableRequestObject['archivistObj'] = _this.selectedObject
                    tableRequestObject['archivistObj']['request_for'] = _this.archivistObj.request_for
                }
                if(this.selectedDatasource.is_reporting_ds){
                    tableRequestObject['isReportingTable'] = true
                }
                // this.mainDataList.push({"tableRequestObject":tableRequestObject,"uniqueId":tableRequestObject.uniqueId})
                _this.pushDataToDatalist(this.mainDataList, tableRequestObject)
            }
            else if(selectedObject.type.toUpperCase()=='BO'){
                _this.manageBusinessObjectSelection(_this,selectedObject)
            }
            else if(selectedObject.type.toUpperCase()=== "BV")
                _this.manageBusinessViewSelection(_this,selectedObject)
            else
                _this.showError("Unsupported object type is loaded, object type is "+selectedObject.type)
        },
        manageBusinessObjectSelection(_this,selectedObject){
            _this.perPage = 10
            var requestData = {
                "object_id":selectedObject.object_id,
                "object_name":selectedObject.object_name,
                "object_type":"BO",
                "env_id":this.selectedEnvironment.id,
                "datasource_id":this.selectedDatasource.id,
                "user_id":this.userId,
                "client_id":this.clientId,
                "datasourceIndex":_this.selectedDatasource.datasource_index
            }
            _this.loader = true
            postToServer(_this,config.DATA_VIEWER_URL+'/get_business_object_root_tables',requestData).then(response=>{
                _this.loader = false
                if(response.length > 1)
                    _this.perPage = 5
                if(response){
                    response.forEach((obj)=>{
                        let tableRequestObject = getTableObj()
                        tableRequestObject['tableName'] = obj.table_name
                        tableRequestObject['table_name'] = obj.table_name
                        tableRequestObject['datasourceIndex'] = obj.datasource_index || _this.selectedDatasource.datasource_index
                        // tableRequestObject['datasourceDetails'] = _this.allDatasources[tableRequestObject['datasourceIndex']]
                        tableRequestObject['objectType'] = "T"
                        tableRequestObject['parentId'] = ''
                        tableRequestObject['objectDescription'] = ''
                        tableRequestObject['associatedBusinessObjectName'] = obj.associated_business_object_name
                        tableRequestObject['associatedBusinessObjectId'] = obj.associated_business_object_id
                        tableRequestObject['mergeTableDetails'] = obj.merge_tables_detail
                        tableRequestObject['nodeFilters'] = obj.node_filters
                        tableRequestObject['context_object_id'] = _this.selectedObject.object_id
                        tableRequestObject['context_object_type'] = _this.selectedObject.type
                        _this.pushDataToDatalist(_this.mainDataList, tableRequestObject)
                    })
                }
            }).catch(error=>{
                _this.loader = false
                _this.showError(error)
            })
        },
        manageBusinessViewSelection(_this,selectedObject){
            var requestData = {
                "object_id":selectedObject.object_id,
                "object_name":selectedObject.object_name,
                "object_type":"BV",
                "env_id":this.selectedEnvironment.id,
                "datasource_id":this.selectedDatasource.id,
                "user_id":this.userId,
                "client_id":this.clientId,
                "ds_info":this.selectedDatasource,
                "all_available_ds":this.datasourceForBackend,
                "is_super_admin":this.isSuperAdmin,
                "security_model":this.securityModelByEnv
            }
            postToServer(_this,config.DATA_VIEWER_URL+'/get_business_view_details',requestData).then(response=>{
                let tableRequestObject = getTableObj()
                tableRequestObject['uniqueId'] = response.unique_id
                tableRequestObject['tableName'] = selectedObject.object_id
                tableRequestObject['table_name'] = selectedObject.object_id
                tableRequestObject['datasourceIndex'] = response.primary_datasource_index
                tableRequestObject['objectType'] = "BV"
                tableRequestObject['business_view_details'] = response
                _this.pushDataToDatalist(_this.mainDataList, tableRequestObject)
            }).catch(error=>{
                _this.showError(error)
            })
        },
        closePanel(uniqueId,error){
            this.getGridObjectById(uniqueId,this.mainDataList,true)
            if(error)
                this.showError(error)
        },
        getGridObjectById(uniqueId,dataList,is_delete_request=false){
            var _this = this
            if(!dataList)
                dataList = _this.mainDataList
            for(var i=0;i<dataList.length;i++){
                let currentObj = dataList[i]
                if(currentObj.uniqueId === uniqueId){
                    if(is_delete_request){
                        dataList.splice(i,1)
                        return true
                    }
                    else
                    return currentObj
                } 
                else
                    if(!currentObj.childrenDataList || !currentObj.childrenDataList.length)
                        continue
                    let is_obj_found = _this.getGridObjectById(uniqueId,currentObj.childrenDataList,is_delete_request)
                        if (is_obj_found === true || !_.isEmpty(is_obj_found)){
                            return is_obj_found
                        }
            }
            return {}
        },
        loadChildTable(childId,childObj,parentFilters, loadType){
            var _this = this

            // first check is the given object already loaded or not. If its loaded fetch that object and change the external filters
            let gridObj = _this.getGridObjectById(childId)
            if(!_.isEmpty(gridObj)){
                gridObj['externalFilters'] = parentFilters
                // we are clearing the child once parent get refreshed.
                _this.clearMyChildren(gridObj.uniqueId)
                return
            }

            var tableRequestObject = getTableObj()
            
            // Object.assign(tableObj, childObj)
            // some properties we have to map to matchup with our table object
            tableRequestObject['tableName'] = childObj['table_name']
            tableRequestObject['objectName'] = childObj['table_name']
            tableRequestObject['uniqueId'] = childId
            tableRequestObject['datasourceIndex'] = childObj.associated_datasource_index != undefined?childObj.associated_datasource_index : _this.selectedDatasource.datasource_index
            // tableObj['datasourceDetails'] = 
            tableRequestObject['parentId'] = childObj['parent_id']
            tableRequestObject['mergeTableDetails'] = childObj['merge_tables_detail'] || {}
            tableRequestObject['associatedBusinessObjectName'] = childObj['associated_business_object_name']
            tableRequestObject['associatedBusinessObjectId'] = childObj['associated_business_object']

            // now fetch parent object
            let parentGridObj = _this.getGridObjectById(tableRequestObject['parentId'])
            if(_.isEmpty(parentGridObj)){
                return _this.showError("Parent grid not found for table "+tableRequestObject['tableName'])
            }
            tableRequestObject['context_object_id'] = _this.selectedObject.object_id
            tableRequestObject['context_object_type'] = _this.selectedObject.type
            _this.pushDataToDatalist(parentGridObj.childrenDataList, tableRequestObject, parentFilters)
        },
        clearMyChildren(parentId){
            var _this = this
            let gridObj = _this.getGridObjectById(parentId)
            if(gridObj.childrenDataList && gridObj.childrenDataList.length){
                for(let i=0;i<gridObj.childrenDataList.length;i++){
                    gridObj.childrenDataList[i]['externalFilters'] = {}
                    _this.clearMyChildren(gridObj.childrenDataList[i].uniqueId)
                }
            }
        },
        isJdeWorldAccelerator(){
            var _this = this
            postToServer(_this,config.JDE_FORMATER_URL+'/is_jde_world_erp',{}).then(response=>{
                _this.$session.set("is_jde_world_erp", response.is_jde_erp_world)
            }).catch(err=>{

            })
        }
    }
}
</script>