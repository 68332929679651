import {getDropdownComponent,bindStaticItemList,getTextboxComponent, getCheckboxComponent,applyNumberPropsToText} from "./../methods/widgets/widget.js"
import controlComponents from "./../components/dashboardManagement/widgetOptions/controlComponents.vue";
import { post as postToServer } from '@/methods/serverCall.js';
export const mixinWidget={
    components:{
        'custom-control':controlComponents
    },
    data() {
        return {
            
        }
    },
    computed:{
        selectedEnvironment(){
            return this.$session.get('selected_env')
        },
        isEnvironmentSelected(){
            return !_.isEmpty(this.selectedEnvironment)
        },
        envId(){
            return this.isEnvironmentSelected?this.selectedEnvironment.id:0
        },
        envName(){
            return this.isEnvironmentSelected?this.selectedEnvironment.name:''
        },
        clientId(){
            return this.$session.get('client_id')
        },
        userName(){
            return this.$session.get("email")
        },
        userId(){
            return this.$session.get('user_id')
        },
    },
    methods:{
        getDropdownComponent,
        bindStaticItemList,
        getTextboxComponent,
        getCheckboxComponent,
        applyNumberPropsToText,
        loadDropdownList(url,controlToBind,additionalParams={}){
            var _this =  this
            var commonData = {
                "env_id": this.envId,
                "client_id": this.$session.get("client_id"),
                "user_id": this.$session.get("user_id"),
                "username": this.$session.get("email"),
            }
            Object.assign(commonData,additionalParams)
            this.loader = true
            postToServer(this,url,commonData).then(response=>{
                this.loader = false
                this[controlToBind] = response.result || response
            }).catch(err=>{
                this.loader = false
                alert(err)
            })
        }
    }
}