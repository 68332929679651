/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pathfinder_merged_view': {
    width: 16,
    height: 16,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M35.1 23.9v16.2h25V23.9h-25zm2.8 15.2h-1.8v-3h1.8v3zm0-4h-1.8v-3h1.8v3zm0-4h-1.8v-3h1.8v3zm0-4h-1.8v-2.2h1.8v2.2zm5.6 12h-4.6v-3h4.6v3zm0-4h-4.6v-3h4.6v3zm0-4h-4.6v-3h4.6v3zm0-4h-4.6v-2.2h4.6v2.2zm8.4 12h-7.4v-3h7.4v3zm0-4h-7.4v-3h7.4v3zm0-4h-7.4v-3h7.4v3zm0-4h-7.4v-2.2h7.4v2.2zm7.2 12h-6.2v-3h6.2v3zm0-4h-6.2v-3h6.2v3zm0-4h-6.2v-3h6.2v3zm0-4h-6.2v-2.2h6.2v2.2zM34.5 17.27l-1.8 1.57 7.41 1.35-1.85-6.55-1.35 1.45c-2.55-2.06-6.56-.69-6.74-.63l-2.69.94 2.85.03c.03 0 2.12.05 4.17 1.84zm2.08-1.13l.37.37.83-.89.93 3.29-3.79-.69 1.05-.92-.38-.38a8.914 8.914 0 00-2.89-1.94c1.28-.09 2.83.09 3.88 1.16zM13 6c-5.13 0-9 2.15-9 5v12c0 2.85 3.87 5 9 5s9-2.15 9-5V11c0-2.85-3.87-5-9-5zm7 17c0 1.42-2.99 3-7 3s-7-1.58-7-3v-8.79c1.62 1.1 4.11 1.79 7 1.79s5.38-.68 7-1.79V23zm-7-9c-4.01 0-7-1.58-7-3s2.99-3 7-3 7 1.58 7 3-2.99 3-7 3zM13 36.17c-5.13 0-9 2.15-9 5v12c0 2.85 3.87 5 9 5s9-2.15 9-5v-12c0-2.85-3.87-5-9-5zm7 17c0 1.42-2.99 3-7 3s-7-1.58-7-3v-8.79c1.62 1.1 4.11 1.79 7 1.79s5.38-.68 7-1.79v8.79zm-7-9c-4.01 0-7-1.58-7-3s2.99-3 7-3 7 1.58 7 3-2.99 3-7 3zM34.5 46.9c-2.05 1.79-4.14 1.84-4.16 1.84l-2.85.03 2.69.94c.18.06 4.19 1.43 6.74-.63l1.35 1.45 1.85-6.55-7.41 1.35 1.79 1.57zm4.21-1.64l-.93 3.29-.83-.89-.37.38c-1.05 1.07-2.61 1.24-3.88 1.16.88-.36 1.9-.96 2.89-1.94l.38-.38-1.05-.92 3.79-.7z"/>'
  }
})
