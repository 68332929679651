/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pathfinder_normal_view': {
    width: 16,
    height: 16,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M8 16.42v31.17h48.05V16.42H8zm1.92 15.77h3.46v5.77H9.92v-5.77zm44.21-1.92H42.21V24.5h11.92v5.77zm-13.84 0H26.07V24.5h14.22v5.77zm-14.22-7.7v-4.23h14.22v4.23H26.07zm-1.93 0H15.3v-4.23h8.84v4.23zm0 1.93v5.77H15.3V24.5h8.84zm-10.76 5.77H9.92V24.5h3.46v5.77zm1.92 1.92h8.84v5.77H15.3v-5.77zm8.84 7.7v5.77H15.3v-5.77h8.84zm1.93 0h14.22v5.77H26.07v-5.77zm0-1.93v-5.77h14.22v5.77H26.07zm16.14-5.77h11.92v5.77H42.21v-5.77zm11.92-9.62H42.21v-4.23h11.92v4.23zm-40.75-4.23v4.23H9.92v-4.23h3.46zM9.92 39.89h3.46v5.77H9.92v-5.77zm32.29 5.77v-5.77h11.92v5.77H42.21z"/>'
  }
})
