<template>
    <div>
        <v-row no-gutters>
            <v-col class="pt-2 pr-0 pb-2">
                <edc-pathfinder  :isWidget="true" @stopWidgetLoader="stopWidgetLoader"
                :key="widgetUniqueKey" :widgetUniqueKey="widgetUniqueKey"/>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>

</style>
<script>
import {mapActions} from 'vuex'
import { v4 as EDCuuidv4 } from 'uuid';
import edcPathfinder from '../components/pathfinder/edcPathfinder.vue'
export default{
    name:"GridWidget",
    components:{
        'edc-pathfinder':edcPathfinder
    },
    props:{
        dataObject:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return{
            widgetUniqueKey:EDCuuidv4() // this is variable is used to make sure same widget has own pathfinder grid
        }
    },
    watch:{
        'dataObject':{
            handler(newValue){
                this.manageDataObject()
            }
        },
        'widgetUniqueKey':{
            handler(newValue){
                this.$emit("onWidgetUniqueKeyChange",newValue)
            }
        }
    },
    mounted(){
        this.manageDataObject()
    },
    methods: {
        ...mapActions(['setDataviewerProps']),
        manageDataObject(){
            var _this = this
            this.widgetUniqueKey = EDCuuidv4()
            this.setDataviewerProps({})
            if(_.isEmpty(this.dataObject))
                return
            let dataObject = _.cloneDeep(this.dataObject)
            let envDetails = {"env_id":_this.$session.get("last_selected_env_id"),"id":_this.$session.get("last_selected_env_id")}
           
            var dataToPass = {
                'selected_env':envDetails,
                'selected_ds':dataObject.selected_ds,
                'selected_bo_tbl':dataObject.selected_bo_tbl,
                'dictionary_data':dataObject.selected_ds.dictionary_data,
                'object_librarian_data':dataObject.selected_ds.object_librarian_data,
                'control_data':dataObject.selected_ds.control_data,
                'ds_list_for_backend':dataObject.ds_list_for_backend,
                'EnvironmentList':[envDetails],
                'isSuperAdmin':true,
                'securityModelByEnv':[],
                'ds_list':dataObject.ds_list,
                'acceleration_type':dataObject.selected_ds.acceleration_type,
                'whichSide':"wholepage",
                'isArchivistCall':false,
                'archivistObj':{},
                'widgetUniqueKey':this.widgetUniqueKey,
            }
            setTimeout(()=>{
                _this.setDataviewerProps(_.cloneDeep(dataToPass))
            },100)
        },
        stopWidgetLoader(){
            this.$emit("stopWidgetLoader")
        }
    },
}
</script>