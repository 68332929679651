<template>
    <div :style="{ height: chartHeight + 'px'}">
        <v-chart :options="dataSeries" :autoresize="true"
        @click="onGraphClick" ref="chartObj"/>
    </div>
</template>
<style>
    .echarts {
      width: 100% !important;
      height: 100% !important;
    }
</style>
<style scoped>
    
</style>
<script>
    import ECharts from 'vue-echarts'
    import 'echarts/lib/chart/line'
    import 'echarts/lib/component/polar'
    import 'echarts/lib/chart/bar'
    import 'echarts/lib/chart/pie'
    import 'echarts/lib/component/tooltip'
    import 'echarts/lib/component/toolbox'
    import 'echarts/lib/component/legend'
    export default{
        name:"ChartWidget",
        components: {
            'v-chart': ECharts
        },
        props:{
            widgetDivHeight:{
                type:Number,
                default:0
            },
            dataObject:{
                type:Object,
                default:()=>{

                }
            },
            redirectionURL:{
                type:String,
                default:""
            }
        },
        data(){
            return {
                height:200,
                width:200,
                dataSeries:{},
                isDrilldown:false,
                dataSeriesBackUp:{}
            }
        },
        computed:{
            chartHeight(){
                return this.widgetDivHeight * .80
            },
            selectedProps(){
                return this.dataObject.selectedProps || {}
            },
            isColorPaletteSelected(){
                return this.selectedProps.selectColorPalette && this.selectedProps.selectColorPalette != 'default'
            },
            seriesColors(){
                let colorCodes = {
                    "gray":[ "#2F3E46", "#40515B", "#506774", "#627D8B", "#78909C", "#90A4AE", "#B0BEC5", "#CFD8DC", "#ECEFF1", "#F5F7F9" ],
                    "red":[ "#6A0F0F", "#8B1C1C", "#AC2929", "#D84545", "#E57373", "#EF9A9A", "#F8BDBD", "#FFCDD2", "#FFE5E7", "#FFEBEE" ],
                    "green":[ "#1B5E20", "#2E7D32", "#388E3C", "#4CAF50", "#66BB6A", "#81C784", "#A5D6A7", "#C8E6C9", "#E0F2E9", "#E8F5E9" ],
                    "blue":[ "#264B73", "#33698D", "#4185A7", "#509FDA", "#64B5F6", "#82C9FA", "#A0DBFF", "#BBDEFB", "#D6EFFF", "#E3F2FD" ]
                }
                return colorCodes[this.selectedProps.selectColorPalette]    
            },
            showDownoadButton(){
                return this.selectedProps.showDownoadButton || false
            },
            showTooltip(){
                return this.selectedProps.showTooltip
            },
            showLegend(){
                return this.selectedProps.showLegend || false
            },
            showDataLabel(){
                return this.selectedProps.showLabels
            },
            chartType(){
                return this.selectedProps.selectChartDropdown
            },
            isPie(){
                return this.chartType === 'pie'
            }
        },
        watch:{
            'widgetDivHeight':{
                handler(newValue){

                }
            },
            'dataObject':{
                handler(newValue){
                    this.manageSeriesData()
                }
            },
            'redirectionURL':{
                handler(newValue){

                }
            }
        },
        mounted(){
            this.manageSeriesData()
        },
        methods: {
            manageSeriesData(){
                var _this = this
                let dataSeries = _.cloneDeep(this.dataObject.series)
                this.dataSeries = {}
                if(_.isEmpty(dataSeries))
                    return {}
                
                if(this.isColorPaletteSelected){
                    dataSeries['color'] = this.seriesColors
                }
                let formattedUnit = dataSeries["unit"]
                dataSeries ['grid'] = {
                    left: '3%',
                    right: '4%',
                    bottom: '4%',
                    top:'8%',
                    containLabel: true
                }
                dataSeries['tooltip'] = {
                    trigger: 'item',
                    show:this.showTooltip,
                },
                dataSeries['legend'] = {
                    show:true
                }
                dataSeries['legend']={
                    show:this.showLegend,
                    orient: 'vertical',
                    left: 'left',
                    data: dataSeries.series[0]['data'].map((item) => item.name),
                    formatter: function (name) {
                        let item = dataSeries.series[0]['data'].find((entry) => entry.name == name);
                        return item && typeof item.description === 'string' ? item.description.trim() : name;
                    }
                    }
                dataSeries['toolbox'] = {show:true,orient: 'vertical' ,itemSize: 15 ,itemGap: 8, right: "1%"}
                dataSeries['toolbox']['feature']={
                    saveAsImage: {
                        'type':'png',
                        'name':"",
                        'title':'Download',
                        show:this.showDownoadButton,
                        emphasis:{
                            iconStyle:{
                                textAlign:'right'
                            }
                        }
                    },
                    myBackArrow: {
                        show: this.isDrilldown,
                        title: 'Back',
                        icon: 'path://M12 2L3 12l9 10v-6h8v-8h-8V2z', // SVG Path for Back Arrow
                        onclick: function () {
                            _this.revertDrilldown()
                            // Add custom logic for the back arrow functionality
                        }
                    },
                }
                dataSeries['series'][0]['label'] = {
                    show: this.showDataLabel,
                    position: this.isPie?'outside':'inside',
                    // rotate:-90,
                    // formatter: this.isPie ? '{c}' : null,
                    formatter: this.isPie
                    ? params => `${params.value}${formattedUnit}` 
                    : params => null,
                    align: 'center', // Align text horizontally
                    fontSize: 12
                }

                if(dataSeries['xAxis']){
                    
                    // dataSeries['xAxis']['name'] = dataSeries.x_axis_unit?this.selectedProps.xaxisName+' ('+dataSeries.x_axis_unit+')':this.selectedProps.xaxisName
                    dataSeries['xAxis']['name'] = dataSeries.x_axis_unit
                    dataSeries['xAxis']['nameTextStyle'] = {
                        fontWeight: 'bold'  // Make X-axis name bold
                    }
                    dataSeries['xAxis']['nameGap'] = 22
                    dataSeries['xAxis']['nameLocation'] = 'center'
                    dataSeries['xAxis']['axisTick'] = {alignWithLabel:true}
                    
                    dataSeries['xAxis']["axisLabel"] =  {
                    //     "formatter": function(value) {
                    //         return value.length > 10 ? value.substring(0, 10) + '...' : value;  // Adjust length as needed
                    //     }
                        formatter: function (name) {
                            let item = dataSeries.series[0]['data'].find((entry) => entry.name == name);
                            return item && typeof item.description === 'string' ? item.description.trim() : name;
                        }
                    }
                }
                if(dataSeries['yAxis']){
                    
                // dataSeries['yAxis']['name'] = dataSeries.y_axis_unit?this.selectedProps.yaxisName+' ('+dataSeries.y_axis_unit+')':this.selectedProps.yaxisName
                 dataSeries['yAxis']['name'] = dataSeries.y_axis_unit
                dataSeries['yAxis']['nameLocation'] = 'center'
                dataSeries['yAxis']['nameGap'] = 28
                dataSeries['yAxis']['axisLabel']= {interval: 0,rotate: 45}
                dataSeries['yAxis']['nameTextStyle'] = {
                        fontWeight: 'bold'  // Make X-axis name bold
                    }
                }
                console.log("dataSeries", dataSeries)
                this.dataSeries = dataSeries || {}
            },
            onGraphClick(event){
                if(this.isDrilldown)
                    return
                if(this.selectedProps.selectGraphDrilldownType === 'on_pathfinder'){
                    let url = this.redirectionURL
                    /* Here we need to manage the drilldown. 
                        1. We need to indentify the column which not involved in the Aggregate
                        2. If aggregate ON 'NONE' then we can need to choose X axis column name
                        3. Add filter column and filter vlaue as query string
                    */
                   console.log("evnt",event)
                   let filterCol = ""
                   let filterValue = ""
                    if(this.selectedProps.selectAggAxisDropdown ==='yaxis' ||this.selectedProps.selectAggAxisDropdown ==='none'){
                        filterCol = this.selectedProps.selectXaxisDropdown.column_name
                        filterValue = event.name
                    } else{
                        filterCol = this.selectedProps.selectYaxisDropdown.column_name
                        filterValue = event.value
                    }
                    url = url+"&autofiltercolumn="+filterCol+"&autofilterval="+filterValue
                    window.open(url, '_blank');
                    return
                }
                this.isDrilldown = true
                this.dataSeriesBackUp = _.cloneDeep(this.dataSeries)
                this.dataSeries = {}
                this.$emit("onDrilldownClick",{"drillDownValue":event.name})
            },
            revertDrilldown(){
                this.isDrilldown = false
                this.dataSeries = _.cloneDeep(this.dataSeriesBackUp)
            }   
        },
    }
</script>
