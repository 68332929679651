import _ from 'lodash';
export default function getUserRole(userRole ,service, name){
    if(userRole && userRole[service] && _.indexOf(userRole[service], name) !== -1)
            return true;
        else
            return false;    
}

export function getUserMenuByAction(userRole, serviceName, group_code_list){
    if(userRole.is_superadmin)
      return true
    if(userRole[serviceName]){
      var all_group = userRole[serviceName]
      if(group_code_list.some(value => all_group.includes(value))){
        return true
      }
    }
    return false;
}