<template>
    <div>
        <loading-panel :loader="loader"></loading-panel>
        <v-row class="EDC-Row">
        <v-col cols="8" class="EDC-Col">
            <v-row class="EDC-Row breadcrumbRow">
            <v-col cols="12" class="EDC-Col">
                <ul class="breadcrumb breadcrumbUL">
                <li>Database Link Mapping</li>
                </ul>
            </v-col>
            </v-row>
        </v-col>
        <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel"  @onValueUpdate="onValueUpdate" @onAgentPing="validateDBLink"></edc-data-grid>
        
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import { post as postToServer, get as getToServer,} from './../../methods/serverCall.js';
import config from '../../config.json'
import { SERVER_ERROR } from '../../data/client_message.js'
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import {DATASOURCE_SERVICE_ID, MANAGE_DB_LINK} from "../../data/macros.js"
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {GET_DATASOURCE_LIST} from '../../data/url_constants.js';
import getUserRole from './../../methods/GetUserRole.js'
export default{
    data(){
        return{
            snackbar:false,
            snackbartext:'',
            colorValue:'error',
            loader: false,
            snackbartimeout: Snackbar_Default_Timeout,
            loading: false,
            envIpJson: {
                "filter": [],
                "sort": [{
                    "column_name": "",
                    "type": ""
                }],
                "page": 1,
                "page_size": 5
            },
            dataSourceList:[],
            tableList:{
                headers: [
                {text: 'From Datasource', value: 'from_datasource_name', width:"20%", option:[], 'item_text':'from_datasource_name', 'item_value':'from_datasource_name', useAutoComplete:true},
                { text: 'To Datasource', value: 'to_datasource_name', width: '20%', option:[], 'item_text':'to_datasource_name', 'item_value':'to_datasource_name', useAutoComplete:true},
                {text: 'DB Link', value: 'dblink_name', width:"20%", useTextField:true,'item_text':'dblink_name', 'item_value':'dblink_name'},
                {text: 'Created By', value: 'created_by', width:"20%", 'item_text':'created_by', 'item_value':'created_by'},
                {text: 'Created Date', value: 'created_date', width:"20%",'item_text':'created_date', 'item_value':'created_date'},

                ], 
                actions: [],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                is_row_editable:true,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                disableDefaltSorting:true,
                itemkey:'id',
                is_grid_inline_edit:true,
                hideFilter:true,
                hideExport:true,
                hideShowColumns:true
            },
        }
    },
    mounted(){
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions = [
        {'text':'edit','key':"id", selectType:"single", 
        role:this.userRole.is_superadmin || getUserRole(this.userRole,DATASOURCE_SERVICE_ID, MANAGE_DB_LINK), index:1},
        {'text':'new','key':"id", 
        role:this.userRole.is_superadmin || getUserRole(this.userRole,DATASOURCE_SERVICE_ID, MANAGE_DB_LINK), index:2},    
        {'text':"save","key":"is_row_editable",selectType:"single", 
        role:this.userRole.is_superadmin || getUserRole(this.userRole,DATASOURCE_SERVICE_ID, MANAGE_DB_LINK), index:3},
        {'text':"cancel","key":"is_row_editable",selectType:"single", 
        role:this.userRole.is_superadmin || getUserRole(this.userRole,DATASOURCE_SERVICE_ID, MANAGE_DB_LINK), index:4},
        {'text':'delete','key':"id", selectType:"single", 
        role:this.userRole.is_superadmin || getUserRole(this.userRole,DATASOURCE_SERVICE_ID, MANAGE_DB_LINK), index:5},
        {'text':'PingAgent', 'displayText':'Validate DBLink', 'key':'id', selectType:"single",
        role:this.userRole.is_superadmin || getUserRole(this.userRole,DATASOURCE_SERVICE_ID, MANAGE_DB_LINK), index:6}];
        this.getDataSourceList();
        this.GetAllDBLinkMapping();
    },
    methods:{
        getDataSourceList () {
            let _this = this;
            var client_id = this.$session.get('client_id')
            getToServer(this, config.DATA_SOURCE_URL + GET_DATASOURCE_LIST + client_id
            ).then(response => {
                _this.loader = false;
                if(response){
                    var rows=[]
                    _.forEach(response,function(obj){
                        rows.push(obj.datasource_info)
                    })
                    rows.forEach(function(obj){
                        obj.from_datasource_name = obj.datasource_name;
                        obj.to_datasource_name = obj.datasource_name
                    })
                    var final_rows=[]
                    rows.forEach(function(obj){
                        if (obj.database_type == 'oracle'){
                            final_rows.push(obj)
                        }
                    })
                    _this.dataSourceList = final_rows.slice();
                    _this.tableList.headers[0].option = final_rows;
                    _this.tableList.headers[1].option = final_rows;
                }else{
                    _this.tableList.rows = [];
                    _this.tableList.total_count = 0; 
                }
            }).catch(error_response => {
                _this.loader = false;
                if(error_response){
                    this.loader = null 
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
                    }
                    else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                    }
            });
        },
        GetAllDBLinkMapping(){
            var _this = this
            var data = {"client_id": _this.$session.get('client_id')}
            postToServer(_this, config.DATA_SOURCE_URL+'/get_dblink_mapping', data).then(response=>{
                if(response){
                    this.tableList.rows = response;
                }
                else{
                    this.tableList.rows = [];
                }
            }).catch(error_response => {
                this.tableList.rows = [];
            });
        },
        onNew() {
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fiilup the open row';
                return
            }
            this.tableList.rows.unshift({
                'from_datasource_name':'',
                'to_datasource_name':'',
                'dblink_name':'',
                'is_row_editable': true,
            });
        },
        onSave(record){
            if(!record.from_datasource_name || !record.to_datasource_name || !record.dblink_name){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'All fields are required';
                return false
            }
            let link_data = {
                "id": record.id,
                "client_id": this.$session.get('client_id'),
                "from_datasource_id": _.find(this.dataSourceList,['datasource_name', record.from_datasource_name]).datasource_id,
                "to_datasource_id": _.find(this.dataSourceList,['datasource_name', record.to_datasource_name]).datasource_id,
                "dblink_name": record.dblink_name
            }
            record.id ? this.updateDBLinkMapping(link_data) : this.saveDBLinkMapping(link_data);
        },
        saveDBLinkMapping(link_data){
            var url = config.DATA_SOURCE_URL+"/add_dblink_mapping"
            postToServer(this, url, link_data).then(Response => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "Mapping added successfully";
                this.GetAllDBLinkMapping();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },
        updateDBLinkMapping(link_data) {  
            var url = config.DATA_SOURCE_URL + '/update_dblink_mapping'
            postToServer(this, url, link_data).then(Response => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "DBLink updated successfully";
                this.GetAllDBLinkMapping();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },
        onCancel(){
            this.GetAllDBLinkMapping();
        },
        onDelete(record) {
            let inputJson = {
                'id':record.id,
                "client_id": this.$session.get('client_id')
            }
            let url = config.DATA_SOURCE_URL + '/delete_dblink_mapping'              
            postToServer(this, url, inputJson).then(response  => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = 'DBLink Mapping deleted successfully';
                this.GetAllDBLinkMapping();
            }).catch(error_response => {
                this.GetAllDBLinkMapping();
                if(error_response){
                this.snackbar = true;
                this.colorValue = 'error';
                this.snackbartext = error_response;
                } else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
                }
            });              
        },
        validateDBLink(record){
            var validate_data = {"client_id": record.client_id,
                "from_datasource_id": record.from_datasource_id,
                "to_datasource_id": record.to_datasource_id,
                "dblink_name": record.dblink_name
            }
            var validate_dblink_url = config.DATA_SOURCE_URL + '/validate_dblink'
            this.loader = true;
            postToServer(this, validate_dblink_url, validate_data, true
              ).then(response => {
                this.loader = false;
                if (response) {
                    this.loader = null 
                    this.snackbar = true
                    this.colorValue = 'success'
                    this.snackbartext = 'DBLink Validation Successful';
                } else {
                    this.loader = null 
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = 'Failed To Validate with DBLink'
                }
            }).catch(ConnectionError => {
                this.loader = false;
                if (ConnectionError) {
                    this.loader = null 
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = ConnectionError;
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });

        }
    }
}
</script>
