<template>
    <div>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6" xxl="6">
                <v-row no-gutters>
                    <v-col cols="12" class="spacing-slot" id="slot1" @dragover.prevent @drop="dropOnDiv(slot1Obj.slotNumber)">
                        <layout-slot-card heightClass="dynamic-height-50" 
                        :slotNumber="slot1Obj.slotNumber"
                        :draggedWidgetId="slot1Obj.draggedWidgetId" :heightLimit="slot1Height"
                        @deAttachWidget="deAttachWidget($event,1)"
                        @onWidgetChange="onWidgetChange"
                        :dashboardId="dashboardId" :isDesigner="isDesigner"
                        />
                    </v-col>
                
                    <v-col cols="12" class="spacing-slot" id="slot2" @dragover.prevent @drop="dropOnDiv(slot2Obj.slotNumber)">
                        <layout-slot-card heightClass="dynamic-height-50" 
                        :slotNumber="slot2Obj.slotNumber"
                        :draggedWidgetId="slot2Obj.draggedWidgetId" :heightLimit="slot2Height"
                        @deAttachWidget="deAttachWidget($event,2)"
                        @onWidgetChange="onWidgetChange"
                        :dashboardId="dashboardId" :isDesigner="isDesigner"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6" xxl="6">
                <v-row no-gutters>
                    <v-col cols="12" class="spacing-slot" id="slot3" @dragover.prevent @drop="dropOnDiv(slot3Obj.slotNumber)">
                        <layout-slot-card heightClass="dynamic-height-50" 
                        :slotNumber="slot3Obj.slotNumber"
                        :draggedWidgetId="slot3Obj.draggedWidgetId" :heightLimit="slot3Height"
                        @deAttachWidget="deAttachWidget($event,3)"
                        @onWidgetChange="onWidgetChange"
                        :dashboardId="dashboardId" :isDesigner="isDesigner"
                        />
                    </v-col>
                
                    <v-col cols="12" class="spacing-slot" id="slot4" @dragover.prevent @drop="dropOnDiv(slot4Obj.slotNumber)">
                        <layout-slot-card heightClass="dynamic-height-50" 
                        :slotNumber="slot4Obj.slotNumber"
                        :draggedWidgetId="slot4Obj.draggedWidgetId" :heightLimit="slot4Height"
                        @deAttachWidget="deAttachWidget($event,4)"
                        @onWidgetChange="onWidgetChange"
                        :dashboardId="dashboardId" :isDesigner="isDesigner"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import {mixinLayout} from "../mixins/layout"
    export default{
        name:"TwoRowsWithTwoSlots",
        mixins:[mixinLayout],
        data(){
            return{
                slot1Height:{
                    "minimum":10,
                    "maximum":50,
                    "unit":"vh"
                },
                slot2Height:{
                    "minimum":10,
                    "maximum":50,
                    "unit":"vh"
                },
                slot3Height:{
                    "minimum":10,
                    "maximum":50,
                    "unit":"vh"
                },
                slot4Height:{
                    "minimum":10,
                    "maximum":50,
                    "unit":"vh"
                },

                // work needed on breakpoint settings
                slot1UISettings:{
                    "sm":12,
                    "md":6,
                    "lg":4,
                    "xl":3
                },
                slot2UISettings:{
                    "sm":12,
                    "md":6,
                    "lg":4,
                    "xl":3
                },
                slot3UISettings:{
                    "sm":12,
                    "md":12,
                    "lg":6,
                    "xl":6
                },
                slot4UISettings:{
                    "sm":12,
                    "md":12,
                    "lg":6,
                    "xl":6
                }
            }
        },
        methods:{
            
        }
    }
</script>