import { render, staticRenderFns } from "./groupComponent.vue?vue&type=template&id=98e101a2&scoped=true&"
import script from "./groupComponent.vue?vue&type=script&lang=js&"
export * from "./groupComponent.vue?vue&type=script&lang=js&"
import style0 from "./groupComponent.vue?vue&type=style&index=0&id=98e101a2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98e101a2",
  null
  
)

export default component.exports