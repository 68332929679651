<template>
    <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>User Group List</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <edc-data-grid :dataList="tableList" @onNew="onNew" @onEdit="onEdit" @onDelete="onDelete" 
                 @ondblClick="onEdit" @onCopy="onCopy"></edc-data-grid>
            </v-col>
        </v-row>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbarTimeout" id="edcmessage" top right>
            {{ snackbarText }}
        </v-snackbar>
        <loading-panel :loader="loader"></loading-panel>
    </v-container>
</div>
</template>
<style scoped>

</style>
<script>
import { GET_ALL_USERS_LIST, GET_USER_ROLE_MAPPING, GET_USER_ENV_ROLE_MAPPING, GET_ALL_ROLES, GET_ENVIRONMENTAL_ROLES, MANAGE_USER_ROLE_MAPPING, DELETE_USER_ROLE_MAPPING, GET_ROLES_BY_TYPE, MANAGE_ENV_USER_ROLE_MAPPING, ADD_ENV_USER_ROLE_MAPPING } from '../../data/url_constants.js'
import { post as postToServer } from "./../../methods/serverCall.js";
import { get as getToServer } from "./../../methods/serverCall.js";
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import getUserRole from './../../methods/GetUserRole.js'
import {
    USER_PROVISIONING_SERVICE_ID, MANAGE_USER_GROUP
} from './../../data/macros.js'
import config from "../../config.json";
    export default{
        name:'UserGroup',
        data(){
            return {
                userList:[],
                snackbar: false,
                snackbarText: "",
                colorValue: "error",
                loader: null,
                snackbarTimeout: Snackbar_Default_Timeout,
                tableList:{
                    headers: [{
                        text: 'User Group Name',
                        value: 'group_name',
                        title: 'User Group Name',
                        useTextField:true,
                        },
                        {
                        text: 'User List',
                        value: 'user_list',
                        width: "30%",
                        title: 'User List',
                        'item_text':'username',
                        'item_value':'user_id',
                        gridDisplayTextKey:"display_nameText",
                        gridDisplayTextTitleKey:"display_nameTitle",
                        option:[],
                        useAdvanceAutoComplete:true,
                        useMultiple:true,
                        show_select_all:true,
                        resetSelectCounter:0,
                        headerFor:'Users',
                        returnObject:true,
                        selectAllKey:'isAllObjectSelected'
                        },
                        {
                            text: 'Created By',
                            value: 'created_by',
                            width: "30%",
                            title: 'Group Created By'
                        },
                        {
                            text: 'Created Date',
                            value: 'timezone_aware_created_date',
                            width: "20%",
                            title: 'Group Created Date',
                            dataType:'date'
                        },
                        {
                            text: 'Created Time',
                            value: 'timezone_aware_created_time',
                            width: "20%",
                            title: 'Group Created Time',
                            dataType:'time',
                            hideInlineFilter: true
                        }
                    ],
                    actions:[],
                    sorting_type: this.clientSide,
                    filterType: this.clientSide,
                    paginationType: this.clientSide,
                    total_count: 0,
                    rows: [],
                    selected_rows: [],
                    hideExport:true,
                    hideShowColumns:true,
                    hideFilter:true,
                    itemkey:'id',
                },
            }
        },
        watch:{
            
        },
        mounted() {
        this.userRole = this.$session.get('user_role_mapping');
            this.tableList.actions = [
                {'text': 'new','key': "group_name",selectType:"single",index:1, 
                role:this.userRole.is_superadmin || getUserRole(this.userRole, USER_PROVISIONING_SERVICE_ID, MANAGE_USER_GROUP),
                            index:1},
                {'text': 'edit','key': "id",selectType:"single",index:2,
                    role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,MANAGE_USER_GROUP),
                },
                {'text': 'copy','key': "id",selectType:"single",index:3,
                    role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,MANAGE_USER_GROUP),
                },
                {
                    'text':"save","key":"is_row_editable", selectType:"single", index: 4, role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID, MANAGE_USER_GROUP),
                },
                {'text':"cancel","key":"is_row_editable", selectType:"single", index: 5, 
                    role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,MANAGE_USER_GROUP),
                },
                {'text': 'delete','key': "id",selectType:"single",index:6, 
                    role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,MANAGE_USER_GROUP),
                }
            ]
            this.getAllUserGroups()
        },
        methods:{
            onNew(record){
                let _this = this;
                _this.$router.push('/manageusergroup');
            },
            onEdit(record){
                this.$router.push({ name: 'manageusergroup', query: { 'groupid': record.id, 'type':'edit'}})
            },
            onCopy(record){
                this.$router.push({ name: 'manageusergroup', query: {'groupid': record.id, 'type':'copy', 'groupname': record.group_name}})
            },
            showErrorMessage(errorMessage){
                this.loader = false;                  
                this.colorValue = 'error'
                this.snackbarText = errorMessage || SERVER_ERROR; 
                this.snackbar = true                   
            },
            getAllUserGroups(){
                let _this = this;
                _this.loader = true;
                var request_data = {
                    'client_id': this.$session.get('client_id'),
                   }
                postToServer(_this, config.USER_PROVISION_URL + '/get_all_group_details', request_data).then(response => {
                    _this.loader = false;
                    _.forEach(response, (obj)=>{
                        var userId = 0
                        var objForBind = []
                        obj.isAllObjectSelected = false
                        if(obj.user_list){
                            obj.display_nameTitle = _.map(obj.user_list,'username').join(",")
                            _.forEach(obj.user_list, (Objlist)=>{
                                objForBind.push({
                                    "username":Objlist.username, 
                                    "user_id":Objlist.user_id,
                                })
                            })
                           
                            if(obj.user_list.length > 1){
                                obj.display_nameText = (obj.user_list[0]['username']) +" (+" + (obj.user_list.length-1) + _this.tableList.headers[1].headerFor+")"
                            }
                            else{
                                if(obj.user_list[0] == undefined){
                                    obj.display_nameText = ''
                                }
                                else{
                                    obj.display_nameText = obj.user_list[0]['username']
                                }
                            }
                            if(objForBind.length === 1 && objForBind[0].user_id == 0){
                                obj.isAllObjectSelected = true
                                objForBind = []
                            }
                            if(objForBind.length == 0){
                                obj.user_list  = ""
                            }else{
                                obj.user_list = objForBind
                            }
                        }
                    })
                    if (response) {
                        _this.tableList.rows = response;
                    } else {
                        _this.tableList.rows = [];
                    }
                })
                    .catch(errorResponse => {
                        _this.showErrorMessage(errorResponse)
                });
            },
            onDelete(record){
                var _this = this
                var data = {"group_id_list": [record.id]}
                var url = config.USER_PROVISION_URL + '/delete_group_details'
                postToServer(_this, url, data).then(response => {
                    _this.getAllUserGroups()
                })
                .catch(errorResponse => {
                _this.showErrorMessage(errorResponse)
                });
                
            },
        }
    }
</script>