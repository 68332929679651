export function getCommonWidgetUIComponent(type,key,returnType,selectedValue,dependOn=[]){
    return {
        "type":type,
        "key":key,
        "returnType":returnType,
        "selectedValue":selectedValue,
        "dependOn":[],
        "id":'cmp'+key
    }
}


export function getDropdownComponent(key,returnType,selectedValue,label,itemValue,itemText,isMultiple){
    let commonProp = getCommonWidgetUIComponent('autocomplete',key,returnType,selectedValue)
    // add dropdown specific properties
    commonProp['label'] = label
    commonProp['itemText'] = itemText
    commonProp['itemValue'] = itemValue
    commonProp['isMultiple'] = isMultiple || false
    return commonProp
}

export function bindStaticItemList(component,staticList){
    component['itemListSource'] = {
        "type":"static",
        "itemList":staticList
    }
    return component
}

export function getTextboxComponent(key,returnType,selectedValue,label){
     let commonProp = getCommonWidgetUIComponent('text_field',key,returnType,selectedValue)
     commonProp['label'] = label
     return commonProp
}

export function getCheckboxComponent(key,returnType,selectedValue,label){
     let commonProp = getCommonWidgetUIComponent('checkbox',key,returnType,selectedValue)
     commonProp['label'] = label
     return commonProp
}

export function applyNumberPropsToText(control,minValue,maxValue=-1){
    control['type'] ='number'
    control['minValue'] = minValue
    control['maxValue'] = maxValue
    return control
}