<template>
    <div>
        <v-autocomplete  v-if="isAutoComplete" :id="componentId"  hide-details="auto" solo dense v-model="modelValue" 
        :multiple="useMultiple" :disabled="isDisabled" 
        :items="itemList" :item-text="itemText"  
         :label="labelText" :item-value="itemValue" 
         @input="onItemSelectionChange($event)" :return-object="returnObject"></v-autocomplete>

         <v-text-field v-if="isTextField" :label="labelText" :placeholder="labelText" solo></v-text-field>

         <!-- <v-switch v-if="isSwitchInput" 
         v-model="modelValue" color="primary" :label="labelText" value="primary" hide-details>
         </v-switch> -->
         <v-checkbox v-if="isCheckbox" :label="labelText" value="primary" hide-details></v-checkbox>

         <!-- <v-radio-group v-if="isRadioGroup" 
            v-model="modelValue" 
            :label="labelText"
            row
            >
            <v-radio
                v-for="(item, index) in itemList"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></v-radio>
        </v-radio-group> -->

    </div>
</template>
<style scoped>
</style>
<script>
    import { makeAPICall,parseInputParams } from '../methods/layoutFunc.js';
    export default{
        name:"ComponentContainer",
        props:{
            parentSelectedValues:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            componentDetails:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            triggerFetchItemListFor:{
                type:Array,
                default:()=>{
                    return []
                }
            }
        },
        data(){
            return{
                modelValue:"",
                itemList:[],
                currentAPIInputData:[],
                valueTimer:null
            }
        },
        computed:{
            isCheckbox(){
                return this.componentDetails.type === "checkbox"
            },
            isAutoComplete(){
                return this.componentDetails.type === "autocomplete"
            },
            isTextField(){
                return this.componentDetails.type === "text_field"
            },
            isRadioGroup(){
                return this.componentDetails.type === "radio-group"
            },
            componentId(){
                return this.componentDetails.id || "cmpid"
            },
            componentKey(){
                return this.componentDetails.key
            },
            labelText(){
                return this.componentDetails.label || "select"
            },
            itemText(){
                return this.componentDetails.itemText || "object_name"
            },
            itemValue(){
                return this.componentDetails.itemValue || ""
            },
            returnObject(){
                return this.componentDetails.returnType === 'object'
            },
            isServerLoadType(){
                return this.componentDetails.loadType === 'server'
            },
            useMultiple(){
                return this.componentDetails.useMultiple || false
            },
            isDisabled(){
                return this.componentDetails.isDisabled || false
            },
            isServerSearch(){
                return this.componentDetails.searchType === 'server'
            },
            itemListSource(){
                return _.get(this.componentDetails,'itemListSource',{})
            },
            isServerAPI(){
                return this.itemListSource.type === "api"
            },
            serverAPIDetails(){
                return this.isServerAPI?this.itemListSource.api:{}

            },
            isStaticItemList(){
                return this.itemListSource.type === "static"
            },
            OnSelectionChangeDetails(){
                return _.get(this.componentDetails,'onChange',{})
            },
            isDatasourceDropdown(){
                return this.componentId === "drpdatasourcelist"
            },
            dependOn(){
                return this.componentDetails.dependOn||[]
            }
        },
        watch:{
            'parentSelectedValues':{
                handler(newValue,oldValue){
                    // here we have to make sure the "key" difference between old and new should not be current
                    // component key else it will make redudent call => todo
                    clearTimeout(this.valueTimer)
                    this.valueTimer = setTimeout(()=>{
                        this.manageAutoCompleteItemList()
                    },300)
                },
                deep:true
            },
            'componentDetails.selectedValue':{
                handler(newValue){
                    this.modelValue = !_.isEmpty(newValue)?_.cloneDeep(newValue):this.returnObject?{}:""
                }
            },
            'triggerFetchItemListFor':{
                handler(newValue){

                }
            }
        },
        mounted() {
            this.manageAutoCompleteItemList()
            
            if(!_.isEmpty(this.componentDetails.selectedValue)){
                this.modelValue = _.cloneDeep(this.componentDetails.selectedValue)
                this.onItemSelectionChange(this.selectedValue)
            }
        },
        methods:{
            manageAutoCompleteItemList(){
                var _this = this
                if(_.isEmpty(this.componentDetails))
                    return

                if(this.isStaticItemList){
                    this.itemList = this.componentDetails.itemListSource.itemList
                    return
                }

                if(_.isEmpty(this.serverAPIDetails))
                    return
                this.itemList = []
                if(this.dependOn.length){
                    // we have to check each depended object has value selected in parentSelectedValues
                    let isDependOnSelected = true
                    for(var i=0;i<this.dependOn.length;i++){
                        if(_.isEmpty(this.parentSelectedValues[this.dependOn[i]])){
                            isDependOnSelected = false
                            break
                        }
                    }
                    if(!isDependOnSelected)
                        return
                }

                let inputData = parseInputParams(this,this.serverAPIDetails.params,this.parentSelectedValues)
                makeAPICall(this,this.serverAPIDetails,inputData).then(response=>{
                    this.itemList = response.result || response
                }).catch(error=>{
                    // alert("error in API get_all_task_list_by_env")
                })

            },
            onItemSelectionChange(selectedObject){
                var _this = this
                if(_.isEmpty(this.OnSelectionChangeDetails)){
                    alert("OnSelectionChangeDetails not found")
                }
                this.$emit("updateModelData",_this.componentKey,selectedObject)

                if(this.OnSelectionChangeDetails.triggerWidgetApiCall)
                    this.$emit("onTriggerWidgetApiCall",this.OnSelectionChangeDetails.api,selectedObject)
                else{

                }
            },
            manageDatasourceWithErpDetails(datasource,index){
      			var mainDatasource = this.manageDatasourceJson(datasource.business_data)
      			if(datasource.temp_data)
        			mainDatasource['temp_data'] = this.manageDatasourceJson(datasource.temp_data)
      			if(datasource.accelerator_details){
        			mainDatasource['acceleration_type'] = datasource.accelerator_details.accelerator_type
	        		if(datasource.accelerator_details.dictionary_datasource){
	          			mainDatasource['dictionary_data'] = this.manageDatasourceJson(datasource.accelerator_details.dictionary_datasource)
	          			mainDatasource['dictionary_data']['dictionary_conn_str'] = mainDatasource['dictionary_data']['conn_str']
	          			mainDatasource['dictionary_data']['dictionary_data_schema'] = mainDatasource['dictionary_data']['schema_name']
	          			mainDatasource['dictionary_data']['dictionary_data_id'] = mainDatasource['dictionary_data']['datasource_id']
	        		}
	        		if(datasource.accelerator_details.object_librarian_datasource){
	          			mainDatasource['object_librarian_data'] = this.manageDatasourceJson(datasource.accelerator_details.object_librarian_datasource)
	          			mainDatasource['object_librarian_data']['object_librarian_conn_str'] = mainDatasource['object_librarian_data']['conn_str']
	          			mainDatasource['object_librarian_data']['object_librarian_schema'] = mainDatasource['object_librarian_data']['schema_name']
	          			mainDatasource['object_librarian_data']['object_librarian_id'] = mainDatasource['object_librarian_data']['datasource_id']
	        		}
	        		if(datasource.accelerator_details.control_datasource){
	          			mainDatasource['control_data'] = this.manageDatasourceJson(datasource.accelerator_details.control_datasource)
	          			mainDatasource['control_data']['control_conn_str'] = mainDatasource['control_data']['conn_str']
	          			mainDatasource['control_data']['control_data_schema'] = mainDatasource['control_data']['schema_name']
	          			mainDatasource['control_data']['control_conn_str'] = mainDatasource['control_data']['datasource_id']
	        		}
	      		}
                mainDatasource['datasource_index'] = index 
                if(datasource.datasource_index>-1)
                    mainDatasource['datasource_index'] = datasource.datasource_index
                mainDatasource['isArchivistWorkTable'] = datasource.isArchivistWorkTable
                mainDatasource['disabledTableSelection'] = datasource.disabledTableSelection
                mainDatasource['resetArchivistObject'] = datasource.resetArchivistObject
      			return mainDatasource
    		},
	    	manageDatasourceJson(datasourceJson){
	      		datasourceJson['env_id'] = this.$session.get("last_selected_env_id")
	      		datasourceJson['ds_id'] = datasourceJson.datasource_id
	      		datasourceJson['id'] = datasourceJson.datasource_id
	      		datasourceJson['ds_name'] = datasourceJson.datasource_name
	      		datasourceJson['db_type'] = datasourceJson.database_type
	      		datasourceJson['schema'] = datasourceJson.schema_name
	      		datasourceJson['conn_str'] = datasourceJson.connection_string
	      		return datasourceJson
	    	},
	    	generateDS(unformattedDS){
	      		let formatedDS = {"business_data":unformattedDS.business_data,
	        	"engine_data":unformattedDS.business_data,"db_type":unformattedDS.business_data.database_type}
	      		if(unformattedDS.accelerator_details){
	        		formatedDS["acceleration_type"]=unformattedDS.accelerator_details.accelerator_type
	        		formatedDS["control_data"]=unformattedDS.accelerator_details.control_datasource
	        		formatedDS["object_librarian_data"]=unformattedDS.accelerator_details.object_librarian_datasource
	        		formatedDS["dictionary_data"]=unformattedDS.accelerator_details.dictionary_datasource
	      		}
	      		return formatedDS
	    	},
        }
    }
</script>