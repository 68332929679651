<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col cols="8" class="EDC-Col">
                        <v-row class="EDC-Row breadcrumbRow">
                            <v-col class="EDC-Col">
                                <ul class="breadcrumb breadcrumbUL">
                                    <li>
                                    <router-link to="/actionbasedrolelist">Role List</router-link>
                                    </li>
                                    <li>
                                        Manage {{roleTypeForTitle}} Roles
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4">

                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-row align="start" class="EDC-Row EDC-TopRow">
                            <v-col cols="3" class="EDC-Col EDC-ColsSpacing">
                                <v-text-field label="Name of Role" id="txtrolename" v-model="roleName" :disabled="isDisabled" @blur="validateRoleName" class="EDC-UIComponent" hide-details autocomplete="off" dense></v-text-field>
                            </v-col>
                            <v-col cols="3" class="EDC-Col EDC-ColsSpacing EDC-ColDescription">
                                <v-textarea v-model="description" id="txtroledescription" label="Description" :disabled="isDisabled" value rows="1" class="EDC-UIComponent" hide-details dense/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters class="EDC-Row EDC-GridRow">
                    <v-col class="EDC-Col" cols="12">
                        <edc-action-based-rule :modulesFromParent="moduleList" :defaultSelectedItems="defaultSelectedItems"
                        @getSelectedModules="selectedModules=$event"></edc-action-based-rule>
                        
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col" align="right">
                                <vc-button id="btnsave" itemText="Save" @click.native="saveActionBasedRole" :disabled="isDisabled"></vc-button>
                                <vc-button id="btncancel" itemText="Cancel" @click.native="cancel" ></vc-button>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                
                <!-- <v-row class="EDC-Row"> -->
                    <!-- <v-col class="EDC-Col">
                        <v-card class="mainCard">
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                    <template v-for="(moduleItem,moduleIndex) in moduleList">
                                        <v-card :key="moduleItem.module_code+moduleIndex" class="moduleCard">
                                            <v-card-title class="moduleCardTitle">
                                                <v-row class="EDC-Row">
                                                    <v-col class="EDC-Col" align="left">
                                                        <v-checkbox class="radioClass" :label="moduleItem.module_name" v-model="moduleItem.isSelected" :indeterminate="moduleItem.isInterminate" @change="changeSelection(moduleItem)" dense hide-details/>
                                                    </v-col>
                                                    <v-col class="EDC-Col" cols="1" align="right">
                                                        <svgicon  class="svg-icon-grid svg-fill-grid" name="cancel_v2"  :original="true"  title="Close" @click="clearSelection(moduleItem)"></svgicon>
                                                    </v-col>
                                                </v-row>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row class="EDC-Row">
                                                    <v-col class="EDC-Col">
                                                        <template v-for="row in [0,4,8]">
                                                            <v-row class="EDC-Row" :key="moduleItem.module_code+row" v-if="row < moduleItem.group_list.length">
                                                                <template v-for="col in [0,1,2,3]">
                                                                    <v-col class="EDC-Col groupName" align="left" :cols="3" v-if="row+col < moduleItem.group_list.length" :key="moduleItem.group_list[row+col].group_code">
                                                                        <v-checkbox class="radioClass" v-model="moduleItem.group_list[row+col].isSelected" :label="moduleItem.group_list[row+col].group_name" 
                                                                        :disabled="moduleItem.group_list[row+col].is_default" @change="manageIntermediate(moduleItem)" hide-details dense/>
                                                                    </v-col>
                                                                </template>
                                                            </v-row>
                                                        </template>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col> -->
                <!-- </v-row> -->
            </v-col>
            <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbarTimeout" id="edcmessage" top right>
                {{ snackbarText }}
            </v-snackbar>
            <loading-panel :loader="loader"></loading-panel>
        </v-row>
    </v-container>
</template>
<style scoped>
    .customCheckbox >>> label {
        top: 5px !important;
    }
    .v-expansion-panel >>> .v-expansion-panel__header {
        padding-bottom: 0px !important;
    }
    
    .EDC-UIComponent {
        margin: 0px !important;
        padding: 0px !important;
    }
    
    .radioClass >>> label {
        top: 4px !important;
    }
    
    .EDC-TopRow {
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    
    .EDC-ColDescription {
        margin-top: -7px !important;
    }
    .EDC-ColEnv {
        margin-top: -22px !important;
    }
    
    .EDC-GridRow {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    
    .EDC-ButtonRow {
        padding-right: 10px !important;
        padding-bottom: 10px !important;
    }
</style>
<script>
    
    import { post as postToServer } from "./../../../methods/serverCall.js";
    import config from "../../../config.json";
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import {mixinSecurity} from '../../../mixins/securitymixin.js'
    export default{
        name:'ManageActionBasedRole',
       
        components:{

        },
        mixins:[mixinSecurity],
        data() {
            return {

            }
        },
        computed:{
            clientId(){
                return this.$session.get('client_id')
            },
            isDisabled(){
                return false
            },
        },
        mounted() {
            if(this.$route && this.$route.params.roleId){
                this.roleId = this.$route.params.roleId
            }
            this.getGroups()

        },
        methods: {
            validateRoleName() {
                if(!this.roleName.trim()) {
                    this.roleName = "Untitled Role";
                }
            },
            cancel(){
                this.$router.push({name:'actionbasedrolelist'});
            },
        },
    }
</script>
